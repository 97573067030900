<template>
    <path
        d="M5.812 9a15.001 15.001 0 0 0-2.655 2.556A.703.703 0 0 0 3 12m2.812 3a15 15 0 0 1-2.655-2.556A.703.703 0 0 1 3 12m0 0h13m-5-7.472A6 6 0 0 1 21 9v6a6 6 0 0 1-10 4.472"
        fill="none"
        stroke="currentColor"
        stroke-linecap="round"
        stroke-linejoin="round"
        stroke-width="2"
    />
</template>

<script>
import IconSvgBase from "./IconSvgBase";

export default {
    extends: IconSvgBase,
    name: "IconLogout",
};
</script>
