<template>
    <Badge :variant="variant" :text="text" />
</template>

<script>
import Badge, { BadgeVariants } from "@/components/shared/Badge";

export default {
    name: "SensitivityLevel",
    components: { Badge },
    props: {
        sensitive: {
            type: Boolean,
            default: false,
        },
    },
    data: () => ({
        BadgeVariants,
    }),
    computed: {
        variant() {
            if (this.sensitive) {
                return BadgeVariants.ERROR;
            }

            return BadgeVariants.WARNING;
        },
        text() {
            if (this.sensitive) {
                return this.$t("breaches.high");
            }

            return this.$t("breaches.medium");
        },
    },
};
</script>
