var render = function render(){var _vm=this,_c=_vm._self._c;return _c('ValidationObserver',{ref:"employeeSettingsForm",scopedSlots:_vm._u([{key:"default",fn:function({ passes }){return [_c('Alert',{attrs:{"error":_vm.error}}),_c('form',{on:{"submit":function($event){$event.preventDefault();return passes(_vm.saveSettings)}}},[_c('div',{staticClass:"flex flex-col items-stretch justify-evenly",class:_vm.gridClasses},[_c('FormFieldSet',{staticClass:"w-full",class:[{ 'xl:w-1/2': _vm.cols === 2 }]},[_c('FormInput',{attrs:{"type":"text","name":"first_name"},model:{value:(_vm.form.first_name),callback:function ($$v) {_vm.$set(_vm.form, "first_name", $$v)},expression:"form.first_name"}}),_c('FormInput',{attrs:{"type":"text","name":"last_name"},model:{value:(_vm.form.last_name),callback:function ($$v) {_vm.$set(_vm.form, "last_name", $$v)},expression:"form.last_name"}}),_c('FormInput',{attrs:{"type":"email","rules":"required","name":"email"},model:{value:(_vm.form.email),callback:function ($$v) {_vm.$set(_vm.form, "email", $$v)},expression:"form.email"}}),_c('FormInput',{attrs:{"type":"text","name":"department"},model:{value:(_vm.form.department),callback:function ($$v) {_vm.$set(_vm.form, "department", $$v)},expression:"form.department"}})],1),_c('FormFieldSet',{staticClass:"w-full",class:[{ 'xl:w-1/2': _vm.cols === 2 }]},[_c('FormRadioGroup',{attrs:{"options":_vm.statusOptions,"rules":"required","name":"status"},model:{value:(_vm.form.status),callback:function ($$v) {_vm.$set(_vm.form, "status", $$v)},expression:"form.status"}}),(_vm.isInactive && _vm.deactivation_reason)?_c('div',{staticClass:"relative col-span-6"},[_c('div',{staticClass:"flex items-center whitespace-pre-wrap rounded-lg bg-yellow-50/50 p-2 text-xs text-warning-dark ring-1 ring-inset ring-yellow-500/20",domProps:{"innerHTML":_vm._s(
                            _vm.$t(
                                'employees.deactivation_notice.' +
                                    _vm.deactivation_reason,
                                { date: _vm.formatDateTime(_vm.deactivated_at) }
                            )
                        )}})]):_vm._e(),_c('FormSelect',{attrs:{"type":"text","name":"locale","rules":"required"},model:{value:(_vm.form.locale),callback:function ($$v) {_vm.$set(_vm.form, "locale", $$v)},expression:"form.locale"}},_vm._l((_vm.locales),function(locale){return _c('option',{key:locale.value,domProps:{"value":locale.value}},[_vm._v(" "+_vm._s(_vm.$t("locale." + locale.text))+" ")])}),0),_c('div',{staticClass:"col-span-6 flex flex-row justify-end"},[_c('FormButton',{attrs:{"type":"submit","loading":_vm.loading}},[_vm._v(" "+_vm._s(_vm.$t("forms.buttons.save_settings"))+" ")])],1)],1)],1)])]}}])})
}
var staticRenderFns = []

export { render, staticRenderFns }