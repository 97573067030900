<template>
    <WorkspaceSettingsPage>
        <SlideOver
            :active="showPrompt"
            @close="showPrompt = false"
            :gutter="false"
        >
            <h2
                slot="title"
                class="text-base font-semibold leading-6 text-slate-900"
            >
                {{ $t("settings.simulation.services.heading") }}
            </h2>
            <template v-slot:content>
                <SelectServices
                    :loading="updateLoading"
                    @saved="handleServicesSaved"
                />
            </template>
        </SlideOver>
        <div class="flex flex-col lg:flex-row lg:space-x-6">
            <div class="flex flex-1 flex-col xl:w-1/2">
                <div class="card mb-4">
                    <CardHeading
                        :title="
                            $t('settings.simulation.general.heading').toString()
                        "
                        :text="
                            $t('settings.simulation.general.text').toString()
                        "
                    />
                    <div class="p-4">
                        <CampaignSettingsForm @saved="handleSaved" />
                    </div>
                </div>
                <div class="card">
                    <CardHeading
                        :title="
                            $t(
                                'settings.simulation.software_targeting.heading'
                            ).toString()
                        "
                        :text="
                            $t(
                                'settings.simulation.software_targeting.text'
                            ).toString()
                        "
                    >
                        <PremiumFeatureBadge
                            v-if="!hasFeature('app-simulations-software')"
                        />
                    </CardHeading>
                    <div class="relative p-4">
                        <LockedFeatureOverlay
                            v-if="!hasFeature('app-simulations-software')"
                        />
                        <CampaignServicesPreview
                            @edit="showPrompt = true"
                            @saved="handleSaved"
                        />
                    </div>
                </div>
            </div>
            <div class="flex flex-shrink-0 flex-col xl:w-1/2">
                <div class="card">
                    <CardHeading
                        :title="
                            $t(
                                'settings.simulation.ceo_fraud.heading'
                            ).toString()
                        "
                        :text="
                            $t('settings.simulation.ceo_fraud.text').toString()
                        "
                    >
                        <PremiumFeatureBadge
                            v-if="!hasFeature('app-simulations-ceo')"
                        />
                    </CardHeading>
                    <div class="relative p-4">
                        <LockedFeatureOverlay
                            v-if="!hasFeature('app-simulations-ceo')"
                        />
                        <CampaignPeopleForm @saved="handleSaved" />
                    </div>
                </div>
            </div>
        </div>
    </WorkspaceSettingsPage>
</template>

<script>
import Bus from "@/bus";
import CampaignSettingsForm from "@/components/forms/CampaignSettingsForm";
import WorkspaceSettingsPage from "@/components/layout/WorkspaceSettingsPage";
import CardHeading from "@/components/ui/CardHeading";
import CampaignServicesPreview from "@/components/display/campaign/CampaignServicesPreview";
import CampaignPeopleForm from "@/components/forms/CampaignPeopleForm";
import FeatureFlagMixin from "@/mixins/FeatureFlagMixin";
import LockedFeatureOverlay from "@/components/ui/LockedFeatureOverlay";
import PremiumFeatureBadge from "@/components/ui/PremiumFeatureBadge";
import SlideOver from "@/components/ui/SlideOver";
import SelectServices from "@/components/display/campaign/SelectServices";

export default {
    name: "SimulationSettings",
    mixins: [FeatureFlagMixin],
    components: {
        SelectServices,
        SlideOver,
        PremiumFeatureBadge,
        LockedFeatureOverlay,
        CampaignPeopleForm,
        CampaignServicesPreview,
        CardHeading,
        WorkspaceSettingsPage,
        CampaignSettingsForm,
    },
    data: () => ({
        showPrompt: false,
        updateLoading: false,
    }),
    methods: {
        handleSaved() {
            Bus.$emit("flash-message", {
                text: this.$t("settings.saved"),
                type: "success",
            });
        },
        handleServicesSaved() {
            this.showPrompt = false;
            this.handleSaved();
        },
    },
};
</script>
