import Vue from "vue";
import Vuex from "vuex";

import createPersistedState from "vuex-persistedstate";
import {
    getEncryptedStorageItem,
    setEncryptedStorageItem,
    removeStorageItem,
} from "@/utils/storage";

import auth, { AuthStoreNamespacedTypes } from "./modules/auth";
import locale, { LocaleStoreNamespacedTypes } from "./modules/locale";
import layout, { LayoutStoreNamespacedTypes } from "@/store/modules/layout";
import workspace, { WorkspaceStoreNamespacedTypes } from "./modules/workspace";
import statistics, {
    StatisticsStoreNamespacedTypes,
} from "./modules/statistics";
import training, { TrainingStoreNamespacedTypes } from "./modules/training";
import threat, { ThreatStoreNamespacedTypes } from "@/store/modules/threat";
import automation, {
    AutomationStoreNamespacedTypes,
} from "@/store/modules/automation";
import breach, { BreachStoreNamespacedTypes } from "./modules/breach";
import campaign, { CampaignStoreNamespacedTypes } from "./modules/campaign";
import team, { TeamStoreNamespacedTypes } from "./modules/team";
import integration, {
    IntegrationStoreNamespacedTypes,
} from "./modules/integration";
import upload, { UploadStoreNamespacedTypes } from "./modules/upload";
import report, { ReportStoreNamespacedTypes } from "./modules/report";
import domain, { DomainStoreNamespacedTypes } from "./modules/domain";

Vue.use(Vuex);

export default new Vuex.Store({
    modules: {
        auth,
        locale,
        layout,
        workspace,
        statistics,
        training,
        threat,
        automation,
        breach,
        campaign,
        team,
        integration,
        upload,
        report,
        domain,
    },
    plugins: [
        createPersistedState({
            key: "state",
            paths: ["auth", "locale", "workspace"],
            storage: {
                getItem: (key) => getEncryptedStorageItem(key),
                setItem: (key, value) => setEncryptedStorageItem(key, value),
                removeItem: (key) => removeStorageItem(key),
            },
        }),
    ],
    strict: process.env.MIX_APP_ENV !== "production",
});

export const Types = {
    Auth: AuthStoreNamespacedTypes,
    Locale: LocaleStoreNamespacedTypes,
    Layout: LayoutStoreNamespacedTypes,
    Workspace: WorkspaceStoreNamespacedTypes,
    Statistics: StatisticsStoreNamespacedTypes,
    Training: TrainingStoreNamespacedTypes,
    Threat: ThreatStoreNamespacedTypes,
    Automation: AutomationStoreNamespacedTypes,
    Breach: BreachStoreNamespacedTypes,
    Campaign: CampaignStoreNamespacedTypes,
    Team: TeamStoreNamespacedTypes,
    Integration: IntegrationStoreNamespacedTypes,
    Upload: UploadStoreNamespacedTypes,
    Report: ReportStoreNamespacedTypes,
    Domain: DomainStoreNamespacedTypes,
};
