<template>
    <OneCol>
        <Prompt :active="showPrompt" @close="showPrompt = false"
            ><h3 slot="header">{{ $t("automation.create_automation") }}</h3>
            <div slot="content">
                <CreateAutomationForm @stored="handleAutomationStored" /></div
        ></Prompt>
        <SlideOver
            :active="showAutomationPrompt"
            @close="showAutomationPrompt = false"
            :gutter="false"
            :variant="SizeVariants.BIG"
        >
            <h2
                slot="title"
                class="text-base font-semibold leading-6 text-slate-900"
            >
                {{ $t("employees.preview.title") }}
            </h2>
            <template v-slot:content>
                <AutomationPreview @duplicated="handleAutomationDuplicated" />
            </template>
        </SlideOver>
        <Heading
            :title="$t('automation.heading').toString()"
            :text="$t('automation.text').toString()"
            :loading="loading"
        >
            <FormButton @click="showPrompt = true">{{
                $t("automation.create_automation")
            }}</FormButton>
        </Heading>
        <AutomationTable
            :rows="rows"
            :meta="meta"
            :loading="loading"
            :error="error"
            @refresh="refreshData"
            @emit="handleOpen"
            class="card"
        />
    </OneCol>
</template>

<script>
import { AutomationStoreNamespacedTypes } from "@/store/modules/automation";

import { getProperty } from "@/utils/object";

import OneCol from "@/components/layout/OneCol";
import Heading from "@/components/layout/Heading";
import AutomationTable from "@/components/display/automation/AutomationTable";
import Prompt from "@/components/ui/Prompt";
import CreateAutomationForm from "@/components/forms/CreateAutomationForm";
import FormButton from "@/components/forms/fields/FormButton";
import SlideOver, { SizeVariants } from "@/components/ui/SlideOver";
import AutomationPreview from "@/components/display/automation/AutomationPreview";

export default {
    name: "AutomationIndex",
    components: {
        AutomationPreview,
        SlideOver,
        FormButton,
        CreateAutomationForm,
        Prompt,
        AutomationTable,
        Heading,
        OneCol,
    },
    data: () => ({
        showPrompt: false,
        automation: null,
        SizeVariants,
        showAutomationPrompt: false,
    }),
    computed: {
        /**
         * @type {Boolean}
         */
        loading() {
            return this.$store.getters[
                AutomationStoreNamespacedTypes.getters.AUTOMATION_LOADING
            ]();
        },

        /**
         * @type {Object}
         */
        error() {
            return this.$store.getters[
                AutomationStoreNamespacedTypes.getters.AUTOMATION_ERROR
            ]();
        },

        /**
         * @type {Array}
         */
        automations() {
            return this.$store.getters[
                AutomationStoreNamespacedTypes.getters.AUTOMATIONS
            ]();
        },

        /**
         * @type {Array}
         */
        rows() {
            return getProperty(this.automations, "data", []);
        },

        /**
         * @type {Object}
         */
        meta() {
            return getProperty(this.automations, "meta", null);
        },
    },
    methods: {
        refreshData(payload = {}) {
            this.$store.dispatch(
                AutomationStoreNamespacedTypes.actions.GET_AUTOMATIONS,
                payload
            );
        },
        handleAutomationStored() {
            this.refreshData();
            this.showPrompt = false;
        },
        handleOpen(automation) {
            this.$store
                .dispatch(
                    AutomationStoreNamespacedTypes.actions.RESET_AUTOMATION
                )
                .then(() => {
                    this.$store
                        .dispatch(
                            AutomationStoreNamespacedTypes.actions
                                .GET_AUTOMATION,
                            getProperty(automation, "id")
                        )
                        .then(() => {
                            this.showAutomationPrompt = true;
                        });
                });
        },
        handleAutomationDuplicated() {
            this.refreshData();
            this.showAutomationPrompt = false;
        },
    },
    beforeCreate() {
        this.$store.commit(
            AutomationStoreNamespacedTypes.mutations.SET_AUTOMATION_LOADING,
            true
        );
    },
    mounted() {
        this.refreshData();

        this.$store.dispatch(
            AutomationStoreNamespacedTypes.actions.GET_ACTION_DATA
        );
    },
};
</script>
