<template>
    <div :class="{ relative: loading }">
        <div v-if="loading" class="flex flex-row space-x-2 px-3 py-2">
            <div
                class="h-8 w-44 animate-pulse rounded-full border-0 bg-slate-50/50 ring-1 ring-inset ring-slate-500/20 hover:ring-slate-500/30"
            ></div>
            <div
                class="h-8 w-56 animate-pulse rounded-full border-0 bg-slate-50/50 ring-1 ring-inset ring-slate-500/20 hover:ring-slate-500/30"
            ></div>
        </div>
        <div v-else class="flex flex-row space-x-2 px-3 py-2">
            <slot />
        </div>
    </div>
</template>

<script>
export default {
    name: "FilterLoading",
    props: {
        loading: {
            type: Boolean,
            default: true,
        },
    },
};
</script>
