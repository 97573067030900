<template>
    <div class="group-scope relative flex h-[300px] overflow-x-auto px-2 pt-6">
        <div class="group relative flex flex-col">
            <div
                class="relative flex w-full justify-end px-4"
                style="flex: 1 1 100%"
            >
                <div
                    class="absolute inset-0 flex flex-col justify-between py-px"
                >
                    <div class="relative flex w-full justify-end">
                        <div
                            class="flex-1 border-b border-dashed border-slate-300"
                        ></div>
                    </div>
                    <div class="relative flex w-full justify-end">
                        <div
                            class="absolute mr-2 flex-1 -translate-x-2 -translate-y-1/2 transform text-right text-xs text-slate-400"
                        >
                            50
                        </div>
                        <div
                            class="w-4 border-b border-dashed border-slate-300"
                        ></div>
                    </div>
                    <div class="relative flex w-full justify-end">
                        <div
                            class="absolute mr-2 flex-1 -translate-x-2 -translate-y-1/2 transform text-right text-xs text-slate-400"
                        >
                            0
                        </div>
                        <div
                            class="w-4 border-b border-dashed border-slate-300"
                        ></div>
                    </div>
                </div>
                <div class="relative mt-px">
                    <div
                        class="inline-flex -translate-y-1/2 transform items-center space-x-1 rounded bg-slate-200 px-2 py-0.5 text-sm text-slate-800"
                    >
                        <span>100</span>
                        <div
                            class="absolute right-4 top-1 z-[-1] h-4 w-4 translate-x-[17px] rotate-45 transform rounded-sm bg-slate-200 text-slate-800"
                        ></div>
                    </div>
                </div>
            </div>
            <div
                class="relative mt-4 h-10 w-[100px] min-w-full rounded-l-md border-b border-l border-t border-slate-200 bg-slate-100"
            ></div>
        </div>
        <FunnelChartLoadingItem
            v-for="(x, index) in loadingArray"
            :key="'loading-' + x.key"
            :index="index"
        />
    </div>
</template>

<script>
import FunnelChartLoadingItem from "@/components/charts/FunnelChart/partials/FunnelChartLoadingItem";

export default {
    name: "FunnelChartLoading",
    components: { FunnelChartLoadingItem },
    data: () => ({
        loadingArray: [
            { key: 1, value: 16 },
            { key: 2, value: 12 },
            { key: 3, value: 8 },
            { key: 4, value: 8 },
            { key: 5, value: 2 },
        ],
    }),
};
</script>
