<template>
    <EmployeePage>
        <ContentLoading :loading="loading">
            <div class="card p-4">
                <EmployeeSettingsForm @saved="handleSaved" />
            </div>
        </ContentLoading>
    </EmployeePage>
</template>

<script>
import { TeamStoreNamespacedTypes } from "@/store/modules/team";

import EmployeePage from "@/components/layout/EmployeePage";
import EmployeeSettingsForm from "@/components/forms/EmployeeSettingsForm";
import Bus from "@/bus";
import ContentLoading from "@/components/ui/ContentLoading";

export default {
    name: "EmployeeSingle",
    components: {
        ContentLoading,
        EmployeeSettingsForm,
        EmployeePage,
    },
    computed: {
        /**
         * The loading state for the API request.
         * @type {Boolean}
         */
        loading() {
            return this.$store.getters[
                TeamStoreNamespacedTypes.getters.LOADING
            ]();
        },
    },
    methods: {
        /**
         * Handle the saved event from the form.
         */
        handleSaved() {
            Bus.$emit("flash-message", {
                text: this.$t("settings.saved"),
                type: "success",
            });
        },
    },
    mounted() {
        this.$store.dispatch(
            TeamStoreNamespacedTypes.actions.GET_EMPLOYEE,
            this.$route.params.id
        );
    },
};
</script>
