/**
 * Threshold data mixin
 */
import { IconEnums } from "@/utils/icons";

export default {
    props: {
        upperThreshold: {
            type: Number,
            default: 0,
        },
        lowerThreshold: {
            type: Number,
            default: 0,
        },
    },
    data: () => ({
        IconEnums,
    }),
    computed: {
        threshold() {
            if (this.upperThreshold > 0) {
                return "upper";
            }

            if (this.lowerThreshold > 0) {
                return "lower";
            }

            return "default";
        },
        hasThreshold() {
            return this.threshold !== "default";
        },
        isAboveUpperThreshold() {
            return this.value >= this.upperThreshold;
        },
        isBelowLowerThreshold() {
            return this.value <= this.lowerThreshold;
        },
        showAlert() {
            if (this.threshold === "upper") {
                return this.isAboveUpperThreshold;
            }

            if (this.threshold === "lower") {
                return this.isBelowLowerThreshold;
            }

            return false;
        },

        /**
         * The total number of quizzes taken
         * @returns {string}
         */
        badgeClass() {
            return this.showAlert
                ? "bg-emerald-50/50 text-success-normal ring-emerald-500/20"
                : "bg-rose-50/50 text-error-normal ring-rose-500/20";
        },
        badgeIcon() {
            return this.showAlert ? IconEnums.SIGNAL : IconEnums.ALERT;
        },
    },
};
