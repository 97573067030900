<template>
    <div class="relative flex h-full min-h-screen flex-col bg-slate-100">
        <FlashMessage />
        <Navigation v-if="isLoggedIn" />
        <div
            :class="{ 'px-4 pb-4 pt-5 xl:pt-[4.25rem]': isLoggedIn }"
            class="relative flex h-full flex-col overflow-hidden"
        >
            <main
                :class="{ 'max-w-screen-3xl': isLoggedIn }"
                class="mx-auto w-full"
            >
                <router-view />
            </main>
        </div>
        <div
            v-if="isImpersonating"
            class="fixed bottom-0 right-0 flex items-center justify-center rounded-tl bg-slate-800 p-2 text-xs text-green-500 shadow-xl"
        >
            <div class="blink size-2 mr-2 rounded-full bg-green-600"></div>
            <span class="mr-1 italic">IM session: {{ user.email }}</span>
        </div>
    </div>
</template>

<script>
import { AuthStoreNamespacedTypes } from "@/store/modules/auth";
import { LocaleStoreNamespacedTypes } from "@/store/modules/locale";
import { LayoutStoreNamespacedTypes } from "@/store/modules/layout";

import FlashMessage from "@/components/ui/FlashMessage";
import Navigation from "@/components/layout/nav/Navigation";
import { redirectTo } from "@/router";

export default {
    components: { Navigation, FlashMessage },
    data: () => ({
        layout: "default",
    }),
    computed: {
        /**
         * The authentication state for the user model
         * @type {Object}
         */
        isLoggedIn() {
            return this.$store.getters[
                AuthStoreNamespacedTypes.getters.IS_LOGGED_IN
            ]();
        },

        /**
         * The user model
         * @type {Boolean}
         */
        isManager() {
            return this.$store.getters[
                AuthStoreNamespacedTypes.getters.IS_MANAGER
            ]();
        },

        /**
         * The impersonation state for the user model
         * @type {Boolean}
         */
        isImpersonating() {
            return this.$store.getters[
                AuthStoreNamespacedTypes.getters.IS_IMPERSONATING
            ]();
        },

        /**
         * The user model
         * @type {Object}
         */
        user() {
            return this.$store.getters[AuthStoreNamespacedTypes.getters.USER]();
        },
    },
    watch: {
        $route: {
            handler(newRoute) {
                if (
                    typeof newRoute.meta.auth !== "undefined" &&
                    newRoute.matched.some((record) => record.meta.auth) &&
                    !this.isLoggedIn
                ) {
                    redirectTo("login");
                    return;
                }

                if (
                    typeof newRoute.meta.auth !== "undefined" &&
                    newRoute.matched.some((record) => !record.meta.auth) &&
                    this.isLoggedIn
                ) {
                    redirectTo("dashboard.overview");
                }

                if (
                    typeof newRoute.meta.manager !== "undefined" &&
                    newRoute.matched.some((record) => record.meta.manager) &&
                    !this.isManager
                ) {
                    redirectTo("dashboard.overview");
                }
            },
            immediate: true,
        },
        layout: {
            handler(newLayout) {
                this.applyLayout(newLayout);
            },
            immediate: true,
        },
    },
    methods: {
        setLocale() {
            this.$store.dispatch(
                LocaleStoreNamespacedTypes.actions.UPDATE_LOCALE,
                null
            );
        },
        applyLayout(layout) {
            const showMobileNavigation = layout === "mobile-navigation";
            this.$store.dispatch(
                LayoutStoreNamespacedTypes.actions.SET_VISIBLE_MULTIPLE,
                {
                    "mobile-navigation": showMobileNavigation,
                }
            );
        },
    },
    created() {
        if (this.isLoggedIn) {
            this.$store.dispatch(
                AuthStoreNamespacedTypes.actions.CHECK_CURRENT_SESSION
            );
        }

        this.setLocale();
    },
    mounted() {
        this.$store.dispatch(
            AuthStoreNamespacedTypes.actions.FETCH_CSRF_COOKIE
        );
    },
};
</script>
