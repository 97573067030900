<template>
    <ValidationProvider
        tag="div"
        class="form-field form-field-toggle"
        :vid="vid"
        :rules="rules"
        :name="name || label"
        v-slot="{ errors, ariaMsg }"
    >
        <button
            :disabled="disabled"
            type="button"
            class="relative mr-2.5 inline-flex h-6 w-10 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent p-0.5 transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
            @click="innerValue = !innerValue"
            :class="{
                'bg-slate-600': disabled,
                'bg-indigo-600': innerValue && !disabled,
                'bg-slate-200': !innerValue && !disabled,
            }"
        >
            <span class="sr-only">{{ label || name }}</span>
            <span
                aria-hidden="true"
                class="pointer-events-none inline-block h-4 w-4 translate-x-0 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out"
                :class="{
                    'translate-x-4': innerValue,
                    'translate-x-0': !innerValue,
                }"
            ></span>
        </button>
        <div @click="toggle">
            <p
                class="mb-1 block cursor-pointer text-sm font-medium text-slate-900"
            >
                {{ label || name }}
            </p>
            <span
                class="block text-sm text-red-600"
                v-bind="ariaMsg"
                v-if="errors[0]"
                >{{ errors[0] }}</span
            >
            <p
                v-else-if="help"
                class="block cursor-pointer text-sm text-slate-500"
            >
                {{ help }}
            </p>
        </div>
    </ValidationProvider>
</template>

<script>
import FieldMixin from "./FieldMixin";

export default {
    name: "FormToggle",
    mixins: [FieldMixin],
    props: {
        type: {
            type: String,
            default: "button",
        },
    },
    methods: {
        toggle() {
            if (!this.disabled) {
                this.innerValue = !this.innerValue;
            }
        },
    },
};
</script>
