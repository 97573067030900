<template>
    <Badge :variant="variant" :text="text" />
</template>

<script>
import Badge, { BadgeVariants } from "@/components/shared/Badge";

export default {
    components: { Badge },
    props: {
        open: {
            type: Boolean,
            default: false,
        },
        click: {
            type: Boolean,
            default: false,
        },
    },
    data: () => ({
        BadgeVariants,
    }),
    computed: {
        failed() {
            return this.open && this.click;
        },
        text() {
            if (this.failed) {
                return this.$t("simulations.clicked");
            }

            if (this.open) {
                return this.$t("simulations.opened");
            }

            return this.$t("simulations.received");
        },

        variant() {
            if (this.failed) {
                return BadgeVariants.ERROR;
            }

            if (this.open) {
                return BadgeVariants.WARNING;
            }

            return BadgeVariants.DEFAULT;
        },
    },
};
</script>
