<template>
    <ValidationObserver ref="verifyDomainForm" v-slot="{ passes }">
        <form
            @submit.prevent="passes(verifyDomain)"
            class="flex flex-row justify-end gap-4"
        >
            <FormButton type="submit" variant="secondary" :loading="loading">
                {{ $t("forms.buttons.verify") }}
            </FormButton>
        </form>
    </ValidationObserver>
</template>

<script>
import { DomainStoreNamespacedTypes } from "@/store/modules/domain";

import { ValidationObserver } from "vee-validate";
import Bus from "@/bus";
import FormButton from "@/components/forms/fields/FormButton";

export default {
    components: {
        FormButton,
        ValidationObserver,
    },
    props: {
        domain: {
            type: String,
            required: true,
        },
    },
    data: () => ({
        loading: false,
    }),
    methods: {
        /**
         * Sends domain details to the server.
         */
        verifyDomain() {
            this.loading = true;
            this.$store
                .dispatch(
                    DomainStoreNamespacedTypes.actions.VERIFY_DOMAIN,
                    this.domain
                )
                .then(() => {
                    this.loading = false;

                    if (!this.error) {
                        Bus.$emit("flash-message", {
                            text: "Verification email sent",
                            type: "success",
                        });
                    }
                });
        },
    },
};
</script>
