<template>
    <Page
        :heading="$t('register.heading').toString()"
        :text="$t('register.text').toString()"
    >
        <RegisterForm @authenticated="handleAuthenticated" />
        <div slot="footer">
            <p class="max-w mt-8 text-center text-slate-600">
                {{ $t("register.login.text") }}
                <router-link
                    :to="{ name: 'login' }"
                    class="text-base font-medium text-indigo-600"
                    >{{ $t("register.login.link") }}</router-link
                >
            </p>
        </div>
    </Page>
</template>

<script>
import Page from "@/components/layout/Page";
import RegisterForm from "@/components/forms/RegisterForm";

export default {
    name: "RegisterPage",
    components: { Page, RegisterForm },
    methods: {
        handleAuthenticated() {
            this.$router.push({ name: "onboarding" });
        },
    },
};
</script>
