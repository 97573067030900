<template>
    <div
        @click="$emit('input', id)"
        class="relative flex cursor-pointer rounded-xl border-2 bg-white p-2 transition-colors duration-150 focus:outline-none"
        :class="[
            { 'border-indigo-600': selected },
            { 'border-slate-200 hover:border-slate-400': !selected },
        ]"
    >
        <div class="absolute inset-0 z-10 bg-transparent"></div>
        <div class="flex flex-1">
            <div class="flex items-center gap-x-3">
                <div
                    class="flex h-10 w-10 items-center overflow-hidden rounded-full border border-slate-300 bg-white"
                >
                    <img :src="image" class="size-10" :alt="name" />
                </div>
                <div class="flex flex-col">
                    <span
                        :id="id"
                        class="block text-sm font-medium text-slate-900"
                        >{{ name }}</span
                    >
                    <div class="mt-1 flex flex-row items-center gap-x-1.5">
                        <Icon
                            :icon="IconEnums.TAG"
                            class="h-4 w-4 text-slate-400"
                        />
                        <span class="text-sm text-slate-400">
                            {{
                                $t(
                                    "settings.simulation.services.target." +
                                        target
                                )
                            }}</span
                        >
                    </div>
                </div>
            </div>
        </div>
        <svg
            v-if="selected"
            class="h-5 w-5 text-indigo-600"
            viewBox="0 0 20 20"
            fill="currentColor"
            aria-hidden="true"
        >
            <path
                fill-rule="evenodd"
                d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.857-9.809a.75.75 0 00-1.214-.882l-3.483 4.79-1.88-1.88a.75.75 0 10-1.06 1.061l2.5 2.5a.75.75 0 001.137-.089l4-5.5z"
                clip-rule="evenodd"
            />
        </svg>
    </div>
</template>

<script>
import { WorkspaceStoreNamespacedTypes } from "@/store/modules/workspace";
import { getProperty } from "@/utils/object";
import { IconEnums } from "@/utils/icons";
import Icon from "@/components/icons/BaseIcon";

export default {
    name: "CampaignService",
    props: {
        service: {
            type: Object,
            required: true,
        },
    },
    components: {
        Icon,
    },
    data: () => ({
        IconEnums,
    }),
    computed: {
        /**
         * @type {Array}
         */
        selectedServices() {
            return this.$store.getters[
                WorkspaceStoreNamespacedTypes.getters.CAMPAIGN_SELECTED_SERVICES
            ]();
        },

        /**
         * @return {string}
         */
        id() {
            return getProperty(this.service, "id");
        },

        /**
         * @return {string}
         */
        name() {
            return getProperty(this.service, "name");
        },

        /**
         * @return {string}
         */
        image() {
            return getProperty(this.service, "image");
        },

        /**
         * @return {string}
         */
        target() {
            return getProperty(this.service, "target");
        },

        /**
         * @return {boolean}
         */
        selected() {
            return this.selectedServices.includes(this.id);
        },
    },
};
</script>
