<template>
    <OneCol>
        <DynamicTabs :tabs="tabs" />
        <Heading
            :title="$t('training.heading').toString()"
            :text="$t('training.text').toString()"
            :loading="loading"
        >
            <FormInput
                v-model="query"
                id="search"
                type="search"
                :placeholder="$t('training.filter.search').toString()"
            />
        </Heading>
        <div class="card">
            <FilterLoading :loading="loading">
                <SelectFilter v-model="type" name="type">
                    <option v-for="type in types" :key="type" :value="type">
                        {{ $t(`training.filter.${type}`) }}
                    </option>
                </SelectFilter>
            </FilterLoading>
            <TrainingTable
                :rows="rows"
                :meta="meta"
                :loading="loading"
                :error="error"
                @refresh="handleRefresh"
            />
        </div>
    </OneCol>
</template>

<script>
import { LocaleStoreNamespacedTypes } from "@/store/modules/locale";
import { TrainingStoreNamespacedTypes } from "@/store/modules/training";

import { getProperty } from "@/utils/object";

import OneCol from "@/components/layout/OneCol";
import Heading from "@/components/layout/Heading";
import TrainingTable from "@/components/display/training/TrainingTable";
import FormInput from "@/components/forms/fields/FormInput";
import DynamicTabs from "@/components/tabs/DynamicTabs";
import FilterLoading from "@/components/tables/FilterLoading";
import SelectFilter from "@/components/forms/fields/SelectFilter";

export default {
    name: "LessonIndex",
    components: {
        SelectFilter,
        FilterLoading,
        DynamicTabs,
        FormInput,
        TrainingTable,
        Heading,
        OneCol,
    },
    data: () => ({
        query: "",
        type: "course",
        types: ["course", "video"],
    }),
    computed: {
        /**
         * Navigation tabs.
         * @type {Array}
         */
        tabs() {
            return [
                {
                    text: "lessons",
                    route: { name: "training.index" },
                },
                {
                    text: "deadlines",
                    route: { name: "training.deadlines" },
                },
            ];
        },

        /**
         * Current locale.
         * @type {String}
         */
        locale() {
            return this.$store.getters[
                LocaleStoreNamespacedTypes.getters.LOCALE
            ]();
        },

        /**
         * @type {Boolean}
         */
        loading() {
            return this.$store.getters[
                TrainingStoreNamespacedTypes.getters.LESSON_LOADING
            ]();
        },

        /**
         * @type {Object}
         */
        error() {
            return this.$store.getters[
                TrainingStoreNamespacedTypes.getters.LESSON_ERROR
            ]();
        },

        /**
         * @type {Array}
         */
        lessons() {
            return this.$store.getters[
                TrainingStoreNamespacedTypes.getters.LESSONS
            ]();
        },

        /**
         * @type {Array}
         */
        rows() {
            return getProperty(this.lessons, "data", []);
        },

        /**
         * @type {Object}
         */
        meta() {
            return getProperty(this.lessons, "meta", null);
        },
    },
    watch: {
        locale() {
            this.query = "";
            this.handleRefresh();
        },
        query() {
            this.handleRefresh();
        },
        type() {
            this.query = "";
            this.handleRefresh();
        },
    },
    methods: {
        handleRefresh(payload = {}) {
            const data = {
                locale: this.locale,
                type: this.type,
                query: this.query,
                ...payload,
            };

            this.$store.dispatch(
                TrainingStoreNamespacedTypes.actions.GET_LESSONS,
                data
            );
        },
    },
    mounted() {
        this.handleRefresh();
    },
};
</script>
