<template>
    <div class="relative">
        <SlideOver
            :active="showReminderPrompt"
            @close="showReminderPrompt = false"
            :gutter="false"
        >
            <h2
                slot="title"
                class="text-base font-semibold leading-6 text-slate-900"
                v-text="$t('employees.preview.title').toString()"
            ></h2>
            <template v-slot:content>
                <div class="flex flex-col justify-items-end px-4">
                    <div class="rounded-xl bg-white p-4">
                        <EmailPreview
                            :email="reminderEmail"
                            class="mb-4"
                            height="h-96"
                        />
                        <FormButton
                            :loading="sendLoading"
                            :variant="ButtonVariants.PRIMARY"
                            @click="sendTraining"
                        >
                            {{ $t("forms.buttons.send_training") }}
                        </FormButton>
                    </div>
                </div>
            </template>
        </SlideOver>
        <div class="mb-4 sm:flex sm:items-center sm:justify-between">
            <div class="sm:w-0 sm:flex-1">
                <h1
                    class="flex justify-start align-middle text-lg font-bold text-slate-800"
                    v-text="full_name || $t('navigation.loading').toString()"
                ></h1>
                <p
                    class="mt-1 flex flex-row items-center overflow-hidden overflow-ellipsis text-sm text-slate-600"
                >
                    <span class="flex flex-row items-center">
                        <router-link
                            :to="{ name: 'employee.index' }"
                            class="mr-2 flex flex-row items-center"
                        >
                            <Icon :icon="IconEnums.BACK" class="mr-2" />
                            {{ $t("navigation.go_back") }}
                        </router-link>
                        <span class="mr-2">&middot;</span>
                    </span>
                    <span v-text="department || email"></span>
                </p>
            </div>
            <div
                class="flex items-center gap-4 sm:ml-6 sm:mt-0 sm:flex-shrink-0 sm:justify-start"
            >
                <FormButton
                    v-if="isManager"
                    :variant="ButtonVariants.SECONDARY"
                    @click="showReminderPrompt = true"
                >
                    {{ $t("forms.buttons.send_training") }}
                </FormButton>
            </div>
        </div>
        <DynamicTabs :tabs="tabs" />
        <Alert :error="error" class="mb-4" />
        <slot />
    </div>
</template>

<script>
import { AuthStoreNamespacedTypes } from "@/store/modules/auth";
import { TeamStoreNamespacedTypes } from "@/store/modules/team";

import { getProperty } from "@/utils/object";

import Alert from "@/components/ui/Alert";
import Bus from "@/bus";
import { IconEnums } from "@/utils/icons";
import FormButton, {
    ButtonVariants,
} from "@/components/forms/fields/FormButton";
import DynamicTabs from "@/components/tabs/DynamicTabs";
import FeatureFlagMixin from "@/mixins/FeatureFlagMixin";
import SlideOver from "@/components/ui/SlideOver";
import EmailPreview from "@/components/ui/EmailPreview";
import Icon from "@/components/icons/BaseIcon";

export default {
    name: "EmployeePage",
    mixins: [FeatureFlagMixin],
    components: {
        Icon,
        EmailPreview,
        SlideOver,
        DynamicTabs,
        FormButton,
        Alert,
    },
    data: () => ({
        IconEnums,
        ButtonVariants,
        sendLoading: false,
        showReminderPrompt: false,
    }),
    watch: {
        $route: {
            handler(newRoute) {
                this.$store.dispatch(
                    TeamStoreNamespacedTypes.actions.GET_EMPLOYEE,
                    newRoute.params.id
                );
            },
        },
    },
    computed: {
        /**
         * The user model
         * @type {Boolean}
         */
        isManager() {
            return this.$store.getters[
                AuthStoreNamespacedTypes.getters.IS_MANAGER
            ]();
        },

        /**
         * @type {Object}
         */
        employee() {
            return this.$store.getters[
                TeamStoreNamespacedTypes.getters.EMPLOYEE
            ]();
        },

        /**
         * @type {string}
         */
        employeeId() {
            return getProperty(this.employee, "id", "");
        },

        /**
         * @type {string|null}
         */
        department() {
            return getProperty(this.employee, "department");
        },

        /**
         * @type {string|null}
         */
        email() {
            return getProperty(this.employee, "email");
        },

        /**
         * @type {Array}
         */
        tabs() {
            if (!this.employeeId) {
                return [
                    {
                        text: "loading",
                        route: {},
                    },
                ];
            }

            let tabs = [
                {
                    text: "overall",
                    route: {
                        name: "employee.single.index",
                        params: { id: this.employeeId },
                    },
                },
            ];

            if (this.hasFeature("app-simulations")) {
                tabs.push({
                    text: "simulations",
                    route: {
                        name: "employee.single.simulations",
                        params: { id: this.employeeId },
                    },
                });
            }

            if (this.hasFeature("app-breaches")) {
                tabs.push({
                    text: "breaches",
                    route: {
                        name: "employee.single.breaches",
                        params: { id: this.employeeId },
                    },
                });
            }

            tabs.push({
                text: "groups",
                route: {
                    name: "employee.single.groups",
                    params: { id: this.employeeId },
                },
            });

            if (this.isManager) {
                tabs.push({
                    text: "settings",
                    route: {
                        name: "employee.single.settings",
                        params: { id: this.employeeId },
                    },
                });
            }

            return tabs;
        },

        /**
         * The error state of the API request.
         * @type {Object}
         */
        error() {
            return this.$store.getters[
                TeamStoreNamespacedTypes.getters.ERROR
            ]();
        },

        /**
         * @type {String}
         */
        full_name() {
            return getProperty(this.employee, "full_name", "Loading...");
        },

        reminderEmail() {
            return {
                from_name: "Moxso",
                sent_at: new Date(),
                subject: this.$t("employees.reminder.subject"),
                template: {
                    format: "text",
                    html: this.$t("employees.reminder.html"),
                },
            };
        },
    },
    methods: {
        sendTraining() {
            this.sendLoading = true;

            this.$store
                .dispatch(
                    TeamStoreNamespacedTypes.actions.SEND_TRAINING,
                    this.$route.params.id
                )
                .then(() => {
                    Bus.$emit("flash-message", {
                        text: this.$t("employees.training_sent"),
                        type: "success",
                    });
                })
                .finally(() => {
                    this.showReminderPrompt = false;
                    this.sendLoading = false;
                });
        },
    },
};
</script>
