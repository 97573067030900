var render = function render(){var _vm=this,_c=_vm._self._c;return _c('li',[_c(_vm.tagName,{tag:"component",class:[
            'group relative flex w-full items-center rounded-xl px-2.5 py-1.5 text-sm font-semibold leading-6 transition focus:outline-none',
            { 'bg-slate-100 text-slate-800': _vm.isActive },
            {
                'bg-transparent text-slate-600 hover:bg-slate-100 hover:text-slate-800':
                    !_vm.isActive,
            },
        ],attrs:{"to":_vm.route,"active-class":"bg-slate-100 text-slate-800"},on:{"click":_vm.onClick}},[(_vm.hasCount)?_c('div',{staticClass:"absolute left-0.5 top-2 ml-1 h-1 w-1"},[_c('div',{staticClass:"absolute h-1 w-1 animate-ping rounded-full bg-red-500"}),_c('div',{staticClass:"h-1 w-1 rounded-full bg-red-500"})]):_vm._e(),(_vm.icon)?_c('Icon',{staticClass:"h-4.5 w-4.5",class:{ 'mr-2': _vm.text },attrs:{"icon":_vm.icon}}):_vm._e(),_vm._v(" "+_vm._s(_vm.text)+" ")],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }