<template>
    <ValidationProvider
        tag="div"
        class="form-field form-field-password"
        :vid="vid"
        :rules="rules"
        :name="name || label"
        v-slot="{ errors, ariaInput, ariaMsg }"
    >
        <label
            class="mb-2 block text-sm font-medium text-slate-900"
            @click="$refs.input.focus()"
            :for="id || name"
        >
            {{ label || name }}
        </label>
        <input
            class="min-w-form-field block w-full rounded-md border-slate-300 focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
            :class="{ 'is-invalid': errors[0] }"
            :id="id || name"
            :type="type"
            ref="input"
            v-model="innerValue"
            v-bind="ariaInput"
            autocomplete="off"
        />
        <div class="-mx-1 mt-3 flex">
            <div class="w-1/5 px-1" v-for="(num, index) in 5" :key="num">
                <div
                    class="h-1 rounded-xl transition-colors"
                    :class="
                        index < passwordScore
                            ? passwordScore <= 2
                                ? 'bg-red-400'
                                : passwordScore <= 4
                                ? 'bg-yellow-400'
                                : 'bg-green-500'
                            : 'bg-slate-200'
                    "
                ></div>
            </div>
        </div>
        <span
            class="mt-3 text-sm text-red-600"
            v-bind="ariaMsg"
            v-if="errors[0]"
            >{{ errors[0] }}</span
        >
    </ValidationProvider>
</template>

<script>
import FieldMixin from "./FieldMixin";

export default {
    name: "FormPassword",
    mixins: [FieldMixin],
    props: {
        type: {
            type: String,
            default: "password",
        },
    },
    computed: {
        passwordScore() {
            let length = this.innerValue ? this.innerValue.length : 0;

            if (length === 0) {
                return 0;
            } else if (length < 4) {
                return 1;
            } else if (length < 6) {
                return 2;
            } else if (length < 8) {
                return 3;
            } else if (length < 12) {
                return 4;
            } else {
                return 5;
            }
        },
    },
};
</script>
