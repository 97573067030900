<template>
    <ValidationObserver ref="CreateAutomationForm" v-slot="{ passes }">
        <Alert :error="error" />
        <form @submit.prevent="passes(createAutomation)">
            <div class="flex flex-col">
                <FormFieldSet>
                    <FormInput
                        v-model="form.name"
                        type="text"
                        rules="required"
                        name="name"
                    />
                    <FormSelect v-model="form.group_id" name="group">
                        <option
                            v-for="group in rows"
                            :key="group.id"
                            :value="group.id"
                        >
                            {{ group.name }}
                        </option>
                    </FormSelect>
                    <div class="flex justify-end">
                        <FormButton type="submit" :loading="loading">
                            {{ $t("automation.create_automation") }}
                        </FormButton>
                    </div>
                </FormFieldSet>
            </div>
        </form>
    </ValidationObserver>
</template>

<script>
import { AutomationStoreNamespacedTypes } from "@/store/modules/automation";
import { TeamStoreNamespacedTypes } from "@/store/modules/team";

import { getProperty } from "@/utils/object";

import { ValidationObserver } from "vee-validate";
import Alert from "@/components/ui/Alert";
import FormInput from "@/components/forms/fields/FormInput";
import FormFieldSet from "@/components/forms/fields/FormFieldSet";
import FormButton from "@/components/forms/fields/FormButton";
import FormSelect from "@/components/forms/fields/FormSelect";

export default {
    name: "CreateAutomationForm",
    components: {
        FormSelect,
        FormButton,
        FormFieldSet,
        Alert,
        ValidationObserver,
        FormInput,
    },
    data: () => ({
        loading: false,
        form: {
            name: null,
            group_id: null,
        },
    }),
    computed: {
        /**
         * @type {Object}
         */
        error() {
            return this.$store.getters[
                AutomationStoreNamespacedTypes.getters.AUTOMATION_ERROR
            ]();
        },
        groups() {
            return this.$store.getters[
                TeamStoreNamespacedTypes.getters.GROUPS
            ]();
        },
        rows() {
            return getProperty(this.groups, "data", []);
        },
    },
    methods: {
        /**
         * Sends authentication credentials to the server.
         */
        createAutomation() {
            this.loading = true;
            this.$store
                .dispatch(
                    AutomationStoreNamespacedTypes.actions.CREATE_AUTOMATION,
                    this.form
                )
                .then(() => {
                    this.loading = false;
                    if (!this.error) {
                        this.$emit("stored");
                    }
                });
        },
    },
    mounted() {
        this.$store.dispatch(TeamStoreNamespacedTypes.actions.GET_ALL_GROUPS);
    },
};
</script>
