<template>
    <path
        fill="none"
        stroke="currentColor"
        stroke-linecap="round"
        stroke-linejoin="round"
        stroke-width="2"
        d="M8.03 4a20.76 20.76 0 0 0-3.885 3.679.64.64 0 0 0-.145.404m4.03 4.084a20.762 20.762 0 0 1-3.885-3.68A.64.64 0 0 1 4 8.084m0 0h10.963c2.782 0 5.037 2.22 5.037 4.959C20 15.78 17.745 18 14.963 18H12"
    />
</template>

<script>
import IconSvgBase from "@/components/icons/IconSvgBase";

export default {
    extends: IconSvgBase,
    name: "IconUndo",
};
</script>
