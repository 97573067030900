<template>
    <path
        fill="none"
        stroke="currentColor"
        stroke-linecap="round"
        stroke-linejoin="round"
        stroke-width="2"
        d="M12 6.289a20.786 20.786 0 0 1 5.347-.202.625.625 0 0 1 .566.566A20.785 20.785 0 0 1 17.71 12M12 17.711a20.785 20.785 0 0 1-5.347.202.624.624 0 0 1-.566-.566A20.786 20.786 0 0 1 6.29 12"
    />
</template>

<script>
import IconSvgBase from "./IconSvgBase";

export default {
    extends: IconSvgBase,
    name: "IconExpand",
};
</script>
