<template>
    <div
        @click="$emit('select', action)"
        class="relative flex cursor-pointer flex-col items-center justify-center bg-slate-50 p-4 hover:bg-slate-100 sm:p-8"
    >
        <div
            v-if="showAiBadge"
            class="gradient-bg-down absolute right-2 top-2 inline-flex items-center justify-center gap-x-1 rounded-md border border-slate-800 bg-slate-700 px-2 py-1 text-center text-xs font-bold text-white shadow-sm"
        >
            <Icon :icon="IconEnums.MAGIC" class="h-3 w-3" />
            AI
        </div>
        <Icon :icon="iconEnum" class="mb-2 h-5 w-5 text-slate-900" />
        <p class="text-slate-600">{{ title }}</p>
    </div>
</template>

<script>
import Icon from "@/components/icons/BaseIcon";
import { IconEnums } from "@/utils/icons";

export default {
    name: "ActionCard",
    components: {
        Icon,
    },
    data: () => ({
        IconEnums,
    }),
    props: {
        action: {
            type: String,
            default: undefined,
        },
        iconEnum: {
            type: Object,
            default: undefined,
        },
    },
    computed: {
        title() {
            return this.$t(
                "automation.flow.title.editing." + this.action
            ).toString();
        },
        showAiBadge() {
            return this.action === "assign_lesson_action";
        },
    },
};
</script>
