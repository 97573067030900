<template>
    <ValidationObserver ref="generateEmployeeReportForm" v-slot="{ passes }">
        <form @submit.prevent="passes(generateReport)">
            <FormFieldSet>
                <Alert :error="error" />
                <FormSelect
                    v-model="form.group_id"
                    name="group"
                    rules="required"
                >
                    <option
                        v-for="group in rows"
                        :key="group.id"
                        :value="group.id"
                    >
                        {{ group.name }}
                    </option>
                </FormSelect>
                <FormSelect
                    v-model="form.status"
                    name="status"
                    rules="required"
                >
                    <option value="all">
                        {{ $t("settings.export.form.status.all") }}
                    </option>
                    <option value="active">
                        {{ $t("settings.export.form.status.active") }}
                    </option>
                    <option value="inactive">
                        {{ $t("settings.export.form.status.inactive") }}
                    </option>
                </FormSelect>
                <FormSelect
                    v-model="form.range"
                    name="date_range"
                    rules="required"
                >
                    <option value="TODAY">
                        {{ $t("settings.export.form.dates.today") }}
                    </option>
                    <option value="7-DAYS">
                        {{ $t("settings.export.form.dates.days", { days: 7 }) }}
                    </option>
                    <option value="4-WEEKS">
                        {{
                            $t("settings.export.form.dates.weeks", { weeks: 4 })
                        }}
                    </option>
                    <option value="3-MONTHS">
                        {{
                            $t("settings.export.form.dates.months", {
                                months: 3,
                            })
                        }}
                    </option>
                    <option value="6-MONTHS">
                        {{
                            $t("settings.export.form.dates.months", {
                                months: 6,
                            })
                        }}
                    </option>
                    <option value="12-MONTHS">
                        {{
                            $t("settings.export.form.dates.months", {
                                months: 12,
                            })
                        }}
                    </option>
                    <option value="MTD">
                        {{ $t("settings.export.form.dates.mtd") }}
                    </option>
                    <option value="QTD">
                        {{ $t("settings.export.form.dates.qtd") }}
                    </option>
                    <option value="YTD">
                        {{ $t("settings.export.form.dates.ytd") }}
                    </option>
                </FormSelect>
                <div class="flex justify-end">
                    <FormButton type="submit" :loading="loading">
                        {{ $t("settings.export.form.button") }}
                    </FormButton>
                </div>
            </FormFieldSet>
        </form>
    </ValidationObserver>
</template>

<script>
import { ReportStoreNamespacedTypes } from "@/store/modules/report";
import { TeamStoreNamespacedTypes } from "@/store/modules/team";

import { getProperty } from "@/utils/object";

import { ValidationObserver } from "vee-validate";
import Alert from "@/components/ui/Alert";
import FormFieldSet from "@/components/forms/fields/FormFieldSet";
import FormButton from "@/components/forms/fields/FormButton";
import FormSelect from "@/components/forms/fields/FormSelect";

export default {
    name: "GenerateComplianceReportForm",
    components: {
        FormSelect,
        FormButton,
        FormFieldSet,
        Alert,
        ValidationObserver,
    },
    data: () => ({
        form: {
            report_type: "compliance",
            group_id: null,
            status: "active",
            range: "DEFAULT",
        },
    }),
    computed: {
        /**
         * @type {Boolean}
         */
        loading() {
            return this.$store.getters[
                ReportStoreNamespacedTypes.getters.REPORTS_LOADING
            ]();
        },

        /**
         * @type {Object}
         */
        error() {
            return this.$store.getters[
                ReportStoreNamespacedTypes.getters.REPORTS_ERROR
            ]();
        },
        groups() {
            return this.$store.getters[
                TeamStoreNamespacedTypes.getters.GROUPS
            ]();
        },
        rows() {
            return getProperty(this.groups, "data", []);
        },
    },
    methods: {
        /**
         * Sends authentication credentials to the server.
         */
        generateReport() {
            this.$store
                .dispatch(
                    ReportStoreNamespacedTypes.actions.DOWNLOAD_REPORT,
                    this.form
                )
                .then((response) => {
                    if (!this.error) {
                        this.$emit("saved", response.data);
                    }
                });
        },
    },
    beforeCreate() {
        this.$store.commit(
            ReportStoreNamespacedTypes.mutations.SET_REPORTS_ERROR,
            null
        );
    },
};
</script>
