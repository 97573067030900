<template>
    <div
        class="grid grid-cols-2 gap-1 overflow-hidden rounded-xl sm:mx-0 md:grid-cols-4"
    >
        <ActionCard
            action="assign_course_action"
            :icon-enum="IconEnums.TRAINING"
            @select="handleSelect"
        />
        <ActionCard
            action="assign_video_action"
            :icon-enum="IconEnums.VIDEO"
            @select="handleSelect"
        />
        <ActionCard
            action="assign_lesson_action"
            :icon-enum="IconEnums.REMINDER"
            @select="handleSelect"
        />
        <ActionCard
            action="add_tag_action"
            :icon-enum="IconEnums.TAG"
            @select="handleSelect"
        />
        <ActionCard
            action="remove_tag_action"
            :icon-enum="IconEnums.TAG"
            @select="handleSelect"
        />
        <ActionCard
            action="wait_action"
            :icon-enum="IconEnums.CLOCK"
            @select="handleSelect"
        />
        <ActionCard
            action="halt_action"
            :icon-enum="IconEnums.HALT"
            @select="handleSelect"
        />
    </div>
</template>

<script>
import ActionCard from "@/components/display/automation/ActionCard";
import { IconEnums } from "@/utils/icons";
import AutomationActionMixin from "@/mixins/AutomationActionMixin";

export default {
    name: "ActionSelector",
    mixins: [AutomationActionMixin],
    components: { ActionCard },
    data: () => ({
        IconEnums,
    }),
    methods: {
        handleSelect(key) {
            this.$emit("action-selected", key, this.index);
        },
    },
};
</script>
