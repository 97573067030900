var render = function render(){var _vm=this,_c=_vm._self._c;return _c('ValidationObserver',{ref:"campaignSettingsForm",scopedSlots:_vm._u([{key:"default",fn:function({ passes }){return [_c('form',{staticClass:"-mb-4",on:{"submit":function($event){$event.preventDefault();return passes(_vm.updateSettings)}}},[_c('FormFieldSet',[_c('FormFieldOutline',[_c('FormToggle',{attrs:{"name":"remedial_training"},model:{value:(_vm.form.remedial_training),callback:function ($$v) {_vm.$set(_vm.form, "remedial_training", $$v)},expression:"form.remedial_training"}})],1),_c('FormFieldOutline',[_c('div',{staticClass:"flex flex-row items-center justify-between"},[_c('div',{staticClass:"flex flex-col"},[_c('div',{staticClass:"mb-1 flex flex-row items-center space-x-1"},[_c('Icon',{staticClass:"size-4 text-slate-900",attrs:{"icon":_vm.IconEnums.DOMAIN}}),_c('h3',{staticClass:"block text-sm font-medium text-slate-900",domProps:{"textContent":_vm._s(
                                    _vm.$t(
                                        'settings.simulation.download_domains.heading'
                                    ).toString()
                                )}})],1),_c('p',{staticClass:"block text-sm text-slate-500",domProps:{"textContent":_vm._s(
                                _vm.$t(
                                    'settings.simulation.download_domains.text'
                                ).toString()
                            )}})]),_c('FormButton',{attrs:{"variant":_vm.ButtonVariants.SMALL,"icon":_vm.IconEnums.DOWNLOAD,"loading":_vm.exportLoading},nativeOn:{"click":function($event){return _vm.exportToCsv.apply(null, arguments)}}},[_vm._v(" "+_vm._s(_vm.$t( "settings.simulation.download_domains.button" ))+" ")])],1)]),_c('div',{staticClass:"flex justify-end"},[_c('FormButton',{attrs:{"type":"submit","loading":_vm.formLoading}},[_vm._v(" "+_vm._s(_vm.$t("forms.buttons.save_settings"))+" ")])],1)],1)],1)]}}])})
}
var staticRenderFns = []

export { render, staticRenderFns }