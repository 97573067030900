<template>
    <Badge
        v-if="hasStatus"
        :variant="variant"
        :text="$t('threats.status.' + status).toString()"
    />
</template>

<script>
import Badge, { BadgeVariants } from "@/components/shared/Badge";

export default {
    components: { Badge },
    props: {
        status: {
            type: String,
            default: undefined,
        },
    },
    computed: {
        hasStatus() {
            return this.status !== undefined;
        },
        variant() {
            if (this.status === "fraudulent") {
                return BadgeVariants.ERROR;
            }

            if (this.status === "legitimate") {
                return BadgeVariants.SUCCESS;
            }

            if (this.status === "spam") {
                return BadgeVariants.WARNING;
            }

            return BadgeVariants.DEFAULT;
        },
    },
};
</script>
