<template>
    <WorkspaceSettingsPage>
        <div class="card">
            <CardHeading
                :title="$t('integrations.heading').toString()"
                :text="$t('integrations.text').toString()"
                :loading="loading"
            />
            <div v-show="integrationError" class="border-b p-4">
                <Alert :error="integrationError" />
            </div>
            <ul role="list" class="divide-y divide-slate-200">
                <IntegrationRow
                    v-for="integration in integrations"
                    :key="integration.key"
                    :integration="integration"
                />
            </ul>
        </div>
    </WorkspaceSettingsPage>
</template>

<script>
import { IntegrationStoreNamespacedTypes } from "@/store/modules/integration";

import { integrations } from "@/utils/enums";
import IntegrationRow from "@/components/display/integrations/IntegrationRow";
import Alert from "@/components/ui/Alert";
import WorkspaceSettingsPage from "@/components/layout/WorkspaceSettingsPage";
import CardHeading from "@/components/ui/CardHeading";
import { WorkspaceStoreNamespacedTypes } from "@/store/modules/workspace";

export default {
    name: "WorkspaceSettings",
    components: {
        CardHeading,
        WorkspaceSettingsPage,
        Alert,
        IntegrationRow,
    },
    data: () => ({
        integrations,
        showPrompt: false,
    }),
    computed: {
        /**
         * @type {Boolean}
         */
        loading() {
            return this.$store.getters[
                WorkspaceStoreNamespacedTypes.getters.WORKSPACE_LOADING
            ]();
        },

        /**
         * @type {Object}
         */
        integrationError() {
            return this.$store.getters[
                IntegrationStoreNamespacedTypes.getters.INTEGRATION_ERROR
            ]();
        },
    },
    beforeCreate() {
        this.$store.commit(
            IntegrationStoreNamespacedTypes.mutations.SET_INTEGRATION_ERROR,
            null
        );
    },
};
</script>
