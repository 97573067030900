<template>
    <li>
        <component
            :is="tagName"
            :to="route"
            active-class="bg-slate-100 text-slate-800"
            :class="[
                'group relative flex w-full items-center rounded-xl px-2.5 py-1.5 text-sm font-semibold leading-6 transition focus:outline-none',
                { 'bg-slate-100 text-slate-800': isActive },
                {
                    'bg-transparent text-slate-600 hover:bg-slate-100 hover:text-slate-800':
                        !isActive,
                },
            ]"
            @click="onClick"
        >
            <div v-if="hasCount" class="absolute left-0.5 top-2 ml-1 h-1 w-1">
                <div
                    class="absolute h-1 w-1 animate-ping rounded-full bg-red-500"
                ></div>
                <div class="h-1 w-1 rounded-full bg-red-500"></div>
            </div>
            <Icon
                v-if="icon"
                :icon="icon"
                class="h-4.5 w-4.5"
                :class="{ 'mr-2': text }"
            />
            {{ text }}
        </component>
    </li>
</template>

<script>
import Icon from "@/components/icons/BaseIcon";
import { getProperty } from "@/utils/object";

export default {
    name: "NavItem",
    components: { Icon },
    props: {
        route: {
            type: Object,
            required: false,
        },
        group: {
            type: String,
            required: false,
        },
        text: {
            type: String,
            required: false,
        },
        icon: {
            type: Object,
            default: () => {},
        },
        count: {
            type: Number,
            default: 0,
        },
    },
    computed: {
        tagName() {
            if (this.route) {
                return "router-link";
            }

            return "button";
        },
        isActive() {
            return getProperty(this.$route.meta, "group") === this.group;
        },
        hasCount() {
            return this.count > 0;
        },
    },
    methods: {
        onClick(event) {
            /**
             * Emitted when the button is clicked.
             * @event click
             * @type {Event}
             */
            this.$emit("click", event);
        },
    },
};
</script>
