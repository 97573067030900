<template>
    <li class="p-4">
        <div class="flex items-center space-x-4">
            <div class="flex-shrink-0">
                <Icon :icon="icon" class="h-8 w-8" />
            </div>
            <div class="min-w-0 flex-1">
                <p class="truncate text-sm font-medium text-slate-900">
                    {{ title }}
                </p>
                <p class="truncate text-sm text-slate-500">
                    {{
                        isInstalled
                            ? $t("integrations.last_update", {
                                  time: syncedAt
                                      ? formatDateTime(syncedAt)
                                      : $t("integrations.never"),
                              })
                            : text
                    }}
                </p>
            </div>
            <div
                v-if="loading"
                class="h-9 w-32 animate-pulse rounded bg-slate-300"
            ></div>
            <div
                v-else-if="isActive"
                class="flex flex-row items-center justify-between gap-4"
            >
                <div
                    v-if="failedAt"
                    class="flex h-8 w-8 items-center justify-center rounded-full bg-rose-50/50 text-error-normal ring-1 ring-inset ring-rose-500/20"
                >
                    <Icon :icon="IconEnums.ERROR" class="h-5 w-5" />
                </div>
                <FormButton
                    v-if="isInstalled"
                    :variant="ButtonVariants.SECONDARY"
                    :to="{
                        name: 'settings.integrations.single',
                        params: { id: this.integrationID },
                    }"
                    :icon="IconEnums.SETTINGS"
                />
                <FormButton
                    @click="getGrant"
                    :loading="syncLoading"
                    :variant="ButtonVariants.SECONDARY"
                    :icon="isInstalled ? IconEnums.REFRESH : IconEnums.SIGNAL"
                >
                    {{
                        isInstalled
                            ? $t("integrations.refresh")
                            : $t("integrations.setup", { provider: key })
                    }}
                </FormButton>
            </div>
            <div v-else class="text-xs text-slate-400">Coming soon</div>
        </div>
    </li>
</template>

<script>
import { IntegrationStoreNamespacedTypes } from "@/store/modules/integration";
import { WorkspaceStoreNamespacedTypes } from "@/store/modules/workspace";

import { formatDateTime } from "@/utils/date";
import { getProperty } from "@/utils/object";
import { IconEnums } from "@/utils/icons";

import Bus from "@/bus";
import FormButton, {
    ButtonVariants,
} from "@/components/forms/fields/FormButton";
import Icon from "@/components/icons/BaseIcon";

export default {
    components: { FormButton, Icon },
    props: {
        integration: {
            type: Object,
            default: () => {},
        },
    },
    data: () => ({
        IconEnums,
        ButtonVariants,
        syncLoading: false,
    }),
    computed: {
        /**
         * @type {Boolean}
         */
        loading() {
            return this.$store.getters[
                WorkspaceStoreNamespacedTypes.getters.WORKSPACE_LOADING
            ]();
        },

        /**
         * @type {Array}
         */
        integrations() {
            return this.$store.getters[
                WorkspaceStoreNamespacedTypes.getters.INTEGRATIONS
            ]();
        },

        /**
         * @type {boolean}
         */
        isActive() {
            return getProperty(this.integration, "active", false);
        },

        /**
         * @type {string}
         */
        key() {
            return getProperty(this.integration, "key");
        },

        /**
         * @type {Object}
         */
        icon() {
            return this.IconEnums[this.key.toUpperCase()];
        },

        /**
         * @type {string}
         */
        title() {
            return this.$t("integrations.provider." + this.key + ".title");
        },

        /**
         * @type {string}
         */
        text() {
            return this.$t("integrations.provider." + this.key + ".text");
        },

        /**
         * @type {Object}
         */
        installationSettings() {
            return this.integrations.find(
                (integration) => integration.provider === this.key
            );
        },

        /**
         * @type {Boolean}
         */
        isInstalled() {
            return this.installationSettings;
        },

        /**
         * @type {string}
         */
        integrationID() {
            return getProperty(this.installationSettings, "id");
        },

        /**
         * @type {string}
         */
        syncedAt() {
            return getProperty(this.installationSettings, "synced_at", "");
        },

        /**
         * @type {string}
         */
        failedAt() {
            return getProperty(this.installationSettings, "token_failure_at");
        },
    },
    methods: {
        formatDateTime,
        getGrant() {
            this.syncLoading = true;
            this.$store
                .dispatch(
                    IntegrationStoreNamespacedTypes.actions.GET_GRANT,
                    this.key
                )
                .then((response) => {
                    if (!this.error) {
                        if (getProperty(response.data, "synced", false)) {
                            Bus.$emit("flash-message", {
                                text: this.$t("integrations.sync_started"),
                                type: "success",
                            });

                            return this.$store.dispatch(
                                WorkspaceStoreNamespacedTypes.actions
                                    .GET_WORKSPACE,
                                {
                                    force: true,
                                }
                            );
                        }

                        window.location.replace(response.data.url);
                    }
                })
                .finally(() => {
                    this.syncLoading = false;
                });
        },
    },
};
</script>
