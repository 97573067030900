<template>
    <li>
        <div
            class="block cursor-pointer hover:bg-slate-50"
            :class="{ 'border-b': active }"
            @click="$emit('selectStep', step)"
        >
            <div class="flex items-center px-4 py-4 sm:px-6">
                <div
                    class="min-w-0 flex-1 sm:flex sm:items-center sm:justify-between"
                >
                    <div class="truncate">
                        <div class="flex text-sm">
                            <p
                                :class="[
                                    { 'text-slate-700': !active },
                                    { 'text-slate-900': active },
                                ]"
                                class="font-display flex-shrink-0 text-lg font-bold"
                            >
                                {{ title }}
                            </p>
                        </div>

                        <div class="mt-2 flex">
                            <div
                                class="flex items-center text-sm text-slate-500"
                            >
                                <p>
                                    {{ text }}
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="ml-5 flex-shrink-0">
                    <span
                        v-if="completed"
                        class="inline-flex h-6 w-6 items-center justify-center rounded-full bg-indigo-600"
                    >
                        <svg
                            class="h-6 w-6 text-white"
                            fill="none"
                            stroke="currentColor"
                            viewBox="0 0 24 24"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                stroke-width="2"
                                d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z"
                            ></path>
                        </svg>
                    </span>
                    <span
                        v-else
                        class="inline-flex h-6 w-6 items-center justify-center rounded-full bg-slate-500"
                    >
                        <span
                            class="text-xs font-medium leading-none text-white"
                            >{{ step }}</span
                        >
                    </span>
                </div>
            </div>
        </div>
        <transition
            name="zoom"
            mode="out-in"
            appear
            appear-active-class="zoom-appear"
        >
            <div :class="{ 'p-4': gutter }" v-if="active">
                <slot />
            </div>
        </transition>
    </li>
</template>

<script>
export default {
    props: {
        gutter: {
            type: Boolean,
            default: true,
        },
        title: {
            type: String,
            default: undefined,
        },
        text: {
            type: String,
            default: undefined,
        },
        active: {
            type: Boolean,
            default: false,
        },
        completed: {
            type: Boolean,
            default: false,
        },
        step: {
            type: Number,
            default: undefined,
        },
    },
};
</script>
