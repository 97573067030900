<template>
    <ValidationProvider
        tag="div"
        class="form-field form-field-input relative"
        :vid="vid"
        :rules="rules"
        :name="name || label"
        v-slot="{ errors, ariaInput, ariaMsg }"
    >
        <label
            class="pointer-events-none mb-2 block text-sm font-medium text-slate-900"
            @click="$refs.input.focus()"
            :for="id || name"
        >
            {{ label || name }}
        </label>
        <input
            class="block w-full rounded-md border-slate-300 px-2 py-2 text-base text-slate-800 transition-colors placeholder:text-slate-500 hover:bg-slate-50 focus:border-indigo-500 focus:bg-white focus:outline-none focus:ring-2 focus:ring-indigo-500/10 focus:ring-offset-2 focus:ring-offset-slate-50 sm:text-sm"
            :class="{ 'is-invalid': errors[0] }"
            :id="id || name"
            type="text"
            :placeholder="placeholder"
            ref="input"
            v-model="innerValue"
            v-bind="ariaInput"
            autocomplete="off"
            @click="showDatepicker = !showDatepicker"
            @keydown.escape="showDatepicker = false"
            @keydown.tab="showDatepicker = false"
        />
        <div
            class="absolute left-0 top-0 z-10 mt-20 w-72 max-w-lg rounded-lg border border-slate-200 bg-white p-4"
            v-show="showDatepicker"
            ref="datepicker"
        >
            <div class="mb-2 flex items-center justify-between">
                <div>
                    <span
                        v-text="monthNames[month]"
                        class="text-lg font-bold text-slate-800"
                    ></span>
                    <span
                        v-text="year"
                        class="ml-1 text-lg font-normal text-slate-600"
                    ></span>
                </div>
                <div>
                    <button
                        type="button"
                        class="inline-flex cursor-pointer rounded-full p-1 transition duration-100 ease-in-out hover:bg-slate-200"
                        @click="prevMonth"
                    >
                        <svg
                            class="inline-flex h-6 w-6 text-slate-500"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="currentColor"
                        >
                            <path
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                stroke-width="2"
                                d="M15 19l-7-7 7-7"
                            />
                        </svg>
                    </button>
                    <button
                        type="button"
                        class="inline-flex cursor-pointer rounded-full p-1 transition duration-100 ease-in-out hover:bg-slate-200"
                        @click="nextMonth"
                    >
                        <svg
                            class="inline-flex h-6 w-6 text-slate-500"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="currentColor"
                        >
                            <path
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                stroke-width="2"
                                d="M9 5l7 7-7 7"
                            />
                        </svg>
                    </button>
                </div>
            </div>
            <div class="-mx-1 mb-3 grid grid-cols-7">
                <div
                    class="col-span-1 text-center text-xs font-medium text-slate-600"
                    v-for="day in days"
                    :key="day"
                    v-text="day"
                ></div>
            </div>
            <div class="-mx-1 grid grid-cols-7 gap-x-2 gap-y-2">
                <div
                    v-for="blank in blanks"
                    :key="blank"
                    class="flex h-7 w-7 items-center text-center text-sm text-slate-600"
                ></div>
                <div
                    class="flex h-7 w-7 cursor-pointer flex-col items-center rounded-full text-center text-sm leading-loose text-slate-600"
                    v-for="date in no_of_days"
                    :key="date"
                    @click="selectDate(date)"
                    :class="{
                        'bg-indigo-500 text-white': isSelected(date),
                        'text-slate-700 hover:bg-indigo-200': !isSelected(date),
                    }"
                >
                    <span v-text="date"></span>
                </div>
            </div>
        </div>
        <span class="mt-2 block text-sm text-slate-500" v-if="help">{{
            help
        }}</span>
        <span
            class="mt-3 text-sm text-red-600"
            v-bind="ariaMsg"
            v-if="errors[0]"
            >{{ errors[0] }}</span
        >
    </ValidationProvider>
</template>

<script>
import FieldMixin from "./FieldMixin";
import { formatDate } from "@/utils/date";
import dayjs from "dayjs";

export default {
    name: "FormDateInput",
    mixins: [FieldMixin],
    props: {
        placeholder: {
            type: String,
            default: "",
        },
    },
    data: () => ({
        monthNames: [
            "January",
            "February",
            "March",
            "April",
            "May",
            "June",
            "July",
            "August",
            "September",
            "October",
            "November",
            "December",
        ],
        days: ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"],
        showDatepicker: false,
        month: "",
        year: "",
        no_of_days: [],
        blanks: [],
    }),
    methods: {
        initDate() {
            if (this.innerValue) {
                this.month = this.innerValue.split("-")[1] - 1;
                this.year = this.innerValue.split("-")[2];
            } else {
                this.month = dayjs().month();
                this.year = dayjs().year();
                this.innerValue = formatDate(dayjs());
            }
        },
        isSelected(day) {
            return (
                formatDate(
                    dayjs().date(day).month(this.month).year(this.year)
                ) === this.innerValue
            );
        },
        getNoOfDays() {
            // find where to start calendar day of week
            let dayOfWeek = new Date(this.year, this.month).getDay();
            let blanksArray = [];
            for (let a = 1; a <= dayOfWeek; a++) {
                blanksArray.push(a + "-blank");
            }

            let daysInMonth = new Date(
                this.year,
                parseInt(this.month + 1),
                0
            ).getDate();
            let daysArray = [];
            for (let b = 1; b <= daysInMonth; b++) {
                daysArray.push(b);
            }

            this.blanks = blanksArray;
            this.no_of_days = daysArray;
        },
        selectDate(date) {
            let selectedDate = new Date(this.year, this.month, date);
            this.innerValue = formatDate(selectedDate);
            this.showDatepicker = false;
        },
        nextMonth() {
            if (this.month === 11) {
                this.month = 0;
                this.year++;
            } else {
                this.month++;
            }
            this.getNoOfDays();
        },
        prevMonth() {
            if (this.month === 0) {
                this.month = 11;
                this.year--;
            } else {
                this.month--;
            }
            this.getNoOfDays();
        },
        handleClickOutside(evt) {
            if (!this.$el.contains(evt.target)) {
                this.showDatepicker = false;
            }
        },
    },
    mounted() {
        this.initDate();
        this.getNoOfDays();
    },
    created() {
        window.addEventListener("click", this.handleClickOutside, false);
    },
    beforeDestroy() {
        window.removeEventListener("click", this.handleClickOutside, false);
    },
};
</script>
