<template>
    <ValidationObserver ref="campaignSoftwareTargetingForm" v-slot="{ passes }">
        <form @submit.prevent="passes(updateSettings)" class="-mb-4">
            <FormFieldSet>
                <FormFieldOutline>
                    <FormToggle
                        v-model="form.software_targeting_enabled"
                        name="enable_module"
                    />
                </FormFieldOutline>
            </FormFieldSet>
            <FormFieldSet>
                <div
                    class="flex items-center justify-between rounded-xl border border-slate-200 bg-slate-50 px-5 py-4"
                >
                    <div class="flex items-center">
                        <div class="flex -space-x-3">
                            <div
                                class="flex h-10 w-10 items-center overflow-hidden rounded-full border border-slate-300 bg-white"
                            >
                                <img
                                    src="https://d1vfmqj1s5shnz.cloudfront.net/service/logo/QC6Fb812Xesc3JAC0yBtCOW3p54E5gUEMu89j3Jo.png"
                                    class="h-10 w-10"
                                    alt="Google"
                                />
                            </div>
                            <div
                                class="flex h-10 w-10 items-center overflow-hidden rounded-full border border-slate-300 bg-white"
                            >
                                <img
                                    src="https://d1vfmqj1s5shnz.cloudfront.net/service/logo/gU0cPmKGbJCAr5gP3UvPM6NokEcG9aPR96WjSIvI.png"
                                    class="h-10 w-10"
                                    alt="Facebook"
                                />
                            </div>
                            <div
                                class="flex h-10 w-10 items-center overflow-hidden rounded-full border border-slate-300 bg-white"
                            >
                                <img
                                    src="https://d1vfmqj1s5shnz.cloudfront.net/service/logo/DGK9r4KWE7ktqKnKSbG2zFlvqEZ5RVLTNROnQVVI.png"
                                    class="h-10 w-10"
                                    alt="Microsoft"
                                />
                            </div>
                        </div>
                        <div
                            class="ml-2 hidden text-sm font-medium leading-5 text-slate-800 md:flex"
                        >
                            {{
                                $t(
                                    "settings.simulation.services.services_activated",
                                    {
                                        count: countSelectedServices,
                                    }
                                )
                            }}
                        </div>
                    </div>
                    <div>
                        <FormButton
                            type="button"
                            @click="$emit('edit')"
                            :variant="ButtonVariants.SECONDARY"
                        >
                            {{ $t("settings.simulation.services.edit") }}
                        </FormButton>
                    </div>
                </div>
                <div class="mt-4 flex justify-end">
                    <FormButton type="submit" :loading="formLoading">
                        {{ $t("forms.buttons.save_settings") }}
                    </FormButton>
                </div>
            </FormFieldSet>
        </form>
    </ValidationObserver>
</template>
<script>
import { WorkspaceStoreNamespacedTypes } from "@/store/modules/workspace";

import { getProperty } from "@/utils/object";
import FormButton, {
    ButtonVariants,
} from "@/components/forms/fields/FormButton";
import { ValidationObserver } from "vee-validate";
import FormFieldOutline from "@/components/forms/fields/FormFieldOutline";
import FormToggle from "@/components/forms/fields/FormToggle";
import FormFieldSet from "@/components/forms/fields/FormFieldSet";

export default {
    name: "CampaignServicesPreview",
    components: {
        ValidationObserver,
        FormFieldSet,
        FormToggle,
        FormFieldOutline,
        FormButton,
    },
    data: () => ({
        ButtonVariants,
        formLoading: false,
        form: {
            software_targeting_enabled: false,
        },
    }),
    computed: {
        /**
         * @type {Object}
         */
        workspace() {
            return this.$store.getters[
                WorkspaceStoreNamespacedTypes.getters.WORKSPACE
            ]();
        },

        /**
         * @type {Number}
         */
        countSelectedServices() {
            let count = getProperty(
                this.workspace,
                "campaign_services_count",
                0
            );

            if (count === 0) {
                count = 48;
            }

            return count;
        },
    },
    methods: {
        /**
         * Sends authentication credentials to the server.
         */
        updateSettings() {
            this.formLoading = true;
            this.$store
                .dispatch(
                    WorkspaceStoreNamespacedTypes.actions
                        .UPDATE_CAMPAIGN_SETTINGS,
                    this.form
                )
                .then(() => {
                    this.formLoading = false;
                    if (!this.error) {
                        this.$emit("saved");
                    }
                });
        },
    },
    created() {
        this.form = {
            software_targeting_enabled: getProperty(
                this.workspace,
                "simulation_settings.software_targeting_enabled",
                false
            ),
        };
    },
    beforeCreate() {
        this.$store.commit(
            WorkspaceStoreNamespacedTypes.mutations.SET_WORKSPACE_ERROR,
            null
        );
    },
};
</script>
