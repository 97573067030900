<template>
    <ValidationObserver ref="integrationSettingsForm" v-slot="{ passes }">
        <form @submit.prevent="passes(saveSettings)">
            <div class="flex flex-col p-4">
                <Alert :error="error" />
                <FormFieldSet>
                    <FormFieldOutline>
                        <FormToggle
                            v-model="form.enable_sync"
                            type="text"
                            name="enable_sync"
                        />
                    </FormFieldOutline>
                </FormFieldSet>
                <FormFieldSet v-if="form.enable_sync">
                    <FormSelect
                        v-model="form.sync_frequency"
                        name="sync_frequency"
                    >
                        <option
                            v-for="frequency in frequencies"
                            :key="frequency.id"
                            :value="frequency.id"
                        >
                            {{ frequency.name }}
                        </option>
                    </FormSelect>
                    <div
                        class="flex flex-col rounded-xl border border-amber-500/20 bg-amber-50/50 p-3 text-xs text-amber-800"
                    >
                        {{ frequencyText }}
                    </div>
                    <FormFieldOutline>
                        <h3
                            class="flex justify-start align-middle text-sm font-bold text-slate-800"
                        >
                            {{ $t("integrations.edit.groups.title") }}
                        </h3>
                        <p
                            class="mb-4 mt-1 overflow-hidden overflow-ellipsis text-sm text-slate-500"
                        >
                            {{ $t("integrations.edit.groups.text") }}
                        </p>
                        <FormToggle
                            v-model="form.sync_groups"
                            type="text"
                            name="sync_groups"
                        />
                    </FormFieldOutline>
                    <FormFieldOutline>
                        <h3
                            class="flex justify-start align-middle text-sm font-bold text-slate-800"
                        >
                            {{ $t("integrations.edit.offboarding.title") }}
                        </h3>
                        <p
                            class="mb-4 mt-1 overflow-hidden overflow-ellipsis text-sm text-slate-500"
                        >
                            {{ $t("integrations.edit.offboarding.text") }}
                        </p>
                        <FormToggle
                            v-model="form.enable_offboarding"
                            type="text"
                            name="enable_offboarding"
                        />
                    </FormFieldOutline>
                    <FormFieldOutline>
                        <h3
                            class="flex justify-start align-middle text-sm font-bold text-slate-800"
                        >
                            {{ $t("integrations.edit.filtering.title") }}
                        </h3>
                        <p
                            class="mb-4 mt-1 overflow-hidden overflow-ellipsis text-sm text-slate-500"
                        >
                            {{ $t("integrations.edit.filtering.text") }}
                        </p>
                        <FormToggle
                            v-model="form.enable_filtering"
                            type="text"
                            name="enable_filtering"
                        />
                    </FormFieldOutline>
                </FormFieldSet>
                <div class="flex flex-row justify-end">
                    <FormButton type="submit" :loading="loading">
                        {{ $t("forms.buttons.save_settings") }}
                    </FormButton>
                </div>
            </div>
        </form>
    </ValidationObserver>
</template>

<script>
import { IntegrationStoreNamespacedTypes } from "@/store/modules/integration";

import { getProperty } from "@/utils/object";

import { ValidationObserver } from "vee-validate";
import Alert from "@/components/ui/Alert";
import FormButton from "@/components/forms/fields/FormButton";
import FormToggle from "@/components/forms/fields/FormToggle";
import FormSelect from "@/components/forms/fields/FormSelect";
import FormFieldSet from "@/components/forms/fields/FormFieldSet";
import FormFieldOutline from "@/components/forms/fields/FormFieldOutline";

export default {
    name: "IntegrationSettingsForm",
    components: {
        FormFieldOutline,
        FormFieldSet,
        FormSelect,
        FormToggle,
        FormButton,
        Alert,
        ValidationObserver,
    },
    data: () => ({
        form: {
            enable_sync: true,
            sync_frequency: "monthly",
            sync_groups: true,
            enable_offboarding: true,
            enable_filtering: true,
        },
        frequencies: [
            {
                id: "daily",
                name: "Daily",
            },
            {
                id: "weekly",
                name: "Weekly",
            },
            {
                id: "monthly",
                name: "Monthly",
            },
        ],
        loading: false,
    }),
    computed: {
        /**
         * @type {Boolean}
         */
        error() {
            return this.$store.getters[
                IntegrationStoreNamespacedTypes.getters.INTEGRATION_ERROR
            ]();
        },

        /**
         * @type {Object}
         */
        integration() {
            return this.$store.getters[
                IntegrationStoreNamespacedTypes.getters.INTEGRATION
            ]();
        },

        /**
         * @type {string}
         */
        frequencyText() {
            if (this.form.sync_frequency === "daily") {
                return this.$t("integrations.frequency.daily");
            }

            if (this.form.sync_frequency === "weekly") {
                return this.$t("integrations.frequency.weekly");
            }

            return this.$t("integrations.frequency.monthly");
        },
    },
    methods: {
        /**
         * Sends authentication credentials to the server.
         */
        saveSettings() {
            this.loading = true;
            this.$store
                .dispatch(
                    IntegrationStoreNamespacedTypes.actions
                        .UPDATE_INTEGRATION_SETTINGS,
                    { integration: this.$route.params.id, form: this.form }
                )
                .then(() => {
                    this.loading = false;
                    if (!this.error) {
                        this.$emit("saved");
                    }
                });
        },
    },
    created() {
        this.$store
            .dispatch(
                IntegrationStoreNamespacedTypes.actions.GET_INTEGRATION,
                this.$route.params.id
            )
            .then(() => {
                this.form = {
                    enable_sync: getProperty(
                        this.integration,
                        "enable_sync",
                        true
                    ),
                    sync_frequency: getProperty(
                        this.integration,
                        "sync_frequency",
                        "monthly"
                    ),
                    sync_groups: getProperty(
                        this.integration,
                        "sync_groups",
                        true
                    ),
                    enable_offboarding: getProperty(
                        this.integration,
                        "enable_offboarding",
                        true
                    ),
                    enable_filtering: getProperty(
                        this.integration,
                        "enable_filtering",
                        true
                    ),
                };
            });
    },
    beforeCreate() {
        this.$store.commit(
            IntegrationStoreNamespacedTypes.mutations.SET_INTEGRATION_ERROR,
            null
        );
    },
};
</script>
