<template>
    <div class="mb-5 sm:flex sm:items-center sm:justify-between">
        <div class="sm:w-0 sm:flex-1">
            <h1
                class="flex justify-start align-middle text-lg font-bold text-slate-800"
            >
                {{ title }}
            </h1>
            <p
                class="mt-1 flex flex-row items-center overflow-hidden overflow-ellipsis text-sm"
            >
                <span
                    v-if="returnTo"
                    class="flex flex-row items-center text-slate-600"
                    ><router-link
                        :to="returnTo"
                        class="mr-2 flex flex-row items-center"
                        ><Icon :icon="IconEnums.BACK" class="mr-2" />{{
                            $t("navigation.go_back")
                        }}</router-link
                    ><span v-if="text" class="mr-2">&middot;</span></span
                >
                <span class="text-slate-500">{{ text | capitalize }}</span>
            </p>
        </div>
        <div
            class="flex items-center gap-4 pt-4 sm:ml-6 sm:mt-0 sm:flex-shrink-0 sm:justify-start sm:pt-0"
        >
            <LoadingSpinner v-if="loading" />
            <slot />
        </div>
    </div>
</template>

<script>
import Icon from "@/components/icons/BaseIcon";
import { IconEnums } from "@/utils/icons";
import LoadingSpinner from "@/components/ui/LoadingSpinner";

export default {
    name: "HeadingItem",
    components: { LoadingSpinner, Icon },
    filters: {
        capitalize(s) {
            if (typeof s !== "string") return "";
            return s.charAt(0).toUpperCase() + s.slice(1);
        },
    },
    data: () => ({
        IconEnums,
    }),
    props: {
        title: {
            type: String,
            default: "",
        },
        text: {
            type: String,
            default: "",
        },
        loading: {
            type: Boolean,
            required: false,
            default: false,
        },
        returnTo: {
            type: Object,
            default: () => {},
        },
    },
};
</script>
