<template>
    <div class="relative flex h-full min-h-screen w-full flex-col bg-white">
        <div class="flex h-full w-full flex-grow">
            <div
                class="relative z-10 flex flex-1 flex-col bg-white px-4 py-6 md:flex-none md:px-16"
            >
                <div
                    class="mx-auto flex h-full w-full max-w-md flex-col justify-between sm:px-4 md:w-96 md:max-w-sm md:px-0"
                >
                    <div class="mb-10 flex flex-row justify-between">
                        <router-link to="/">
                            <Logo class="h-8" />
                        </router-link>
                        <LocaleSwitcher />
                    </div>
                    <div>
                        <h3
                            class="font-display mb-2 text-2xl font-semibold text-slate-800"
                        >
                            {{ heading }}
                        </h3>
                        <p class="max-w mb-6 mt-3 text-slate-600">
                            {{ text }}
                        </p>
                        <slot />
                    </div>
                    <div>
                        <slot name="footer" />
                    </div>
                </div>
            </div>
            <div class="relative hidden w-full py-6 pr-6 md:flex">
                <div
                    class="gradient-bg-down flex h-full w-full items-center justify-center rounded-2xl bg-slate-900"
                ></div>
            </div>
        </div>
    </div>
</template>

<script>
import Logo from "@/components/ui/Logo";
import LocaleSwitcher from "@/components/ui/LocaleSwitcher";

export default {
    name: "PageWrapper",
    components: { LocaleSwitcher, Logo },
    props: {
        heading: {
            type: String,
        },
        text: {
            type: String,
        },
    },
};
</script>
