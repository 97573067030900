<template>
    <DataTable
        :headers="headers"
        :rows="rows"
        :meta="meta"
        :loading="loading"
        resource="breach.single"
        @update="refresh"
    >
        <template v-slot:name="{ item }">
            <div class="flex items-center">
                <div
                    v-if="item.logo"
                    class="size-8 flex-shrink-0 rounded border bg-slate-100 p-0.5"
                >
                    <img
                        class="h-6 w-full object-contain"
                        :src="item.logo"
                        :alt="item.name"
                    />
                </div>
                <div
                    v-else
                    class="inline-flex size-8 flex-shrink-0 items-center justify-center rounded border bg-slate-100"
                >
                    <div
                        class="text-sm font-medium leading-none text-slate-700"
                    >
                        {{ item.name | initials }}
                    </div>
                </div>
                <div class="ml-3">
                    <div class="text-sm font-medium text-slate-900">
                        {{ item.name || "Untitled data breach" }}
                    </div>
                    <div class="text-sm text-slate-500">
                        {{ item.domain || "N/A" }}
                    </div>
                </div>
            </div>
        </template>
        <template v-slot:is_sensitive="{ item }">
            <SensitivityLevel :sensitive="item.is_sensitive" />
        </template>
        <template v-slot:breached_at="{ item }">
            {{ item.breached_at | formatDate }}
        </template>
    </DataTable>
</template>

<script>
import { formatDate } from "@/utils/date";
import { initials } from "@/utils/filters";

import SensitivityLevel from "./SensitivityLevel";
import DataTableMixin from "@/components/tables/DataTableMixin";

export default {
    mixins: [DataTableMixin],
    components: {
        SensitivityLevel,
    },
    data: () => ({
        headers: [
            {
                title: "name",
                key: "name",
                sortable: true,
            },
            {
                title: "sensitivity",
                key: "is_sensitive",
                sortable: true,
            },
            {
                title: "breached_at",
                key: "breached_at",
                sortable: true,
            },
        ],
    }),
    filters: {
        formatDate,
        initials,
    },
};
</script>
