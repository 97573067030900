<template>
    <OneCol>
        <Heading
            :title="$t('onboarding.heading').toString()"
            :text="$t('onboarding.text').toString()"
        />
        <OnboardingWizard />
    </OneCol>
</template>

<script>
import OneCol from "@/components/layout/OneCol";
import Heading from "@/components/layout/Heading";
import OnboardingWizard from "@/components/display/onboarding/OnboardingWizard";

export default {
    name: "OnboardingPage",
    components: { OneCol, Heading, OnboardingWizard },
};
</script>
