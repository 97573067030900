<template>
    <DashboardPage :grid="false">
        <template v-slot:sidebar>
            <FormDateRangeSelect v-model="range" type="STATISTICS" />
        </template>
        <template>
            <SlideOver
                :active="showPreviewPrompt"
                @close="showPreviewPrompt = false"
                :gutter="false"
            >
                <h2
                    slot="title"
                    class="text-base font-semibold leading-6 text-slate-900"
                >
                    {{ $t("employees.preview.title") }}
                </h2>
                <template v-slot:content>
                    <div class="px-4">
                        <ScoreCard
                            :title="
                                $t(
                                    'statistics.factor.competence.heading'
                                ).toString()
                            "
                            :text="
                                $t(
                                    'statistics.factor.competence.text'
                                ).toString()
                            "
                            :value="89"
                            :factors="[
                                {
                                    title: 'Quiz score',
                                    value: 0,
                                    failure: false,
                                },
                                {
                                    title: 'Threats reported',
                                    value: 0,
                                    failure: false,
                                },
                                { title: 'Ting', value: 0, failure: true },
                            ]"
                        />
                        <div class="card mt-4 p-4">fs</div>
                    </div>
                </template>
            </SlideOver>
            <div
                class="mb-4 grid grid-cols-1 gap-4 md:grid-cols-2 xl:grid-cols-3 2xl:grid-cols-4"
            >
                <AvgScoreCard
                    :loading="loading"
                    :title="$t('statistics.resilience_score.title').toString()"
                    :text="$t('statistics.resilience_score.text').toString()"
                    :value="currentScore"
                    :max="100"
                    :min="0"
                    :unit="$t('statistics.ninety_days').toString()"
                    class="card"
                />
                <BenchmarkCard
                    :loading="loading"
                    :value="currentScore"
                    :network="networkAverage"
                    class="card flex xl:hidden"
                />
                <LineChartCard
                    :loading="loading"
                    :title="
                        $t(
                            'statistics.resilience_score_progression.title'
                        ).toString()
                    "
                    :data="progressionSeries"
                    :labels="progressionLabels"
                    :legends="[
                        {
                            title: $t(
                                'statistics.resilience_score_progression.score'
                            ),
                            color: '#8462ee',
                        },
                    ]"
                    class="card col-span-1 md:col-span-2"
                />
                <BenchmarkCard
                    :loading="loading"
                    :value="currentScore"
                    :network="networkAverage"
                    class="card hidden xl:flex"
                />
                <ScoreCard
                    :loading="loading"
                    :title="$t('statistics.factor.culture.title').toString()"
                    :text="$t('statistics.factor.culture.text').toString()"
                    :value="cultureScore"
                    :factors="cultureFactors"
                />
                <ScoreCard
                    :loading="loading"
                    :title="$t('statistics.factor.competence.title').toString()"
                    :text="$t('statistics.factor.competence.text').toString()"
                    :value="competenceScore"
                    :factors="competenceFactors"
                />
                <ScoreCard
                    :loading="loading"
                    :title="$t('statistics.factor.threats.title').toString()"
                    :text="$t('statistics.factor.threats.text').toString()"
                    :value="threatScore"
                    :factors="threatFactors"
                />
                <ActivityByTypeCard
                    :loading="loading"
                    :simulationsDelivered="simulationsDelivered"
                    :videosWatched="videosWatched"
                    :coursesCompleted="coursesCompleted"
                    :threatsReported="threatsReported"
                    :quizAnswers="quizAnswersTotal"
                />
            </div>
            <div class="mb-8 flex justify-end">
                <p
                    class="text-xxs flex flex-row items-center font-medium text-slate-600"
                >
                    {{ $t("statistics.calculated_at") }}:
                    <span
                        v-if="loading"
                        class="ml-1 h-3 w-24 animate-pulse rounded bg-slate-200"
                    ></span>
                    <span v-else class="ml-1" v-text="calculatedAt"></span>
                </p>
            </div>
            <Tabs>
                <TabItem
                    title="at_risk"
                    @clicked="currentTab = 1"
                    :active="currentTab === 1"
                />
                <TabItem
                    title="insights"
                    @clicked="currentTab = 2"
                    :active="currentTab === 2"
                />
            </Tabs>
            <div class="card mb-4">
                <EmployeeTable
                    v-if="currentTab === 1"
                    :rows="rows"
                    :meta="meta"
                    :loading="employeesLoading"
                    @refresh="getEmployees"
                    class="card"
                />
                <InsightsEmptyState v-else-if="currentTab === 2" />
            </div>
        </template>
    </DashboardPage>
</template>

<script>
import { StatisticsStoreNamespacedTypes } from "@/store/modules/statistics";

import { getProperty } from "@/utils/object";
import { formatDateTime } from "@/utils/date";

import DashboardPage from "@/components/layout/DashboardPage";
import AvgScoreCard from "@/components/statistics/AvgScoreCard";
import Tabs from "@/components/tabs/Tabs";
import TabItem from "@/components/tabs/TabItem";
import ScoreCard from "@/components/score/ScoreCard";
import EmployeeTable from "@/components/display/score/EmployeeTable";
import SlideOver from "@/components/ui/SlideOver";
import ActivityByTypeCard from "@/components/score/ActivityByTypeCard";
import InsightsEmptyState from "@/components/score/InsightsEmptyState";
import LineChartCard from "@/components/statistics/LineChartCard";
import FormDateRangeSelect from "@/components/forms/fields/FormDateRangeSelect";
import BenchmarkCard from "@/components/score/BenchmarkCard";

export default {
    name: "DashboardResiliencePage",
    components: {
        BenchmarkCard,
        FormDateRangeSelect,
        LineChartCard,
        InsightsEmptyState,
        ActivityByTypeCard,
        DashboardPage,
        AvgScoreCard,
        SlideOver,
        EmployeeTable,
        ScoreCard,
        Tabs,
        TabItem,
    },
    data: () => ({
        range: "6-MONTHS",
        showPreviewPrompt: false,
        loading: true,
        employeesLoading: true,
        currentTab: 1,
    }),
    computed: {
        /**
         * @type {Object}
         */
        error() {
            return this.$store.getters[
                StatisticsStoreNamespacedTypes.getters.ERROR
            ]();
        },

        /**
         * @type {Object}
         */
        statistics() {
            return this.$store.getters[
                StatisticsStoreNamespacedTypes.getters.SCORE
            ]();
        },

        /**
         * @type {Object}
         */
        employees() {
            return this.$store.getters[
                StatisticsStoreNamespacedTypes.getters.SCORE_EMPLOYEES
            ]();
        },

        /**
         * @type {Array}
         */
        rows() {
            return getProperty(this.employees, "data", []);
        },

        /**
         * @type {Object}
         */
        meta() {
            return getProperty(this.employees, "meta", null);
        },

        /**
         * Current average score for the last 90 days
         * @type {Number}
         */
        currentScore() {
            return getProperty(this.statistics, "score.current", 0);
        },

        /**
         * Current average score for the last 90 days
         * @type {Number}
         */
        networkAverage() {
            return getProperty(this.statistics, "score.average", 0);
        },

        /**
         * The date the score was calculated
         * @type {String}
         */
        calculatedAt() {
            let date = getProperty(this.statistics, "calculated_at", null);

            return date
                ? formatDateTime(date)
                : this.$t("statistics.not_available");
        },

        /**
         * The reason the score was not calculated
         * @type {String|null}
         */
        failureReason() {
            return getProperty(this.statistics, "failure_reason", null);
        },

        /**
         * Activity data by type
         * @type {Object}
         */
        activity() {
            return getProperty(this.statistics, "activity", {
                mc_quiz_correct_count: 0,
                mc_quiz_incorrect_count: 0,
                phishing_quiz_correct_count: 0,
                phishing_quiz_incorrect_count: 0,
                simulation_click_count: 0,
                simulation_report_count: 0,
                simulation_send_count: 0,
                threat_report_count: 0,
                video_count: 0,
                course_count: 0,
            });
        },

        /**
         * The number of correct phishing quiz answers
         * @type {Number}
         */
        correctPhishingQuizAnswers() {
            return getProperty(this.activity, "phishing_quiz_correct_count", 0);
        },

        /**
         * The number of incorrect phishing quiz answers
         * @type {Number}
         */
        incorrectPhishingQuizAnswers() {
            return getProperty(
                this.activity,
                "phishing_quiz_incorrect_count",
                0
            );
        },

        /**
         * The number of correct multiple choice answers
         * @type {Number}
         */
        correctMultipleChoiceAnswers() {
            return getProperty(this.activity, "mc_quiz_correct_count", 0);
        },

        /**
         * The number of incorrect multiple choice answers
         * @type {Number}
         */
        incorrectMultipleChoiceAnswers() {
            return getProperty(this.activity, "mc_quiz_incorrect_count", 0);
        },

        /**
         * The total number of quiz answers
         * @type {Number}
         */
        quizAnswersTotal() {
            return (
                this.correctPhishingQuizAnswers +
                this.incorrectPhishingQuizAnswers +
                this.correctMultipleChoiceAnswers +
                this.incorrectMultipleChoiceAnswers
            );
        },

        /**
         * The number of simulations delivered
         * @type {Number}
         */
        simulationsDelivered() {
            return getProperty(this.activity, "simulation_send_count", 0);
        },

        /**
         * The number of simulations clicked
         * @type {Number}
         */
        simulationsClicked() {
            return getProperty(this.activity, "simulation_click_count", 0);
        },

        /**
         * The number of simulations missed
         * @type {Number}
         */
        simulationsPassed() {
            let count = this.simulationsDelivered - this.simulationsClicked;
            return count < 0 ? 0 : count;
        },

        /**
         * The number of threats reported
         * @type {Number}
         */
        threatsReported() {
            return getProperty(this.activity, "threat_report_count", 0);
        },

        /**
         * The number of videos watched
         * @type {Number}
         */
        videosWatched() {
            return getProperty(this.activity, "video_count", 0);
        },

        /**
         * The number of courses completed
         * @type {Number}
         */
        coursesCompleted() {
            return getProperty(this.activity, "course_count", 0);
        },

        /**
         * Activity data by type
         * @type {Object}
         */
        factors() {
            return getProperty(this.statistics, "factors", {
                culture: 0,
                competence: 0,
                threats: 0,
            });
        },

        /**
         * Culture pillar score (%)
         * @type {Number}
         */
        cultureScore() {
            return getProperty(this.factors, "culture", 0);
        },

        /**
         * Culture factors
         * @type {Array}
         */
        cultureFactors() {
            return [
                {
                    title: this.$t(
                        "statistics.activities_by_type.videos_watched"
                    ),
                    value: this.videosWatched,
                    failure: false,
                },
                {
                    title: this.$t(
                        "statistics.activities_by_type.simulations_passed"
                    ),
                    value: this.simulationsPassed,
                    failure: false,
                },
                {
                    title: this.$t(
                        "statistics.activities_by_type.simulations_failed"
                    ),
                    value: this.simulationsClicked,
                    failure: true,
                },
            ];
        },

        /**
         * Competence pillar score (%)
         * @type {Number}
         */
        competenceScore() {
            return getProperty(this.factors, "competence", 0);
        },

        /**
         * Competence factors
         * @type {Array}
         */
        competenceFactors() {
            return [
                {
                    title: this.$t(
                        "statistics.activities_by_type.simulations_reported"
                    ),
                    value: this.threatsReported,
                    failure: false,
                },
                {
                    title: this.$t(
                        "statistics.activities_by_type.quiz_correct_answers"
                    ),
                    value: this.correctMultipleChoiceAnswers,
                    failure: false,
                },
                {
                    title: this.$t(
                        "statistics.activities_by_type.quiz_incorrect_answers"
                    ),
                    value: this.incorrectMultipleChoiceAnswers,
                    failure: true,
                },
            ];
        },

        /**
         * Threat pillar score (%)
         * @type {Number}
         */
        threatScore() {
            return getProperty(this.factors, "threats", 0);
        },

        /**
         * Threat factors
         * @type {Array}
         */
        threatFactors() {
            return [
                {
                    title: this.$t(
                        "statistics.activities_by_type.simulations_reported"
                    ),
                    value: this.threatsReported,
                    failure: false,
                },
                {
                    title: this.$t(
                        "statistics.activities_by_type.phishing_quiz_correct_answers"
                    ),
                    value: this.correctPhishingQuizAnswers,
                    failure: false,
                },
                {
                    title: this.$t(
                        "statistics.activities_by_type.phishing_quiz_incorrect_answers"
                    ),
                    value: this.incorrectPhishingQuizAnswers,
                    failure: true,
                },
            ];
        },

        /**
         * Resilience score progression (graph)
         * @type {Object}
         */
        progression() {
            return getProperty(this.statistics, "progression", {
                data: [],
                labels: [],
            });
        },

        /**
         * Resilience score progression series
         * @type {Array}
         */
        progressionSeries() {
            return getProperty(this.progression, "data", []);
        },

        /**
         * Resilience score progression labels
         * @type {Array}
         */
        progressionLabels() {
            return getProperty(this.progression, "labels", []);
        },
    },
    watch: {
        range() {
            this.getScore();
        },
        failureReason(newVal, oldVal) {
            if (newVal) {
                this.$store.commit(
                    StatisticsStoreNamespacedTypes.mutations.SET_ERROR,
                    {
                        message: "api.messages." + newVal,
                    }
                );
            } else if (oldVal) {
                this.$store.commit(
                    StatisticsStoreNamespacedTypes.mutations.SET_ERROR,
                    null
                );
            }
        },
    },
    methods: {
        getScore() {
            this.loading = true;
            this.$store
                .dispatch(StatisticsStoreNamespacedTypes.actions.GET_SCORE, {
                    range: this.range,
                })
                .then(() => {
                    this.loading = false;
                });
        },
        getEmployees(payload = {}) {
            this.employeesLoading = true;
            const data = {
                status: "active",
                ...payload,
            };

            this.$store
                .dispatch(
                    StatisticsStoreNamespacedTypes.actions.GET_SCORE_EMPLOYEES,
                    data
                )
                .then(() => {
                    this.employeesLoading = false;
                });
        },
        showPreview() {
            this.showPreviewPrompt = true;
        },
    },
    mounted() {
        this.$store.commit(
            StatisticsStoreNamespacedTypes.mutations.SET_ERROR,
            null
        );
        this.getScore();
        this.getEmployees();
    },
};
</script>
