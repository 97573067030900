<template>
    <li class="relative mb-4 rounded-xl bg-slate-50 p-4">
        <div class="flex flex-row items-center">
            <div class="flex flex-grow flex-col overflow-hidden">
                <div class="flex flex-row items-center">
                    <div
                        class="mr-2 flex h-5 w-5 items-center justify-center rounded-full bg-slate-300 text-xs font-bold text-slate-900"
                    >
                        {{ step }}
                    </div>
                    <p class="font-medium text-slate-800">
                        {{ title }}
                    </p>
                </div>
                <div
                    v-if="assessmentName"
                    class="ml-7 mt-1 text-sm text-slate-500"
                >
                    <p class="truncate">
                        {{ assessmentName }}
                    </p>
                </div>
            </div>
            <div class="flex items-center gap-x-2">
                <LoadingSpinner v-if="loading" />
                <FormButton
                    :icon="
                        showDeleteConfirmation
                            ? IconEnums.CLOSE
                            : IconEnums.TRASH
                    "
                    :variant="ButtonVariants.SECONDARY"
                    @click.native="
                        showDeleteConfirmation = !showDeleteConfirmation
                    "
                />
                <FormButton
                    v-if="!isEditing && isEditable"
                    :icon="IconEnums.SETTINGS"
                    :variant="ButtonVariants.SECONDARY"
                    @click.native="handleEdit"
                    >{{ $t("automation.flow.actions.edit") }}</FormButton
                >
            </div>
        </div>
        <div
            v-if="showDeleteConfirmation"
            class="mt-2 flex flex-row items-center justify-between rounded-xl bg-white bg-yellow-50/50 p-4 text-warning-dark ring-1 ring-inset ring-yellow-500/20"
        >
            <p class="text-sm font-medium">
                {{ $t("automation.flow.actions.delete.text") }}
            </p>
            <FormButton
                :icon="IconEnums.TRASH"
                :variant="ButtonVariants.SECONDARY"
                @click.native="handleDelete"
                >{{ $t("automation.flow.actions.delete.button") }}</FormButton
            >
        </div>
        <div v-if="showSlot" class="mt-2 rounded-xl bg-white p-4">
            <slot />
        </div>
        <button
            @click="addAction"
            type="button"
            class="absolute -bottom-5 left-1/2 z-10 -ml-1.5 rounded-full bg-indigo-600 p-1 text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
        >
            <svg
                @click="$emit('add', action)"
                class="h-4 w-4"
                viewBox="0 0 20 20"
                fill="currentColor"
                aria-hidden="true"
            >
                <path
                    d="M10.75 4.75a.75.75 0 00-1.5 0v4.5h-4.5a.75.75 0 000 1.5h4.5v4.5a.75.75 0 001.5 0v-4.5h4.5a.75.75 0 000-1.5h-4.5v-4.5z"
                />
            </svg>
        </button>
        <div
            v-if="!showSlot"
            class="mt-4 flex flex-row items-center border-t-2 border-white py-1 pt-4"
        >
            <svg
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                class="mr-2 h-5 w-5"
            >
                <rect
                    x="3.5"
                    y="9.5"
                    width="3"
                    height="6"
                    rx="1.5"
                    fill="currentColor"
                ></rect>
                <rect
                    x="8.5"
                    y="6.5"
                    width="3"
                    height="9"
                    rx="1.5"
                    fill="#dad9fd"
                ></rect>
                <rect
                    x="13.5"
                    y="3.5"
                    width="3"
                    height="12"
                    rx="1.5"
                    fill="#dad9fd"
                ></rect>
            </svg>
            <p class="text-xs font-bold text-slate-500">
                <span class="mr-4"
                    >{{ $t("automation.flow.actions.status.active") }}:
                    {{ activeCount }}</span
                >
                <span class="mr-4"
                    >{{ $t("automation.flow.actions.status.completed") }}:
                    {{ completedCount }}</span
                >
                <span
                    >{{ $t("automation.flow.actions.status.halted") }}:
                    {{ haltedCount }}</span
                >
            </p>
        </div>
    </li>
</template>

<script>
import { AutomationStoreNamespacedTypes } from "@/store/modules/automation";

import { getProperty } from "@/utils/object";

import { IconEnums } from "@/utils/icons";
import FormButton, {
    ButtonVariants,
} from "@/components/forms/fields/FormButton";
import LoadingSpinner from "@/components/ui/LoadingSpinner";

export default {
    name: "AutomationFlowBuilderStep",
    components: { LoadingSpinner, FormButton },
    props: {
        id: {
            type: String,
            default: "",
        },
        index: {
            type: Number,
            default: 0,
        },
    },
    data: () => ({
        IconEnums,
        ButtonVariants,
        showDeleteConfirmation: false,
    }),
    computed: {
        action() {
            return this.$store.getters[
                AutomationStoreNamespacedTypes.getters.ACTION
            ](this.id);
        },

        /**
         * @type {Array}
         */
        courses() {
            return this.$store.getters[
                AutomationStoreNamespacedTypes.getters.COURSES
            ]();
        },

        /**
         * @type {Array}
         */
        videos() {
            return this.$store.getters[
                AutomationStoreNamespacedTypes.getters.VIDEOS
            ]();
        },

        loading() {
            return getProperty(this.action, "loading", false);
        },

        stored() {
            return getProperty(this.action, "stored", false);
        },

        key() {
            return getProperty(this.action, "key", "action_selector");
        },

        attributes() {
            return getProperty(this.action, "attributes");
        },

        statistics() {
            return getProperty(this.action, "statistics");
        },

        activeCount() {
            return getProperty(this.statistics, "active.count", 0);
        },

        completedCount() {
            return getProperty(this.statistics, "completed.count", 0);
        },

        haltedCount() {
            return getProperty(this.statistics, "halted.count", 0);
        },

        isEditable() {
            return (
                this.key !== "action_selector" &&
                this.key !== "halt_action" &&
                this.key !== "assign_lesson_action"
            );
        },

        isEditing() {
            return getProperty(this.action, "editing", false);
        },

        showSlot() {
            return (this.isEditing && this.isEditable) || !this.isEditable;
        },

        /**
         * Step title
         * @type {String}
         */
        title() {
            if (this.stored && this.key === "wait_action") {
                return this.$t("automation.flow.title.stored.wait_action", {
                    length: this.attributes.length,
                    unit: this.$tc(
                        "automation.flow.actions.wait_units." +
                            this.attributes.unit,
                        this.attributes.length
                    ),
                }).toString();
            }

            if (this.stored) {
                return this.$t(
                    "automation.flow.title.stored." + this.key
                ).toString();
            }

            return this.$t(
                "automation.flow.title.editing." + this.key
            ).toString();
        },

        /**
         * Assessment title
         * @type {String}
         */
        assessmentName() {
            if (
                this.key !== "assign_course_action" &&
                this.key !== "assign_video_action"
            ) {
                return null;
            }

            if (this.key === "assign_course_action") {
                let course = this.courses.find(
                    (course) =>
                        course.id === getProperty(this.attributes, "content_id")
                );
                return course ? course.title : null;
            }

            if (this.key === "assign_video_action") {
                let video = this.videos.find(
                    (video) =>
                        video.id === getProperty(this.attributes, "content_id")
                );
                return video ? video.title : null;
            }

            return null;
        },
        step() {
            return this.index + 1;
        },
    },
    methods: {
        addAction() {
            this.$emit("add-action", this.index, true);
        },
        handleEdit() {
            this.$emit("edit-action", this.index);
        },
        handleDelete() {
            this.$emit("delete-action", this.id, this.stored, this.index);
        },
    },
};
</script>
