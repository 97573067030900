<template>
    <ValidationProvider
        tag="div"
        class="filter-select filter"
        :vid="vid"
        :rules="rules"
        :name="name || label"
        v-slot="{ errors, ariaInput }"
    >
        <select
            class="w-full cursor-pointer items-center gap-2 rounded-full border-0 bg-slate-50/50 px-3 py-1.5 pl-3 pr-9 text-xs font-medium text-slate-800 outline-0 ring-1 ring-inset ring-slate-500/20 transition-all hover:ring-slate-500/50 sm:text-sm"
            :class="{ 'is-invalid': errors[0] }"
            :id="id || name"
            v-bind="ariaInput"
            ref="input"
            v-model="innerValue"
        >
            <option v-if="value === null" :value="null">
                {{ label || name }}
            </option>
            <slot />
        </select>
    </ValidationProvider>
</template>

<script>
import FieldMixin from "./FieldMixin";
export default {
    name: "SelectFilter",
    mixins: [FieldMixin],
    props: {
        type: {
            type: String,
            default: "button",
        },
    },
};
</script>
