<template>
    <WorkspaceSettingsPage>
        <SlideOver :active="showPrompt" @close="showPrompt = false"
            ><h3
                slot="title"
                class="text-base font-semibold leading-6 text-slate-900"
            >
                {{ $t("forms.buttons.create_content") }}
            </h3>
            <template v-slot:content>
                <UploadVideoForm
                    @saved="handleVideoSaved"
                    v-if="showPrompt" /></template
        ></SlideOver>
        <div class="card">
            <CardHeading
                :title="$t('settings.content.heading').toString()"
                :text="$t('settings.content.text').toString()"
                :loading="loading"
            >
                <FormButton
                    @click.native="showPrompt = true"
                    :variant="ButtonVariants.SECONDARY"
                >
                    {{ $t("forms.buttons.create_content") }}
                </FormButton>
            </CardHeading>
        </div>
    </WorkspaceSettingsPage>
</template>

<script>
import { WorkspaceStoreNamespacedTypes } from "@/store/modules/workspace";

import { getProperty } from "@/utils/object";

import WorkspaceSettingsPage from "@/components/layout/WorkspaceSettingsPage";
import FormButton, {
    ButtonVariants,
} from "@/components/forms/fields/FormButton";
import Bus from "@/bus";
import CardHeading from "@/components/ui/CardHeading";
import UploadVideoForm from "@/components/forms/UploadVideoForm";
import SlideOver from "@/components/ui/SlideOver";

export default {
    name: "ContentSettings",
    components: {
        SlideOver,
        UploadVideoForm,
        CardHeading,
        FormButton,
        WorkspaceSettingsPage,
    },
    data: () => ({
        ButtonVariants,
        showPrompt: false,
    }),
    computed: {
        /**
         * @type {Boolean}
         */
        loading() {
            return this.$store.getters[
                WorkspaceStoreNamespacedTypes.getters.WORKSPACE_LOADING
            ]();
        },

        /**
         * @type {Object}
         */
        error() {
            return this.$store.getters[
                WorkspaceStoreNamespacedTypes.getters.WORKSPACE_ERROR
            ]();
        },

        /**
         * @type {Object}
         */
        workspace() {
            return this.$store.getters[
                WorkspaceStoreNamespacedTypes.getters.WORKSPACE
            ]();
        },

        /**
         * @type {Array}
         */
        videos() {
            return getProperty(this.workspace, "videos", []);
        },
    },
    methods: {
        getWorkspace() {
            this.$store.dispatch(
                WorkspaceStoreNamespacedTypes.actions.GET_WORKSPACE,
                {
                    force: true,
                }
            );
        },
        handleVideoSaved() {
            this.getWorkspace();
            Bus.$emit("flash-message", {
                text: this.$t("settings.content.video_created"),
                type: "success",
            });
            this.showPrompt = false;
        },
        handleDelete(identifier) {
            this.$store
                .dispatch(WorkspaceStoreNamespacedTypes.actions.UPDATE_FIELDS, {
                    identifier: identifier,
                    type: null,
                })
                .then(() => {
                    if (!this.error) {
                        this.getWorkspace();
                        Bus.$emit("flash-message", {
                            text: this.$t("settings.content.video_deleted"),
                            type: "success",
                        });
                    }
                });
        },
    },
};
</script>
