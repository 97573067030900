<template>
    <path
        d="M5 3v18m1.472-7.483a6.59 6.59 0 0 1 5.377.522 6.589 6.589 0 0 0 5.218.573l1.309-.405A.886.886 0 0 0 19 13.36V4.485c0-.89-1.666-.067-2.045.05A6.028 6.028 0 0 1 12 3.904a6.028 6.028 0 0 0-4.955-.633L5.6 3.717A.85.85 0 0 0 5 4.53v8.927c0 .756 1.138.173 1.472.06Z"
        fill="none"
        stroke="currentColor"
        stroke-linecap="round"
        stroke-linejoin="round"
        stroke-width="2"
    />
</template>

<script>
import IconSvgBase from "./IconSvgBase";

export default {
    extends: IconSvgBase,
    name: "IconAlert",
};
</script>
