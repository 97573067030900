<template>
    <g
        fill="none"
        stroke="currentColor"
        stroke-linecap="round"
        stroke-linejoin="round"
        stroke-width="2"
    >
        <path d="M21 12c0 2-3.5 7-9 7s-9-5-9-7 3.5-7 9-7 9 5 9 7Z" />
        <path d="M15 12a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z" />
    </g>
</template>

<script>
import IconSvgBase from "./IconSvgBase";

export default {
    extends: IconSvgBase,
    name: "IconEye",
};
</script>
