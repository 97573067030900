<template>
    <ValidationProvider
        tag="div"
        class="form-field form-field-select"
        :vid="vid"
        :rules="rules"
        :name="name || label"
        v-slot="{ errors, ariaInput, ariaMsg }"
    >
        <label
            class="mb-2 block text-sm font-medium text-slate-900"
            @click="$refs.input.focus()"
            :for="id || name"
            v-if="label"
        >
            {{ label || name }}
        </label>
        <select
            class="min-w-form-field block w-full rounded-md border-slate-300 py-2 pl-3 pr-10 text-base focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
            :class="{ 'is-invalid': errors[0] }"
            :id="id || name"
            v-bind="ariaInput"
            ref="input"
            v-model="innerValue"
        >
            <option v-if="value === null" :value="null">Please select</option>
            <slot />
        </select>
        <p v-if="help" class="mt-2 block text-sm text-slate-500">
            {{ help }}
        </p>
        <span
            class="mt-2 text-sm text-red-600"
            v-bind="ariaMsg"
            v-if="errors[0]"
            >{{ errors[0] }}</span
        >
    </ValidationProvider>
</template>

<script>
import FieldMixin from "./FieldMixin";
export default {
    name: "FormSelect",
    mixins: [FieldMixin],
    props: {
        type: {
            type: String,
            default: "button",
        },
    },
};
</script>
