import AES from "crypto-js/aes";
import UTF8 from "crypto-js/enc-utf8";

export function setEncryptedStorageItem(key, value) {
    if (!value) return;

    try {
        const encrypted = AES.encrypt(
            JSON.stringify(value),
            process.env.VUE_APP_STATE_KEY
        );

        localStorage.setItem(key, encrypted.toString());
    } catch (error) {
        localStorage.setItem(key, null);
    }
}

export function getEncryptedStorageItem(key) {
    const encryptedText = localStorage.getItem(key);

    if (!encryptedText) return null;

    try {
        const decryptedStr = AES.decrypt(
            encryptedText,
            process.env.VUE_APP_STATE_KEY
        ).toString(UTF8);

        return JSON.parse(decryptedStr);
    } catch (error) {
        return null;
    }
}

export function removeStorageItem(key) {
    localStorage.removeItem(key);
}
