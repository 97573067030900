<template>
    <TwoCol>
        <div slot="content">
            <SlideOver
                :active="showEmployeePrompt"
                @close="showEmployeePrompt = false"
                :gutter="false"
            >
                <h2
                    slot="title"
                    class="text-base font-semibold leading-6 text-slate-900"
                >
                    {{ $t("employees.preview.title") }}
                </h2>
                <template v-slot:content>
                    <EmployeePreview :employee="employee" />
                </template>
            </SlideOver>
            <Heading
                :title="$t('automation.participants.heading').toString()"
                :text="$t('automation.participants.text').toString()"
                :return-to="{
                    name: 'automation.single',
                    params: { id: $route.params.id },
                }"
            >
                <FormSelect v-model="status">
                    <option value="all">
                        {{ $t("automation.filters.status.all") }} ({{
                            total_count
                        }})
                    </option>
                    <option value="active">
                        {{ $t("automation.filters.status.active") }} ({{
                            active_count
                        }})
                    </option>
                    <option value="completed">
                        {{ $t("automation.filters.status.completed") }} ({{
                            completed_count
                        }})
                    </option>
                    <option value="halted">
                        {{ $t("automation.filters.status.halted") }} ({{
                            halted_count
                        }})
                    </option>
                </FormSelect>
            </Heading>
            <div class="card mb-4">
                <ParticipantTable
                    :rows="rows"
                    :meta="meta"
                    :loading="loading"
                    :error="error"
                    @refresh="handleRefresh"
                    @emit="handleOpen"
                    class="card"
                />
            </div>
        </div>
        <div slot="sidebar">
            <Heading
                :title="$t('automation.details.heading').toString()"
                :text="$t('automation.details.text').toString()"
            />
            <ValueCard
                :loading="loading"
                :title="$t('statistics.participant_coverage.title').toString()"
                :text="$t('statistics.participant_coverage.text').toString()"
                :value="participation_coverage"
                :pct="true"
                class="card mb-4"
            />
            <GroupChartCard
                :loading="loading"
                :title="$t('automation.participants.heading').toString()"
                identifier="participant_partition"
                :data="statisticsPartition"
                :rows="3"
                class="card"
            />
        </div>
    </TwoCol>
</template>

<script>
import { AutomationStoreNamespacedTypes } from "@/store/modules/automation";

import { getProperty } from "@/utils/object";
import { pct } from "@/utils/math";

import Heading from "@/components/layout/Heading";
import TwoCol from "@/components/layout/TwoCol";
import GroupChartCard from "@/components/statistics/GroupChartCard";
import ParticipantTable from "@/components/display/automation/ParticipantTable";
import FormSelect from "@/components/forms/fields/FormSelect";
import EmployeePreview from "@/components/display/employee/EmployeePreview";
import SlideOver from "@/components/ui/SlideOver";
import ValueCard from "@/components/statistics/ValueCard";

export default {
    name: "AutomationAction",
    components: {
        ValueCard,
        SlideOver,
        EmployeePreview,
        FormSelect,
        ParticipantTable,
        GroupChartCard,
        TwoCol,
        Heading,
    },
    data: () => ({
        showEmployeePrompt: false,
        employee: null,
        status: "all",
    }),
    computed: {
        /**
         * @type {Boolean}
         */
        loading() {
            return this.$store.getters[
                AutomationStoreNamespacedTypes.getters.AUTOMATION_LOADING
            ]();
        },

        /**
         * @type {Object}
         */
        error() {
            return this.$store.getters[
                AutomationStoreNamespacedTypes.getters.AUTOMATION_ERROR
            ]();
        },

        /**
         * @type {Array}
         */
        automation() {
            return this.$store.getters[
                AutomationStoreNamespacedTypes.getters.AUTOMATION
            ]();
        },

        /**
         * @type {Object}
         */
        firstAction() {
            let actions = getProperty(this.automation, "actions", []);
            return actions[0] || null;
        },

        id() {
            return getProperty(this.$route.params, "actionId");
        },

        /**
         * The action
         * @type {Object}
         */
        action() {
            return this.$store.getters[
                AutomationStoreNamespacedTypes.getters.ACTION
            ](this.id);
        },

        /**
         * The participants
         * @type {Object}
         */
        participants() {
            return this.$store.getters[
                AutomationStoreNamespacedTypes.getters.PARTICIPANTS
            ]();
        },

        /**
         * @type {Array}
         */
        rows() {
            return getProperty(this.participants, "data", []);
        },

        /**
         * @type {Object}
         */
        meta() {
            return getProperty(this.participants, "meta", null);
        },

        /**
         * Step statistics
         * @type {Object}
         */
        statistics() {
            return getProperty(this.action, "statistics", {
                active: 0,
                completed: 0,
                halted: 0,
            });
        },

        /**
         * Participation coverage
         * @type {Number}
         */
        participation_coverage() {
            return (
                pct(
                    getProperty(this.statistics, "total", 0),
                    getProperty(this.firstAction, "statistics.total", 0)
                ) * 100
            );
        },

        /**
         * @type {Number}
         */
        active_count() {
            return getProperty(this.statistics, "active.count", 0);
        },

        /**
         * @type {Number}
         */
        completed_count() {
            return getProperty(this.statistics, "completed.count", 0);
        },

        /**
         * @type {Number}
         */
        halted_count() {
            return getProperty(this.statistics, "halted.count", 0);
        },

        /**
         * @type {Number}
         */
        total_count() {
            return this.active_count + this.completed_count + this.halted_count;
        },

        /**
         * Statistics partition
         * @type {Object}
         */
        statisticsPartition() {
            return {
                count: getProperty(this.statistics, "total", 0),
                groups: [
                    {
                        label: "active",
                        count: this.active_count,
                        pct: getProperty(this.statistics, "active.pct", 0),
                        color: "warning-normal",
                    },
                    {
                        label: "completed",
                        count: this.completed_count,
                        pct: getProperty(this.statistics, "completed.pct", 0),
                        color: "primary-normal",
                    },
                    {
                        label: "halted",
                        count: this.halted_count,
                        pct: getProperty(this.statistics, "halted.pct", 0),
                        color: "error-normal",
                    },
                ],
            };
        },
    },
    watch: {
        status() {
            this.handleRefresh();
        },
    },
    methods: {
        handleRefresh(payload = {}) {
            const data = {
                status: this.status,
                ...payload,
            };

            this.$store.dispatch(
                AutomationStoreNamespacedTypes.actions.GET_PARTICIPANTS,
                {
                    id: this.id,
                    data: data,
                }
            );
        },
        handleOpen(subscriber) {
            this.employee = getProperty(subscriber, "employee", null);
            this.showEmployeePrompt = true;
        },
    },
    beforeCreate() {
        this.$store.dispatch(
            AutomationStoreNamespacedTypes.actions.RESET_AUTOMATION
        );
    },
    mounted() {
        this.handleRefresh();
        this.$store.dispatch(
            AutomationStoreNamespacedTypes.actions.GET_AUTOMATION,
            this.$route.params.id
        );
    },
};
</script>
