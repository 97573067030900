import { checkCollinear, getDistance, moveTo } from "./math";

export function polarToCartesian(radius, angle, xCenter, yCenter) {
    const angleInRadians = ((angle - 90) * Math.PI) / 180;

    return {
        x: xCenter + radius * Math.cos(angleInRadians),
        y: yCenter + radius * Math.sin(angleInRadians),
    };
}

export function describePath(radius, startAngle, endAngle, xCenter, yCenter) {
    const start = polarToCartesian(radius, endAngle, xCenter, yCenter);
    const end = polarToCartesian(radius, startAngle, xCenter, yCenter);

    const largeArcFlag = endAngle - startAngle <= 180 ? "0" : "1";

    return [
        "M",
        start.x,
        start.y,
        "A",
        radius,
        radius,
        0,
        largeArcFlag,
        0,
        end.x,
        end.y,
        "L",
        xCenter,
        yCenter,
    ].join(" ");
}

export function genPoints(arr, { minX, minY, maxX, maxY }, { max, min }) {
    arr = arr.map((item) => (typeof item === "number" ? item : item.value));
    const minValue = Math.min(...arr, min) - 0.001;
    const gridX = (maxX - minX) / (arr.length - 1);
    const gridY = (maxY - minY) / (Math.max(...arr, max) + 0.001 - minValue);

    return arr.map((value, index) => {
        return {
            x: index * gridX + minX,
            y:
                maxY -
                (value - minValue) * gridY +
                +(index === arr.length - 1) * 0.00001 -
                +(index === 0) * 0.00001,
        };
    });
}

export function genPath(points, radius) {
    const start = points.shift();

    return (
        `M${start.x} ${start.y}` +
        points
            .map((point, index) => {
                const next = points[index + 1];
                const prev = points[index - 1] || start;
                const isCollinear = next && checkCollinear(next, point, prev);

                if (!next || isCollinear) {
                    return `L${point.x} ${point.y}`;
                }

                const threshold = Math.min(
                    getDistance(prev, point),
                    getDistance(next, point)
                );
                const isTooCloseForRadius = threshold / 2 < radius;
                const radiusForPoint = isTooCloseForRadius
                    ? threshold / 2
                    : radius;

                const before = moveTo(prev, point, radiusForPoint);
                const after = moveTo(next, point, radiusForPoint);

                return `L${before.x} ${before.y}S${point.x} ${point.y} ${after.x} ${after.y}`;
            })
            .join("")
    );
}
