<template>
    <TwoCol>
        <div slot="content">
            <Heading
                :title="title"
                :text="domain"
                :loading="loading"
                :returnTo="{ name: 'breach.index' }"
            />
            <EmployeeTable
                :rows="rows"
                :loading="loading"
                @emit="handleClick"
                class="card"
            />
        </div>
        <div slot="sidebar">
            <Heading
                :title="$t('breaches.compromised_info').toString()"
                :text="$t('breaches.data_types').toString()"
            />
            <dl class="card divide-y divide-slate-200">
                <div
                    v-for="item in breach.data_classes"
                    :key="item"
                    class="flex justify-between p-4 text-sm font-medium"
                >
                    <dt class="text-slate-500">{{ item }}</dt>
                    <dd>
                        <DataSensitivity :item="item" />
                    </dd>
                </div>
            </dl>
            <dl
                class="card mt-4 grid grid-cols-1 gap-x-4 gap-y-8 p-4 sm:grid-cols-2"
            >
                <div class="sm:col-span-1">
                    <dt class="text-sm font-medium text-slate-500">
                        {{ $t("breaches.date") }}
                    </dt>
                    <dd class="mt-1 text-sm text-slate-900">
                        {{ date }}
                    </dd>
                </div>
                <div class="sm:col-span-1">
                    <dt class="text-sm font-medium text-slate-500">
                        {{ $t("breaches.breached_accounts") }}
                    </dt>
                    <dd class="mt-1 text-sm text-slate-900">
                        {{ total_count }}
                    </dd>
                </div>
            </dl>
        </div>
    </TwoCol>
</template>

<script>
import { BreachStoreNamespacedTypes } from "@/store/modules/breach";

import { getProperty } from "@/utils/object";
import { formatDate } from "@/utils/date";
import { formatNumber } from "@/utils/number";
import { redirectWithParamsTo } from "@/router";

import TwoCol from "@/components/layout/TwoCol";
import Heading from "@/components/layout/Heading";
import DataSensitivity from "@/components/display/breach/DataSensitivity";
import EmployeeTable from "@/components/display/employee/EmployeeTable";

export default {
    name: "BreachSingle",
    components: {
        EmployeeTable,
        DataSensitivity,
        Heading,
        TwoCol,
    },
    data: () => ({
        showPrompt: false,
    }),
    computed: {
        /**
         * @type {Boolean}
         */
        loading() {
            return this.$store.getters[
                BreachStoreNamespacedTypes.getters.BREACH_LOADING
            ]();
        },

        /**
         * @type {Object}
         */
        error() {
            return this.$store.getters[
                BreachStoreNamespacedTypes.getters.BREACH_ERROR
            ]();
        },

        /**
         * @type {Object}
         */
        breach() {
            return this.$store.getters[
                BreachStoreNamespacedTypes.getters.BREACH
            ]();
        },

        /**
         * @type {Array}
         */
        rows() {
            return getProperty(this.breach, "employees", []);
        },

        /**
         * @type {string}
         */
        title() {
            return getProperty(
                this.breach,
                "name",
                this.$t("breaches.information")
            );
        },

        /**
         * @type {string}
         */
        domain() {
            return getProperty(
                this.breach,
                "domain",
                this.$t("breaches.missing_domain")
            );
        },

        date() {
            let date = getProperty(this.breach, "breached_at");

            if (date) {
                return formatDate(date);
            }

            return null;
        },

        total_count() {
            let count = getProperty(this.breach, "total_count", 0);

            return formatNumber(count);
        },
    },
    methods: {
        handleClick(employee) {
            redirectWithParamsTo({
                name: "employee.single.index",
                params: {
                    id: getProperty(employee, "id"),
                },
            });
        },
    },
    mounted() {
        this.$store.dispatch(
            BreachStoreNamespacedTypes.actions.GET_BREACH,
            this.$route.params.id
        );
    },
};
</script>
