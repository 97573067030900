<template>
    <div>
        <header
            id="navigation"
            class="relative inset-x-0 top-0 flex flex-row items-center justify-between gap-6 border-b bg-white px-4 py-2 backdrop-blur-sm transition xl:fixed xl:z-30 xl:gap-8"
        >
            <div class="flex flex-shrink-0 lg:hidden xl:flex">
                <router-link :to="{ name: 'dashboard.overview' }">
                    <Logo class="w-8" />
                </router-link>
            </div>
            <nav class="hidden lg:flex lg:flex-1 lg:flex-col">
                <ul
                    role="list"
                    class="-mx-2 flex flex-1 flex-row items-center space-x-1"
                >
                    <NavItem
                        :text="$t('navigation.dashboard').toString()"
                        :route="{ name: 'dashboard.overview' }"
                        group="dashboard"
                        :icon="IconEnums.GRAPH"
                    />
                    <NavItem
                        v-if="hasFeature('app-simulations')"
                        :text="$t('navigation.simulations').toString()"
                        :route="{ name: 'campaign.index' }"
                        group="simulation"
                        :icon="IconEnums.SIMULATION"
                    />
                    <NavItem
                        v-if="hasFeature('app-training')"
                        :text="$t('navigation.training').toString()"
                        :route="{ name: 'training.index' }"
                        group="training"
                        :icon="IconEnums.TRAINING"
                    />
                    <NavItem
                        v-if="hasFeature('app-automation')"
                        :text="$t('navigation.automation').toString()"
                        :route="{ name: 'automation.index' }"
                        group="automation"
                        :icon="IconEnums.SIGNAL"
                    />
                    <NavItem
                        v-if="hasFeature('app-threats')"
                        :text="$t('navigation.threats').toString()"
                        :route="{ name: 'threat.index' }"
                        :count="unhandledThreatCount"
                        group="threats"
                        :icon="IconEnums.THREAT"
                    />
                    <NavItem
                        v-if="hasFeature('app-breaches')"
                        :text="$t('navigation.breaches').toString()"
                        :route="{ name: 'breach.index' }"
                        group="breaches"
                        :icon="IconEnums.BREACH"
                    />
                    <NavItem
                        :text="$t('navigation.team').toString()"
                        :route="{ name: 'employee.index' }"
                        group="team"
                        :icon="IconEnums.TEAM"
                    />
                </ul>
            </nav>
            <div class="flex flex-row">
                <div class="flex items-center gap-5 lg:hidden">
                    <FormButton
                        :variant="ButtonVariants.SECONDARY"
                        @click.native="toggleMobileNavigation"
                        :icon="
                            isMobileNavigationVisible
                                ? IconEnums.CLOSE
                                : IconEnums.MENU
                        "
                    />
                </div>
                <div class="hidden lg:flex">
                    <ul
                        role="list"
                        class="-mx-2 flex flex-1 flex-row items-stretch space-x-1"
                    >
                        <NavItem
                            v-if="isManager"
                            :text="$t('navigation.settings').toString()"
                            :route="{ name: 'settings' }"
                            group="settings"
                            :icon="IconEnums.SETTINGS"
                        />
                        <NavItem
                            :text="$t('navigation.sign_out').toString()"
                            :icon="IconEnums.LOGOUT"
                            @click="logout"
                        />
                    </ul>
                </div>
            </div>
        </header>
        <transition
            enter-active-class="transition ease-out duration-100"
            enter-class="transform opacity-0 scale-95"
            enter-to-class="transform opacity-100 scale-100"
            leave-active-class="transition ease-in duration-75"
            leave-class="transform opacity-100 scale-100"
            leave-to-class="transform opacity-0 scale-95"
        >
            <div
                class="absolute z-40 inline-flex max-h-full w-full flex-col overflow-hidden rounded-b-xl bg-white px-6 py-4 text-left shadow-xl transition-all xl:hidden"
                v-show="isMobileNavigationVisible"
            >
                <ul role="list" class="-mx-2 flex flex-1 flex-col space-y-2">
                    <NavItem
                        :text="$t('navigation.dashboard').toString()"
                        :route="{ name: 'dashboard.overview' }"
                        group="dashboard"
                        :icon="IconEnums.GRAPH"
                    />
                    <NavItem
                        v-if="hasFeature('app-simulations')"
                        :text="$t('navigation.simulations').toString()"
                        :route="{ name: 'campaign.index' }"
                        group="simulation"
                        :icon="IconEnums.SIMULATION"
                    />
                    <NavItem
                        v-if="hasFeature('app-training')"
                        :text="$t('navigation.training').toString()"
                        :route="{ name: 'training.index' }"
                        group="training"
                        :icon="IconEnums.TRAINING"
                    />
                    <NavItem
                        v-if="hasFeature('app-automation')"
                        :text="$t('navigation.automation').toString()"
                        :route="{ name: 'automation.index' }"
                        group="automation"
                        :icon="IconEnums.SIGNAL"
                    />
                    <NavItem
                        v-if="hasFeature('app-threats')"
                        :text="$t('navigation.threats').toString()"
                        :route="{ name: 'threat.index' }"
                        :count="unhandledThreatCount"
                        group="threats"
                        :icon="IconEnums.THREAT"
                    />
                    <NavItem
                        v-if="hasFeature('app-breaches')"
                        :text="$t('navigation.breaches').toString()"
                        :route="{ name: 'breach.index' }"
                        group="breaches"
                        :icon="IconEnums.BREACH"
                    />
                    <NavItem
                        :text="$t('navigation.team').toString()"
                        :route="{ name: 'employee.index' }"
                        group="team"
                        :icon="IconEnums.TEAM"
                    />
                    <NavItem
                        v-if="isManager"
                        :text="$t('navigation.settings').toString()"
                        :route="{ name: 'settings' }"
                        group="settings"
                        :icon="IconEnums.SETTINGS"
                    />
                </ul>
            </div>
        </transition>
    </div>
</template>

<script>
import { WorkspaceStoreNamespacedTypes } from "@/store/modules/workspace";
import { AuthStoreNamespacedTypes } from "@/store/modules/auth";
import { LayoutStoreNamespacedTypes } from "@/store/modules/layout";

import { getProperty } from "@/utils/object";
import { redirectTo } from "@/router";

import { IconEnums } from "@/utils/icons";
import NavItem from "./NavItem";
import Logo from "@/components/ui/Logo";
import FeatureFlagMixin from "@/mixins/FeatureFlagMixin";
import FormButton, {
    ButtonVariants,
} from "@/components/forms/fields/FormButton";

export default {
    name: "SidebarNavigation",
    mixins: [FeatureFlagMixin],
    components: { FormButton, Logo, NavItem },
    data: () => ({
        IconEnums,
        ButtonVariants,
    }),
    computed: {
        /**
         * The user model
         * @type {Boolean}
         */
        isManager() {
            return this.$store.getters[
                AuthStoreNamespacedTypes.getters.IS_MANAGER
            ]();
        },

        /**
         * The authentication state for the user model
         * @type {Object}
         */
        workspace() {
            return this.$store.getters[
                WorkspaceStoreNamespacedTypes.getters.WORKSPACE
            ]();
        },

        /**
         * The number of unhandled threats
         * @type {Number}
         */
        unhandledThreatCount() {
            return getProperty(this.workspace, "unhandled_threats_count", 0);
        },

        /**
         * show mobile navigation
         * @type {Boolean}
         */
        isMobileNavigationVisible() {
            return this.$store.getters[
                LayoutStoreNamespacedTypes.getters.IS_VISIBLE
            ]("mobile-navigation");
        },
    },
    methods: {
        logout() {
            this.$store
                .dispatch(AuthStoreNamespacedTypes.actions.LOGOUT)
                .then(() => {
                    this.$store
                        .dispatch(
                            WorkspaceStoreNamespacedTypes.actions.CLEAR_SESSION
                        )
                        .then(() => {
                            redirectTo("login");
                        });
                });
        },
        toggleMobileNavigation() {
            this.$store.dispatch(
                LayoutStoreNamespacedTypes.actions.SET_VISIBLE,
                {
                    id: "mobile-navigation",
                    visible: !this.isMobileNavigationVisible,
                }
            );
        },
    },
};
</script>
