<template>
    <li>
        <div class="relative pb-8">
            <span
                class="absolute left-5 top-5 -ml-px hidden h-full w-0.5 bg-slate-200 md:block"
                aria-hidden="true"
            ></span>
            <div class="relative flex items-start md:space-x-3">
                <div class="hidden md:inline">
                    <div class="relative px-1">
                        <div
                            class="flex h-8 w-8 items-center justify-center rounded-full bg-slate-100 ring-8 ring-white"
                        >
                            <svg
                                class="h-5 w-5 text-slate-500"
                                fill="none"
                                stroke="currentColor"
                                viewBox="0 0 24 24"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                    stroke-width="2"
                                    d="M16 12a4 4 0 10-8 0 4 4 0 008 0zm0 0v1.5a2.5 2.5 0 005 0V12a9 9 0 10-9 9m4.5-1.206a8.959 8.959 0 01-4.5 1.207"
                                ></path>
                            </svg>
                        </div>
                    </div>
                </div>
                <EmailPreview :email="simulation" />
            </div>
        </div>
    </li>
</template>

<script>
import EmailPreview from "@/components/ui/EmailPreview";

export default {
    name: "CampaignSendablePreview",
    components: {
        EmailPreview,
    },
    props: {
        simulation: {
            type: Object,
            required: true,
        },
    },
};
</script>
