<template>
    <FilterableDataTable
        name="deadlines.index"
        :headers="headers"
        :rows="rows"
        :meta="meta"
        :loading="loading"
        :error="error"
        default-sort-key="deadline_at"
        default-sort-dir="desc"
        @update="refresh"
    >
        <template v-slot:employee="{ item }">
            <div class="flex items-center">
                <Avatar
                    :image="item.employee.avatar"
                    :name="item.employee.full_name"
                />
                <div class="ml-3">
                    <div class="text-sm font-medium text-slate-900">
                        {{ item.employee.full_name }}
                    </div>
                    <div class="text-sm text-slate-500">
                        {{ item.employee.email || "No email found" }}
                    </div>
                </div>
            </div>
        </template>
        <template v-slot:content="{ item }">
            <div>
                <div class="text-sm font-medium text-slate-900">
                    {{ item.content.title }}
                </div>
                <div class="text-sm text-slate-500">
                    {{ $t("training.type." + item.content.type) }}
                </div>
            </div>
        </template>
        <template v-slot:deadline_at="{ item }">
            <span v-if="item.deadline_at">
                {{ item.deadline_at | formatDate }}
            </span>
            <span v-else>-</span>
        </template>
        <template v-slot:reminder_sent_at="{ item }">
            <span v-if="item.reminder_sent_at">
                {{ item.reminder_sent_at | formatDateTime }}
            </span>
            <span v-else>-</span>
        </template>
        <template v-slot:action="{ item }">
            <div class="flex justify-end space-x-2">
                <DeadlineReminderButton :item="item" />
                <DeadlineDeleteButton :item="item" />
            </div>
        </template>
    </FilterableDataTable>
</template>

<script>
import DataTableMixin from "@/components/tables/DataTableMixin";
import { initials } from "@/utils/filters";
import { formatDate, formatDateTime } from "@/utils/date";
import DeadlineReminderButton from "@/components/display/training/DeadlineReminderButton";
import DeadlineDeleteButton from "@/components/display/training/DeadlineDeleteButton";
import FilterableDataTable from "@/components/tables/FilterableDataTable";
import Avatar from "@/components/shared/Avatar";

export default {
    name: "DeadlineTable",
    mixins: [DataTableMixin],
    components: {
        Avatar,
        FilterableDataTable,
        DeadlineDeleteButton,
        DeadlineReminderButton,
    },
    filters: {
        /**
         * @type {String}
         */
        initials,
        /**
         * @type {String}
         */
        formatDate,
        /**
         * @type {String}
         */
        formatDateTime,
    },
    data: () => ({
        headers: [
            {
                title: "employee",
                key: "employee",
                sortable: false,
            },
            {
                title: "lesson",
                key: "content",
                sortable: false,
            },
            {
                title: "deadline_at",
                key: "deadline_at",
                sortable: true,
            },
            {
                title: "reminder_sent_at",
                key: "reminder_sent_at",
                sortable: true,
            },
            {
                title: "action",
                key: "action",
                sortable: false,
            },
        ],
    }),
};
</script>
