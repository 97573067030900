<template>
    <fieldset class="mb-4 flex flex-col items-stretch space-y-4">
        <slot />
    </fieldset>
</template>

<script>
export default {
    name: "FormFieldSet",
};
</script>
