<template>
    <OneCol>
        <Heading
            :title="$t('breaches.heading').toString()"
            :text="$t('breaches.text').toString()"
            :loading="loading"
        />
        <BreachTable
            :rows="rows"
            :meta="meta"
            :loading="loading"
            :error="error"
            @refresh="refreshData"
            class="card"
        />
    </OneCol>
</template>

<script>
import { BreachStoreNamespacedTypes } from "@/store/modules/breach";

import { getProperty } from "@/utils/object";

import OneCol from "@/components/layout/OneCol";
import Heading from "@/components/layout/Heading";
import BreachTable from "@/components/display/breach/BreachTable";

export default {
    name: "BreachIndex",
    components: {
        BreachTable,
        Heading,
        OneCol,
    },
    computed: {
        /**
         * @type {Boolean}
         */
        loading() {
            return this.$store.getters[
                BreachStoreNamespacedTypes.getters.BREACH_LOADING
            ]();
        },

        /**
         * @type {Object}
         */
        error() {
            return this.$store.getters[
                BreachStoreNamespacedTypes.getters.BREACH_ERROR
            ]();
        },

        /**
         * @type {Array}
         */
        breaches() {
            return this.$store.getters[
                BreachStoreNamespacedTypes.getters.BREACHES
            ]();
        },

        /**
         * @type {Array}
         */
        rows() {
            return getProperty(this.breaches, "data", []);
        },

        /**
         * @type {Object}
         */
        meta() {
            return getProperty(this.breaches, "meta", null);
        },
    },
    methods: {
        refreshData(payload = {}) {
            this.$store.dispatch(
                BreachStoreNamespacedTypes.actions.GET_BREACHES,
                payload
            );
        },
    },
    mounted() {
        if (!this.rows.length) {
            this.refreshData();
        }
    },
};
</script>
