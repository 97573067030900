<template>
    <div class="bar-chart-item group relative flex w-full flex-col">
        <div class="relative flex w-full flex-full justify-end px-1">
            <div class="absolute inset-0 flex flex-col justify-between py-px">
                <div class="relative flex w-full justify-end">
                    <div class="flex-1 border-b border-slate-200"></div>
                </div>
                <div class="relative flex w-full justify-end">
                    <div class="flex-1 border-b border-slate-200"></div>
                </div>
                <div class="relative flex w-full justify-end">
                    <div class="flex-1 border-b border-slate-200"></div>
                </div>
            </div>
            <div
                class="relative flex h-full min-w-full flex-col justify-end text-center text-xs"
            >
                <div
                    class="chart-item-value relative transform"
                    :style="{
                        flex: valueHeight + ' 1 0%',
                    }"
                >
                    <div
                        class="flex h-full flex-col items-center rounded text-center font-medium text-white ring-inset"
                        :class="
                            value === 0
                                ? 'bg-slate-200 py-1'
                                : 'bg-slate-500 py-2 group-hover:shadow-md'
                        "
                    >
                        <span v-if="value > 0" class="text-xs text-white">{{
                            value | formatNumber
                        }}</span>
                    </div>
                </div>
            </div>
        </div>
        <div
            class="text-xxs relative mt-4 w-full min-w-full border-b border-t border-slate-200 bg-slate-100 px-1 py-0.5 text-center text-slate-800"
            :class="[
                isFirst ? 'rounded-l-md border-l' : '',
                isLast ? 'rounded-r-md border-r' : '',
            ]"
        >
            <div class="w-full truncate">
                <span v-text="title"></span>
            </div>
            <div
                class="absolute bottom-0 left-0 top-0 z-10 min-w-full scale-100 items-center justify-center whitespace-nowrap opacity-0 transition-transform group-hover:scale-[1.06] group-hover:opacity-100"
            >
                <div
                    class="flex h-full items-center justify-center rounded border border-slate-300 bg-white px-1"
                >
                    <span
                        class="relative w-full truncate"
                        v-text="title"
                    ></span>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { formatNumber } from "@/utils/number";

export default {
    name: "BarChartItem",
    filters: {
        formatNumber,
    },
    props: {
        index: {
            type: Number,
            default: undefined,
        },
        title: {
            type: String,
            default: undefined,
        },
        value: {
            type: Number,
            default: 0,
        },
        maxValue: {
            type: Number,
            default: undefined,
        },
    },
    computed: {
        valueHeight() {
            return this.value / this.maxValue;
        },
        isFirst() {
            return this.index === 0;
        },
        isLast() {
            return this.index === this.$parent.data.length - 1;
        },
    },
};
</script>
