/**
 * Actionable data mixin
 */
import { redirectTo, redirectWithParamsTo } from "@/router";
import { isObject } from "@/utils/object";

export default {
    props: {
        emit: {
            type: Boolean,
            default: false,
        },
        to: {
            type: String | Object,
            required: false,
        },
    },
    computed: {
        hasLink() {
            return !!this.to;
        },
        hasEmitter() {
            return this.emit !== false;
        },
    },
    methods: {
        navigateToResource() {
            if (this.emit) {
                this.$emit("emit", this.item);
                return;
            }

            if (isObject(this.to)) {
                redirectWithParamsTo(this.to);
                return;
            }

            redirectTo(this.to || "dashboard.overview");
        },
    },
};
