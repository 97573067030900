<template>
    <div class="overflow-hidden rounded-xl border border-slate-200">
        <dl class="-my-3 divide-y divide-slate-100 px-6 py-4 text-sm leading-6">
            <ThreadIndicator
                :title="
                    $t(
                        'threats.analysis.indicators.inconsistency.heading'
                    ).toString()
                "
                :text="
                    $t(
                        'threats.analysis.indicators.inconsistency.help'
                    ).toString()
                "
                :loading="loading"
                :detected="indicatorInconsistency"
            />
            <ThreadIndicator
                :title="
                    $t('threats.analysis.indicators.urgency.heading').toString()
                "
                :text="
                    $t('threats.analysis.indicators.urgency.help').toString()
                "
                :loading="loading"
                :detected="indicatorUrgency"
            />
            <ThreadIndicator
                :title="
                    $t(
                        'threats.analysis.indicators.credentials.heading'
                    ).toString()
                "
                :text="
                    $t(
                        'threats.analysis.indicators.credentials.help'
                    ).toString()
                "
                :loading="loading"
                :detected="indicatorCredentials"
            />
            <ThreadIndicator
                :title="
                    $t(
                        'threats.analysis.indicators.homoglyphs.heading'
                    ).toString()
                "
                :text="
                    $t('threats.analysis.indicators.homoglyphs.help').toString()
                "
                :loading="loading"
                :detected="indicatorHomoglyph"
            />
            <ThreadIndicator
                :title="
                    $t('threats.analysis.indicators.common.heading').toString()
                "
                :text="$t('threats.analysis.indicators.common.help').toString()"
                :loading="loading"
                :detected="indicatorCommon"
            />
        </dl>
    </div>
</template>

<script>
import { getProperty } from "@/utils/object";
import ThreadIndicator from "@/components/display/threat/ThreatIndicator";

export default {
    name: "ThreadIndicators",
    components: { ThreadIndicator },
    props: {
        loading: {
            type: Boolean,
            required: true,
        },
        analysis: {
            type: Object,
            required: false,
            default: () => ({}),
        },
    },
    computed: {
        /**
         * @type {boolean}
         */
        indicatorInconsistency() {
            return getProperty(this.analysis, "indicator_inconsistency", false);
        },

        /**
         * @type {boolean}
         */
        indicatorUrgency() {
            return getProperty(this.analysis, "indicator_urgency", false);
        },

        /**
         * @type {boolean}
         */
        indicatorCredentials() {
            return getProperty(this.analysis, "indicator_credentials", false);
        },

        /**
         * @type {boolean}
         */
        indicatorHomoglyph() {
            return getProperty(this.analysis, "indicator_homoglyph", false);
        },

        /**
         * @type {boolean}
         */
        indicatorCommon() {
            return getProperty(this.analysis, "indicator_common", false);
        },
    },
};
</script>
