<template>
    <Page
        :heading="$t('invite.heading').toString()"
        :text="$t('invite.text').toString()"
    >
        <InviteForm @authenticated="handleInviteAccepted" />
        <div slot="footer">
            <p class="max-w mt-8 text-center text-slate-600">
                {{ $t("register.login.text") }}
                <router-link
                    :to="{ name: 'login' }"
                    class="text-base font-medium text-indigo-600"
                    >{{ $t("register.login.link") }}</router-link
                >
            </p>
        </div>
    </Page>
</template>

<script>
import Page from "@/components/layout/Page";
import InviteForm from "@/components/forms/InviteForm";
import { redirectTo } from "@/router";

export default {
    name: "InvitePage",
    components: { InviteForm, Page },
    methods: {
        handleInviteAccepted() {
            redirectTo("dashboard.overview");
        },
    },
};
</script>
