<template>
    <Badge
        :variant="variant"
        :text="$t('simulations.' + status).toString()"
        :loading="loading"
    />
</template>

<script>
import Badge, { BadgeVariants } from "@/components/shared/Badge";

export default {
    components: { Badge },
    props: {
        status: {
            type: String,
            default: undefined,
        },
    },
    data: () => ({
        BadgeVariants,
    }),
    computed: {
        loading() {
            return this.status === "sending" || this.status === "running";
        },
        variant() {
            if (this.loading) {
                return BadgeVariants.PRIMARY;
            }

            if (this.status === "completed") {
                return BadgeVariants.SUCCESS;
            }

            if (this.status === "paused") {
                return BadgeVariants.WARNING;
            }

            if (this.status === "cancelled") {
                return BadgeVariants.ERROR;
            }

            return BadgeVariants.DEFAULT;
        },
    },
};
</script>
