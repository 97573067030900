<template>
    <ValidationObserver ref="employeeSettingsForm" v-slot="{ passes }">
        <Alert :error="error" />
        <form @submit.prevent="passes(saveSettings)">
            <div
                class="flex flex-col items-stretch justify-evenly"
                :class="gridClasses"
            >
                <FormFieldSet
                    class="w-full"
                    :class="[{ 'xl:w-1/2': cols === 2 }]"
                >
                    <FormInput
                        v-model="form.first_name"
                        type="text"
                        name="first_name"
                    />
                    <FormInput
                        v-model="form.last_name"
                        type="text"
                        name="last_name"
                    />
                    <FormInput
                        v-model="form.email"
                        type="email"
                        rules="required"
                        name="email"
                    />
                    <FormInput
                        v-model="form.department"
                        type="text"
                        name="department"
                    />
                </FormFieldSet>
                <FormFieldSet
                    class="w-full"
                    :class="[{ 'xl:w-1/2': cols === 2 }]"
                >
                    <FormRadioGroup
                        v-model="form.status"
                        :options="statusOptions"
                        rules="required"
                        name="status"
                    />
                    <div
                        v-if="isInactive && deactivation_reason"
                        class="relative col-span-6"
                    >
                        <div
                            class="flex items-center whitespace-pre-wrap rounded-lg bg-yellow-50/50 p-2 text-xs text-warning-dark ring-1 ring-inset ring-yellow-500/20"
                            v-html="
                                $t(
                                    'employees.deactivation_notice.' +
                                        deactivation_reason,
                                    { date: formatDateTime(deactivated_at) }
                                )
                            "
                        ></div>
                    </div>
                    <FormSelect
                        v-model="form.locale"
                        type="text"
                        name="locale"
                        rules="required"
                    >
                        <option
                            v-for="locale in locales"
                            :key="locale.value"
                            :value="locale.value"
                        >
                            {{ $t("locale." + locale.text) }}
                        </option>
                    </FormSelect>
                    <div class="col-span-6 flex flex-row justify-end">
                        <FormButton type="submit" :loading="loading">
                            {{ $t("forms.buttons.save_settings") }}
                        </FormButton>
                    </div>
                </FormFieldSet>
            </div>
        </form>
    </ValidationObserver>
</template>

<script>
import { TeamStoreNamespacedTypes } from "@/store/modules/team";

import { getProperty } from "@/utils/object";
import { formatDateTime } from "@/utils/date";

import { ValidationObserver } from "vee-validate";
import Alert from "@/components/ui/Alert";
import FormInput from "@/components/forms/fields/FormInput";
import FormFieldSet from "@/components/forms/fields/FormFieldSet";
import FormButton from "@/components/forms/fields/FormButton";
import { locales } from "@/utils/enums";
import FormRadioGroup from "@/components/forms/fields/FormRadioGroup";
import FormSelect from "@/components/forms/fields/FormSelect";

export default {
    props: {
        cols: {
            type: Number,
            default: 2,
        },
    },
    components: {
        FormSelect,
        FormRadioGroup,
        FormButton,
        FormFieldSet,
        Alert,
        ValidationObserver,
        FormInput,
    },
    data: () => ({
        form: {
            first_name: null,
            last_name: null,
            email: null,
            department: null,
            status: "active",
            locale: locales[0].value,
        },
        locales: locales,
        loading: false,
    }),
    computed: {
        /**
         * @type {Object}
         */
        error() {
            return this.$store.getters[
                TeamStoreNamespacedTypes.getters.ERROR
            ]();
        },

        /**
         * @type {Object}
         */
        employee() {
            return this.$store.getters[
                TeamStoreNamespacedTypes.getters.EMPLOYEE
            ]();
        },

        /**
         * @type {Array}
         */
        statusOptions() {
            return [
                {
                    title: this.$t("forms.options.status.active.title"),
                    text: this.$t("forms.options.status.active.text"),
                    value: "active",
                },
                {
                    title: this.$t("forms.options.status.inactive.title"),
                    text: this.$t("forms.options.status.inactive.text"),
                    value: "inactive",
                },
            ];
        },
        gridClasses() {
            return {
                "xl:flex-row xl:space-x-4": this.cols === 2,
                "grid-cols-6": this.cols === 1,
            };
        },
        isInactive() {
            return this.form.status === "inactive";
        },
        deactivated_at() {
            return getProperty(this.employee, "deactivated_at");
        },
        deactivation_reason() {
            return getProperty(this.employee, "deactivation_reason");
        },
    },
    methods: {
        formatDateTime,
        /**
         * Sends authentication credentials to the server.
         */
        saveSettings() {
            this.loading = true;
            this.$store
                .dispatch(
                    TeamStoreNamespacedTypes.actions.UPDATE_EMPLOYEE_SETTINGS,
                    {
                        employee: getProperty(this.employee, "id"),
                        form: this.form,
                    }
                )
                .then(() => {
                    this.loading = false;
                    if (!this.error) {
                        this.$emit("saved");
                    }
                });
        },
    },
    created() {
        this.form = {
            first_name: getProperty(this.employee, "first_name", null),
            last_name: getProperty(this.employee, "last_name", null),
            email: getProperty(this.employee, "email", null),
            department: getProperty(this.employee, "department", null),
            status: getProperty(this.employee, "status", "active"),
            locale: getProperty(this.employee, "locale", locales[0]),
        };
    },
    beforeCreate() {
        this.$store.commit(TeamStoreNamespacedTypes.mutations.SET_ERROR, null);
    },
};
</script>
