var render = function render(){var _vm=this,_c=_vm._self._c;return _c('TwoCol',{scopedSlots:_vm._u([{key:"content",fn:function(){return [_c('SlideOver',{attrs:{"active":_vm.showPrompt,"gutter":false},on:{"close":function($event){_vm.showPrompt = false}},scopedSlots:_vm._u([{key:"content",fn:function(){return [_c('SelectMembers',{attrs:{"loading":_vm.enrollmentLoading},on:{"update":_vm.handleEnrollment}})]},proxy:true}])},[_c('h2',{staticClass:"text-base font-semibold leading-6 text-slate-900",attrs:{"slot":"title"},slot:"title"},[_vm._v(" "+_vm._s(_vm.$t("automation.enroll_employees"))+" ")])]),_c('Heading',{attrs:{"title":_vm.name || 'N/A',"text":_vm.$t('automation.details.heading').toString(),"return-to":{
                name: 'automation.index',
            }}},[(_vm.isEditable)?_c('FormButton',{attrs:{"variant":_vm.ButtonVariants.SECONDARY,"icon":_vm.IconEnums.TEAM},on:{"click":function($event){_vm.showPrompt = true}}},[_vm._v(_vm._s(_vm.$t("automation.enroll_employees")))]):_vm._e()],1),_c('div',{staticClass:"card"},[_c('CardHeading',{attrs:{"title":_vm.$t('automation.sequence.heading').toString(),"text":_vm.$t('automation.sequence.text').toString()}}),(_vm.funnelData.length > 2)?_c('div',{staticClass:"hidden max-w-full overflow-hidden px-4 pb-4 md:block md:border-b"},[_c('FunnelChart',{attrs:{"loading":_vm.loading,"data":_vm.funnelData}})],1):_vm._e(),_c('div',{staticClass:"px-4 pt-4"},[_c('ContentLoading',{attrs:{"loading":_vm.loading}},[(_vm.hasActions)?_c('AutomationFlow',{attrs:{"automation-id":_vm.automationId}}):_c('AutomationFlowPlaceholder',{attrs:{"id":_vm.automationId}})],1)],1)],1)]},proxy:true},{key:"sidebar",fn:function(){return [_c('Heading',{attrs:{"title":_vm.$t('automation.details.heading').toString(),"text":_vm.$t('automation.details.text').toString()}}),_c('div',{staticClass:"card mb-4"},[_c('ContentLoading',{attrs:{"loading":_vm.loading}},[_c('div',{staticClass:"flex flex-col divide-y divide-slate-100"},[_c('ListItem',{attrs:{"title":_vm.$t('automation.details.status').toString()}},[_c('Badge',{attrs:{"variant":_vm.status === 'started'
                                    ? _vm.BadgeVariants.SUCCESS
                                    : _vm.BadgeVariants.WARNING,"text":_vm.$t('automation.status.' + _vm.status).toString(),"loading":_vm.status === 'started'}})],1),_c('ListItem',{attrs:{"title":_vm.$t('automation.details.date').toString(),"text":_vm.date || 'N/A'}}),_c('ListItem',{attrs:{"title":_vm.$t(
                                'automation.details.notifications'
                            ).toString()}},[(_vm.notificationsEnabled)?_c('Icon',{staticClass:"mr-2 size-4 flex-none fill-emerald-500 stroke-white",attrs:{"icon":_vm.IconEnums.CHECKMARK}}):_vm._e(),_c('Badge',{attrs:{"text":_vm.notificationFrequency}})],1)],1),(_vm.hasActions)?_c('div',{staticClass:"flex flex-col gap-y-4 border-t border-slate-100 p-4"},[_c('FormButton',{attrs:{"to":{
                            name: 'automation.edit',
                            params: { id: _vm.$route.params.id },
                        },"variant":_vm.ButtonVariants.SECONDARY}},[_vm._v(_vm._s(_vm.$t("automation.edit_automation")))]),_c('ToggleAutomationButton')],1):_vm._e()])],1)]},proxy:true}])})
}
var staticRenderFns = []

export { render, staticRenderFns }