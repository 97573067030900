/**
 * Statistics card mixin
 */

import LoadingSpinner from "@/components/ui/LoadingSpinner";
import Icon from "@/components/icons/BaseIcon";
import { IconEnums } from "@/utils/icons";

export default {
    components: {
        LoadingSpinner,
        Icon,
    },
    props: {
        loading: {
            type: Boolean,
            required: true,
        },
        title: {
            type: String,
            required: true,
        },
        text: {
            type: String,
            required: false,
        },
    },
    data: () => ({
        IconEnums,
        showText: false,
    }),
};
