var render = function render(){var _vm=this,_c=_vm._self._c;return _c('DataTable',{attrs:{"name":"praticipation.index","headers":_vm.headers,"rows":_vm.rows,"meta":_vm.meta,"loading":_vm.loading,"error":_vm.error,"emit":true},on:{"emit":function($event){return _vm.$emit('emit', $event)},"update":_vm.refresh},scopedSlots:_vm._u([{key:"employee",fn:function({ item }){return [_c('div',{staticClass:"flex items-center"},[_c('Avatar',{attrs:{"image":item.avatar,"name":item.full_name}}),_c('div',{staticClass:"ml-3"},[_c('div',{staticClass:"mb-0 text-sm font-medium text-slate-900"},[_vm._v(" "+_vm._s(item.full_name)+" ")]),_c('div',{staticClass:"text-sm text-slate-500"},[_vm._v(" "+_vm._s(item.email || "No email found")+" ")])])],1)]}},{key:"status",fn:function({ item }){return [_c('Badge',{attrs:{"variant":item.status === 'active'
                    ? _vm.BadgeVariants.SUCCESS
                    : _vm.BadgeVariants.ERROR,"text":_vm.$t('employees.' + item.status).toString()}})]}},{key:"completed",fn:function({ item }){return [_c('Badge',{attrs:{"variant":item.completed ? _vm.BadgeVariants.SUCCESS : _vm.BadgeVariants.ERROR,"text":item.completed
                    ? _vm.$t('employees.training.completed')
                    : _vm.$t('employees.training.not_completed')}})]}},{key:"invited",fn:function({ item }){return [_c('Badge',{attrs:{"variant":item.invited ? _vm.BadgeVariants.SUCCESS : _vm.BadgeVariants.ERROR,"text":item.invited
                    ? _vm.$t('employees.training.invited')
                    : _vm.$t('employees.training.not_invited')}})]}}])})
}
var staticRenderFns = []

export { render, staticRenderFns }