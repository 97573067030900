<template>
    <li class="cursor-pointer hover:bg-slate-800">
        <div class="relative flex items-center space-x-3 px-3.5 py-2.5">
            <Avatar :image="avatar" :name="name" />
            <div class="min-w-0 flex-1">
                <p class="text-sm font-medium leading-tight text-white">
                    {{ name }}
                </p>
                <p class="truncate text-sm text-slate-400">
                    {{ email }}
                </p>
            </div>
        </div>
    </li>
</template>

<script>
import { getProperty } from "@/utils/object";
import { initials } from "@/utils/filters";
import Avatar from "@/components/shared/Avatar";

export default {
    name: "EmployeePreview",
    components: { Avatar },
    filters: {
        /**
         * @type {String}
         */
        initials,
    },
    props: {
        employee: {
            type: Object,
            required: true,
        },
    },
    computed: {
        /**
         * The employee's full name
         * @type {String}
         */
        name() {
            return getProperty(this.employee, "full_name", "");
        },

        /**
         * The employee's email
         * @type {String}
         */
        email() {
            return getProperty(this.employee, "email", "");
        },

        /**
         * The employee's avatar
         * @type {String}
         */
        avatar() {
            return getProperty(this.employee, "avatar", "");
        },
    },
};
</script>
