<template>
    <div class="relative flex h-full flex-col overflow-hidden">
        <Alert :error="error" />
        <div class="w-full bg-slate-100 px-4 pb-4 pt-0.5">
            <div
                class="group flex items-center rounded-lg border border-slate-200 bg-white text-sm leading-5 ring-[0.5px] ring-transparent transition-all duration-300 focus-within:border-indigo-500 focus-within:bg-white focus-within:ring-indigo-500 hover:border-indigo-500 hover:ring-indigo-500"
            >
                <div class="flex items-center pl-3">
                    <Icon
                        :icon="IconEnums.SEARCH"
                        class="h-5 w-5 text-slate-400"
                    />
                    <Icon
                        v-if="searchTerm"
                        :icon="IconEnums.CLOSE"
                        @click.native="clearSearch"
                        class="h-5 w-5 cursor-pointer text-slate-400"
                    />
                </div>
                <div class="flex flex-1">
                    <input
                        class="w-full border-0 bg-transparent py-2 pr-3 outline-none focus:outline-none focus:ring-0"
                        v-model="searchTerm"
                        placeholder="Search all employees"
                    />
                </div>
            </div>
        </div>
        <div
            v-if="searchTerm"
            class="flex h-full flex-col overflow-y-auto border-t border-slate-200"
        >
            <ul class="relative z-0 divide-y divide-solid divide-slate-200">
                <MemberItem
                    v-for="employee in filteredByTerm"
                    :key="employee.id"
                    :employee="employee"
                    @select="select"
                />
            </ul>
        </div>
        <div
            v-else
            class="flex h-full flex-col overflow-y-auto"
            aria-label="Directory"
        >
            <div
                class="relative"
                v-for="stack in employees"
                :key="stack.letter"
            >
                <div
                    class="sticky top-0 z-10 border-b border-t border-slate-200 bg-slate-50 px-4 py-1 text-sm font-medium text-slate-500"
                >
                    <h3>{{ stack.letter }}</h3>
                </div>
                <ul class="relative z-0 divide-y divide-solid divide-slate-200">
                    <MemberItem
                        v-for="employee in stack.data"
                        :key="employee.id"
                        :employee="employee"
                        @select="select"
                    />
                </ul>
            </div>
        </div>
        <div
            class="flex flex-row items-center justify-between border-t border-slate-200 bg-slate-100 px-6 py-4"
        >
            <p class="text-sm font-medium text-slate-800">
                {{
                    searchTerm
                        ? $t("groups.members_found", {
                              count: filteredByTerm.length,
                          })
                        : $t("groups.members_count", { count: selected.length })
                }}
            </p>
            <FormButton
                type="submit"
                @click="updateMembers"
                :loading="loading"
                >{{ $t("groups.update_group") }}</FormButton
            >
        </div>
    </div>
</template>

<script>
import { TeamStoreNamespacedTypes } from "@/store/modules/team";

import { getProperty } from "@/utils/object";

import Icon from "@/components/icons/BaseIcon";
import { IconEnums } from "@/utils/icons";

import FormButton from "@/components/forms/fields/FormButton";
import Alert from "@/components/ui/Alert";
import MemberItem from "./MemberItem";

export default {
    components: { MemberItem, Icon, Alert, FormButton },
    props: {
        loading: {
            type: Boolean,
            default: false,
        },
    },
    data: () => ({
        searchTerm: null,
        IconEnums,
    }),
    computed: {
        /**
         * The error state of the API request.
         * @type {Object}
         */
        error() {
            return this.$store.getters[
                TeamStoreNamespacedTypes.getters.ERROR
            ]();
        },

        /**
         * @type {Array}
         */
        group() {
            return this.$store.getters[
                TeamStoreNamespacedTypes.getters.GROUP
            ]();
        },

        /**
         * @type {Array}
         */
        selected() {
            return this.$store.getters[
                TeamStoreNamespacedTypes.getters.SELECTED
            ]();
        },

        /**
         * @type {Array}
         */
        employees() {
            return getProperty(this.group, "employees.all", []);
        },

        /**
         * @type {Array}
         */
        searchable() {
            return getProperty(this.group, "employees.searchable", []);
        },

        /**
         * @type {Array}
         */
        filteredByTerm() {
            return this.searchable.filter((employee) => {
                return employee.full_name
                    .toLowerCase()
                    .includes(this.searchTerm.toLowerCase());
            });
        },
    },
    methods: {
        clearSearch() {
            this.searchTerm = null;
        },
        select(id) {
            this.$store.commit(
                TeamStoreNamespacedTypes.mutations.UPDATE_SELECTED,
                id
            );
        },
        updateMembers() {
            this.$emit("update", this.selected);
        },
    },
};
</script>
