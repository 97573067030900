<template>
    <Tabs v-if="tabs.length > 0">
        <TabItem
            v-for="tab in tabs"
            :key="tab.text"
            :title="tab.text"
            :to="tab.route"
            :icon="tab.icon"
            :active="tab.route.name === $route.name"
        />
    </Tabs>
</template>

<script>
import Tabs from "./Tabs";
import TabItem from "./TabItem";

export default {
    name: "DynamicTabs",
    components: { TabItem, Tabs },
    props: {
        tabs: {
            type: Array,
            default: () => [],
        },
    },
};
</script>
