<template>
    <path
        fill="currentColor"
        stroke="none"
        d="M14,20c-1.1,0-2-0.9-2-2V2c0-1.1,0.9-2,2-2s2,0.9,2,2v16C16,19.1,15.1,20,14,20z M2,20c-1.1,0-2-0.9-2-2V2c0-1.1,0.9-2,2-2s2,0.9,2,2v16C4,19.1,3.1,20,2,20z"
    ></path>
</template>

<script>
import IconSvgBase from "./IconSvgBase";

export default {
    extends: IconSvgBase,
    name: "IconPause",
    data: () => ({
        viewBoxHeight: 16,
        viewBoxWidth: 20,
    }),
};
</script>
