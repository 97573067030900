<template>
    <DashboardPage>
        <template>
            <ValueCard
                :loading="campaignStatisticsLoading"
                :title="$t('statistics.simulations.count.title').toString()"
                :text="$t('statistics.simulations.count.text').toString()"
                :unit="$t('statistics.simulations.count.unit').toString()"
                :value="count"
                to="campaign.index"
                class="card"
            />
            <ValueCard
                :loading="campaignStatisticsLoading"
                :title="$t('statistics.simulations.open_rate.title').toString()"
                :text="$t('statistics.simulations.open_rate.text').toString()"
                :unit="$t('statistics.ninety_days').toString()"
                :value="openRate"
                :pct="true"
                :lower-threshold="7000"
                class="card"
            />
            <ValueCard
                :loading="campaignStatisticsLoading"
                :title="
                    $t('statistics.simulations.click_rate.title').toString()
                "
                :text="$t('statistics.simulations.click_rate.text').toString()"
                :unit="$t('statistics.ninety_days').toString()"
                :value="clickRate"
                :pct="true"
                :lower-threshold="500"
                class="card"
            />
            <ValueCard
                :loading="campaignStatisticsLoading"
                :title="
                    $t('statistics.simulations.reporting_rate.title').toString()
                "
                :text="
                    $t('statistics.simulations.reporting_rate.text').toString()
                "
                :unit="$t('statistics.ninety_days').toString()"
                :value="reportRate"
                :pct="true"
                :upper-threshold="3000"
                class="card"
            />
            <BarChartCard
                :loading="campaignStatisticsLoading"
                :title="$t('statistics.sends.title').toString()"
                :unit="$t('statistics.ninety_days').toString()"
                :data="graph"
                class="card col-span-1 md:col-span-2"
            />
        </template>
    </DashboardPage>
</template>

<script>
import { StatisticsStoreNamespacedTypes } from "@/store/modules/statistics";

import { getProperty } from "@/utils/object";

import DashboardPage from "@/components/layout/DashboardPage";
import ValueCard from "@/components/statistics/ValueCard";
import BarChartCard from "@/components/statistics/BarChartCard";

export default {
    name: "DashboardSimulationsPage",
    components: {
        BarChartCard,
        ValueCard,
        DashboardPage,
    },
    data: () => ({
        campaignStatisticsLoading: true,
    }),
    computed: {
        /**
         * @type {Object}
         */
        error() {
            return this.$store.getters[
                StatisticsStoreNamespacedTypes.getters.ERROR
            ]();
        },

        /**
         * @type {Object}
         */
        statistics() {
            return this.$store.getters[
                StatisticsStoreNamespacedTypes.getters.STATISTICS
            ]();
        },

        /**
         * @type {Object}
         */
        campaigns() {
            return getProperty(this.statistics, "campaigns", []);
        },

        /**
         * @type {Object}
         */
        data() {
            return getProperty(this.campaigns, "data", {
                click_rate: 0,
                count: 0,
                open_rate: 0,
                click_count: 0,
                open_count: 0,
                send_count: 0,
                unique_click_count: 0,
                unique_open_count: 0,
                report_count: 0,
                report_rate: 0,
            });
        },

        /**
         * @type {Number}
         */
        count() {
            return getProperty(this.data, "send_count", 0);
        },

        /**
         * @type {Number}
         */
        openRate() {
            return getProperty(this.data, "open_rate", 0);
        },

        /**
         * @type {Number}
         */
        clickRate() {
            return getProperty(this.data, "click_rate", 0);
        },

        /**
         * @type {Number}
         */
        reportRate() {
            return getProperty(this.data, "report_rate", 0);
        },

        /**
         * @type {Number}
         */
        sendCount() {
            return getProperty(this.data, "send_count", 0);
        },

        /**
         * @type {Array}
         */
        graph() {
            return getProperty(this.campaigns, "graph", []);
        },
    },
    methods: {
        getCampaignStatistics(filters = {}) {
            this.campaignStatisticsLoading = true;
            this.$store
                .dispatch(
                    StatisticsStoreNamespacedTypes.actions
                        .GET_CAMPAIGN_STATISTICS,
                    filters
                )
                .then(() => {
                    this.campaignStatisticsLoading = false;
                });
        },
    },
    mounted() {
        this.$store.commit(
            StatisticsStoreNamespacedTypes.mutations.SET_ERROR,
            null
        );
        this.getCampaignStatistics();
    },
};
</script>
