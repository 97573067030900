<template>
    <ValidationObserver ref="campaignSettingsForm" v-slot="{ passes }">
        <form @submit.prevent="passes(updateSettings)" class="-mb-4">
            <FormFieldSet>
                <FormFieldOutline>
                    <FormToggle
                        v-model="form.remedial_training"
                        name="remedial_training"
                    />
                </FormFieldOutline>
                <FormFieldOutline>
                    <div class="flex flex-row items-center justify-between">
                        <div class="flex flex-col">
                            <div
                                class="mb-1 flex flex-row items-center space-x-1"
                            >
                                <Icon
                                    :icon="IconEnums.DOMAIN"
                                    class="size-4 text-slate-900"
                                />
                                <h3
                                    class="block text-sm font-medium text-slate-900"
                                    v-text="
                                        $t(
                                            'settings.simulation.download_domains.heading'
                                        ).toString()
                                    "
                                ></h3>
                            </div>
                            <p
                                class="block text-sm text-slate-500"
                                v-text="
                                    $t(
                                        'settings.simulation.download_domains.text'
                                    ).toString()
                                "
                            ></p>
                        </div>
                        <FormButton
                            :variant="ButtonVariants.SMALL"
                            :icon="IconEnums.DOWNLOAD"
                            @click.native="exportToCsv"
                            :loading="exportLoading"
                        >
                            {{
                                $t(
                                    "settings.simulation.download_domains.button"
                                )
                            }}
                        </FormButton>
                    </div>
                </FormFieldOutline>
                <div class="flex justify-end">
                    <FormButton type="submit" :loading="formLoading">
                        {{ $t("forms.buttons.save_settings") }}
                    </FormButton>
                </div>
            </FormFieldSet>
        </form>
    </ValidationObserver>
</template>

<script>
import { WorkspaceStoreNamespacedTypes } from "@/store/modules/workspace";

import { getProperty } from "@/utils/object";
import { IconEnums } from "@/utils/icons";
import { ValidationObserver } from "vee-validate";
import Icon from "@/components/icons/BaseIcon";
import FormFieldSet from "@/components/forms/fields/FormFieldSet";
import FormToggle from "@/components/forms/fields/FormToggle";
import FormButton, {
    ButtonVariants,
} from "@/components/forms/fields/FormButton";
import FormFieldOutline from "@/components/forms/fields/FormFieldOutline";

export default {
    name: "CampaignSettingsForm",
    components: {
        Icon,
        FormFieldOutline,
        FormButton,
        FormToggle,
        FormFieldSet,
        ValidationObserver,
    },
    data: () => ({
        IconEnums,
        ButtonVariants,
        exportLoading: false,
        formLoading: false,
        form: {
            remedial_training: false,
        },
    }),
    computed: {
        /**
         * @type {Object}
         */
        workspace() {
            return this.$store.getters[
                WorkspaceStoreNamespacedTypes.getters.WORKSPACE
            ]();
        },

        /**
         * @type {Object}
         */
        error() {
            return this.$store.getters[
                WorkspaceStoreNamespacedTypes.getters.WORKSPACE_ERROR
            ]();
        },
    },
    methods: {
        /**
         * Downloads the list of domains.
         */
        exportToCsv() {
            this.exportLoading = true;
            this.$store
                .dispatch(
                    WorkspaceStoreNamespacedTypes.actions
                        .EXPORT_CAMPAIGN_DOMAINS_LIST
                )
                .then((response) => {
                    this.exportLoading = false;
                    const url = getProperty(response.data, "url");
                    if (url) {
                        window.location.href = url;
                    }
                });
        },

        /**
         * Sends authentication credentials to the server.
         */
        updateSettings() {
            this.formLoading = true;
            this.$store
                .dispatch(
                    WorkspaceStoreNamespacedTypes.actions
                        .UPDATE_CAMPAIGN_SETTINGS,
                    this.form
                )
                .then(() => {
                    this.formLoading = false;
                    if (!this.error) {
                        this.$emit("saved");
                    }
                });
        },
    },
    created() {
        this.form = {
            remedial_training: getProperty(
                this.workspace,
                "simulation_settings.remedial_training",
                false
            ),
        };
    },
    beforeCreate() {
        this.$store.commit(
            WorkspaceStoreNamespacedTypes.mutations.SET_WORKSPACE_ERROR,
            null
        );
    },
};
</script>
