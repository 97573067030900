<template>
    <li :id="id" class="relative flex gap-x-4">
        <div class="absolute -bottom-6 left-0 top-0 flex w-6 justify-center">
            <div class="w-px bg-slate-200"></div>
        </div>
        <div
            class="relative flex h-6 w-6 flex-none items-center justify-center bg-white"
        >
            <div
                class="size-1.5 rounded-full bg-slate-100 ring-1 ring-slate-300"
            ></div>
        </div>
        <p class="flex-auto py-0.5 text-xs leading-5 text-slate-500">
            <span class="font-medium text-slate-900" v-text="name"></span>
            {{ $t("employees.recent_activity.type." + key).toString() }}
        </p>
        <time
            class="flex-none py-0.5 text-xs leading-5 text-slate-500"
            v-text="date"
        ></time>
    </li>
</template>

<script>
import { formatDateTime } from "@/utils/date";
import { getProperty } from "@/utils/object";
import { TeamStoreNamespacedTypes } from "@/store/modules/team";

export default {
    name: "RecentActivityItem",
    props: {
        data: {
            type: Object,
            required: true,
        },
    },
    computed: {
        /**
         * @type {Object}
         */
        employee() {
            return this.$store.getters[
                TeamStoreNamespacedTypes.getters.EMPLOYEE
            ]();
        },

        /**
         * @type {string}
         */
        name() {
            return getProperty(this.employee, "full_name", null);
        },

        /**
         * @type {string}
         */
        id() {
            return getProperty(this.data, "id", null);
        },

        /**
         * @type {string}
         */
        key() {
            return getProperty(this.data, "key", null);
        },

        /**
         * @type {string}
         */
        date() {
            let date = getProperty(this.data, "created_at", null);
            return date ? formatDateTime(date) : null;
        },
    },
};
</script>
