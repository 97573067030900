<template>
    <ValidationObserver ref="createFieldForm" v-slot="{ passes }">
        <form @submit.prevent="passes(createField)">
            <FormFieldSet>
                <Alert :error="error" />
                <FormInput
                    v-model="form.identifier"
                    type="text"
                    rules="required"
                    name="identifier"
                    placeholder="Eg. company"
                />
                <FormSelect v-model="form.type" name="field_type">
                    <option v-for="type in types" :key="type" :value="type">
                        {{ $t("settings.fields.types." + type) }}
                    </option>
                </FormSelect>
                <div class="flex justify-end">
                    <FormButton type="submit" :loading="loading">
                        {{ $t("forms.buttons.create_field") }}
                    </FormButton>
                </div>
            </FormFieldSet>
        </form>
    </ValidationObserver>
</template>

<script>
import { WorkspaceStoreNamespacedTypes } from "@/store/modules/workspace";

import { ValidationObserver } from "vee-validate";
import Alert from "@/components/ui/Alert";
import FormInput from "@/components/forms/fields/FormInput";
import FormButton from "@/components/forms/fields/FormButton";
import FormSelect from "@/components/forms/fields/FormSelect";
import FormFieldSet from "@/components/forms/fields/FormFieldSet";

export default {
    name: "CreateFieldForm",
    components: {
        FormFieldSet,
        FormSelect,
        FormButton,
        Alert,
        ValidationObserver,
        FormInput,
    },
    data: () => ({
        loading: false,
        form: {
            identifier: null,
            type: "text",
        },
        types: ["text", "number", "date", "boolean"],
    }),
    computed: {
        /**
         * @type {Object}
         */
        error() {
            return this.$store.getters[
                WorkspaceStoreNamespacedTypes.getters.FIELDS_ERROR
            ]();
        },
    },
    methods: {
        /**
         * Sends authentication credentials to the server.
         */
        createField() {
            this.loading = true;
            this.$store
                .dispatch(
                    WorkspaceStoreNamespacedTypes.actions.UPDATE_FIELDS,
                    this.form
                )
                .then(() => {
                    this.loading = false;
                    if (!this.error) {
                        this.$emit("saved");
                    }
                });
        },
    },
    beforeCreate() {
        this.$store.commit(
            WorkspaceStoreNamespacedTypes.mutations.SET_FIELDS_ERROR,
            null
        );
    },
};
</script>
