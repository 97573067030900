<template>
    <div class="card">
        <ul class="divide-y divide-slate-200">
            <OnboardingStep
                :gutter="false"
                :title="$t('onboarding.import.title').toString()"
                :text="$t('onboarding.import.text').toString()"
                :step="1"
                :active="activeStep === 1"
                :completed="activeStep >= 2"
                @selectStep="handleStepSelect"
            >
                <div v-show="integrationError" class="border-b p-4">
                    <Alert :error="integrationError" />
                </div>
                <ul role="list" class="divide-y divide-slate-200">
                    <IntegrationRow
                        v-for="integration in integrations"
                        :key="integration.key"
                        :integration="integration"
                    />
                </ul>
                <div class="flex justify-end border-t p-4">
                    <FormButton
                        type="submit"
                        @click.native="handleStepSelect(2)"
                    >
                        {{ $t("onboarding.skip") }}
                    </FormButton>
                </div>
            </OnboardingStep>
            <OnboardingStep
                :title="$t('onboarding.settings.title').toString()"
                :text="$t('onboarding.settings.text').toString()"
                :step="2"
                :active="activeStep === 2"
                :completed="activeStep >= 3"
                @selectStep="handleStepSelect"
            >
                <div class="flex flex-1 flex-grow flex-col">
                    <CampaignSettingsForm @saved="handleSettingsSaved" />
                </div>
            </OnboardingStep>
        </ul>
    </div>
</template>

<script>
import { WorkspaceStoreNamespacedTypes } from "@/store/modules/workspace";
import { IntegrationStoreNamespacedTypes } from "@/store/modules/integration";
import { integrations } from "@/utils/enums";

import { redirectTo } from "@/router";

import Bus from "@/bus";
import OnboardingStep from "./OnboardingStep";
import CampaignSettingsForm from "@/components/forms/CampaignSettingsForm";
import IntegrationRow from "@/components/display/integrations/IntegrationRow";
import Alert from "@/components/ui/Alert";
import FormButton from "@/components/forms/fields/FormButton";

export default {
    components: {
        FormButton,
        Alert,
        IntegrationRow,
        CampaignSettingsForm,
        OnboardingStep,
    },
    data: () => ({
        activeStep: 1,
        integrations,
    }),
    computed: {
        /**
         * The authentication state for the user model
         * @type {Object}
         */
        workspace() {
            return this.$store.getters[
                WorkspaceStoreNamespacedTypes.getters.WORKSPACE
            ]();
        },

        /**
         * @type {Object}
         */
        integrationError() {
            return this.$store.getters[
                IntegrationStoreNamespacedTypes.getters.INTEGRATION_ERROR
            ]();
        },
    },
    methods: {
        handleStepSelect(step) {
            this.activeStep = step;
        },
        handleSettingsSaved() {
            Bus.$emit("flash-message", {
                text: this.$t("settings.saved"),
                type: "success",
            });

            this.$store
                .dispatch(
                    WorkspaceStoreNamespacedTypes.actions
                        .UPDATE_ONBOARDED_STATUS
                )
                .then(() => {
                    redirectTo("dashboard.overview");
                });
        },
    },
    mounted() {
        this.$store
            .dispatch(WorkspaceStoreNamespacedTypes.actions.GET_WORKSPACE, {
                force: true,
            })
            .then(() => {
                if (this.workspace.integrations.length > 0) {
                    this.activeStep = 2;
                }
            });
    },
    beforeCreate() {
        this.$store.commit(
            IntegrationStoreNamespacedTypes.mutations.SET_INTEGRATION_ERROR,
            null
        );
    },
};
</script>
