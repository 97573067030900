<template>
    <div>
        <div class="flex w-full flex-col md:flex-row">
            <div class="flex-grow">
                <DynamicTabs :tabs="tabs" />
            </div>
            <div
                v-if="hasSidebarSlot"
                class="mb-4 flex justify-end md:mb-0 md:ml-2"
            >
                <slot name="sidebar" />
            </div>
        </div>
        <Alert :error="error" class="mb-4" />
        <div
            :class="
                grid
                    ? 'grid grid-cols-1 gap-4 md:grid-cols-2 xl:grid-cols-3 2xl:grid-cols-4'
                    : 'flex flex-col'
            "
        >
            <slot />
        </div>
    </div>
</template>

<script>
import { StatisticsStoreNamespacedTypes } from "@/store/modules/statistics";

import Alert from "@/components/ui/Alert";
import DynamicTabs from "@/components/tabs/DynamicTabs";
import FeatureFlagMixin from "@/mixins/FeatureFlagMixin";

export default {
    name: "DashboardPage",
    mixins: [FeatureFlagMixin],
    components: { DynamicTabs, Alert },
    props: {
        /**
         * Used to set the grid layout for the dashboard
         * @type {Boolean}
         */
        grid: {
            type: Boolean,
            default: true,
        },
    },
    computed: {
        /**
         * The authentication state for the user model
         * @type {Object}
         */
        tabs() {
            let tabs = [
                {
                    text: "overall",
                    route: { name: "dashboard.overview" },
                },
                {
                    text: "resilience",
                    route: { name: "dashboard.resilience" },
                },
            ];

            if (this.hasFeature("app-simulations")) {
                tabs.push({
                    text: "simulations",
                    route: { name: "dashboard.simulations" },
                });
            }

            if (this.hasFeature("app-training")) {
                tabs.push({
                    text: "training",
                    route: { name: "dashboard.training" },
                });
            }

            if (this.hasFeature("app-threats")) {
                tabs.push({
                    text: "threats",
                    route: { name: "dashboard.threats" },
                });
            }

            if (this.hasFeature("app-breaches")) {
                tabs.push({
                    text: "breaches",
                    route: { name: "dashboard.breaches" },
                });
            }

            return tabs;
        },
        /**
         * @type {Object}
         */
        error() {
            return this.$store.getters[
                StatisticsStoreNamespacedTypes.getters.ERROR
            ]();
        },
        /**
         * @type {Boolean}
         */
        hasSidebarSlot() {
            return !!this.$slots.sidebar;
        },
    },
};
</script>
