<template>
    <DataTable
        :headers="headers"
        :rows="rows"
        :loading="loading"
        :error="error"
        @update="refresh"
    >
        <template v-slot:imported_count="{ item }">
            {{ item.imported_count || 0 }}
            {{
                $tc(
                    "integrations.edit.import_type." + item.type,
                    item.imported_count
                )
            }}
        </template>
        <template v-slot:error_count="{ item }">
            {{ item.error_count || 0 }}
        </template>
        <template v-slot:status="{ item }">
            <StatusBadge :status="item.status" />
        </template>
        <template v-slot:created_at="{ item }">
            {{ item.created_at | formatDateTime }}
        </template>
    </DataTable>
</template>

<script>
import { formatDateTime } from "@/utils/date";
import StatusBadge from "@/components/display/integrations/StatusBadge";
import DataTableMixin from "@/components/tables/DataTableMixin";

export default {
    mixins: [DataTableMixin],
    components: {
        StatusBadge,
    },
    filters: {
        /**
         * @type {String}
         */
        formatDateTime,
    },
    data: () => ({
        headers: [
            {
                title: "imported_count",
                key: "imported_count",
                sortable: false,
            },
            {
                title: "error_count",
                key: "error_count",
                sortable: false,
            },
            {
                title: "status",
                key: "status",
                sortable: false,
            },
            {
                title: "created_at",
                key: "created_at",
                sortable: false,
            },
        ],
    }),
};
</script>
