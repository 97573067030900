<template>
    <ValidationObserver ref="segmentSettingsForm" v-slot="{ passes }">
        <Alert :error="error" />
        <form @submit.prevent="passes(updateSegment)">
            <FormFieldSet>
                <FormInput
                    v-model="form.name"
                    type="text"
                    rules="required"
                    name="name"
                />
            </FormFieldSet>
            <div
                class="mb-4 flex flex-col rounded-xl border border-emerald-200 bg-emerald-50 px-4 pt-4"
            >
                <p class="mb-4 block text-sm font-medium text-slate-900">
                    {{ $t("groups.single.segments.form.include") }}
                </p>
                <FormFieldSet>
                    <FormSelect
                        v-model="form.all_positive_tags_required"
                        name="positive_tags_operator"
                    >
                        <option
                            v-for="option in options"
                            :key="option.value"
                            :value="option.value"
                        >
                            {{ option.name }}
                        </option>
                    </FormSelect>
                    <fieldset class="form-field">
                        <legend
                            class="mb-2 block text-sm font-medium text-slate-900"
                        >
                            {{ $t("groups.single.segments.form.select") }}
                        </legend>
                        <div
                            class="divide-y divide-emerald-200 rounded-xl border border-emerald-200 bg-white"
                        >
                            <div
                                v-for="tag in tags"
                                :key="'positive_' + tag.id"
                                class="relative flex items-start px-4 py-2"
                            >
                                <div class="min-w-0 flex-1 text-sm leading-6">
                                    <label
                                        :for="'positive_' + tag.id"
                                        class="select-none font-medium text-slate-900"
                                        >{{ tag.name }}</label
                                    >
                                </div>
                                <div class="ml-3 flex h-6 items-center">
                                    <input
                                        :id="'positive_' + tag.id"
                                        :name="'positive_' + tag.id"
                                        type="checkbox"
                                        :value="tag.id"
                                        v-model="form.positive_tags"
                                        class="h-4 w-4 rounded border-slate-300 text-indigo-600 focus:ring-indigo-600"
                                    />
                                </div>
                            </div>
                        </div>
                    </fieldset>
                </FormFieldSet>
            </div>
            <div
                class="mb-4 flex flex-col rounded-xl border border-rose-200 bg-rose-50 px-4 pt-4"
            >
                <p class="mb-4 block text-sm font-medium text-slate-900">
                    {{ $t("groups.single.segments.form.exclude") }}
                </p>
                <FormFieldSet>
                    <FormSelect
                        v-model="form.all_negative_tags_required"
                        name="negative_tags_operator"
                    >
                        <option
                            v-for="option in options"
                            :key="option.value"
                            :value="option.value"
                        >
                            {{ option.name }}
                        </option>
                    </FormSelect>
                    <fieldset class="form-field">
                        <legend
                            class="mb-2 block text-sm font-medium text-slate-900"
                        >
                            {{ $t("groups.single.segments.form.select") }}
                        </legend>
                        <div
                            class="divide-y divide-rose-200 rounded-xl border border-rose-200 bg-white"
                        >
                            <div
                                v-for="tag in tags"
                                :key="'negative_' + tag.id"
                                class="relative flex items-start px-4 py-2"
                            >
                                <div class="min-w-0 flex-1 text-sm leading-6">
                                    <label
                                        :for="'negative_' + tag.id"
                                        class="select-none font-medium text-slate-900"
                                        >{{ tag.name }}</label
                                    >
                                </div>
                                <div class="ml-3 flex h-6 items-center">
                                    <input
                                        :id="'negative_' + tag.id"
                                        :name="'negative_' + tag.id"
                                        type="checkbox"
                                        :value="tag.id"
                                        v-model="form.negative_tags"
                                        class="h-4 w-4 rounded border-slate-300 text-indigo-600 focus:ring-indigo-600"
                                    />
                                </div>
                            </div>
                        </div>
                    </fieldset>
                </FormFieldSet>
            </div>
            <FormFieldSet>
                <div class="flex justify-end">
                    <FormButton type="submit" :loading="loading">
                        {{ $t("groups.single.segments.update") }}
                    </FormButton>
                </div>
            </FormFieldSet>
        </form>
    </ValidationObserver>
</template>

<script>
import { TeamStoreNamespacedTypes } from "@/store/modules/team";

import { getProperty } from "@/utils/object";

import { ValidationObserver } from "vee-validate";
import Alert from "@/components/ui/Alert";
import FormButton from "@/components/forms/fields/FormButton";
import FormFieldSet from "@/components/forms/fields/FormFieldSet";
import FormInput from "@/components/forms/fields/FormInput";
import FormSelect from "@/components/forms/fields/FormSelect";

export default {
    name: "SegmentSettingsForm",
    components: {
        FormSelect,
        FormInput,
        ValidationObserver,
        FormFieldSet,
        FormButton,
        Alert,
    },
    props: {
        segment: {
            type: Object,
            required: true,
        },
    },
    data: () => ({
        loading: false,
        form: {
            name: null,
            positive_tags: [],
            all_positive_tags_required: "no",
            negative_tags: [],
            all_negative_tags_required: "no",
        },
    }),
    computed: {
        /**
         * The error state of the API request.
         * @type {Object}
         */
        error() {
            return this.$store.getters[
                TeamStoreNamespacedTypes.getters.ERROR
            ]();
        },

        /**
         * @type {Array}
         */
        group() {
            return this.$store.getters[
                TeamStoreNamespacedTypes.getters.GROUP
            ]();
        },

        /**
         * @type {Array}
         */
        tags() {
            return getProperty(this.group, "tags", []);
        },

        /**
         * @type {Array}
         */
        options() {
            return [
                {
                    value: "yes",
                    name: this.$t("groups.single.segments.form.all"),
                },
                {
                    value: "no",
                    name: this.$t("groups.single.segments.form.any"),
                },
            ];
        },
    },
    methods: {
        /**
         * Sends authentication credentials to the server.
         */
        updateSegment() {
            this.loading = true;
            this.$store
                .dispatch(TeamStoreNamespacedTypes.actions.UPDATE_SEGMENT, {
                    id: getProperty(this.segment, "id", null),
                    data: this.form,
                })
                .then(() => {
                    this.loading = false;
                    if (!this.error) {
                        this.$emit("saved");
                    }
                });
        },
    },
    created() {
        let positive_tags_operator = getProperty(
            this.segment,
            "all_positive_tags_required",
            false
        )
            ? "yes"
            : "no";

        let negative_tags_operator = getProperty(
            this.segment,
            "all_negative_tags_required",
            false
        )
            ? "yes"
            : "no";

        let positive_tags = getProperty(this.segment, "positive_tags", []).map(
            (tag) => tag.id
        );

        let negative_tags = getProperty(this.segment, "negative_tags", []).map(
            (tag) => tag.id
        );

        this.form = {
            name: getProperty(this.segment, "name", null),
            positive_tags: positive_tags,
            all_positive_tags_required: positive_tags_operator,
            negative_tags: negative_tags,
            all_negative_tags_required: negative_tags_operator,
        };
    },
    beforeCreate() {
        this.$store.commit(TeamStoreNamespacedTypes.mutations.SET_ERROR, null);
    },
};
</script>
