var render = function render(){var _vm=this,_c=_vm._self._c;return _c('WorkspaceSettingsPage',[_c('SlideOver',{attrs:{"active":_vm.showPrompt,"gutter":false},on:{"close":function($event){_vm.showPrompt = false}},scopedSlots:_vm._u([{key:"content",fn:function(){return [_c('SelectServices',{attrs:{"loading":_vm.updateLoading},on:{"saved":_vm.handleServicesSaved}})]},proxy:true}])},[_c('h2',{staticClass:"text-base font-semibold leading-6 text-slate-900",attrs:{"slot":"title"},slot:"title"},[_vm._v(" "+_vm._s(_vm.$t("settings.simulation.services.heading"))+" ")])]),_c('div',{staticClass:"flex flex-col lg:flex-row lg:space-x-6"},[_c('div',{staticClass:"flex flex-1 flex-col xl:w-1/2"},[_c('div',{staticClass:"card mb-4"},[_c('CardHeading',{attrs:{"title":_vm.$t('settings.simulation.general.heading').toString(),"text":_vm.$t('settings.simulation.general.text').toString()}}),_c('div',{staticClass:"p-4"},[_c('CampaignSettingsForm',{on:{"saved":_vm.handleSaved}})],1)],1),_c('div',{staticClass:"card"},[_c('CardHeading',{attrs:{"title":_vm.$t(
                            'settings.simulation.software_targeting.heading'
                        ).toString(),"text":_vm.$t(
                            'settings.simulation.software_targeting.text'
                        ).toString()}},[(!_vm.hasFeature('app-simulations-software'))?_c('PremiumFeatureBadge'):_vm._e()],1),_c('div',{staticClass:"relative p-4"},[(!_vm.hasFeature('app-simulations-software'))?_c('LockedFeatureOverlay'):_vm._e(),_c('CampaignServicesPreview',{on:{"edit":function($event){_vm.showPrompt = true},"saved":_vm.handleSaved}})],1)],1)]),_c('div',{staticClass:"flex flex-shrink-0 flex-col xl:w-1/2"},[_c('div',{staticClass:"card"},[_c('CardHeading',{attrs:{"title":_vm.$t(
                            'settings.simulation.ceo_fraud.heading'
                        ).toString(),"text":_vm.$t('settings.simulation.ceo_fraud.text').toString()}},[(!_vm.hasFeature('app-simulations-ceo'))?_c('PremiumFeatureBadge'):_vm._e()],1),_c('div',{staticClass:"relative p-4"},[(!_vm.hasFeature('app-simulations-ceo'))?_c('LockedFeatureOverlay'):_vm._e(),_c('CampaignPeopleForm',{on:{"saved":_vm.handleSaved}})],1)],1)])])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }