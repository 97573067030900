import "./index.css";

import Vue from "vue";

/**
 * Vuex
 */
import store from "./store";

/**
 * Routing
 */
import Router from "./router";

/**
 * Translation
 */
import i18n from "./lang";

/**
 * Validation
 */
import "./validation";

/**
 * Apex charts
 */
import VueApexCharts from "vue-apexcharts";
Vue.use(VueApexCharts);
Vue.component("apex-chart", VueApexCharts);

/**
 * Run app
 */
const Main = require("./views/App").default;

new Vue({
    el: "#app",
    router: Router,
    i18n,
    store,
    render: (h) => h(Main),
});
