<template>
    <div class="flex flex-col justify-between">
        <div class="p-4">
            <div class="mb-4 flex w-full flex-row justify-between">
                <h3
                    class="flex-1 font-medium text-slate-500"
                    v-text="title"
                ></h3>
                <div>
                    <LoadingSpinner v-if="loading" class="size-5" />
                    <div v-else class="flex flex-row space-x-2">
                        <div
                            v-if="hasLink || hasEmitter"
                            @click="navigateToResource"
                            class="flex h-6 cursor-pointer items-center justify-center gap-x-1 rounded-full bg-slate-50/50 px-2 text-xs font-medium text-slate-700 ring-1 ring-inset ring-slate-500/20 transition hover:bg-slate-100"
                        >
                            {{ $t("statistics.show_more") }}
                            <Icon
                                :icon="IconEnums.EXPAND"
                                class="h-3.5 w-3.5"
                            />
                        </div>
                    </div>
                </div>
            </div>
            <h4 class="flex items-center">
                <span
                    v-text="formattedValue"
                    class="text-4xl font-bold leading-7 tracking-tighter text-slate-800"
                ></span>
                <span
                    v-if="total"
                    class="ml-3 text-sm font-medium text-slate-500"
                >
                    /
                    <span v-text="formattedTotal"></span>
                </span>
                <span
                    v-else-if="unit"
                    class="ml-3 text-xs font-medium text-slate-500"
                    v-text="unit"
                ></span>
            </h4>
        </div>
        <GroupChart
            class="h-32 rounded-b bg-slate-50"
            :loading="loading"
            :rows="rows"
            :data="data"
            :identifier="identifier"
        />
    </div>
</template>

<script>
import StatisticsCardMixin from "@/mixins/statistics/StatisticsCardMixin";
import ActionableDataMixin from "@/mixins/statistics/ActionableDataMixin";

import { formatNumber } from "@/utils/number";
import { getProperty } from "@/utils/object";

import GroupChart from "@/components/charts/GroupChart/GroupChart";

export default {
    name: "GroupChartCard",
    mixins: [StatisticsCardMixin, ActionableDataMixin],
    components: { GroupChart },
    props: {
        loading: {
            type: Boolean,
            default: false,
        },
        title: {
            type: String,
            required: true,
        },
        identifier: {
            type: String,
            required: true,
        },
        rows: {
            type: Number,
            default: 2,
        },
        data: {
            type: Object,
            default: () => ({}),
        },
        unit: {
            type: String,
            required: false,
        },
    },
    computed: {
        value() {
            return getProperty(this.data, "count", 0);
        },
        total() {
            return getProperty(this.data, "total_count", 0);
        },
        formattedValue() {
            return formatNumber(this.value);
        },
        formattedTotal() {
            return formatNumber(this.total);
        },
    },
};
</script>
