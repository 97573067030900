<template>
    <DataTable
        name="praticipation.index"
        :headers="headers"
        :rows="rows"
        :meta="meta"
        :loading="loading"
        :error="error"
        :emit="true"
        @emit="$emit('emit', $event)"
        @update="refresh"
    >
        <template v-slot:employee="{ item }">
            <div class="flex items-center">
                <Avatar :image="item.avatar" :name="item.full_name" />
                <div class="ml-3">
                    <div class="mb-0 text-sm font-medium text-slate-900">
                        {{ item.full_name }}
                    </div>
                    <div class="text-sm text-slate-500">
                        {{ item.email || "No email found" }}
                    </div>
                </div>
            </div>
        </template>
        <template v-slot:status="{ item }">
            <Badge
                :variant="
                    item.status === 'active'
                        ? BadgeVariants.SUCCESS
                        : BadgeVariants.ERROR
                "
                :text="$t('employees.' + item.status).toString()"
            />
        </template>
        <template v-slot:completed="{ item }">
            <Badge
                :variant="
                    item.completed ? BadgeVariants.SUCCESS : BadgeVariants.ERROR
                "
                :text="
                    item.completed
                        ? $t('employees.training.completed')
                        : $t('employees.training.not_completed')
                "
            />
        </template>
        <template v-slot:invited="{ item }">
            <Badge
                :variant="
                    item.invited ? BadgeVariants.SUCCESS : BadgeVariants.ERROR
                "
                :text="
                    item.invited
                        ? $t('employees.training.invited')
                        : $t('employees.training.not_invited')
                "
            />
        </template>
    </DataTable>
</template>

<script>
import DataTableMixin from "@/components/tables/DataTableMixin";
import { formatDate } from "@/utils/date";
import Badge, { BadgeVariants } from "@/components/shared/Badge";
import Avatar from "@/components/shared/Avatar";

export default {
    name: "ParticipationTable",
    mixins: [DataTableMixin],
    components: {
        Avatar,
        Badge,
    },
    filters: {
        /**
         * @type {String}
         */
        formatDate,
    },
    data: () => ({
        BadgeVariants,
        headers: [
            {
                title: "employee",
                key: "employee",
                sortable: false,
            },
            {
                title: "status",
                key: "status",
                sortable: true,
            },
            {
                title: "lesson_status",
                key: "completed",
                sortable: true,
            },
            {
                title: "invited",
                key: "invited",
                sortable: false,
            },
        ],
    }),
};
</script>
