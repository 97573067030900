<template>
    <path
        d="M12 2v4m0-4h-2m2 0h2m-2 4a8 8 0 1 0 0 16 8 8 0 0 0 0-16Zm7-1 1 1M9 14.285l2.007 2.005a12.843 12.843 0 0 1 3.906-4.23L15 12"
        fill="none"
        stroke="currentColor"
        stroke-linecap="round"
        stroke-linejoin="round"
        stroke-width="2"
    />
</template>

<script>
import IconSvgBase from "./IconSvgBase";

export default {
    extends: IconSvgBase,
    name: "IconTraining",
};
</script>
