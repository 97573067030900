<template>
    <DataTable
        :headers="headers"
        :rows="rows"
        :meta="meta"
        :loading="loading"
        :error="error"
        @update="refresh"
    >
        <template v-slot:title="{ item }">
            <div class="flex items-center space-x-3">
                <div class="flex-shrink-0">
                    <div class="media w-32 rounded-xl ring-1 ring-slate-200">
                        <img
                            :src="item.content.image"
                            class="media-figure"
                            alt="media"
                        />
                    </div>
                </div>
                <div class="min-w-0 flex-1">
                    <p class="text-sm font-medium text-slate-900">
                        {{ item.content.title }}
                    </p>
                    <p class="truncate text-sm text-slate-500">
                        {{ $t("employees.training.type." + item.type) }}
                    </p>
                </div>
            </div>
        </template>
        <template v-slot:completed="{ item }">
            <div class="flex justify-start">
                <div
                    v-if="item.completed"
                    class="flex items-center gap-2 rounded-full bg-emerald-50/50 py-1 pl-1.5 pr-3 text-xs text-success-dark ring-1 ring-inset ring-emerald-500/20"
                >
                    <Icon
                        :icon="IconEnums.CHECKMARK"
                        class="h-4 w-4 flex-none fill-emerald-500 stroke-white"
                    />
                    {{ $t("employees.training.completed") }}
                </div>
                <div
                    v-else
                    class="flex items-center gap-2 rounded-full bg-rose-50/50 px-3 py-1 text-xs text-error-dark ring-1 ring-inset ring-rose-500/20"
                >
                    {{ $t("employees.training.not_completed") }}
                </div>
            </div>
        </template>
        <template v-slot:deadline="{ item }">
            {{ item.deadline | formatDate }}
        </template>
    </DataTable>
</template>

<script>
import DataTableMixin from "@/components/tables/DataTableMixin";
import { formatDate } from "@/utils/date";
import Icon from "@/components/icons/BaseIcon";
import { IconEnums } from "@/utils/icons";

export default {
    name: "TrainingTable",
    mixins: [DataTableMixin],
    components: {
        Icon,
    },
    filters: {
        /**
         * @type {String}
         */
        formatDate,
    },
    data: () => ({
        IconEnums,
        headers: [
            {
                title: "title",
                key: "title",
                sortable: false,
            },
            {
                title: "status",
                key: "completed",
                sortable: false,
            },
            {
                title: "deadline",
                key: "deadline",
                sortable: false,
            },
        ],
    }),
};
</script>
