<script>
export default {
    name: "IconSvgBase",
    data: () => ({
        viewBoxHeight: 24,
        viewBoxWidth: 24,
    }),
    methods: {
        /**
         * @return {number}
         * @public
         */
        getViewBoxHeight() {
            return this.viewBoxHeight;
        },
        /**
         * @return {number}
         * @public
         */
        getViewBoxWidth() {
            return this.viewBoxWidth;
        },
    },
};
</script>
