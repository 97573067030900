<template>
    <div class="h-[160px] w-full overflow-hidden">
        <div v-if="loading" class="flex h-full items-center justify-center">
            <div
                class="h-full w-full animate-pulse rounded-xl bg-slate-100"
            ></div>
        </div>
        <apex-chart
            v-else
            type="line"
            :options="options"
            :series="data"
            height="165"
        ></apex-chart>
    </div>
</template>

<script>
import { buildTooltip } from "@/utils/charts";

export default {
    name: "LineChart",
    props: {
        loading: {
            type: Boolean,
            required: true,
        },
        data: {
            type: Array,
            required: true,
        },
        labels: {
            type: Array,
            required: true,
        },
        legends: {
            type: Array,
            default: () => [],
        },
    },
    computed: {
        colors() {
            return this.legends.map((legend) => legend.color);
        },
        options() {
            return {
                chart: {
                    toolbar: {
                        show: false,
                    },
                    zoom: {
                        enabled: false,
                    },
                },
                dataLabels: {
                    enabled: false,
                },
                stroke: {
                    curve: "smooth",
                    width: [3, 3],
                    dashArray: [0, 4],
                },
                grid: {
                    strokeDashArray: 0,
                    padding: {
                        top: -20,
                        right: 0,
                    },
                },
                title: {
                    show: false,
                },
                legend: {
                    show: false,
                },
                xaxis: {
                    type: "category",
                    categories: this.labels,
                    axisBorder: {
                        show: false,
                    },
                    axisTicks: {
                        show: false,
                    },
                    tooltip: {
                        enabled: false,
                    },
                    labels: {
                        offsetY: 2,
                        style: {
                            colors: "#9ca3af",
                            fontSize: "13px",
                            fontFamily: "ui-sans-serif, system-ui, sans-serif",
                            fontWeight: 400,
                        },
                    },
                },
                yaxis: {
                    min: 0,
                    max: 100,
                    tickAmount: 4,
                    labels: {
                        align: "left",
                        minWidth: 0,
                        maxWidth: 140,
                        style: {
                            colors: "#9ca3af",
                            fontSize: "12px",
                            fontFamily: "ui-sans-serif, system-ui, sans-serif",
                            fontWeight: 400,
                        },
                        formatter: (value) =>
                            value >= 1000 ? `${value / 1000}k` : value,
                    },
                },
                tooltip: {
                    custom: function (props) {
                        return buildTooltip(props, {
                            title: "Score",
                            valuePostfix: "%",
                            hasTextLabel: true,
                            wrapperExtClasses: "min-w-[200px]",
                            labelDivider: ":",
                            labelExtClasses: "ms-2",
                        });
                    },
                },
                colors: this.colors,
            };
        },
    },
};
</script>
