var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"card p-4"},[_c('div',{staticClass:"flex flex-row justify-between"},[_c('div',{staticClass:"flex flex-col"},[_c('h3',{staticClass:"mb-1 font-medium leading-tight text-slate-500",domProps:{"textContent":_vm._s(
                    _vm.$t('statistics.activities_by_type.title').toString()
                )}}),_c('p',{staticClass:"text-xs font-medium text-slate-400",domProps:{"textContent":_vm._s(_vm.$t('statistics.activities_by_type.text').toString())}})]),(_vm.loading)?_c('LoadingSpinner',{staticClass:"size-5"}):_vm._e()],1),_c('div',{staticClass:"mt-4 space-y-4"},[(_vm.loading)?_c('div',{staticClass:"flex h-2.5 w-full animate-pulse overflow-hidden rounded-full bg-slate-200"}):_c('div',{staticClass:"flex h-2.5 w-full space-x-1 overflow-hidden rounded-full"},[(_vm.simulationsDeliveredWidth > 0)?_c('div',{staticClass:"flex flex-col justify-center overflow-hidden whitespace-nowrap bg-blue-500 text-center text-xs text-white",style:({
                    width: _vm.simulationsDeliveredWidth + '%',
                }),attrs:{"role":"progressbar","aria-valuenow":_vm.simulationsDeliveredWidth,"aria-valuemin":"0","aria-valuemax":"100"}}):_vm._e(),(_vm.videosWatchedWidth > 0)?_c('div',{staticClass:"flex flex-col justify-center overflow-hidden whitespace-nowrap bg-violet-500 text-center text-xs text-white",style:({
                    width: _vm.videosWatchedWidth + '%',
                }),attrs:{"role":"progressbar","aria-valuenow":_vm.videosWatchedWidth,"aria-valuemin":"0","aria-valuemax":"100"}}):_vm._e(),(_vm.coursesCompletedWidth > 0)?_c('div',{staticClass:"flex flex-col justify-center overflow-hidden whitespace-nowrap bg-teal-400 text-center text-xs text-white",style:({
                    width: _vm.coursesCompletedWidth + '%',
                }),attrs:{"role":"progressbar","aria-valuenow":_vm.coursesCompletedWidth,"aria-valuemin":"0","aria-valuemax":"100"}}):_vm._e(),(_vm.threatsReportedWidth > 0)?_c('div',{staticClass:"flex flex-col justify-center overflow-hidden whitespace-nowrap bg-blue-400 text-center text-xs text-white",style:({
                    width: _vm.threatsReportedWidth + '%',
                }),attrs:{"role":"progressbar","aria-valuenow":_vm.threatsReportedWidth,"aria-valuemin":"0","aria-valuemax":"100"}}):_vm._e(),(_vm.quizAnswersWidth > 0)?_c('div',{staticClass:"flex flex-col justify-center overflow-hidden whitespace-nowrap bg-yellow-300 text-center text-xs text-white",style:({
                    width: _vm.quizAnswersWidth + '%',
                }),attrs:{"role":"progressbar","aria-valuenow":_vm.quizAnswersWidth,"aria-valuemin":"0","aria-valuemax":"100"}}):_vm._e()]),_c('ul',{staticClass:"space-y-4"},[_c('li',{staticClass:"grid grid-cols-2 items-center justify-between gap-x-4"},[_c('div',{staticClass:"flex items-center"},[_c('span',{staticClass:"me-2.5 inline-block h-2.5 w-2.5 flex-shrink-0 rounded-sm bg-blue-600"}),_c('span',{staticClass:"text-sm font-medium text-slate-600",domProps:{"textContent":_vm._s(
                            _vm.$t(
                                'statistics.activities_by_type.simulations_delivered'
                            ).toString()
                        )}})]),_c('div',{staticClass:"text-end"},[_c('span',{staticClass:"rounded-md bg-slate-200 px-1.5 py-0.5 text-center text-xs font-medium text-slate-600",domProps:{"textContent":_vm._s(_vm.simulationsDeliveredFormatted)}})])]),_c('li',{staticClass:"grid grid-cols-2 items-center justify-between gap-x-4"},[_c('div',{staticClass:"flex items-center"},[_c('span',{staticClass:"me-2.5 inline-block h-2.5 w-2.5 flex-shrink-0 rounded-sm bg-violet-500"}),_c('span',{staticClass:"text-sm font-medium text-slate-600",domProps:{"textContent":_vm._s(
                            _vm.$t(
                                'statistics.activities_by_type.videos_watched'
                            ).toString()
                        )}})]),_c('div',{staticClass:"text-end"},[_c('span',{staticClass:"rounded-md bg-slate-200 px-1.5 py-0.5 text-center text-xs font-medium text-slate-600",domProps:{"textContent":_vm._s(_vm.videosWatchedFormatted)}})])]),_c('li',{staticClass:"grid grid-cols-2 items-center justify-between gap-x-4"},[_c('div',{staticClass:"flex items-center"},[_c('span',{staticClass:"me-2.5 inline-block h-2.5 w-2.5 flex-shrink-0 rounded-sm bg-teal-400"}),_c('span',{staticClass:"text-sm font-medium text-slate-600",domProps:{"textContent":_vm._s(
                            _vm.$t(
                                'statistics.activities_by_type.completed_courses'
                            ).toString()
                        )}})]),_c('div',{staticClass:"text-end"},[_c('span',{staticClass:"rounded-md bg-slate-200 px-1.5 py-0.5 text-center text-xs font-medium text-slate-600",domProps:{"textContent":_vm._s(_vm.coursesCompletedFormatted)}})])]),_c('li',{staticClass:"grid grid-cols-2 items-center justify-between gap-x-4"},[_c('div',{staticClass:"flex items-center"},[_c('span',{staticClass:"me-2.5 inline-block h-2.5 w-2.5 flex-shrink-0 rounded-sm bg-blue-400"}),_c('span',{staticClass:"text-sm font-medium text-slate-600",domProps:{"textContent":_vm._s(
                            _vm.$t(
                                'statistics.activities_by_type.threats_reported'
                            ).toString()
                        )}})]),_c('div',{staticClass:"text-end"},[_c('span',{staticClass:"rounded-md bg-slate-200 px-1.5 py-0.5 text-center text-xs font-medium text-slate-600",domProps:{"textContent":_vm._s(_vm.threatsReportedFormatted)}})])]),_c('li',{staticClass:"grid grid-cols-2 items-center justify-between gap-x-4"},[_c('div',{staticClass:"flex items-center"},[_c('span',{staticClass:"me-2 inline-block h-2.5 w-2.5 flex-shrink-0 rounded-sm bg-yellow-300"}),_c('span',{staticClass:"text-sm font-medium text-slate-600",domProps:{"textContent":_vm._s(
                            _vm.$t(
                                'statistics.activities_by_type.quiz_answers'
                            ).toString()
                        )}})]),_c('div',{staticClass:"text-end"},[_c('span',{staticClass:"rounded-md bg-slate-200 px-1.5 py-0.5 text-center text-xs font-medium text-slate-600",domProps:{"textContent":_vm._s(_vm.quizAnswersFormatted)}})])])])])])
}
var staticRenderFns = []

export { render, staticRenderFns }