var render = function render(){var _vm=this,_c=_vm._self._c;return _c(_vm.tagName,{tag:"component",staticClass:"form-button inline-flex cursor-pointer items-center justify-center rounded-md border font-medium leading-none shadow-sm transition duration-150 ease-in-out focus:outline-none focus:ring-2 focus:ring-offset-1 disabled:cursor-not-allowed disabled:opacity-75",class:[
        {
            'is-pressed': _vm.pressed,
            'has-content': _vm.hasContentInSlot,
        },
        _vm.variantClasses,
    ],attrs:{"disabled":_vm.disabled || _vm.loading,"href":_vm.href,"target":_vm.target,"title":_vm.title,"type":_vm.buttonType,"to":_vm.to},on:{"click":_vm.onClick}},[(_vm.loading)?_c('svg',{staticClass:"h-3.5 w-3.5 animate-spin",class:{ 'mr-2': _vm.hasContentInSlot },attrs:{"xmlns":"http://www.w3.org/2000/svg","fill":"none","viewBox":"0 0 24 24"}},[_c('circle',{staticClass:"opacity-25",attrs:{"cx":"12","cy":"12","r":"10","stroke":"currentColor","stroke-width":"4"}}),_c('path',{staticClass:"opacity-75",attrs:{"fill":"currentColor","d":"M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"}})]):_vm._e(),(_vm.icon && !_vm.loading)?_c('Icon',{staticClass:"h-4 w-4",class:[{ 'mr-2': _vm.hasContentInSlot }],attrs:{"icon":_vm.icon}}):_vm._e(),_vm._t("default")],2)
}
var staticRenderFns = []

export { render, staticRenderFns }