<template>
    <div>
        <div class="p-4">
            <div
                class="flex flex-col md:flex-row md:items-center md:justify-between"
            >
                <div class="mb-2 flex flex-col md:mb-0">
                    <h3
                        class="font-medium leading-7 text-slate-500"
                        v-text="title"
                    ></h3>
                    <p
                        v-if="text"
                        class="text-xs font-medium text-slate-400"
                        v-text="text"
                    ></p>
                </div>
                <LoadingSpinner v-if="loading" class="size-5" />
                <div v-else class="flex items-center gap-x-4">
                    <div
                        v-for="(legend, index) in legends"
                        :key="`legend-${index}`"
                        class="inline-flex items-center"
                    >
                        <span
                            class="me-2 inline-block h-2.5 w-2.5 flex-shrink-0 rounded-sm"
                            :class="`bg-[${legend.color}]`"
                        ></span>
                        <span class="text-sm font-medium text-slate-600">{{
                            legend.title
                        }}</span>
                    </div>
                </div>
            </div>
        </div>
        <div class="h-full overflow-hidden rounded-b bg-slate-50 p-4">
            <LineChart
                :loading="loading"
                :data="data"
                :labels="labels"
                :legends="legends"
            />
        </div>
    </div>
</template>

<script>
import StatisticsCardMixin from "@/mixins/statistics/StatisticsCardMixin";
import ActionableDataMixin from "@/mixins/statistics/ActionableDataMixin";
import LineChart from "@/components/charts/LineChart/LineChart";

export default {
    name: "LineChartCard",
    mixins: [StatisticsCardMixin, ActionableDataMixin],
    components: { LineChart },
    props: {
        data: {
            type: Array,
            default: () => [],
        },
        labels: {
            type: Array,
            default: () => [],
        },
        legends: {
            type: Array,
            default: () => [],
        },
    },
};
</script>
