/**
 * A store breach.
 *
 * @typedef {{ breachLoading: boolean, breachError: ErrorOrObject, breaches: Array }} BreachStoreState
 */
import { addNamespace } from "./namespace";
import { getData, postData } from "@/api";
import { getProperty } from "@/utils/object";

/**
 * The types used in this store
 * @enum {string}
 */
export const BreachStoreTypes = {
    getters: {
        BREACH_LOADING: "breachLoading",
        BREACH_ERROR: "breachError",
        BREACHES: "breaches",
        BREACH: "breach",
    },
    actions: {
        GET_BREACHES: "getBreaches",
        GET_BREACH: "getBreach",
    },
    mutations: {
        SET_BREACH_LOADING: "setBreachLoading",
        SET_BREACH_ERROR: "setBreachError",
        SET_BREACHES: "setBreaches",
        SET_BREACH: "setBreach",
    },
};

/**
 * A namespaced version of the types used in this store
 * @enum {string}
 */
export const BreachStoreNamespacedTypes = addNamespace(
    "breach",
    BreachStoreTypes
);

/**
 * @returns {BreachStoreState}
 */
export function state() {
    return {
        breachLoading: false,
        breachError: null,
        breaches: {
            data: [],
            meta: {
                current_page: 1,
                from: 0,
                last_page: 1,
                per_page: 10,
                to: 10,
                total: 0,
            },
        },
        breach: {
            data_classes: [],
        },
    };
}

export const getters = {
    [BreachStoreTypes.getters.BREACH_LOADING]: (state) => () => {
        return state.breachLoading;
    },
    [BreachStoreTypes.getters.BREACH_ERROR]: (state) => () => {
        return state.breachError;
    },
    [BreachStoreTypes.getters.BREACHES]: (state) => () => {
        return state.breaches;
    },
    [BreachStoreTypes.getters.BREACH]: (state) => () => {
        return state.breach;
    },
};

export const actions = {
    /**
     * Sends the given breach to the server.
     *
     * @param {VuexCommit} commit
     * @param {Object} query
     * @return {Promise}
     */
    [BreachStoreTypes.actions.GET_BREACHES]({ commit }, query) {
        commit(BreachStoreTypes.mutations.SET_BREACH_LOADING, true);
        commit(BreachStoreTypes.mutations.SET_BREACH_ERROR, null);

        return postData("/breaches", query)
            .then((response) => {
                commit(BreachStoreTypes.mutations.SET_BREACHES, {
                    data: getProperty(response.data, "data", []),
                    meta: getProperty(response.data, "meta", {
                        current_page: 1,
                        from: 0,
                        last_page: 1,
                        per_page: 10,
                        to: 10,
                        total: 0,
                    }),
                });
            })
            .catch((errors) => {
                commit(BreachStoreTypes.mutations.SET_BREACH_ERROR, errors);
            })
            .finally(() => {
                commit(BreachStoreTypes.mutations.SET_BREACH_LOADING, false);
            });
    },

    /**
     * Sends the given breach to the server.
     *
     * @param {VuexCommit} commit
     * @param breach
     * @return {Promise}
     */
    [BreachStoreTypes.actions.GET_BREACH]({ commit }, breach) {
        commit(BreachStoreTypes.mutations.SET_BREACH_LOADING, true);
        commit(BreachStoreTypes.mutations.SET_BREACH_ERROR, null);

        return getData("/breaches/" + breach)
            .then((response) => {
                commit(BreachStoreTypes.mutations.SET_BREACH, response.data);
            })
            .catch((errors) => {
                commit(BreachStoreTypes.mutations.SET_BREACH_ERROR, errors);
            })
            .finally(() => {
                commit(BreachStoreTypes.mutations.SET_BREACH_LOADING, false);
            });
    },
};

export const mutations = {
    /**
     * Sets the loading state for breach.
     *
     * @param {BreachStoreState} state
     * @param {boolean} loading
     */
    [BreachStoreTypes.mutations.SET_BREACH_LOADING](state, loading) {
        state.breachLoading = loading;
    },

    /**
     * Sets a new breach error.
     *
     * @param {BreachStoreState} state
     * @param {ErrorOrObject} error
     */
    [BreachStoreTypes.mutations.SET_BREACH_ERROR](state, error) {
        state.breachError = error;
    },

    /**
     * Sets a new breach error.
     *
     * @param {BreachStoreState} state
     * @param {Object} data
     */
    [BreachStoreTypes.mutations.SET_BREACHES](state, data) {
        state.breaches = data;
    },

    /**
     * Sets a new breach error.
     *
     * @param {BreachStoreState} state
     * @param {Object} data
     */
    [BreachStoreTypes.mutations.SET_BREACH](state, data) {
        state.breach = data;
    },
};

export default {
    namespaced: true,
    Types: BreachStoreTypes,
    NamespacedTypes: BreachStoreNamespacedTypes,
    state,
    getters,
    actions,
    mutations,
};
