<template>
    <li class="cursor-pointer bg-white" @click="select">
        <div
            class="relative flex items-center space-x-3 px-3.5 py-2.5 hover:bg-slate-50"
        >
            <input
                type="checkbox"
                :id="id"
                :value="id"
                v-model="selected"
                class="h-4 w-4 rounded border-slate-300 text-indigo-600 focus:ring-indigo-500"
            />
            <Avatar :image="avatar" :name="full_name" />
            <div class="min-w-0 flex-1">
                <span class="absolute inset-0" aria-hidden="true"></span>
                <p
                    class="text-sm font-medium leading-tight text-slate-900"
                    v-text="full_name"
                ></p>
                <p class="truncate text-sm text-slate-500" v-text="email"></p>
            </div>
        </div>
    </li>
</template>

<script>
import { TeamStoreNamespacedTypes } from "@/store/modules/team";

import { getProperty } from "@/utils/object";
import Avatar from "@/components/shared/Avatar";

export default {
    components: { Avatar },
    props: {
        employee: {
            type: Object,
            default: () => {},
        },
    },
    computed: {
        /**
         * @type {Array}
         */
        selected() {
            return this.$store.getters[
                TeamStoreNamespacedTypes.getters.SELECTED
            ]();
        },
        /**
         * @type {string}
         */
        id() {
            return getProperty(this.employee, "id", null);
        },

        /**
         * @type {string}
         */
        avatar() {
            return getProperty(this.employee, "avatar", null);
        },

        /**
         * @type {string}
         */
        first_name() {
            return getProperty(this.employee, "first_name", "Anonymous");
        },

        /**
         * @type {string}
         */
        full_name() {
            return getProperty(this.employee, "full_name", null);
        },

        /**
         * @type {string}
         */
        email() {
            return getProperty(this.employee, "email", "No email found");
        },
    },
    methods: {
        select() {
            this.$emit("select", this.id);
        },
    },
};
</script>
