/* Update route title tags & page meta */
export default function updatePageTags(to, from, next) {
    const nearestWithTitle = to.matched
        .slice()
        .reverse()
        .find((r) => r.meta && r.meta.title);

    if (nearestWithTitle) {
        document.title = nearestWithTitle.meta.title;
    }

    Array.from(
        document.querySelectorAll("[data-vue-router-controlled]")
    ).forEach((el) => {
        el.parentNode.removeChild(el);
    });

    next();
}
