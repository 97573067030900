<template>
    <DataTable
        name="employees.index"
        :headers="headers"
        :rows="rows"
        :meta="meta"
        :loading="loading"
        :error="error"
        :emit="true"
        @emit="$emit('emit', $event)"
        @update="refresh"
    >
        <template v-slot:first_name="{ item }">
            <div class="flex items-center">
                <Avatar :image="item.avatar" :name="item.full_name" />
                <div class="ml-3">
                    <div class="mb-0 text-sm font-medium text-slate-900">
                        {{ item.full_name }}
                    </div>
                    <div class="text-sm text-slate-500">
                        {{ item.email || "No email found" }}
                    </div>
                </div>
            </div>
        </template>
        <template v-slot:status="{ item }">
            <Badge
                :variant="
                    item.status === 'active'
                        ? BadgeVariants.SUCCESS
                        : BadgeVariants.ERROR
                "
                :text="$t('employees.' + item.status).toString()"
            />
        </template>
        <template v-slot:score="{ item }">
            <ScoreBadge :value="item.score" />
        </template>
        <template v-slot:locale="{ item }">
            {{ $t(`language.${item.locale}`) }}
        </template>
        <template v-slot:group_count="{ item }">
            {{ item.group_count || 0 }}
        </template>
        <template v-slot:created_at="{ item }">
            {{ item.created_at | formatDate }}
        </template>
    </DataTable>
</template>

<script>
import { initials } from "@/utils/filters";
import { formatDate } from "@/utils/date";

import DataTableMixin from "@/components/tables/DataTableMixin";
import ScoreBadge from "@/components/display/employee/ScoreBadge";
import Badge, { BadgeVariants } from "@/components/shared/Badge";
import Avatar from "@/components/shared/Avatar";

export default {
    mixins: [DataTableMixin],
    components: {
        Avatar,
        Badge,
        ScoreBadge,
    },
    filters: {
        /**
         * @type {String}
         */
        initials,

        /**
         * @type {String}
         */
        formatDate,
    },
    data: () => ({
        BadgeVariants,
        headers: [
            {
                title: "employee",
                key: "first_name",
                sortable: true,
            },
            {
                title: "status",
                key: "status",
                sortable: true,
            },
            {
                title: "score",
                key: "score",
                sortable: true,
            },
            {
                title: "groups",
                key: "group_count",
                sortable: false,
            },
            {
                title: "locale",
                key: "locale",
                sortable: true,
            },
            {
                title: "created",
                key: "created_at",
                sortable: true,
            },
        ],
    }),
};
</script>
