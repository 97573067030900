<template>
    <path
        d="M12 2a15.267 15.267 0 0 1 2.92 2.777c.054.066.08.145.08.225M12 8a15.266 15.266 0 0 0 2.92-2.777.356.356 0 0 0 .08-.221M12 16a15.264 15.264 0 0 0-2.92 2.777.356.356 0 0 0-.08.221M12 22a15.264 15.264 0 0 1-2.92-2.777.355.355 0 0 1-.08-.225m6-13.996C14.7 5 14.368 5 14 5h-4c-1.861 0-2.792 0-3.545.245a5 5 0 0 0-3.21 3.21C3 9.208 3 10.139 3 12s0 2.792.245 3.545A5 5 0 0 0 5 18m4 .998c.3.002.632.002 1 .002h4c1.861 0 2.792 0 3.545-.245a5 5 0 0 0 3.21-3.21C21 14.792 21 13.861 21 12s0-2.792-.245-3.545A5 5 0 0 0 19 6"
        fill="none"
        stroke="currentColor"
        stroke-linecap="round"
        stroke-linejoin="round"
        stroke-width="2"
    />
</template>

<script>
import IconSvgBase from "./IconSvgBase";

export default {
    extends: IconSvgBase,
    name: "IconRefresh",
};
</script>
