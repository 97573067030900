<template>
    <div
        class="relative flex cursor-pointer items-start px-4 py-3 hover:bg-slate-50"
        @click="innerValue = !innerValue"
    >
        <div class="flex min-w-0 flex-1 flex-col text-sm">
            <p class="mb-1 text-sm font-medium text-slate-900">
                {{ label || name }}
            </p>
            <div class="flex">
                <span
                    class="text-xxs mr-2 flex items-center justify-center rounded border border-slate-200 bg-slate-50 px-1 py-0.5 font-bold uppercase text-slate-600"
                >
                    <Icon :icon="IconEnums.SIGNAL" class="mr-1 h-3 w-3" />
                    {{ $t("settings.export.form.types." + type) }}</span
                >
                <span
                    class="text-xxs mr-2 flex items-center justify-center rounded border border-slate-200 bg-slate-50 px-1 py-0.5 font-bold uppercase text-slate-600"
                >
                    <Icon :icon="IconEnums.DATABASE" class="mr-1 h-3 w-3" />
                    {{ model }}.{{ key }}</span
                >
            </div>
        </div>
        <div class="ml-3 flex h-5 items-center">
            <input
                :id="key"
                :name="key"
                v-model="innerValue"
                type="checkbox"
                class="h-4 w-4 rounded border-slate-300 text-indigo-600 focus:ring-indigo-500"
            />
        </div>
    </div>
</template>

<script>
import FieldMixin from "./FieldMixin";
import { getProperty } from "@/utils/object";

import Icon from "@/components/icons/BaseIcon";
import { IconEnums } from "@/utils/icons";

export default {
    name: "DataFieldCheckbox",
    mixins: [FieldMixin],
    components: {
        Icon,
    },
    props: {
        field: {
            type: Object,
            required: true,
        },
    },
    data: () => ({
        IconEnums,
    }),
    computed: {
        key() {
            return getProperty(this.field, "key");
        },
        type() {
            return getProperty(this.field, "type");
        },
        model() {
            return getProperty(this.field, "model");
        },
    },
};
</script>
