<template>
    <div :class="sensitivityClass">
        <Icon class="h-5 w-5" :icon="sensitivityIcon" />
    </div>
</template>

<script>
import { IconEnums } from "@/utils/icons";
import { sensitivityMap } from "@/utils/breach";

import Icon from "@/components/icons/BaseIcon";

export default {
    components: { Icon },
    props: {
        item: {
            type: String,
            default: undefined,
        },
    },
    data: () => ({
        IconEnums,
        sensitivityMap,
    }),
    computed: {
        sensitivity() {
            const dataClass = this.sensitivityMap.find(
                (item) => item.name === this.item
            );

            if (typeof dataClass.score === undefined) {
                return 50;
            }

            return dataClass.score;
        },
        sensitivityIcon() {
            if (this.sensitivity === 100) {
                return this.IconEnums.ERROR;
            } else if (this.sensitivity === 50) {
                return this.IconEnums.DOMAIN;
            }

            return this.IconEnums.DOMAIN;
        },
        sensitivityClass() {
            if (this.sensitivity === 100) {
                return "text-error-normal";
            }

            return "text-slate-500";
        },
    },
};
</script>
