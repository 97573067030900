<template>
    <path
        d="m21 3-6.232 6.232M3 21l6.305-6.305m5.463-5.463c.044-.252.122-.563.226-.974l.44-1.753c.527-2.093.79-3.14.53-3.673a1.457 1.457 0 0 0-1.136-.828c-.561-.063-1.38.579-3.018 1.863L6.054 8.379c-1.248.978-1.872 1.467-2.004 1.991-.115.456-.03.942.234 1.322.302.436 1.05.65 2.546 1.077l.568.162c.786.225 1.18.337 1.439.586.228.219.385.509.449.829.021.108.027.221.02.349m5.462-5.463-5.463 5.463M8.067 21c.134.527.661.932 1.188.995.558.067 1.372-.558 3-1.809l5.652-4.343c1.27-.976 1.904-1.463 2.04-1.989a1.632 1.632 0 0 0-.228-1.328c-.271-.394-.909-.608-2.13-.96"
        fill="none"
        stroke="currentColor"
        stroke-linecap="round"
        stroke-linejoin="round"
        stroke-width="2"
    />
</template>

<script>
import IconSvgBase from "./IconSvgBase";

export default {
    extends: IconSvgBase,
    name: "IconHalt",
};
</script>
