<template>
    <WorkspaceSettingsPage>
        <div class="card">
            <CardHeading
                :title="$t('settings.import.heading').toString()"
                :text="$t('settings.import.text').toString()"
                :loading="loading"
            >
                <FormButton
                    v-if="showImport"
                    :variant="ButtonVariants.SECONDARY"
                    @click.native="resetState"
                >
                    {{ $t("settings.import.reset") }}
                </FormButton>
            </CardHeading>
            <div :class="{ 'px-4 pt-4': error }">
                <Alert :error="error" />
            </div>
            <ImportsTable v-if="showImport" :rows="rows" :loading="loading" />
            <form
                v-else
                enctype="multipart/form-data"
                novalidate
                class="relative flex w-full items-center justify-center p-4"
            >
                <div
                    class="flex h-64 w-full flex-col items-center justify-center rounded-lg border-2 border-dashed border-slate-300 bg-slate-50 hover:bg-slate-100"
                >
                    <div
                        class="relative flex flex-col items-center justify-center pb-6 pt-5"
                    >
                        <Icon
                            class="size-10 mb-3 text-slate-500"
                            :icon="IconEnums.UPLOAD"
                        />
                        <p class="mb-2 text-sm text-slate-500">
                            <span class="font-semibold">{{
                                $t("settings.import.button")
                            }}</span>
                            {{ $t("settings.import.drop") }}
                        </p>
                        <p class="text-xs text-slate-500">
                            {{ $t("settings.import.formats") }}
                        </p>
                    </div>
                    <input
                        id="file-upload"
                        name="file-upload"
                        type="file"
                        class="absolute left-0 top-0 h-full w-full cursor-pointer opacity-0"
                        :disabled="loading"
                        @change="handleChange"
                        accept=".xlsx, .xls, .csv"
                    />
                </div>
            </form>
        </div>
    </WorkspaceSettingsPage>
</template>

<script>
import { TeamStoreNamespacedTypes } from "@/store/modules/team";

import Icon from "@/components/icons/BaseIcon";
import { IconEnums } from "@/utils/icons";
import ImportsTable from "@/components/display/integrations/ImportsTable";
import FormButton, {
    ButtonVariants,
} from "@/components/forms/fields/FormButton";
import Alert from "@/components/ui/Alert";
import WorkspaceSettingsPage from "@/components/layout/WorkspaceSettingsPage";
import CardHeading from "@/components/ui/CardHeading";

export default {
    name: "EmployeeSingle",
    components: {
        CardHeading,
        Alert,
        FormButton,
        ImportsTable,
        Icon,
        WorkspaceSettingsPage,
    },
    data: () => ({
        ButtonVariants,
        IconEnums,
        columns: ["first_name", "last_name", "email"],
        showImport: false,
    }),
    computed: {
        /**
         * The loading state for the API request.
         * @type {Boolean}
         */
        loading() {
            return this.$store.getters[
                TeamStoreNamespacedTypes.getters.LOADING
            ]();
        },

        /**
         * The error state of the API request.
         * @type {Object}
         */
        error() {
            return this.$store.getters[
                TeamStoreNamespacedTypes.getters.ERROR
            ]();
        },

        /**
         * The error state of the API request.
         * @type {Object}
         */
        import() {
            return this.$store.getters[
                TeamStoreNamespacedTypes.getters.CSV_IMPORT
            ]();
        },

        rows() {
            return [this.import];
        },
    },
    methods: {
        resetState() {
            this.showImport = false;
        },
        handleChange(event) {
            const file = event.target.files[0];
            if (!file) return;
            const formData = new FormData();
            formData.append("file", file);

            this.$store
                .dispatch(TeamStoreNamespacedTypes.actions.UPLOAD_CSV, formData)
                .then(() => {
                    if (!this.error) {
                        this.showImport = true;

                        setTimeout(() => {
                            this.$store.dispatch(
                                TeamStoreNamespacedTypes.actions
                                    .GET_CSV_IMPORT_STATUS
                            );
                        }, 6000);
                    }
                });
        },
    },
    mounted() {
        this.resetState();
    },
};
</script>
