<template>
    <EmployeePage>
        <TrainingTable
            :rows="rows"
            :loading="loading"
            :error="null"
            class="card"
        />
    </EmployeePage>
</template>

<script>
import { TeamStoreNamespacedTypes } from "@/store/modules/team";

import { getProperty } from "@/utils/object";

import EmployeePage from "@/components/layout/EmployeePage";
import TrainingTable from "@/components/display/training/EmployeeTrainingTable";

export default {
    name: "EmployeeTraining",
    components: {
        TrainingTable,
        EmployeePage,
    },
    computed: {
        /**
         * The loading state for the API request.
         * @type {Boolean}
         */
        loading() {
            return this.$store.getters[
                TeamStoreNamespacedTypes.getters.LOADING
            ]();
        },

        /**
         * @type {Object}
         */
        employee() {
            return this.$store.getters[
                TeamStoreNamespacedTypes.getters.EMPLOYEE
            ]();
        },

        /**
         * @type {Array}
         */
        rows() {
            return getProperty(this.employee, "training", []);
        },
    },
    mounted() {
        this.$store.dispatch(
            TeamStoreNamespacedTypes.actions.GET_EMPLOYEE,
            this.$route.params.id
        );
    },
};
</script>
