var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"card flex flex-col space-y-4 p-4"},[_c('div',{staticClass:"flex flex-row justify-between"},[_c('div',{staticClass:"flex flex-col"},[_c('h3',{staticClass:"mb-1 font-medium leading-tight text-slate-500",domProps:{"textContent":_vm._s(_vm.title)}}),_c('p',{staticClass:"text-xs font-medium text-slate-400",domProps:{"textContent":_vm._s(_vm.text)}})]),(_vm.loading)?_c('LoadingSpinner',{staticClass:"size-5"}):_vm._e()],1),_c('div',{staticClass:"flex flex-col space-y-4 rounded-xl border p-4"},[_c('div',{staticClass:"flex items-center justify-between"},[_c('div',{staticClass:"inline-flex w-1/4 items-center"},[_c('span',{staticClass:"me-1.5 inline-block h-2.5 w-2.5 flex-shrink-0 rounded-sm bg-red-500"}),_c('span',{staticClass:"text-xs font-semibold text-slate-500",domProps:{"textContent":_vm._s(
                        _vm.$t('statistics.factor.indicator.bad').toString()
                    )}})]),_c('div',{staticClass:"inline-flex w-1/4 items-center"},[_c('span',{staticClass:"me-1.5 inline-block h-2.5 w-2.5 flex-shrink-0 rounded-sm bg-orange-500"}),_c('span',{staticClass:"text-xs font-semibold text-slate-500",domProps:{"textContent":_vm._s(
                        _vm.$t('statistics.factor.indicator.average').toString()
                    )}})]),_c('div',{staticClass:"inline-flex w-1/4 items-center"},[_c('span',{staticClass:"me-1.5 inline-block h-2.5 w-2.5 flex-shrink-0 rounded-sm bg-yellow-300"}),_c('span',{staticClass:"text-xs font-semibold text-slate-500",domProps:{"textContent":_vm._s(
                        _vm.$t('statistics.factor.indicator.good').toString()
                    )}})]),_c('div',{staticClass:"inline-flex w-1/4 items-center"},[_c('span',{staticClass:"me-1.5 inline-block h-2.5 w-2.5 flex-shrink-0 rounded-sm bg-teal-400"}),_c('span',{staticClass:"text-xs font-semibold text-slate-500",domProps:{"textContent":_vm._s(
                        _vm.$t(
                            'statistics.factor.indicator.excellent'
                        ).toString()
                    )}})])]),_c('div',{staticClass:"relative"},[_c('div',{staticClass:"flex h-2.5 w-full overflow-hidden rounded-full bg-slate-200"},[_c('div',{staticClass:"flex w-full flex-col justify-center overflow-hidden whitespace-nowrap bg-gradient-to-r from-red-500 via-yellow-400 to-teal-400 text-center text-xs text-white",attrs:{"role":"progressbar","aria-valuenow":_vm.value,"aria-valuemin":"0","aria-valuemax":"100"}})]),_c('div',{class:[
                    _vm.value < 25
                        ? 'bg-red-500'
                        : _vm.value < 50
                        ? 'bg-orange-500'
                        : _vm.value < 75
                        ? 'bg-yellow-400'
                        : 'bg-teal-400',
                    'absolute top-1/2 h-5 w-2 -translate-y-1/2 transform rounded-full border-2 border-white transition-all duration-300 ease-in-out',
                ],style:({ left: `calc(${_vm.value}% - 0.5rem)` })})])]),_c('div',{staticClass:"rounded-xl bg-slate-100 p-4"},[_c('h2',{staticClass:"mb-4 text-sm font-semibold text-slate-800"},[_vm._v(" "+_vm._s(_vm.$t("statistics.factor.title"))+" ")]),_c('ul',{staticClass:"space-y-4"},_vm._l((_vm.factors),function(factor,index){return _c('li',{key:_vm.title + index,staticClass:"flex grow items-center justify-between gap-x-2"},[_c('span',{staticClass:"text-sm font-medium text-slate-600",domProps:{"textContent":_vm._s(factor.title)}}),_c('span',{staticClass:"rounded-md px-1.5 py-0.5 text-center text-xs font-medium",class:{
                        'bg-slate-200 text-slate-600': _vm.loading,
                        'bg-red-200 text-red-600':
                            factor.failure && !_vm.loading,
                        'bg-teal-200 text-teal-800':
                            !factor.failure && !_vm.loading,
                    }},[_vm._v(_vm._s(_vm._f("formatNumber")(factor.value)))])])}),0),(_vm.preview)?_c('p',{staticClass:"mt-4 text-center"},[_c('span',{staticClass:"inline-flex cursor-pointer items-center justify-center gap-x-1 rounded-md px-1.5 py-1 text-xs font-medium text-slate-600 hover:bg-white hover:text-slate-800 focus:bg-white",on:{"click":function($event){return _vm.$emit('preview')}}},[_vm._v(" "+_vm._s(_vm.$t("statistics.factor.preview"))+" "),_c('svg',{staticClass:"h-3.5 w-3.5 flex-shrink-0",attrs:{"xmlns":"http://www.w3.org/2000/svg","width":"24","height":"24","viewBox":"0 0 24 24","fill":"none","stroke":"currentColor","stroke-width":"2","stroke-linecap":"round","stroke-linejoin":"round"}},[_c('path',{attrs:{"d":"m9 18 6-6-6-6"}})])])]):_vm._e()])])
}
var staticRenderFns = []

export { render, staticRenderFns }