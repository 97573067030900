<template>
    <th
        scope="col"
        class="px-4 py-3 text-left text-xs font-medium uppercase tracking-wider text-slate-500"
        @click="initSort(header)"
    >
        <span v-if="sortable" class="inline-flex cursor-pointer items-center">
            {{ title }}
            <svg
                class="ml-2 w-1.5"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 8 14"
            >
                <g id="sortable-icon" fill="none" fill-rule="evenodd">
                    <path
                        class="fill-current"
                        :class="descClass"
                        d="M1.70710678 4.70710678c-.39052429.39052429-1.02368927.39052429-1.41421356 0-.3905243-.39052429-.3905243-1.02368927 0-1.41421356l3-3c.39052429-.3905243 1.02368927-.3905243 1.41421356 0l3 3c.39052429.39052429.39052429 1.02368927 0 1.41421356-.39052429.39052429-1.02368927.39052429-1.41421356 0L4 2.41421356 1.70710678 4.70710678z"
                    />
                    <path
                        class="fill-current"
                        :class="ascClass"
                        fill-rule="nonzero"
                        d="M6.29289322 9.29289322c.39052429-.39052429 1.02368927-.39052429 1.41421356 0 .39052429.39052429.39052429 1.02368928 0 1.41421358l-3 3c-.39052429.3905243-1.02368927.3905243-1.41421356 0l-3-3c-.3905243-.3905243-.3905243-1.02368929 0-1.41421358.3905243-.39052429 1.02368927-.39052429 1.41421356 0L4 11.5857864l2.29289322-2.29289318z"
                    />
                </g>
            </svg>
        </span>
        <span v-else>
            {{ title }}
        </span>
    </th>
</template>

<script>
import { getProperty } from "@/utils/object";

export default {
    props: {
        header: {
            type: Object,
        },
        active: {
            type: Boolean,
            default: false,
        },
    },
    data: () => ({
        direction: "desc",
    }),
    computed: {
        title() {
            return this.$t(
                "tables." + getProperty(this.header, "title", "default")
            );
        },
        sortable() {
            return getProperty(this.header, "sortable", false);
        },
        ascClass() {
            if (
                this.sortable &&
                this.direction === "desc" &&
                this.active === true
            ) {
                return "text-indigo-700";
            }
            return "text-slate-400";
        },
        descClass() {
            if (
                this.sortable &&
                this.direction === "asc" &&
                this.active === true
            ) {
                return "text-indigo-700";
            }
            return "text-slate-400";
        },
    },
    methods: {
        initSort(payload) {
            if (!this.sortable) {
                return;
            }

            this.direction = this.direction === "desc" ? "asc" : "desc";

            this.$emit("sort", {
                key: getProperty(payload, "key"),
                direction: this.direction,
            });
        },
    },
};
</script>
