<template>
    <div>
        <GroupLoadingSkeleton :count="rows" v-if="loading" />
        <div v-else class="flex flex-col p-4">
            <div
                v-if="hasGroupData"
                class="flex h-4 w-full space-x-2 overflow-hidden"
            >
                <GroupChartItem
                    v-for="group in groups"
                    :key="group.label + '-badge'"
                    :group="group"
                />
            </div>
            <div v-else class="flex h-4 w-full rounded bg-slate-100"></div>
            <div
                class="-mx-4 mt-4 flex flex-row items-stretch space-x-1 divide-x divide-slate-200"
            >
                <div
                    v-for="group in groups"
                    :key="group.label"
                    class="flex flex-1 flex-col items-center justify-center px-2"
                >
                    <p class="mb-2 text-sm">
                        <span
                            class="mr-2 inline-block h-2 w-2 rounded-full"
                            :class="`bg-${group.color}`"
                        ></span>
                        <span
                            class="align-middle capitalize"
                            v-text="
                                $t(
                                    'statistics.' +
                                        identifier +
                                        '.' +
                                        group.label
                                )
                            "
                        ></span>
                    </p>
                    <p class="text-lg font-medium">
                        {{ group.pct || 0 | formatPercentRound }}%
                    </p>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import GroupLoadingSkeleton from "@/components/charts/GroupChart/partials/GroupLoadingSkeleton";

import { formatPercentRound } from "@/utils/number";
import { getProperty } from "@/utils/object";
import GroupChartItem from "@/components/charts/GroupChart/partials/GroupChartItem";

export default {
    name: "GroupChart",
    components: { GroupChartItem, GroupLoadingSkeleton },
    filters: { formatPercentRound },
    props: {
        loading: {
            type: Boolean,
            required: true,
        },
        rows: {
            type: Number,
            default: 2,
        },
        data: {
            type: Object,
            default: () => ({}),
        },
        identifier: {
            type: String,
            required: true,
        },
    },
    computed: {
        groups() {
            return getProperty(this.data, "groups", []);
        },
        hasGroupData() {
            return this.groups.reduce((acc, group) => {
                return acc || group.pct > 0;
            }, false);
        },
    },
};
</script>
