<template>
    <div class="flex items-stretch overflow-hidden rounded-xl bg-white">
        <div
            class="flex h-24 w-36 flex-shrink-0 items-center justify-center rounded-xl bg-slate-200"
        >
            <img
                src="/img/svg/ai-lesson.svg"
                alt="AI Lesson"
                class="h-auto w-32"
            />
        </div>
        <div class="px-4">
            <h4 class="text-sm font-bold text-slate-900">
                {{
                    $t(
                        "automation.flow.title.preview.assign_lesson_action.title"
                    )
                }}
            </h4>
            <p class="mt-1 text-sm text-slate-500">
                {{
                    $t(
                        "automation.flow.title.preview.assign_lesson_action.text"
                    )
                }}
            </p>
        </div>
    </div>
</template>

<script>
import AutomationActionMixin from "@/mixins/AutomationActionMixin";

export default {
    name: "AssignLessonAction",
    mixins: [AutomationActionMixin],
};
</script>
