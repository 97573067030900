var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"mb-4 overflow-hidden rounded-xl border border-slate-200"},[(_vm.hasProvider)?_c('div',{staticClass:"flex items-center gap-x-4 border-b border-slate-900/5 bg-slate-50 px-4 py-3"},[(_vm.image)?_c('img',{staticClass:"size-10 flex-none rounded-lg bg-white object-cover ring-1 ring-slate-900/10",attrs:{"src":_vm.image,"alt":_vm.title}}):_vm._e(),_c('div',{staticClass:"text-sm font-medium leading-6 text-slate-900"},[_vm._v(" "+_vm._s(_vm.title)+" ")])]):_vm._e(),_c('dl',{staticClass:"-my-3 divide-y divide-slate-100 px-6 py-4 text-sm leading-6"},[(_vm.hasProvider)?_c('div',{staticClass:"flex justify-between gap-x-4 py-3"},[_c('dt',{staticClass:"text-slate-500"},[_vm._v(" "+_vm._s(_vm.$t("threats.analysis.sender.type"))+" ")]),_c('dd',[_c('Badge',{attrs:{"variant":_vm.isPublic
                            ? _vm.BadgeVariants.ERROR
                            : _vm.BadgeVariants.SUCCESS,"text":_vm.isPublic
                            ? _vm.$t(
                                  'threats.analysis.sender.type_text.free'
                              ).toString()
                            : _vm.$t(
                                  'threats.analysis.sender.type_text.paid'
                              ).toString()}})],1)]):_vm._e(),(_vm.sender_email)?_c('div',{staticClass:"flex justify-between gap-x-4 py-3"},[_c('dt',{staticClass:"text-slate-500"},[_vm._v(" "+_vm._s(_vm.$t("threats.analysis.sender.email"))+" ")]),_c('dd',{staticClass:"text-slate-700",domProps:{"textContent":_vm._s(_vm.sender_email)}})]):_vm._e(),(_vm.sender_domain)?_c('div',{staticClass:"flex justify-between gap-x-4 py-3"},[_c('dt',{staticClass:"text-slate-500"},[_vm._v(" "+_vm._s(_vm.$t("threats.analysis.sender.domain"))+" ")]),_c('dd',{staticClass:"text-slate-700",domProps:{"textContent":_vm._s(_vm.sender_domain)}})]):_vm._e(),(_vm.sender_ip)?_c('div',{staticClass:"flex justify-between gap-x-4 py-3"},[_c('dt',{staticClass:"text-slate-500"},[_vm._v(" "+_vm._s(_vm.$t("threats.analysis.sender.ip"))+" ")]),_c('dd',{staticClass:"text-slate-700",domProps:{"textContent":_vm._s(_vm.sender_ip)}})]):_vm._e()])])
}
var staticRenderFns = []

export { render, staticRenderFns }