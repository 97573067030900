<template>
    <div class="flex flex-col p-4">
        <div class="mb-4 flex w-full flex-row justify-between">
            <h3 class="flex-1 font-medium text-slate-500" v-text="title"></h3>
            <div>
                <LoadingSpinner v-if="loading" class="size-5" />
                <div v-else class="flex flex-row space-x-2">
                    <div
                        v-if="hasLink || hasEmitter"
                        @click="navigateToResource"
                        class="flex h-6 cursor-pointer items-center justify-center gap-x-1 rounded-full bg-slate-50/50 px-2 text-xs font-medium text-slate-700 ring-1 ring-inset ring-slate-500/20 transition hover:bg-slate-100"
                    >
                        {{ $t("statistics.show_more") }}
                        <Icon :icon="IconEnums.EXPAND" class="h-3.5 w-3.5" />
                    </div>
                    <div
                        v-if="hasThreshold"
                        class="flex h-6 w-6 items-center justify-center rounded-full ring-1 ring-inset"
                        :class="badgeClass"
                    >
                        <Icon :icon="badgeIcon" class="h-3 w-3" />
                    </div>
                </div>
            </div>
        </div>
        <div class="flex flex-grow flex-col justify-between">
            <div>
                <div class="flex items-center">
                    <span
                        class="text-4xl font-bold leading-7 tracking-tighter text-slate-800"
                        v-text="formattedValue"
                    ></span>
                    <span
                        v-if="unit && !pct"
                        class="ml-2.5 text-xs font-medium text-slate-500"
                        v-text="unit"
                    >
                    </span>
                    <span v-else-if="pct" class="ml-2 font-bold text-slate-500"
                        >%</span
                    >
                </div>
                <p
                    v-if="pct && unit"
                    class="mt-2 block text-xs font-medium text-slate-500"
                    v-text="unit"
                ></p>
            </div>
            <div
                v-if="text"
                class="flex items-center space-x-2 pt-4 text-slate-400"
            >
                <div class="h-6 w-6">
                    <Icon :icon="IconEnums.INFO" class="h-6 w-6" />
                </div>
                <span class="text-xs leading-4" v-text="text"></span>
            </div>
        </div>
    </div>
</template>

<script>
import StatisticsCardMixin from "@/mixins/statistics/StatisticsCardMixin";
import ActionableDataMixin from "@/mixins/statistics/ActionableDataMixin";
import ThresholdDataMixin from "@/mixins/statistics/ThresholdDataMixin";
import { formatNumber, formatPercentRound } from "@/utils/number";

export default {
    name: "ValueCard",
    mixins: [StatisticsCardMixin, ActionableDataMixin, ThresholdDataMixin],
    props: {
        value: {
            type: Number,
            required: true,
        },
        unit: {
            type: String,
            required: false,
        },
        pct: {
            type: Boolean,
            required: false,
            default: false,
        },
    },
    computed: {
        formattedValue() {
            if (this.pct) {
                return formatPercentRound(this.value);
            } else {
                return formatNumber(this.value);
            }
        },
    },
};
</script>
