<template>
    <div class="p-4">
        <div class="flex w-full">
            <h3
                class="mb-4 flex-1 font-medium leading-tight text-slate-500"
                v-text="$t('employees.recent_activity.heading').toString()"
            ></h3>
        </div>
        <div class="flow-root">
            <LoadingSpinner class="py-12" v-if="loading" />
            <div
                v-else-if="data.length === 0"
                class="flex flex-col justify-center px-4 py-8 text-center"
            >
                <svg
                    class="w-full"
                    width="178"
                    height="138"
                    viewBox="0 0 178 138"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <defs>
                        <filter
                            id="filter0_d_499_322852"
                            x="0"
                            y="0"
                            width="178"
                            height="64"
                            filterUnits="userSpaceOnUse"
                            color-interpolation-filters="sRGB"
                        >
                            <feFlood
                                flood-opacity="0"
                                result="BackgroundImageFix"
                            />
                            <feColorMatrix
                                in="SourceAlpha"
                                type="matrix"
                                values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                                result="hardAlpha"
                            />
                            <feOffset dy="6" />
                            <feGaussianBlur stdDeviation="6" />
                            <feComposite in2="hardAlpha" operator="out" />
                            <feColorMatrix
                                type="matrix"
                                values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.03 0"
                            />
                            <feBlend
                                mode="normal"
                                in2="BackgroundImageFix"
                                result="effect1_dropShadow_499_322852"
                            />
                            <feBlend
                                mode="normal"
                                in="SourceGraphic"
                                in2="effect1_dropShadow_499_322852"
                                result="shape"
                            />
                        </filter>
                    </defs>
                    <g filter="url(#filter0_d_499_322852)">
                        <rect
                            x="12"
                            y="6"
                            width="154"
                            height="40"
                            rx="8"
                            class="fill-white"
                            fill="currentColor"
                            shape-rendering="crispEdges"
                        />
                        <rect
                            x="20"
                            y="14"
                            width="24"
                            height="24"
                            rx="4"
                            class="fill-slate-200"
                            fill="currentColor"
                        />
                        <rect
                            x="52"
                            y="17"
                            width="60"
                            height="6"
                            rx="3"
                            class="fill-slate-200"
                            fill="currentColor"
                        />
                        <rect
                            x="52"
                            y="29"
                            width="106"
                            height="6"
                            rx="3"
                            class="fill-slate-200"
                            fill="currentColor"
                        />
                        <rect
                            x="12.5"
                            y="6.5"
                            width="153"
                            height="39"
                            rx="7.5"
                            class="stroke-slate-200"
                            stroke="currentColor"
                            shape-rendering="crispEdges"
                        />
                    </g>
                    <rect
                        x="12.5"
                        y="52.5"
                        width="153"
                        height="39"
                        rx="7.5"
                        class="fill-white"
                        fill="currentColor"
                    />
                    <rect
                        x="20"
                        y="60"
                        width="24"
                        height="24"
                        rx="4"
                        class="fill-slate-100"
                        fill="currentColor"
                    />
                    <rect
                        x="52"
                        y="63"
                        width="60"
                        height="6"
                        rx="3"
                        class="fill-slate-200"
                        fill="currentColor"
                    />
                    <rect
                        x="52"
                        y="75"
                        width="106"
                        height="6"
                        rx="3"
                        class="fill-slate-200"
                        fill="currentColor"
                    />
                    <rect
                        x="12.5"
                        y="52.5"
                        width="153"
                        height="39"
                        rx="7.5"
                        class="stroke-slate-200"
                        stroke="currentColor"
                    />
                    <rect
                        x="12.5"
                        y="98.5"
                        width="153"
                        height="39"
                        rx="7.5"
                        class="fill-white"
                        fill="currentColor"
                    />
                    <rect
                        x="20"
                        y="106"
                        width="24"
                        height="24"
                        rx="4"
                        class="fill-slate-50"
                    />
                    <rect
                        x="52"
                        y="109"
                        width="60"
                        height="6"
                        rx="3"
                        class="fill-slate-100"
                    />
                    <rect
                        x="52"
                        y="121"
                        width="106"
                        height="6"
                        rx="3"
                        class="fill-slate-100"
                    />
                    <rect
                        x="12.5"
                        y="98.5"
                        width="153"
                        height="39"
                        rx="7.5"
                        class="stroke-slate-100"
                        stroke="currentColor"
                    />
                </svg>
                <p
                    class="mt-6 font-semibold text-slate-800"
                    v-text="
                        $t('employees.recent_activity.empty.title').toString()
                    "
                ></p>
                <p
                    class="mt-1 text-sm text-slate-500"
                    v-text="
                        $t('employees.recent_activity.empty.text').toString()
                    "
                ></p>
            </div>
            <ul v-else role="list" class="space-y-4">
                <RecentActivityItem
                    v-for="activity in data"
                    :key="activity.id"
                    :data="activity"
                />
            </ul>
        </div>
    </div>
</template>

<script>
import { TeamStoreNamespacedTypes } from "@/store/modules/team";

import LoadingSpinner from "@/components/ui/LoadingSpinner";
import RecentActivityItem from "@/components/display/employee/RecentActivityItem";

export default {
    name: "RecentActivity",
    components: { RecentActivityItem, LoadingSpinner },
    props: {
        data: {
            type: Array,
            required: true,
        },
    },
    computed: {
        /**
         * The loading state for the API request.
         * @type {Boolean}
         */
        loading() {
            return this.$store.getters[
                TeamStoreNamespacedTypes.getters.LOADING
            ]();
        },
    },
};
</script>
