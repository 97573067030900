<template>
    <WorkspaceSettingsPage>
        <Prompt :active="showPrompt" @close="showPrompt = false"
            ><h3 slot="header">{{ $t("settings.domains.verify_domain") }}</h3>
            <div slot="content">
                <CreateDomainForm @saved="handleDomainSaved" /></div
        ></Prompt>
        <div class="card">
            <CardHeading
                :title="$t('settings.domains.heading').toString()"
                :text="$t('settings.domains.text').toString()"
                :loading="loading"
            >
                <FormButton
                    @click="showPrompt = true"
                    :variant="ButtonVariants.SECONDARY"
                    >{{ $t("settings.domains.add_domain") }}</FormButton
                >
            </CardHeading>
            <DomainTable :rows="domains" :loading="loading" :error="null" />
        </div>
    </WorkspaceSettingsPage>
</template>

<script>
import { WorkspaceStoreNamespacedTypes } from "@/store/modules/workspace";

import { getProperty } from "@/utils/object";

import Bus from "@/bus";
import DomainTable from "@/components/display/settings/DomainTable";
import FormButton, {
    ButtonVariants,
} from "@/components/forms/fields/FormButton";
import CreateDomainForm from "@/components/forms/CreateDomainForm";
import Prompt from "@/components/ui/Prompt";
import WorkspaceSettingsPage from "@/components/layout/WorkspaceSettingsPage";
import CardHeading from "@/components/ui/CardHeading";

export default {
    name: "WorkspaceSettings",
    components: {
        CardHeading,
        WorkspaceSettingsPage,
        Prompt,
        CreateDomainForm,
        DomainTable,
        FormButton,
    },
    data: () => ({
        ButtonVariants,
        showPrompt: false,
    }),
    computed: {
        /**
         * @type {Boolean}
         */
        loading() {
            return this.$store.getters[
                WorkspaceStoreNamespacedTypes.getters.WORKSPACE_LOADING
            ]();
        },

        /**
         * @type {Object}
         */
        workspace() {
            return this.$store.getters[
                WorkspaceStoreNamespacedTypes.getters.WORKSPACE
            ]();
        },

        /**
         * @type {Array}
         */
        domains() {
            return getProperty(this.workspace, "domains", []);
        },
    },
    methods: {
        handleDomainSaved() {
            this.$store
                .dispatch(WorkspaceStoreNamespacedTypes.actions.GET_WORKSPACE, {
                    force: true,
                })
                .then(() => {
                    Bus.$emit("flash-message", {
                        text: this.$t("settings.domains.domain_created"),
                        type: "success",
                    });
                    this.showPrompt = false;
                });
        },
    },
};
</script>
