var render = function render(){var _vm=this,_c=_vm._self._c;return _c('DataTable',{attrs:{"headers":_vm.headers,"rows":_vm.rows,"meta":_vm.meta,"loading":_vm.loading,"loadingRows":2,"error":_vm.error},on:{"update":_vm.refresh},scopedSlots:_vm._u([{key:"first_name",fn:function({ item }){return [_c('div',{staticClass:"flex items-center"},[_c('div',{staticClass:"h-10 w-10 flex-shrink-0"},[_c('span',{staticClass:"inline-flex h-10 w-10 items-center justify-center rounded-full bg-indigo-500"},[_c('span',{staticClass:"text-sm font-medium leading-none text-white"},[_vm._v(_vm._s(_vm._f("initials")(item.first_name)))])])]),_c('div',{staticClass:"ml-4"},[_c('div',{staticClass:"text-sm font-medium text-slate-900"},[_vm._v(" "+_vm._s(item.first_name)+" "+_vm._s(item.last_name)+" ")]),_c('div',{staticClass:"text-sm text-slate-500"},[_vm._v(" "+_vm._s(item.email || "No email found")+" ")])])])]}},{key:"role",fn:function({ item }){return [_c('Badge',{attrs:{"text":_vm.$t(
                    `settings.workspace.users.role.${item.role}.title`
                ).toString()}})]}},{key:"verified_at",fn:function({ item }){return [_c('Badge',{attrs:{"variant":item.verified_at
                    ? _vm.BadgeVariants.SUCCESS
                    : _vm.BadgeVariants.WARNING,"text":item.verified_at
                    ? _vm.$t(`settings.verified`).toString()
                    : _vm.$t(`settings.verification_required`).toString()}})]}},{key:"created_at",fn:function({ item }){return [_vm._v(" "+_vm._s(_vm._f("formatDate")(item.created_at))+" ")]}},{key:"action",fn:function({ item }){return [_c('div',{staticClass:"flex justify-end"},[_c('FormButton',{attrs:{"variant":_vm.ButtonVariants.SECONDARY,"icon":_vm.IconEnums.ERROR,"loading":_vm.formLoading},nativeOn:{"click":function($event){return _vm.revokeAccess(item.id)}}},[_vm._v(_vm._s(_vm.$t("settings.workspace.users.remove_user")))])],1)]}}])})
}
var staticRenderFns = []

export { render, staticRenderFns }