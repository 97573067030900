<template>
    <div class="flex w-full">
        <div
            class="h-100 flex flex-1 flex-col overflow-hidden rounded-xl border bg-slate-50"
        >
            <div class="flex justify-end border-b px-3 py-3">
                <div
                    class="flex h-6 cursor-pointer items-center justify-center gap-x-1 rounded-full bg-slate-50/50 px-2 text-xs font-medium text-slate-700 ring-1 ring-inset ring-slate-500/20 transition hover:bg-slate-100"
                    @click="previewExpanded = !previewExpanded"
                >
                    {{
                        previewExpanded
                            ? $t("simulations.expand_preview.close")
                            : $t("simulations.expand_preview.open")
                    }}
                    <Icon
                        :icon="
                            previewExpanded ? IconEnums.CLOSE : IconEnums.EXPAND
                        "
                        class="h-3.5 w-3.5"
                    />
                </div>
            </div>
            <div class="flex flex-col bg-white p-3">
                <div class="flex flex-col">
                    <div
                        class="mb-1.5 flex flex-row items-center justify-between space-x-2"
                    >
                        <span class="font-bold">{{ name }}</span>
                        <span class="text-sm text-slate-400">{{
                            date | formatDateTime
                        }}</span>
                    </div>
                    <span class="font-bold text-slate-600">{{ subject }}</span>
                </div>
            </div>
            <div class="relative h-full">
                <div class="absolute left-0 top-0 z-30 h-full w-full"></div>
                <div
                    class="absolute bottom-0 left-0 z-40 h-20 w-full bg-gradient-to-t from-white"
                ></div>
                <div
                    v-html="html"
                    class="pointer-events-none w-full"
                    :class="[{ 'prose prose-sm p-4': !isHtml }, heightClass]"
                ></div>
                <div
                    v-if="has_open || has_click"
                    class="absolute bottom-0 right-0 z-40 m-4 flex flex-row items-center justify-between rounded-md border bg-white p-2"
                >
                    <Icon
                        :icon="IconEnums.EYE"
                        class="h-5 w-5 text-slate-600"
                    />
                    <div v-if="has_click" class="ml-2 border-l pl-2">
                        <Icon
                            :icon="IconEnums.CLICK"
                            class="h-5 w-5 text-error-dark"
                        />
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { getProperty } from "@/utils/object";
import { formatDateTime } from "@/utils/date";
import { IconEnums } from "@/utils/icons";
import Icon from "@/components/icons/BaseIcon";

export default {
    name: "EmailPreview",
    components: {
        Icon,
    },
    props: {
        email: {
            type: Object,
            required: true,
        },
        height: {
            type: String,
            default: "h-48",
        },
    },
    filters: {
        formatDateTime,
    },
    data: () => ({
        IconEnums,
        previewExpanded: false,
    }),
    computed: {
        heightClass() {
            return this.previewExpanded ? "h-full" : this.height;
        },
        name() {
            return getProperty(this.email, "from_name", "Name");
        },
        date() {
            return getProperty(this.email, "sent_at");
        },
        subject() {
            return getProperty(this.email, "subject", "Subject");
        },
        template() {
            return getProperty(this.email, "template", {
                html: null,
                format: "html",
            });
        },
        html() {
            return getProperty(this.template, "html");
        },
        isHtml() {
            return this.format === "html";
        },
        format() {
            return getProperty(this.template, "format", "html");
        },
        has_open() {
            return getProperty(this.email, "has_open", false);
        },
        has_click() {
            return getProperty(this.email, "has_click", false);
        },
    },
};
</script>
