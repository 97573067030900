<template>
    <FormButton @click="toggleState" :variant="variant">
        <Icon class="mr-2 h-3 w-3 text-inherit" :icon="icon" v-if="!loading" />
        {{ text }}</FormButton
    >
</template>

<script>
import { AutomationStoreNamespacedTypes } from "@/store/modules/automation";

import Bus from "@/bus";
import Icon from "@/components/icons/BaseIcon";
import { IconEnums } from "@/utils/icons";
import FormButton, {
    ButtonVariants,
} from "@/components/forms/fields/FormButton";
import { getProperty } from "@/utils/object";

export default {
    components: { Icon, FormButton },
    data: () => ({
        ButtonVariants,
        IconEnums,
        loading: false,
    }),
    computed: {
        /**
         * @type {Object}
         */
        error() {
            return this.$store.getters[
                AutomationStoreNamespacedTypes.getters.AUTOMATION_ERROR
            ]();
        },

        /**
         * @type {Array}
         */
        automation() {
            return this.$store.getters[
                AutomationStoreNamespacedTypes.getters.AUTOMATION
            ]();
        },

        /**
         * @type {string}
         */
        id() {
            return getProperty(this.automation, "id", null);
        },

        /**
         * @type {string}
         */
        status() {
            return getProperty(this.automation, "status", "started");
        },

        /**
         * @type {Boolean}
         */
        isRunning() {
            return this.status === "started";
        },

        /**
         * @type {string}
         */
        variant() {
            return this.isRunning
                ? ButtonVariants.WARNING
                : ButtonVariants.PRIMARY;
        },

        /**
         * @type {Object}
         */
        icon() {
            return this.isRunning ? IconEnums.PAUSE : IconEnums.PLAY;
        },

        /**
         * @type {string}
         */
        text() {
            return this.isRunning
                ? this.$t("automation.pause")
                : this.$t("automation.play");
        },
    },
    methods: {
        toggleState() {
            this.loading = true;
            this.$store
                .dispatch(
                    AutomationStoreNamespacedTypes.actions
                        .TOGGLE_AUTOMATION_STATE,
                    this.id
                )
                .then(() => {
                    this.loading = false;
                    Bus.$emit("flash-message", {
                        text: this.$t("automation.automation_updated"),
                        type: "success",
                    });
                });
        },
    },
};
</script>
