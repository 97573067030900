<template>
    <div
        class="h-full rounded"
        :class="`bg-${color}`"
        :style="{
            width: width,
            display: pct > 0 ? 'inline' : 'none',
        }"
    ></div>
</template>

<script>
import { getProperty } from "@/utils/object";
import { formatPercentRound } from "@/utils/number";

export default {
    name: "GroupChartItem",
    props: {
        group: {
            type: Object,
            required: true,
        },
    },
    computed: {
        color() {
            return getProperty(this.group, "color", "bg-primary-normal");
        },
        pct() {
            return getProperty(this.group, "pct", 0);
        },
        width() {
            return formatPercentRound(this.pct) + "%";
        },
    },
};
</script>
