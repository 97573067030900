<template>
    <path
        fill="none"
        stroke="currentColor"
        stroke-linecap="round"
        stroke-linejoin="round"
        stroke-width="2"
        d="m6 16 2.982-8.45c.927-2.625 1.39-3.937 2.072-4.303a2 2 0 0 1 1.892 0c.682.366 1.145 1.678 2.072 4.303L18 16M8 11h8m1 9H7"
    />
</template>

<script>
import IconSvgBase from "@/components/icons/IconSvgBase";

export default {
    extends: IconSvgBase,
    name: "IconParagraph",
};
</script>
