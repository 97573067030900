<template>
    <div class="card p-4">
        <div class="flex flex-row justify-between">
            <div class="flex flex-col">
                <h3
                    class="mb-1 font-medium leading-tight text-slate-500"
                    v-text="
                        $t('statistics.activities_by_type.title').toString()
                    "
                ></h3>
                <p
                    class="text-xs font-medium text-slate-400"
                    v-text="$t('statistics.activities_by_type.text').toString()"
                ></p>
            </div>
            <LoadingSpinner v-if="loading" class="size-5" />
        </div>
        <div class="mt-4 space-y-4">
            <div
                v-if="loading"
                class="flex h-2.5 w-full animate-pulse overflow-hidden rounded-full bg-slate-200"
            ></div>
            <div
                v-else
                class="flex h-2.5 w-full space-x-1 overflow-hidden rounded-full"
            >
                <div
                    v-if="simulationsDeliveredWidth > 0"
                    class="flex flex-col justify-center overflow-hidden whitespace-nowrap bg-blue-500 text-center text-xs text-white"
                    :style="{
                        width: simulationsDeliveredWidth + '%',
                    }"
                    role="progressbar"
                    :aria-valuenow="simulationsDeliveredWidth"
                    aria-valuemin="0"
                    aria-valuemax="100"
                ></div>
                <div
                    v-if="videosWatchedWidth > 0"
                    class="flex flex-col justify-center overflow-hidden whitespace-nowrap bg-violet-500 text-center text-xs text-white"
                    :style="{
                        width: videosWatchedWidth + '%',
                    }"
                    role="progressbar"
                    :aria-valuenow="videosWatchedWidth"
                    aria-valuemin="0"
                    aria-valuemax="100"
                ></div>
                <div
                    v-if="coursesCompletedWidth > 0"
                    class="flex flex-col justify-center overflow-hidden whitespace-nowrap bg-teal-400 text-center text-xs text-white"
                    :style="{
                        width: coursesCompletedWidth + '%',
                    }"
                    role="progressbar"
                    :aria-valuenow="coursesCompletedWidth"
                    aria-valuemin="0"
                    aria-valuemax="100"
                ></div>
                <div
                    v-if="threatsReportedWidth > 0"
                    class="flex flex-col justify-center overflow-hidden whitespace-nowrap bg-blue-400 text-center text-xs text-white"
                    :style="{
                        width: threatsReportedWidth + '%',
                    }"
                    role="progressbar"
                    :aria-valuenow="threatsReportedWidth"
                    aria-valuemin="0"
                    aria-valuemax="100"
                ></div>
                <div
                    v-if="quizAnswersWidth > 0"
                    class="flex flex-col justify-center overflow-hidden whitespace-nowrap bg-yellow-300 text-center text-xs text-white"
                    :style="{
                        width: quizAnswersWidth + '%',
                    }"
                    role="progressbar"
                    :aria-valuenow="quizAnswersWidth"
                    aria-valuemin="0"
                    aria-valuemax="100"
                ></div>
            </div>
            <ul class="space-y-4">
                <li
                    class="grid grid-cols-2 items-center justify-between gap-x-4"
                >
                    <div class="flex items-center">
                        <span
                            class="me-2.5 inline-block h-2.5 w-2.5 flex-shrink-0 rounded-sm bg-blue-600"
                        ></span>
                        <span
                            class="text-sm font-medium text-slate-600"
                            v-text="
                                $t(
                                    'statistics.activities_by_type.simulations_delivered'
                                ).toString()
                            "
                        >
                        </span>
                    </div>
                    <div class="text-end">
                        <span
                            class="rounded-md bg-slate-200 px-1.5 py-0.5 text-center text-xs font-medium text-slate-600"
                            v-text="simulationsDeliveredFormatted"
                        >
                        </span>
                    </div>
                </li>
                <li
                    class="grid grid-cols-2 items-center justify-between gap-x-4"
                >
                    <div class="flex items-center">
                        <span
                            class="me-2.5 inline-block h-2.5 w-2.5 flex-shrink-0 rounded-sm bg-violet-500"
                        ></span>
                        <span
                            class="text-sm font-medium text-slate-600"
                            v-text="
                                $t(
                                    'statistics.activities_by_type.videos_watched'
                                ).toString()
                            "
                        >
                        </span>
                    </div>
                    <div class="text-end">
                        <span
                            class="rounded-md bg-slate-200 px-1.5 py-0.5 text-center text-xs font-medium text-slate-600"
                            v-text="videosWatchedFormatted"
                        >
                        </span>
                    </div>
                </li>
                <li
                    class="grid grid-cols-2 items-center justify-between gap-x-4"
                >
                    <div class="flex items-center">
                        <span
                            class="me-2.5 inline-block h-2.5 w-2.5 flex-shrink-0 rounded-sm bg-teal-400"
                        ></span>
                        <span
                            class="text-sm font-medium text-slate-600"
                            v-text="
                                $t(
                                    'statistics.activities_by_type.completed_courses'
                                ).toString()
                            "
                        >
                        </span>
                    </div>
                    <div class="text-end">
                        <span
                            class="rounded-md bg-slate-200 px-1.5 py-0.5 text-center text-xs font-medium text-slate-600"
                            v-text="coursesCompletedFormatted"
                        >
                        </span>
                    </div>
                </li>
                <li
                    class="grid grid-cols-2 items-center justify-between gap-x-4"
                >
                    <div class="flex items-center">
                        <span
                            class="me-2.5 inline-block h-2.5 w-2.5 flex-shrink-0 rounded-sm bg-blue-400"
                        ></span>
                        <span
                            class="text-sm font-medium text-slate-600"
                            v-text="
                                $t(
                                    'statistics.activities_by_type.threats_reported'
                                ).toString()
                            "
                        >
                        </span>
                    </div>
                    <div class="text-end">
                        <span
                            class="rounded-md bg-slate-200 px-1.5 py-0.5 text-center text-xs font-medium text-slate-600"
                            v-text="threatsReportedFormatted"
                        >
                        </span>
                    </div>
                </li>
                <li
                    class="grid grid-cols-2 items-center justify-between gap-x-4"
                >
                    <div class="flex items-center">
                        <span
                            class="me-2 inline-block h-2.5 w-2.5 flex-shrink-0 rounded-sm bg-yellow-300"
                        ></span>
                        <span
                            class="text-sm font-medium text-slate-600"
                            v-text="
                                $t(
                                    'statistics.activities_by_type.quiz_answers'
                                ).toString()
                            "
                        >
                        </span>
                    </div>
                    <div class="text-end">
                        <span
                            class="rounded-md bg-slate-200 px-1.5 py-0.5 text-center text-xs font-medium text-slate-600"
                            v-text="quizAnswersFormatted"
                        >
                        </span>
                    </div>
                </li>
            </ul>
        </div>
    </div>
</template>

<script>
import { formatNumber } from "@/utils/number";
import LoadingSpinner from "@/components/ui/LoadingSpinner";

export default {
    name: "ActivityByTypeCard",
    components: { LoadingSpinner },
    props: {
        loading: {
            type: Boolean,
            default: false,
        },
        simulationsDelivered: {
            type: Number,
            default: 0,
        },
        videosWatched: {
            type: Number,
            default: 0,
        },
        coursesCompleted: {
            type: Number,
            default: 0,
        },
        threatsReported: {
            type: Number,
            default: 0,
        },
        quizAnswers: {
            type: Number,
            default: 0,
        },
    },
    computed: {
        simulationsDeliveredFormatted() {
            return formatNumber(this.simulationsDelivered);
        },
        videosWatchedFormatted() {
            return formatNumber(this.videosWatched);
        },
        coursesCompletedFormatted() {
            return formatNumber(this.coursesCompleted);
        },
        threatsReportedFormatted() {
            return formatNumber(this.threatsReported);
        },
        quizAnswersFormatted() {
            return formatNumber(this.quizAnswers);
        },
        total() {
            return (
                this.simulationsDelivered +
                this.videosWatched +
                this.coursesCompleted +
                this.threatsReported +
                this.quizAnswers
            );
        },
        simulationsDeliveredWidth() {
            return formatNumber((this.simulationsDelivered / this.total) * 100);
        },
        videosWatchedWidth() {
            return formatNumber((this.videosWatched / this.total) * 100);
        },
        coursesCompletedWidth() {
            return formatNumber((this.coursesCompleted / this.total) * 100);
        },
        threatsReportedWidth() {
            return formatNumber((this.threatsReported / this.total) * 100);
        },
        quizAnswersWidth() {
            return formatNumber((this.quizAnswers / this.total) * 100);
        },
    },
};
</script>
