<template>
    <div class="badge flex">
        <div
            class="flex items-center gap-1 rounded-full bg-slate-50/50 px-2 py-1.5 text-xs text-slate-600 ring-1 ring-inset ring-slate-500/20"
        >
            <Icon :icon="IconEnums.LOCK" class="pointer-events-none size-3" />
            <span class="text-xs font-medium leading-none">{{
                $t("general.locked_feature")
            }}</span>
        </div>
    </div>
</template>

<script>
import Icon from "@/components/icons/BaseIcon";
import { IconEnums } from "@/utils/icons";

export default {
    name: "LockedFeatureOverlay",
    components: { Icon },
    data: () => ({
        IconEnums,
    }),
};
</script>
