<template>
    <ValidationObserver ref="AssignVideoActionForm" v-slot="{ passes }">
        <form @submit.prevent="passes(saveAction)">
            <FormFieldSet>
                <FormSelect
                    v-model="form.content_id"
                    rules="required"
                    name="video"
                >
                    <option
                        v-for="video in videos"
                        :key="video.value"
                        :value="video.id"
                    >
                        {{ video.title }}
                    </option>
                </FormSelect>
            </FormFieldSet>
            <div
                class="mb-4 flex flex-col rounded-xl border bg-slate-50 px-4 pt-4"
            >
                <p
                    class="pointer-events-none mb-2 block text-sm font-medium text-slate-600"
                >
                    {{ $t("automation.flow.actions.completion_deadline") }}
                </p>
                <fieldset
                    class="mb-4 flex flex-col space-y-4 md:flex-row md:space-x-4 md:space-y-0"
                >
                    <FormInput
                        class="md:w-1/2"
                        v-model="form.length"
                        type="number"
                        rules="required"
                        name="length"
                    />
                    <FormSelect
                        class="md:w-1/2"
                        v-model="form.unit"
                        rules="required"
                        name="unit"
                    >
                        <option
                            v-for="unit in units"
                            :key="unit.value"
                            :value="unit.value"
                        >
                            {{ unit.title }}
                        </option>
                    </FormSelect>
                </fieldset>
            </div>
            <div class="flex justify-end">
                <FormButton
                    type="submit"
                    :variant="ButtonVariants.SECONDARY"
                    :loading="loading"
                >
                    {{ $t("automation.save_step") }}
                </FormButton>
            </div>
        </form>
    </ValidationObserver>
</template>

<script>
import { AutomationStoreNamespacedTypes } from "@/store/modules/automation";
import AutomationActionMixin from "@/mixins/AutomationActionMixin";
import FormSelect from "@/components/forms/fields/FormSelect";
import { getProperty } from "@/utils/object";
import FormInput from "@/components/forms/fields/FormInput";

export default {
    name: "AssignVideoAction",
    components: { FormInput, FormSelect },
    mixins: [AutomationActionMixin],
    data: () => ({
        form: {
            content_id: null,
            content_type: "video",
            length: 1,
            unit: "months",
        },
    }),
    computed: {
        /**
         * @type {Array}
         */
        videos() {
            return this.$store.getters[
                AutomationStoreNamespacedTypes.getters.SORTED_VIDEOS
            ]();
        },
    },
    created() {
        this.form = {
            content_id: getProperty(this.action, "attributes.content_id", null),
            content_type: "video",
            length: getProperty(this.action, "attributes.length", 1),
            unit: getProperty(this.action, "attributes.unit", "months"),
        };
    },
};
</script>
