<template>
    <DashboardPage>
        <template v-slot:sidebar>
            <FormDateRangeSelect v-model="range" />
        </template>
        <template>
            <GroupChartCard
                :loading="loading"
                :title="$t('statistics.quizzing.title').toString()"
                identifier="quizzing"
                :data="quizzes"
                :rows="2"
                class="card"
            />
            <ValueCard
                :loading="loading"
                :title="$t('statistics.courses.title').toString()"
                :unit="$t('statistics.courses.unit').toString()"
                :value="courseCount"
                class="card"
            />
            <ValueCard
                :loading="loading"
                :title="$t('statistics.videos.title').toString()"
                :unit="$t('statistics.videos.unit').toString()"
                :value="videoCount"
                class="card"
            />
        </template>
    </DashboardPage>
</template>

<script>
import { StatisticsStoreNamespacedTypes } from "@/store/modules/statistics";
import { LocaleStoreNamespacedTypes } from "@/store/modules/locale";

import { getProperty } from "@/utils/object";

import FormDateRangeSelect from "@/components/forms/fields/FormDateRangeSelect";
import GroupChartCard from "@/components/statistics/GroupChartCard";
import DashboardPage from "@/components/layout/DashboardPage";
import ValueCard from "@/components/statistics/ValueCard";

export default {
    name: "DashboardTrainingPage",
    components: {
        ValueCard,
        DashboardPage,
        GroupChartCard,
        FormDateRangeSelect,
    },
    data: () => ({
        loading: true,
        range: "3-MONTHS",
    }),
    computed: {
        /**
         * Current locale.
         * @type {String}
         */
        locale() {
            return this.$store.getters[
                LocaleStoreNamespacedTypes.getters.LOCALE
            ]();
        },

        /**
         * @type {Object}
         */
        error() {
            return this.$store.getters[
                StatisticsStoreNamespacedTypes.getters.ERROR
            ]();
        },

        /**
         * @type {Object}
         */
        statistics() {
            return this.$store.getters[
                StatisticsStoreNamespacedTypes.getters.STATISTICS
            ]();
        },

        quizzes() {
            return getProperty(this.statistics, "quizzes", {
                count: 0,
                groups: [
                    {
                        label: "incorrect",
                        count: 0,
                        pct: 0,
                        color: "error-dark",
                    },
                    {
                        label: "correct",
                        count: 0,
                        pct: 0,
                        color: "success-dark",
                    },
                ],
            });
        },

        /**
         * The total number of videos watched
         * @returns {number}
         */
        videoCount() {
            return getProperty(this.statistics, "videos.count", 0);
        },

        /**
         * The total number of courses completed
         * @returns {number}
         */
        courseCount() {
            return getProperty(this.statistics, "courses.count", 0);
        },
    },
    watch: {
        range() {
            this.getTrainingStatistics();
        },
    },
    methods: {
        getTrainingStatistics() {
            this.loading = true;
            this.$store
                .dispatch(
                    StatisticsStoreNamespacedTypes.actions
                        .GET_TRAINING_STATISTICS,
                    { range: this.range }
                )
                .then(() => {
                    this.loading = false;
                });
        },
    },
    mounted() {
        this.$store.commit(
            StatisticsStoreNamespacedTypes.mutations.SET_ERROR,
            null
        );
        this.getTrainingStatistics();
    },
};
</script>
