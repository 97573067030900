<template>
    <OneCol>
        <Prompt :active="showPrompt" @close="showPrompt = false"
            ><h3 slot="header">{{ $t("simulations.create.title") }}</h3>
            <div slot="content">
                <CampaignCreateForm @saved="handleSaved" />
            </div>
        </Prompt>
        <Heading
            :title="$t('simulations.heading').toString()"
            :text="$t('simulations.text').toString()"
            :loading="loading"
        >
            <FormButton v-if="isManager" @click="showPrompt = true">{{
                $t("simulations.create.title")
            }}</FormButton>
        </Heading>
        <CampaignTable
            :rows="rows"
            :meta="meta"
            :loading="loading"
            :error="error"
            @refresh="refreshData"
            class="card"
        />
    </OneCol>
</template>

<script>
import { AuthStoreNamespacedTypes } from "@/store/modules/auth";
import { CampaignStoreNamespacedTypes } from "@/store/modules/campaign";

import { getProperty } from "@/utils/object";
import { redirectWithParamsTo } from "@/router";

import OneCol from "@/components/layout/OneCol";
import Heading from "@/components/layout/Heading";
import CampaignCreateForm from "@/components/forms/CampaignCreateForm";
import CampaignTable from "@/components/display/campaign/CampaignTable";
import Prompt from "@/components/ui/Prompt";
import FormButton, {
    ButtonVariants,
} from "@/components/forms/fields/FormButton";

export default {
    name: "SimulationIndex",
    components: {
        FormButton,
        Prompt,
        CampaignTable,
        CampaignCreateForm,
        Heading,
        OneCol,
    },
    data: () => ({
        ButtonVariants,
        showPrompt: false,
    }),
    computed: {
        /**
         * The user model
         * @type {Boolean}
         */
        isManager() {
            return this.$store.getters[
                AuthStoreNamespacedTypes.getters.IS_MANAGER
            ]();
        },

        /**
         * @type {Boolean}
         */
        loading() {
            return this.$store.getters[
                CampaignStoreNamespacedTypes.getters.CAMPAIGNS_LOADING
            ]();
        },

        /**
         * @type {Object}
         */
        error() {
            return this.$store.getters[
                CampaignStoreNamespacedTypes.getters.CAMPAIGNS_ERROR
            ]();
        },

        /**
         * @type {Array}
         */
        campaigns() {
            return this.$store.getters[
                CampaignStoreNamespacedTypes.getters.CAMPAIGNS
            ]();
        },

        /**
         * @type {Array}
         */
        rows() {
            return getProperty(this.campaigns, "data", []);
        },

        /**
         * @type {Object}
         */
        meta() {
            return getProperty(this.campaigns, "meta", null);
        },
    },
    methods: {
        handleSaved(campaign) {
            let id = getProperty(campaign, "data.id", null);

            if (!id) {
                return;
            }

            redirectWithParamsTo({
                name: "campaign.single",
                params: { id: id },
            });
        },
        refreshData(payload = null) {
            this.$store.dispatch(
                CampaignStoreNamespacedTypes.actions.GET_CAMPAIGNS,
                payload
            );
        },
    },
    created() {
        this.refreshData();
    },
};
</script>
