var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"overflow-hidden rounded-xl border border-slate-200"},[_c('dl',{staticClass:"-my-3 divide-y divide-slate-100 px-6 py-4 text-sm leading-6"},[_c('ThreadIndicator',{attrs:{"title":_vm.$t(
                    'threats.analysis.indicators.inconsistency.heading'
                ).toString(),"text":_vm.$t(
                    'threats.analysis.indicators.inconsistency.help'
                ).toString(),"loading":_vm.loading,"detected":_vm.indicatorInconsistency}}),_c('ThreadIndicator',{attrs:{"title":_vm.$t('threats.analysis.indicators.urgency.heading').toString(),"text":_vm.$t('threats.analysis.indicators.urgency.help').toString(),"loading":_vm.loading,"detected":_vm.indicatorUrgency}}),_c('ThreadIndicator',{attrs:{"title":_vm.$t(
                    'threats.analysis.indicators.credentials.heading'
                ).toString(),"text":_vm.$t(
                    'threats.analysis.indicators.credentials.help'
                ).toString(),"loading":_vm.loading,"detected":_vm.indicatorCredentials}}),_c('ThreadIndicator',{attrs:{"title":_vm.$t(
                    'threats.analysis.indicators.homoglyphs.heading'
                ).toString(),"text":_vm.$t('threats.analysis.indicators.homoglyphs.help').toString(),"loading":_vm.loading,"detected":_vm.indicatorHomoglyph}}),_c('ThreadIndicator',{attrs:{"title":_vm.$t('threats.analysis.indicators.common.heading').toString(),"text":_vm.$t('threats.analysis.indicators.common.help').toString(),"loading":_vm.loading,"detected":_vm.indicatorCommon}})],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }