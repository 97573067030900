<template>
    <li class="relative mb-3 rounded-xl bg-slate-50 px-4 py-3">
        <div class="flex flex-row items-center">
            <div class="flex flex-grow flex-col overflow-hidden">
                <div class="flex flex-row items-center">
                    <div
                        class="mr-2 flex h-5 w-5 items-center justify-center rounded-full bg-slate-300 text-xs font-bold text-slate-900"
                    >
                        {{ step }}
                    </div>
                    <p class="font-medium text-slate-800">
                        {{ title }}
                    </p>
                </div>
                <div
                    v-if="assessmentName"
                    class="ml-7 mt-1 text-sm text-slate-500"
                >
                    <p class="truncate">
                        {{ assessmentName }}
                    </p>
                </div>
            </div>
            <div class="flex items-center gap-x-2">
                <LoadingSpinner v-if="loading" />
                <FormButton
                    v-if="key !== 'halt_action'"
                    :to="{
                        name: 'automation.action',
                        params: { id: automationId, actionId: actionId },
                    }"
                    :variant="
                        variant === StepVariants.SMALL
                            ? ButtonVariants.SMALL
                            : ButtonVariants.SECONDARY
                    "
                    >{{ $t("automation.flow.actions.preview") }}</FormButton
                >
            </div>
        </div>
        <div
            class="mt-3 flex flex-row items-center border-t-2 border-white py-1 pt-3"
        >
            <svg
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                class="mr-2 h-5 w-5"
            >
                <rect
                    x="3.5"
                    y="9.5"
                    width="3"
                    height="6"
                    rx="1.5"
                    fill="currentColor"
                ></rect>
                <rect
                    x="8.5"
                    y="6.5"
                    width="3"
                    height="9"
                    rx="1.5"
                    fill="#dad9fd"
                ></rect>
                <rect
                    x="13.5"
                    y="3.5"
                    width="3"
                    height="12"
                    rx="1.5"
                    fill="#dad9fd"
                ></rect>
            </svg>
            <p class="text-xs font-bold text-slate-500">
                <span class="mr-4"
                    >{{ $t("automation.flow.actions.status.active") }}:
                    {{ activeCount }}</span
                >
                <span class="mr-4"
                    >{{ $t("automation.flow.actions.status.completed") }}:
                    {{ completedCount }}</span
                >
                <span
                    >{{ $t("automation.flow.actions.status.halted") }}:
                    {{ haltedCount }}</span
                >
            </p>
        </div>
    </li>
</template>

<script>
import { AutomationStoreNamespacedTypes } from "@/store/modules/automation";

import { getProperty } from "@/utils/object";

import FormButton, {
    ButtonVariants,
} from "@/components/forms/fields/FormButton";
import LoadingSpinner from "@/components/ui/LoadingSpinner";

export const StepVariants = {
    NORMAL: "normal",
    SMALL: "small",
};

export default {
    name: "AutomationFlowStep",
    components: { LoadingSpinner, FormButton },
    props: {
        automationId: {
            type: String,
            default: "",
        },
        actionId: {
            type: String,
            default: "",
        },
        index: {
            type: Number,
            default: 0,
        },
        variant: {
            type: String,
            default: StepVariants.NORMAL,
            validator(type) {
                return Object.values(StepVariants).includes(type);
            },
        },
    },
    data: () => ({
        StepVariants,
        ButtonVariants,
    }),
    computed: {
        action() {
            return this.$store.getters[
                AutomationStoreNamespacedTypes.getters.ACTION
            ](this.actionId);
        },

        /**
         * @type {Array}
         */
        courses() {
            return this.$store.getters[
                AutomationStoreNamespacedTypes.getters.COURSES
            ]();
        },

        /**
         * @type {Array}
         */
        videos() {
            return this.$store.getters[
                AutomationStoreNamespacedTypes.getters.VIDEOS
            ]();
        },

        loading() {
            return getProperty(this.action, "loading", false);
        },

        stored() {
            return getProperty(this.action, "stored", false);
        },

        key() {
            return getProperty(this.action, "key", "action_selector");
        },

        attributes() {
            return getProperty(this.action, "attributes");
        },

        statistics() {
            return getProperty(this.action, "statistics");
        },

        activeCount() {
            return getProperty(this.statistics, "active.count", 0);
        },

        completedCount() {
            return getProperty(this.statistics, "completed.count", 0);
        },

        haltedCount() {
            return getProperty(this.statistics, "halted.count", 0);
        },

        /**
         * Step title
         * @type {String}
         */
        title() {
            if (this.key === "wait_action") {
                return this.$t("automation.flow.title.stored.wait_action", {
                    length: this.attributes.length,
                    unit: this.$tc(
                        "automation.flow.actions.wait_units." +
                            this.attributes.unit,
                        this.attributes.length
                    ),
                }).toString();
            }

            return this.$t(
                "automation.flow.title.stored." + this.key
            ).toString();
        },

        /**
         * Assessment title
         * @type {String}
         */
        assessmentName() {
            if (
                this.key !== "assign_course_action" &&
                this.key !== "assign_video_action"
            ) {
                return null;
            }

            if (this.key === "assign_course_action") {
                let course = this.courses.find(
                    (course) =>
                        course.id === getProperty(this.attributes, "content_id")
                );
                return course ? course.title : null;
            }

            if (this.key === "assign_video_action") {
                let video = this.videos.find(
                    (video) =>
                        video.id === getProperty(this.attributes, "content_id")
                );
                return video ? video.title : null;
            }

            return null;
        },
        step() {
            return this.index + 1;
        },
    },
};
</script>
