<template>
    <div>
        <BarChartLoading v-if="loading" />
        <div v-else class="group-scope relative flex h-[160px] pt-2">
            <div class="group relative hidden flex-col lg:flex">
                <div
                    class="relative -mt-1 mb-8 flex h-full flex-col justify-between pr-2"
                >
                    <div class="flex w-full justify-end">
                        <p class="text-right text-xs text-slate-400">
                            {{ maxValue | formatNumber }}
                        </p>
                    </div>
                    <div class="flex w-full justify-end">
                        <p class="text-right text-xs text-slate-400">
                            {{ halfValue | formatNumber }}
                        </p>
                    </div>
                    <div class="flex w-full justify-end">
                        <p class="text-right text-xs text-slate-400">0</p>
                    </div>
                </div>
            </div>
            <BarChartItem
                v-for="(item, index) in data"
                :index="index"
                :key="'item-' + index"
                :title="item.title"
                :value="item.value"
                :max-value="maxValue"
            />
        </div>
    </div>
</template>

<script>
import BarChartLoading from "@/components/charts/BarChart/partials/BarChartLoading";
import BarChartItem from "@/components/charts/BarChart/partials/BarChartItem";
import { formatNumber } from "@/utils/number";

export default {
    name: "BarChart",
    components: { BarChartLoading, BarChartItem },
    props: {
        loading: {
            type: Boolean,
            required: true,
        },
        data: {
            type: Array,
            default: () => [],
        },
    },
    filters: {
        formatNumber,
    },
    computed: {
        maxValue() {
            return Math.max(...this.data.map((item) => item.value));
        },
        halfValue() {
            return this.maxValue / 2;
        },
    },
};
</script>
