<template>
    <OneCol>
        <Prompt :active="showPrompt" @close="showPrompt = false"
            ><h3 slot="header">{{ $t("groups.create_group") }}</h3>
            <div slot="content">
                <CreateGroupForm @stored="handleGroupCreated" /></div
        ></Prompt>
        <TeamTabs />
        <Heading
            :title="$t('groups.heading').toString()"
            :text="$t('groups.text').toString()"
            :loading="loading"
        >
            <FormButton v-if="isManager" @click="showPrompt = true">{{
                $t("groups.create_group")
            }}</FormButton>
        </Heading>
        <GroupTable
            :rows="rows"
            :meta="meta"
            :loading="loading"
            :error="error"
            @refresh="refreshData"
            class="card"
        />
    </OneCol>
</template>

<script>
import { AuthStoreNamespacedTypes } from "@/store/modules/auth";
import { TeamStoreNamespacedTypes } from "@/store/modules/team";

import { getProperty } from "@/utils/object";

import Bus from "@/bus";
import CreateGroupForm from "@/components/forms/CreateGroupForm";
import Prompt from "@/components/ui/Prompt";
import OneCol from "@/components/layout/OneCol";
import Heading from "@/components/layout/Heading";
import FormButton from "@/components/forms/fields/FormButton";
import TeamTabs from "@/components/tabs/TeamTabs";
import GroupTable from "@/components/display/group/GroupTable";

export default {
    name: "GroupIndex",
    components: {
        GroupTable,
        TeamTabs,
        FormButton,
        Heading,
        OneCol,
        Prompt,
        CreateGroupForm,
    },
    data: () => ({
        showPrompt: false,
    }),
    computed: {
        /**
         * The user model
         * @type {Boolean}
         */
        isManager() {
            return this.$store.getters[
                AuthStoreNamespacedTypes.getters.IS_MANAGER
            ]();
        },

        /**
         * The loading state for the API request.
         * @type {Boolean}
         */
        loading() {
            return this.$store.getters[
                TeamStoreNamespacedTypes.getters.LOADING
            ]();
        },

        /**
         * The error state of the API request.
         * @type {Object}
         */
        error() {
            return this.$store.getters[
                TeamStoreNamespacedTypes.getters.ERROR
            ]();
        },

        /**
         * @type {Object}
         */
        groups() {
            return this.$store.getters[
                TeamStoreNamespacedTypes.getters.GROUPS
            ]();
        },

        /**
         * @type {Array}
         */
        rows() {
            return getProperty(this.groups, "data", []);
        },

        /**
         * @type {Object}
         */
        meta() {
            return getProperty(this.groups, "meta", null);
        },
    },
    methods: {
        handleGroupCreated() {
            this.$store
                .dispatch(TeamStoreNamespacedTypes.actions.GET_GROUPS)
                .then(() => {
                    Bus.$emit("flash-message", {
                        text: this.$t("groups.group_created"),
                        type: "success",
                    });
                    this.showPrompt = false;
                });
        },
        refreshData(payload = null) {
            this.$store.dispatch(
                TeamStoreNamespacedTypes.actions.GET_GROUPS,
                payload
            );
        },
    },
    mounted() {
        this.refreshData();
    },
};
</script>
