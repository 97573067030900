<template>
    <component
        :class="[
            { 'border-slate-800 bg-transparent text-slate-800': active },
            {
                'border-b-transparent bg-transparent text-slate-500': !active,
            },
        ]"
        class="isolate flex cursor-pointer flex-row items-center gap-x-2 border-b-[2px] px-1 pb-2 text-sm font-semibold leading-6 transition-all duration-150 hover:border-slate-800 hover:text-slate-800"
        :disabled="disabled"
        :href="href"
        :is="tagName"
        :title="title"
        :type="buttonType"
        :to="to"
        @click="onClick"
    >
        <Icon v-if="icon" :icon="icon" class="size-4" />
        {{ $t("navigation." + title) }}
    </component>
</template>

<script>
import Icon from "@/components/icons/BaseIcon";

export default {
    name: "TabItem",
    components: { Icon },
    props: {
        href: {
            type: String,
            required: false,
        },
        to: {
            type: Object,
            required: false,
        },
        icon: {
            type: Object,
            required: false,
        },
        title: {
            type: String,
            required: false,
        },
        disabled: {
            type: Boolean,
            default: false,
        },
        type: {
            type: String,
            default: "button",
        },
        active: {
            type: Boolean,
            default: false,
        },
    },
    computed: {
        isLink() {
            return !!(this.href || this.to);
        },
        tagName() {
            if (this.to) {
                return "router-link";
            }
            if (this.href) {
                return "a";
            }
            return "button";
        },
        buttonType() {
            return this.isLink ? null : this.type;
        },
    },
    methods: {
        onClick(event) {
            /**
             * Emitted when the button is clicked.
             * @event click
             * @type {Event}
             */
            this.$emit("clicked", event);
        },
    },
};
</script>
