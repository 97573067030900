<template>
    <FormButton
        @click.native="deleteDeadline(item)"
        :variant="ButtonVariants.SECONDARY"
        :icon="IconEnums.TRASH"
        :loading="loading"
    />
</template>

<script>
import { TrainingStoreNamespacedTypes } from "@/store/modules/training";

import { getProperty } from "@/utils/object";

import { IconEnums } from "@/utils/icons";
import FormButton, {
    ButtonVariants,
} from "@/components/forms/fields/FormButton";

export default {
    name: "DeadlineDeleteButton",
    components: {
        FormButton,
    },
    props: {
        item: {
            type: Object,
            required: true,
        },
    },
    data: () => ({
        ButtonVariants,
        IconEnums,
        loading: false,
    }),
    computed: {
        id() {
            return getProperty(this.item, "id");
        },
    },
    methods: {
        deleteDeadline() {
            this.loading = true;
            this.$store
                .dispatch(
                    TrainingStoreNamespacedTypes.actions.DELETE_DEADLINE,
                    this.id
                )
                .then(() => {
                    this.loading = false;
                });
        },
    },
};
</script>
