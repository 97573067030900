/**
 * A store for handling auth (login, register and password resets).
 *
 * @typedef {{ id: string|null, first_name: string|null, last_name: string|null, email: string|null, role: string|null }} UserData
 * @typedef {{ csrfReady: boolean, csrfError: ErrorOrObject, csrfPromise: Promise|null, token: string|null, workspace: string|null, features: array, user: UserData|null, loginLoading: boolean, loginError: ErrorOrObject, registerLoading: boolean, registerError: ErrorOrObject, resetPasswordLoading: boolean, resetPasswordError: ErrorOrObject}} AuthStoreState
 */

import {
    getData,
    postData,
    fetchCsrfCookie,
    setAuthToken,
    setWorkspaceToken,
} from "@/api";
import { addNamespace } from "./namespace";
import { redirectTo } from "@/router";
import { getProperty } from "@/utils/object";

/**
 * The types used in this store
 * @enum {string}
 */
export const AuthStoreTypes = {
    getters: {
        CSRF_ERROR: "csrfError",
        HAS_CSRF_COOKIE: "hasCsrfCookie",
        CSRF_PROMISE: "csrfPromise",
        IS_LOGGED_IN: "isLoggedIn",
        TOKEN: "token",
        USER: "user",
        FEATURES: "features",
        IS_MANAGER: "isManager",
        LOGIN_LOADING: "loginLoading",
        LOGIN_ERROR: "loginError",
        REGISTER_LOADING: "registerLoading",
        REGISTER_ERROR: "registerError",
        RESET_PASSWORD_LOADING: "resetPasswordLoading",
        RESET_PASSWORD_ERROR: "resetPasswordError",
        INVITE_ERROR: "inviteError",
        IS_IMPERSONATING: "isImpersonating",
    },
    actions: {
        FETCH_CSRF_COOKIE: "fetchCsrfCookie",
        LOGIN: "login",
        REGISTER: "register",
        RESET_PASSWORD: "resetPassword",
        VALIDATE_RESET_PASSWORD: "validateResetPassword",
        ACCEPT_INVITE: "acceptInvite",
        IMPERSONATE: "impersonate",
        LOGOUT: "logout",
        CHECK_CURRENT_SESSION: "checkForCurrentSession",
    },
    mutations: {
        CLEAR_CSRF_ERROR: "clearCsrfError",
        SET_CSRF_ERROR: "setCsrfError",
        SET_CSRF_PROMISE: "setCsrfPromise",
        SET_CSRF_READY: "setCsrfReady",
        SET_TOKEN: "setToken",
        SET_WORKSPACE: "setWorkspace",
        SET_USER: "setUser",
        SET_FEATURES: "setFeatures",
        SET_LOGIN_LOADING: "setLoginLoading",
        SET_LOGIN_ERROR: "setLoginError",
        SET_REGISTER_LOADING: "setRegisterLoading",
        SET_REGISTER_ERROR: "setRegisterError",
        SET_RESET_PASSWORD_LOADING: "setResetPasswordLoading",
        SET_RESET_PASSWORD_ERROR: "setResetPasswordError",
        SET_INVITE_ERROR: "setInviteError",
        SET_IMPERSONATING: "setImpersonating",
    },
};

/**
 * A namespaced version of the types used in this store
 * @enum {string}
 */
export const AuthStoreNamespacedTypes = addNamespace("auth", AuthStoreTypes);

/**
 * @returns {AuthStoreState}
 */
export function state() {
    return {
        csrfReady: false,
        csrfError: null,
        csrfPromise: null,
        token: null,
        workspace: null,
        user: {
            id: null,
            first_name: null,
            last_name: null,
            email: null,
            role: null,
        },
        features: [],
        loginLoading: false,
        loginError: null,
        registerLoading: false,
        registerError: null,
        resetPasswordLoading: false,
        resetPasswordError: null,
        inviteError: null,
        isImpersonating: false,
    };
}

export const getters = {
    [AuthStoreTypes.getters.CSRF_ERROR]: (state) => () => {
        return state.csrfError;
    },
    [AuthStoreTypes.getters.HAS_CSRF_COOKIE]: (state) => () => {
        return state.csrfReady;
    },
    [AuthStoreTypes.getters.CSRF_PROMISE]: (state) => () => {
        return state.csrfPromise;
    },
    [AuthStoreTypes.getters.IS_LOGGED_IN]: (state) => () => {
        return state.user.id !== null;
    },
    [AuthStoreTypes.getters.TOKEN]: (state) => () => {
        return state.token;
    },
    [AuthStoreTypes.getters.USER]: (state) => () => {
        return state.user;
    },
    [AuthStoreTypes.getters.FEATURES]: (state) => () => {
        return state.features;
    },
    [AuthStoreTypes.getters.IS_MANAGER]: (state) => () => {
        return getProperty(state.user, "role", "analyst") === "manager";
    },
    [AuthStoreTypes.getters.LOGIN_LOADING]: (state) => () => {
        return state.loginLoading;
    },
    [AuthStoreTypes.getters.LOGIN_ERROR]: (state) => () => {
        return state.loginError;
    },
    [AuthStoreTypes.getters.REGISTER_LOADING]: (state) => () => {
        return state.registerLoading;
    },
    [AuthStoreTypes.getters.REGISTER_ERROR]: (state) => () => {
        return state.registerError;
    },
    [AuthStoreTypes.getters.RESET_PASSWORD_LOADING]: (state) => () => {
        return state.resetPasswordLoading;
    },
    [AuthStoreTypes.getters.RESET_PASSWORD_ERROR]: (state) => () => {
        return state.resetPasswordError;
    },
    [AuthStoreTypes.getters.INVITE_ERROR]: (state) => () => {
        return state.inviteError;
    },
    [AuthStoreTypes.getters.IS_IMPERSONATING]: (state) => () => {
        return state.isImpersonating;
    },
};

export const actions = {
    /**
     * Fetches a CSRF protection cookie.
     * Returns a promise which will resolve once the cookie has been fetched.
     *
     * @param {VuexCommit} commit
     * @returns {Promise}
     */
    [AuthStoreTypes.actions.FETCH_CSRF_COOKIE]({ commit }) {
        commit(AuthStoreTypes.mutations.SET_CSRF_READY, false);
        commit(AuthStoreTypes.mutations.CLEAR_CSRF_ERROR);

        const csrfPromise = fetchCsrfCookie()
            .then(() => {
                commit(AuthStoreTypes.mutations.SET_CSRF_READY, true);
            })
            .catch((error) => {
                commit(AuthStoreTypes.mutations.SET_CSRF_ERROR, error);
            });

        commit(AuthStoreTypes.mutations.SET_CSRF_PROMISE, csrfPromise);

        return csrfPromise;
    },

    /**
     * Sends the given credentials to the server and updates the store with the returned login data.
     *
     * @param {VuexCommit} commit
     * @param {VuexDispatch} dispatch
     * @param {VuexGetters} getters
     * @param {AuthStoreState} state
     * @param {Object} credentials
     * @return {Promise}
     */
    async [AuthStoreTypes.actions.LOGIN](
        { commit, dispatch, getters, state },
        credentials
    ) {
        commit(AuthStoreTypes.mutations.SET_LOGIN_LOADING, true);
        commit(AuthStoreTypes.mutations.SET_LOGIN_ERROR, null);

        // Make sure we have a valid CSRF cookie before submitting any data
        if (state.csrfPromise === null) {
            await dispatch(AuthStoreTypes.actions.FETCH_CSRF_COOKIE);
        }

        await state.csrfPromise;

        if (!getters[AuthStoreTypes.getters.HAS_CSRF_COOKIE]()) {
            return commit(AuthStoreTypes.mutations.SET_CSRF_ERROR, {
                message: "general_exception",
            });
        }

        return postData("/auth/login", credentials)
            .then(({ data }) => {
                commit(AuthStoreTypes.mutations.SET_TOKEN, data.token);
                commit(AuthStoreTypes.mutations.SET_WORKSPACE, data.workspace);
                commit(AuthStoreTypes.mutations.SET_USER, data.user);
                commit(AuthStoreTypes.mutations.SET_FEATURES, data.features);
            })
            .catch((errors) => {
                commit(AuthStoreTypes.mutations.SET_LOGIN_ERROR, errors);
            })
            .finally(() => {
                commit(AuthStoreTypes.mutations.SET_LOGIN_LOADING, false);
            });
    },

    /**
     * Sends the given credentials to the server and updates the store with the returned login data.
     *
     * @param {VuexCommit} commit
     * @param {Object} credentials
     * @return {Promise}
     */
    [AuthStoreTypes.actions.REGISTER]({ commit }, credentials) {
        commit(AuthStoreTypes.mutations.SET_REGISTER_LOADING, true);
        commit(AuthStoreTypes.mutations.SET_REGISTER_ERROR, null);

        return postData("/auth/register", credentials)
            .then(({ data }) => {
                commit(AuthStoreTypes.mutations.SET_TOKEN, data.token);
                commit(AuthStoreTypes.mutations.SET_WORKSPACE, data.workspace);
                commit(AuthStoreTypes.mutations.SET_USER, data.user);
                commit(AuthStoreTypes.mutations.SET_FEATURES, data.features);
            })
            .catch((errors) => {
                commit(AuthStoreTypes.mutations.SET_REGISTER_ERROR, errors);
            })
            .finally(() => {
                commit(AuthStoreTypes.mutations.SET_REGISTER_LOADING, false);
            });
    },

    /**
     * Sends the given password reset request to the server.
     *
     * @param {VuexCommit} commit
     * @param {string} email
     * @return {Promise}
     */
    [AuthStoreTypes.actions.RESET_PASSWORD]({ commit }, email) {
        commit(AuthStoreTypes.mutations.SET_RESET_PASSWORD_LOADING, true);
        commit(AuthStoreTypes.mutations.SET_RESET_PASSWORD_ERROR, null);

        return postData("/auth/password/reset", { email: email })
            .catch((errors) => {
                commit(
                    AuthStoreTypes.mutations.SET_RESET_PASSWORD_ERROR,
                    errors
                );
            })
            .finally(() => {
                commit(
                    AuthStoreTypes.mutations.SET_RESET_PASSWORD_LOADING,
                    false
                );
            });
    },

    /**
     * Sends the given password reset validation request to the server.
     *
     * @param {VuexCommit} commit
     * @param {object} data
     * @return {Promise}
     */
    [AuthStoreTypes.actions.VALIDATE_RESET_PASSWORD]({ commit }, data) {
        commit(AuthStoreTypes.mutations.SET_RESET_PASSWORD_LOADING, true);
        commit(AuthStoreTypes.mutations.SET_RESET_PASSWORD_ERROR, null);

        return postData("/auth/password/validate", data)
            .catch((errors) => {
                commit(
                    AuthStoreTypes.mutations.SET_RESET_PASSWORD_ERROR,
                    errors
                );
            })
            .finally(() => {
                commit(
                    AuthStoreTypes.mutations.SET_RESET_PASSWORD_LOADING,
                    false
                );
            });
    },

    /**
     * Sends the given credentials to the server and updates the store with the returned login data.
     *
     * @param {VuexCommit} commit
     * @param {VuexDispatch} dispatch
     * @param {VuexGetters} getters
     * @param {AuthStoreState} state
     * @param {Object} credentials
     * @return {Promise}
     */
    async [AuthStoreTypes.actions.ACCEPT_INVITE](
        { commit, dispatch, getters, state },
        credentials
    ) {
        commit(AuthStoreTypes.mutations.SET_INVITE_ERROR, null);

        // Make sure we have a valid CSRF cookie before submitting any data
        if (state.csrfPromise === null) {
            await dispatch(AuthStoreTypes.actions.FETCH_CSRF_COOKIE);
        }

        await state.csrfPromise;

        if (!getters[AuthStoreTypes.getters.HAS_CSRF_COOKIE]()) {
            return commit(AuthStoreTypes.mutations.SET_CSRF_ERROR, {
                message: "general_exception",
            });
        }

        return postData("/auth/invite", credentials)
            .then(({ data }) => {
                commit(AuthStoreTypes.mutations.SET_TOKEN, data.token);
                commit(AuthStoreTypes.mutations.SET_WORKSPACE, data.workspace);
                commit(AuthStoreTypes.mutations.SET_USER, data.user);
                commit(AuthStoreTypes.mutations.SET_FEATURES, data.features);
            })
            .catch((errors) => {
                commit(AuthStoreTypes.mutations.SET_INVITE_ERROR, errors);
            });
    },

    /**
     * Start an impersonation session for the given user.
     *
     * @param {VuexDispatch} dispatch
     * @param {VuexCommit} commit
     * @param {string} token
     * @param {string} wid
     * @return {Promise}
     */
    async [AuthStoreTypes.actions.IMPERSONATE](
        { dispatch, commit },
        { token, wid }
    ) {
        await dispatch(AuthStoreTypes.actions.LOGOUT);

        // Make sure we have a valid CSRF cookie before submitting any data
        if (state.csrfPromise === null) {
            await dispatch(AuthStoreTypes.actions.FETCH_CSRF_COOKIE);
        }

        await state.csrfPromise;

        commit(AuthStoreTypes.mutations.SET_TOKEN, token);
        commit(AuthStoreTypes.mutations.SET_WORKSPACE, wid);
        commit(AuthStoreTypes.mutations.SET_IMPERSONATING, true);

        getData("session").then(({ data }) => {
            commit(AuthStoreTypes.mutations.SET_USER, data.user);
            commit(AuthStoreTypes.mutations.SET_FEATURES, data.features);
            redirectTo("dashboard.overview");
        });
    },

    /**
     * Logs out the user and clears the user data from the store.
     *
     * @param {VuexCommit} commit
     */
    async [AuthStoreTypes.actions.LOGOUT]({ commit }) {
        commit(AuthStoreTypes.mutations.SET_USER, {
            id: null,
            first_name: null,
            last_name: null,
            email: null,
            role: null,
        });
        commit(AuthStoreTypes.mutations.SET_FEATURES, []);
        commit(AuthStoreTypes.mutations.SET_TOKEN, null);
        commit(AuthStoreTypes.mutations.SET_WORKSPACE, null);
        commit(AuthStoreTypes.mutations.SET_IMPERSONATING, false);
        localStorage.clear();
    },

    /**
     * Validates a users session upon page refresh.
     *
     * @param {AuthStoreState} state
     * @param {VuexCommit} commit
     * @return {Promise}
     */
    [AuthStoreTypes.actions.CHECK_CURRENT_SESSION]({ state, commit }) {
        commit(AuthStoreTypes.mutations.SET_TOKEN, state.token);
        commit(AuthStoreTypes.mutations.SET_WORKSPACE, state.workspace);

        return getData("session");
    },
};

export const mutations = {
    [AuthStoreTypes.mutations.SET_CSRF_ERROR](state, error) {
        state.csrfError = error;
    },
    [AuthStoreTypes.mutations.SET_CSRF_PROMISE](state, promise) {
        state.csrfPromise = promise;
    },
    [AuthStoreTypes.mutations.SET_CSRF_READY](state, ready) {
        state.csrfReady = ready;
    },
    [AuthStoreTypes.mutations.CLEAR_CSRF_ERROR](state) {
        state.csrfError = null;
    },
    /**
     * Sets the user token and updates axios bearer token.
     *
     * @param {AuthStoreState} state
     * @param {string} token
     */
    [AuthStoreTypes.mutations.SET_TOKEN](state, token) {
        state.token = token;
        setAuthToken(token);
    },

    /**
     * Sets the user workspace and update API.
     *
     * @param {AuthStoreState} state
     * @param {string} workspace
     */
    [AuthStoreTypes.mutations.SET_WORKSPACE](state, workspace) {
        state.workspace = workspace;
        setWorkspaceToken(workspace);
    },

    /**
     * Sets user data.
     *
     * @param {AuthStoreState} state
     * @param {string|null} id
     * @param {string|null} first_name
     * @param {string|null} last_name
     * @param {string|null} email
     * @param {string|null} role
     */
    [AuthStoreTypes.mutations.SET_USER](
        state,
        {
            id = null,
            first_name = null,
            last_name = null,
            email = null,
            role = null,
        }
    ) {
        state.user.id = id;
        state.user.first_name = first_name;
        state.user.last_name = last_name;
        state.user.email = email;
        state.user.role = role;
    },

    /**
     * Sets the features state for the user.
     *
     * @param {AuthStoreState} state
     * @param {array} features
     */
    [AuthStoreTypes.mutations.SET_FEATURES](state, features) {
        state.features = features;
    },

    /**
     * Sets the loading state for the login form.
     *
     * @param {AuthStoreState} state
     * @param {boolean} loading
     */
    [AuthStoreTypes.mutations.SET_LOGIN_LOADING](state, loading) {
        state.loginLoading = loading;
    },

    /**
     * Sets a new login error.
     *
     * @param {AuthStoreState} state
     * @param {ErrorOrObject} error
     */
    [AuthStoreTypes.mutations.SET_LOGIN_ERROR](state, error) {
        state.loginError = error;
    },

    /**
     * Sets the loading state for the register form.
     *
     * @param {AuthStoreState} state
     * @param {boolean} loading
     */
    [AuthStoreTypes.mutations.SET_REGISTER_LOADING](state, loading) {
        state.registerLoading = loading;
    },

    /**
     * Sets a new register error.
     *
     * @param {AuthStoreState} state
     * @param {ErrorOrObject} error
     */
    [AuthStoreTypes.mutations.SET_REGISTER_ERROR](state, error) {
        state.registerError = error;
    },

    /**
     * Sets the loading state for the reset password form.
     *
     * @param {AuthStoreState} state
     * @param {boolean} loading
     */
    [AuthStoreTypes.mutations.SET_RESET_PASSWORD_LOADING](state, loading) {
        state.resetPasswordLoading = loading;
    },

    /**
     * Sets a new reset password error.
     *
     * @param {AuthStoreState} state
     * @param {ErrorOrObject} error
     */
    [AuthStoreTypes.mutations.SET_RESET_PASSWORD_ERROR](state, error) {
        state.resetPasswordError = error;
    },

    /**
     * Sets a new invite error.
     *
     * @param {AuthStoreState} state
     * @param {ErrorOrObject} error
     */
    [AuthStoreTypes.mutations.SET_INVITE_ERROR](state, error) {
        state.inviteError = error;
    },

    /**
     * Sets the impersonating state.
     *
     * @param {AuthStoreState} state
     * @param {boolean} isImpersonating
     */
    [AuthStoreTypes.mutations.SET_IMPERSONATING](state, isImpersonating) {
        state.isImpersonating = isImpersonating;
    },
};

export default {
    namespaced: true,
    Types: AuthStoreTypes,
    NamespacedTypes: AuthStoreNamespacedTypes,
    state,
    getters,
    actions,
    mutations,
};
