<template>
    <DataTable
        name="automation.index"
        :headers="headers"
        :rows="rows"
        :meta="meta"
        :loading="loading"
        :error="error"
        :emit="true"
        @emit="$emit('emit', $event)"
        @update="refresh"
    >
        <template v-slot:status="{ item }">
            <Badge
                :variant="
                    item.status === 'started'
                        ? BadgeVariants.SUCCESS
                        : BadgeVariants.WARNING
                "
                :text="$t('automation.status.' + item.status).toString()"
                :loading="item.status === 'started'"
            />
        </template>
        <template v-slot:updated_at="{ item }">
            {{ item.updated_at | formatDate }}
        </template>
        <template v-slot:group="{ item }">
            <div v-if="item.group" class="flex items-center">
                <span
                    class="inline-flex h-10 w-10 items-center justify-center rounded-full bg-indigo-500"
                >
                    <span class="text-sm font-medium leading-none text-white">{{
                        item.group.name | initials
                    }}</span>
                </span>
                <div class="ml-4">
                    <div class="text-sm font-medium text-slate-900">
                        {{ item.group.name }}
                    </div>
                    <div class="text-sm font-medium text-slate-500">
                        {{
                            $tc(
                                "groups.members_count",
                                item.group.employee_active_count
                            )
                        }}
                    </div>
                </div>
            </div>
        </template>
    </DataTable>
</template>

<script>
import { formatDate } from "@/utils/date";
import { initials } from "@/utils/filters";

import DataTableMixin from "@/components/tables/DataTableMixin";
import Badge, { BadgeVariants } from "@/components/shared/Badge";

export default {
    name: "AutomationTable",
    components: { Badge },
    mixins: [DataTableMixin],
    data: () => ({
        BadgeVariants,
        headers: [
            {
                title: "name",
                key: "name",
                sortable: true,
            },
            {
                title: "status",
                key: "status",
                sortable: true,
            },
            {
                title: "updated_at",
                key: "updated_at",
                sortable: true,
            },
            {
                title: "audience",
                key: "group",
                sortable: false,
            },
        ],
    }),
    filters: {
        formatDate,
        initials,
    },
};
</script>
