/**
 * A store for handling the different uploads in the app.
 *
 * @typedef {{loading: boolean, error: ErrorOrObject}} UploadStoreState
 */

import { addNamespace } from "./namespace";
/**
 * The types used in this store
 * @enum {string}
 */
export const UploadStoreTypes = {
    getters: {
        LOADING: "loading",
        ERROR: "error",
    },
    actions: {
        UPLOAD_FILE: "uploadFile",
    },
    mutations: {
        SET_LOADING: "setLoading",
        SET_ERROR: "setError",
    },
};

/**
 * A namespaced version of the types used in this store
 * @enum {string}
 */
export const UploadStoreNamespacedTypes = addNamespace(
    "upload",
    UploadStoreTypes
);

/**
 * @returns {UploadStoreState}
 */
export function state() {
    return {
        loading: false,
        error: null,
    };
}

export const getters = {
    [UploadStoreTypes.getters.LOADING]: (state) => () => {
        return state.loading;
    },
    [UploadStoreTypes.getters.ERROR]: (state) => () => {
        return state.error;
    },
};

export const actions = {
    /**
     * Updates the current upload.
     *
     * @param {VuexCommit} commit
     */
    [UploadStoreTypes.actions.UPLOAD_FILE]({ commit }) {
        commit(UploadStoreTypes.mutations.SET_LOADING, true);
        commit(UploadStoreTypes.mutations.SET_ERROR, null);

        //
    },
};

export const mutations = {
    /**
     * Sets the loading state for the upload
     *
     * @param {UploadStoreState} state
     * @param {boolean} loading
     */
    [UploadStoreTypes.mutations.SET_LOADING](state, loading) {
        state.loading = loading;
    },

    /**
     * Sets the error state for the upload
     *
     * @param {UploadStoreState} state
     * @param {ErrorOrObject} error
     */
    [UploadStoreTypes.mutations.SET_ERROR](state, error) {
        state.error = error;
    },
};

export default {
    namespaced: true,
    Types: UploadStoreTypes,
    NamespacedTypes: UploadStoreNamespacedTypes,
    state,
    getters,
    actions,
    mutations,
};
