<template>
    <div class="flex flex-col lg:flex-row lg:space-x-6">
        <div class="order-2 flex flex-1 flex-col lg:order-1">
            <slot name="content" />
        </div>
        <aside
            class="order-1 flex w-full flex-shrink-0 flex-col lg:order-2 lg:w-96"
        >
            <slot name="sidebar" />
        </aside>
    </div>
</template>

<script>
export default {
    name: "TwoCol",
};
</script>
