<template>
    <DynamicTabs :tabs="tabs" />
</template>

<script>
import DynamicTabs from "./DynamicTabs";

export default {
    name: "TeamTabs",
    components: { DynamicTabs },
    computed: {
        /**
         * The tabs
         * @type {Array}
         */
        tabs() {
            return [
                {
                    text: "employees",
                    route: { name: "employee.index" },
                },
                {
                    text: "groups",
                    route: { name: "group.index" },
                },
            ];
        },
    },
};
</script>
