<template>
    <div class="relative flex h-full flex-col overflow-hidden">
        <Alert :error="error" />
        <div class="w-full bg-slate-100 px-4 pb-4 pt-0.5">
            <div
                class="group flex items-center rounded-lg border border-slate-200 bg-white text-sm leading-5 ring-[0.5px] ring-transparent transition-all duration-300 focus-within:border-indigo-500 focus-within:bg-white focus-within:ring-indigo-500 hover:border-indigo-500 hover:ring-indigo-500"
            >
                <div class="flex items-center pl-3">
                    <Icon
                        :icon="IconEnums.SEARCH"
                        class="h-5 w-5 text-slate-400"
                    />
                    <Icon
                        v-if="query"
                        :icon="IconEnums.CLOSE"
                        @click.native="clearSearch"
                        class="h-5 w-5 cursor-pointer text-slate-400"
                    />
                </div>
                <div class="flex flex-1">
                    <input
                        class="w-full border-0 bg-transparent py-2 pr-3 outline-none focus:outline-none focus:ring-0"
                        v-model="query"
                        :placeholder="$t('settings.simulation.services.search')"
                    />
                </div>
            </div>
        </div>
        <div
            class="flex h-full flex-col overflow-y-auto border-t border-slate-200 bg-white"
        >
            <ContentLoading :loading="loading">
                <div v-if="query">
                    <div class="bg-slate-50 px-4 py-2">
                        <p
                            v-if="query"
                            class="text-sm font-medium text-slate-800"
                        >
                            {{
                                $t("settings.simulation.services.search_for", {
                                    query: query,
                                })
                            }}
                        </p>
                    </div>
                    <div class="grid grid-cols-1 gap-4 p-4 sm:grid-cols-2">
                        <CampaignService
                            v-for="service in filteredList"
                            :key="service.id"
                            :service="service"
                            @input="onInput"
                        />
                    </div>
                </div>
                <div v-else>
                    <div v-for="group in serviceGroups" :key="group.category">
                        <div class="bg-slate-50 px-4 py-2">
                            <p class="text-sm font-medium text-slate-800">
                                {{
                                    $t(
                                        "settings.simulation.services.category." +
                                            group.category
                                    )
                                }}
                            </p>
                        </div>
                        <div class="grid grid-cols-1 gap-4 p-4 sm:grid-cols-2">
                            <CampaignService
                                v-for="service in group.data"
                                :key="service.id"
                                :service="service"
                                @input="onInput"
                            />
                        </div>
                    </div>
                </div>
            </ContentLoading>
        </div>
        <div
            class="flex flex-row items-center justify-between border-t border-slate-200 bg-slate-100 px-6 py-4"
        >
            <p class="text-sm font-medium text-slate-800">
                {{
                    $t("settings.simulation.services.services_activated", {
                        count: countSelectedServices,
                    })
                }}
            </p>
            <FormButton
                type="submit"
                @click="handleSave"
                :loading="formLoading"
                >{{ $t("settings.simulation.services.update") }}</FormButton
            >
        </div>
    </div>
</template>

<script>
import { WorkspaceStoreNamespacedTypes } from "@/store/modules/workspace";

import Icon from "@/components/icons/BaseIcon";
import { IconEnums } from "@/utils/icons";

import FormButton, {
    ButtonVariants,
} from "@/components/forms/fields/FormButton";
import Alert from "@/components/ui/Alert";
import ContentLoading from "@/components/ui/ContentLoading";
import CampaignService from "@/components/display/campaign/CampaignService";

export default {
    components: {
        CampaignService,
        ContentLoading,
        Icon,
        Alert,
        FormButton,
    },
    data: () => ({
        ButtonVariants,
        IconEnums,
        query: "",
        formLoading: false,
    }),
    computed: {
        /**
         * @type {Boolean}
         */
        loading() {
            return this.$store.getters[
                WorkspaceStoreNamespacedTypes.getters.WORKSPACE_LOADING
            ]();
        },

        /**
         * The error state of the API request.
         * @type {Object}
         */
        error() {
            return this.$store.getters[
                WorkspaceStoreNamespacedTypes.getters.WORKSPACE_ERROR
            ]();
        },

        /**
         * @type {Array}
         */
        selectedServices() {
            return this.$store.getters[
                WorkspaceStoreNamespacedTypes.getters.CAMPAIGN_SELECTED_SERVICES
            ]();
        },

        /**
         * @type {Number}
         */
        countSelectedServices() {
            return this.selectedServices.length;
        },

        /**
         * @type {Array}
         */
        serviceGroups() {
            return this.$store.getters[
                WorkspaceStoreNamespacedTypes.getters.CAMPAIGN_SERVICES
            ]();
        },

        /**
         * @type {Array}
         */
        filteredList() {
            let list = this.serviceGroups.map((group) => group.data).flat();

            return list.filter((service) =>
                service.name.toLowerCase().includes(this.query.toLowerCase())
            );
        },
    },
    methods: {
        handleSave() {
            this.formLoading = true;
            this.$store
                .dispatch(
                    WorkspaceStoreNamespacedTypes.actions
                        .UPDATE_CAMPAIGN_SERVICES_SETTINGS
                )
                .then(() => {
                    this.$emit("saved");
                    this.formLoading = false;
                });
        },
        onInput(service) {
            this.$store.commit(
                WorkspaceStoreNamespacedTypes.mutations
                    .UPDATE_CAMPAIGN_SELECTED_SERVICES,
                service
            );
        },
        clearSearch() {
            this.query = "";
        },
    },
    mounted() {
        this.$store.dispatch(
            WorkspaceStoreNamespacedTypes.actions.GET_CAMPAIGN_SERVICES
        );
    },
};
</script>
