<template>
    <div class="avatar flex-shrink-0" :class="sizeClass">
        <img
            v-if="image"
            class="rounded-full"
            :class="sizeClass"
            :src="image"
            :alt="name"
        />
        <svg
            v-else
            class="rounded-full"
            :class="sizeClass"
            viewBox="0 0 18 18"
            xmlns="http://www.w3.org/2000/svg"
        >
            <g fill="none" fill-rule="evenodd">
                <rect
                    fill="#F2F5F9"
                    fill-rule="nonzero"
                    width="18"
                    height="18"
                    rx="9"
                />
                <path
                    d="M3.286 15.954v-.048a4.286 4.286 0 0 1 4.206-4.205H10.508a4.286 4.286 0 0 1 4.206 4.205v.048A8.963 8.963 0 0 1 9 18c-2.17 0-4.16-.768-5.714-2.046ZM5.972 7.07a3.143 3.143 0 1 1 6.286 0 3.143 3.143 0 0 1-6.286 0Z"
                    fill="#B0BBCE"
                />
                <rect
                    stroke-opacity=".04"
                    stroke="#000"
                    stroke-width="1.1"
                    x=".55"
                    y=".55"
                    width="16.9"
                    height="16.9"
                    rx="8.45"
                />
            </g>
        </svg>
    </div>
</template>

<script>
export const SizeVariants = {
    BIG: "big",
    DEFAULT: "default",
};

import { initials } from "@/utils/filters";

export default {
    name: "AvatarItem",
    filters: {
        initials,
    },
    props: {
        size: {
            type: String,
            default: SizeVariants.DEFAULT,
            validator(type) {
                return Object.values(SizeVariants).includes(type);
            },
        },
        image: {
            type: String,
            default: "",
        },
        name: {
            type: String,
            required: true,
        },
    },
    computed: {
        sizeClass() {
            if (this.size === SizeVariants.BIG) {
                return "size-10";
            }

            return "size-8";
        },
    },
};
</script>
