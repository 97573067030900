<template>
    <div class="card flex flex-col space-y-4 p-4">
        <div class="flex flex-row justify-between">
            <div class="flex flex-col">
                <h3
                    class="mb-1 font-medium leading-tight text-slate-500"
                    v-text="title"
                ></h3>
                <p class="text-xs font-medium text-slate-400" v-text="text"></p>
            </div>
            <LoadingSpinner v-if="loading" class="size-5" />
        </div>
        <div class="flex flex-col space-y-4 rounded-xl border p-4">
            <div class="flex items-center justify-between">
                <div class="inline-flex w-1/4 items-center">
                    <span
                        class="me-1.5 inline-block h-2.5 w-2.5 flex-shrink-0 rounded-sm bg-red-500"
                    ></span>
                    <span
                        class="text-xs font-semibold text-slate-500"
                        v-text="
                            $t('statistics.factor.indicator.bad').toString()
                        "
                    ></span>
                </div>
                <div class="inline-flex w-1/4 items-center">
                    <span
                        class="me-1.5 inline-block h-2.5 w-2.5 flex-shrink-0 rounded-sm bg-orange-500"
                    ></span>
                    <span
                        class="text-xs font-semibold text-slate-500"
                        v-text="
                            $t('statistics.factor.indicator.average').toString()
                        "
                    ></span>
                </div>
                <div class="inline-flex w-1/4 items-center">
                    <span
                        class="me-1.5 inline-block h-2.5 w-2.5 flex-shrink-0 rounded-sm bg-yellow-300"
                    ></span>
                    <span
                        class="text-xs font-semibold text-slate-500"
                        v-text="
                            $t('statistics.factor.indicator.good').toString()
                        "
                    ></span>
                </div>
                <div class="inline-flex w-1/4 items-center">
                    <span
                        class="me-1.5 inline-block h-2.5 w-2.5 flex-shrink-0 rounded-sm bg-teal-400"
                    ></span>
                    <span
                        class="text-xs font-semibold text-slate-500"
                        v-text="
                            $t(
                                'statistics.factor.indicator.excellent'
                            ).toString()
                        "
                    ></span>
                </div>
            </div>
            <div class="relative">
                <div
                    class="flex h-2.5 w-full overflow-hidden rounded-full bg-slate-200"
                >
                    <div
                        class="flex w-full flex-col justify-center overflow-hidden whitespace-nowrap bg-gradient-to-r from-red-500 via-yellow-400 to-teal-400 text-center text-xs text-white"
                        role="progressbar"
                        :aria-valuenow="value"
                        aria-valuemin="0"
                        aria-valuemax="100"
                    ></div>
                </div>
                <div
                    :style="{ left: `calc(${value}% - 0.5rem)` }"
                    :class="[
                        value < 25
                            ? 'bg-red-500'
                            : value < 50
                            ? 'bg-orange-500'
                            : value < 75
                            ? 'bg-yellow-400'
                            : 'bg-teal-400',
                        'absolute top-1/2 h-5 w-2 -translate-y-1/2 transform rounded-full border-2 border-white transition-all duration-300 ease-in-out',
                    ]"
                ></div>
            </div>
        </div>
        <div class="rounded-xl bg-slate-100 p-4">
            <h2 class="mb-4 text-sm font-semibold text-slate-800">
                {{ $t("statistics.factor.title") }}
            </h2>
            <ul class="space-y-4">
                <li
                    v-for="(factor, index) in factors"
                    :key="title + index"
                    class="flex grow items-center justify-between gap-x-2"
                >
                    <span
                        class="text-sm font-medium text-slate-600"
                        v-text="factor.title"
                    ></span>
                    <span
                        :class="{
                            'bg-slate-200 text-slate-600': loading,
                            'bg-red-200 text-red-600':
                                factor.failure && !loading,
                            'bg-teal-200 text-teal-800':
                                !factor.failure && !loading,
                        }"
                        class="rounded-md px-1.5 py-0.5 text-center text-xs font-medium"
                        >{{ factor.value | formatNumber }}</span
                    >
                </li>
            </ul>
            <p v-if="preview" class="mt-4 text-center">
                <span
                    class="inline-flex cursor-pointer items-center justify-center gap-x-1 rounded-md px-1.5 py-1 text-xs font-medium text-slate-600 hover:bg-white hover:text-slate-800 focus:bg-white"
                    @click="$emit('preview')"
                >
                    {{ $t("statistics.factor.preview") }}
                    <svg
                        class="h-3.5 w-3.5 flex-shrink-0"
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        stroke="currentColor"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                    >
                        <path d="m9 18 6-6-6-6" />
                    </svg>
                </span>
            </p>
        </div>
    </div>
</template>

<script>
import { formatNumber } from "@/utils/number";
import LoadingSpinner from "@/components/ui/LoadingSpinner";

export default {
    name: "ScoreCard",
    components: { LoadingSpinner },
    props: {
        title: {
            type: String,
            default: "",
        },
        text: {
            type: String,
            default: "",
        },
        loading: {
            type: Boolean,
            required: false,
            default: false,
        },
        value: {
            type: Number,
            default: 0,
        },
        factors: {
            type: Array,
            default: () => [],
        },
        preview: {
            type: Boolean,
            required: false,
            default: false,
        },
    },
    filters: {
        formatNumber,
    },
};
</script>
