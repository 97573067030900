<template>
    <div class="flex items-center space-x-4 px-4 py-2.5">
        <p class="text-sm font-medium text-slate-500" v-text="title"></p>
        <div class="flex min-w-0 flex-grow items-center justify-end">
            <slot v-if="hasContentInSlot"></slot>
            <p
                v-else
                class="block truncate text-sm font-medium leading-6 text-slate-900"
                v-text="text"
            ></p>
        </div>
    </div>
</template>

<script>
export default {
    name: "ListItem",
    props: {
        title: {
            type: String,
            required: true,
        },
        text: {
            type: [String, Number, null],
            required: false,
        },
    },
    computed: {
        hasContentInSlot() {
            return !!(this.$slots.default && this.$slots.default[0]);
        },
    },
};
</script>
