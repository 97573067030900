<template>
    <ValidationObserver ref="AutomationSettingsForm" v-slot="{ passes }">
        <form @submit.prevent="passes(updateAutomation)">
            <Alert :error="error" class="mb-4" />
            <div
                class="flex flex-col items-stretch justify-evenly xl:flex-row xl:space-x-4"
            >
                <FormFieldSet class="w-full xl:w-1/2">
                    <FormInput
                        v-model="form.name"
                        type="text"
                        rules="required"
                        name="name"
                    />
                    <FormSelect
                        v-model="form.trigger"
                        name="trigger"
                        rules="required"
                    >
                        <option
                            v-for="trigger in triggers"
                            :key="trigger"
                            :value="trigger"
                        >
                            {{ $t(`automation.triggers.${trigger}`) }}
                        </option>
                    </FormSelect>
                    <FormDateInput
                        v-if="hasDateTrigger"
                        :value="form.date"
                        name="start_date"
                        rules="required"
                        @input="handleDateSelect"
                    />
                    <FormSelect
                        v-if="hasTagTrigger"
                        v-model="form.tag_id"
                        rules="required"
                        name="tag"
                    >
                        <option
                            v-for="tag in tags"
                            :key="tag.id"
                            :value="tag.id"
                        >
                            {{ tag.name }}
                        </option>
                    </FormSelect>
                    <FormFieldOutline>
                        <FormSelect
                            v-model="form.group_id"
                            name="group"
                            class="mb-4"
                        >
                            <option
                                v-for="group in groups"
                                :key="group.id"
                                :value="group.id"
                            >
                                {{ group.name }}
                            </option>
                        </FormSelect>
                        <FormToggle v-model="use_segment" name="use_segment" />
                        <div v-show="use_segment" class="mt-4 border-t pt-4">
                            <FormSelect
                                v-if="hasSegments"
                                v-model="form.segment_id"
                                name="segment"
                            >
                                <option
                                    v-for="segment in segments"
                                    :key="segment.id"
                                    :value="segment.id"
                                >
                                    {{ segment.name }}
                                </option>
                            </FormSelect>
                            <p v-else class="text-sm text-slate-500">
                                {{ $t("automation.no_segment_found") }}.
                                <router-link
                                    v-if="form.group_id"
                                    class="text-indigo-600 underline"
                                    :to="{
                                        name: 'group.single',
                                        params: { id: form.group_id },
                                    }"
                                    >{{
                                        $t("automation.create_segment")
                                    }}</router-link
                                >
                            </p>
                        </div>
                        <div
                            class="mt-4 flex flex-col rounded-xl border p-3 text-xs"
                            :class="{
                                'border-rose-500/20 bg-rose-50/50 text-rose-800':
                                    audienceSize === 0,
                                'border-amber-500/20 bg-amber-50/50 text-amber-800':
                                    audienceSize > 0,
                            }"
                        >
                            <p>
                                {{
                                    $t("automation.audience", {
                                        count: audienceSize,
                                    })
                                }}.
                            </p>
                        </div>
                    </FormFieldOutline>
                    <FormFieldOutline v-if="show_advanced_settings">
                        <FormToggle
                            v-model="form.repeatable_assignments_enabled"
                            name="repeatable_assignments_enabled"
                        />
                    </FormFieldOutline>
                    <FormFieldOutline v-if="show_advanced_settings">
                        <FormToggle
                            v-model="form.repeat_enabled"
                            name="repeat_enabled"
                        />
                        <div
                            v-if="form.repeat_enabled"
                            class="mt-4 border-t pt-4"
                        >
                            <FormToggle
                                v-model="form.repeat_only_after_halt"
                                name="repeat_only_after_halt"
                            />
                        </div>
                    </FormFieldOutline>
                    <div class="flex w-full flex-col items-start justify-start">
                        <FormButton
                            :variant="ButtonVariants.SMALL"
                            :icon="
                                show_advanced_settings
                                    ? IconEnums.CLOSE
                                    : IconEnums.SETTINGS
                            "
                            @click.native="
                                show_advanced_settings = !show_advanced_settings
                            "
                        >
                            {{
                                show_advanced_settings
                                    ? $t("automation.hide_advanced_settings")
                                    : $t("automation.show_advanced_settings")
                            }}
                        </FormButton>
                    </div>
                </FormFieldSet>
                <FormFieldSet class="w-full xl:w-1/2">
                    <FormFieldOutline>
                        <FormToggle
                            v-model="form.notification_flow_enabled"
                            name="notification_flow_enabled"
                        />
                        <div
                            v-if="form.notification_flow_enabled"
                            class="mt-4 border-t pt-4"
                        >
                            <FormToggle
                                v-model="form.notification_prelude_enabled"
                                name="notification_prelude_enabled"
                                class="mb-4"
                            />
                            <FormSelect
                                v-model="form.notification_frequency"
                                name="notification_frequency"
                            >
                                <option
                                    v-for="frequency in frequencies"
                                    :key="frequency"
                                    :value="frequency"
                                >
                                    {{
                                        $t(`automation.frequency.${frequency}`)
                                    }}
                                </option>
                            </FormSelect>
                        </div>
                    </FormFieldOutline>
                    <FormFieldOutline>
                        <FormToggle
                            v-model="form.relative_deadlines_enabled"
                            name="relative_deadlines_enabled"
                            :disabled="form.repeatable_assignments_enabled"
                        />
                        <div
                            v-if="form.repeatable_assignments_enabled"
                            class="mt-4 flex flex-col rounded-xl border border-amber-500/20 bg-amber-50/50 p-3 text-xs text-amber-800"
                        >
                            {{
                                $t("automation.repeatable_assignments_enabled")
                            }}
                        </div>
                    </FormFieldOutline>
                    <FormFieldOutline>
                        <FormToggle
                            v-model="form.relative_progression_enabled"
                            name="relative_progression_enabled"
                        />
                    </FormFieldOutline>
                    <div class="col-span-6 flex justify-end">
                        <FormButton type="submit" :loading="loading">
                            {{ $t("automation.save_settings") }}
                        </FormButton>
                    </div>
                </FormFieldSet>
            </div>
        </form>
    </ValidationObserver>
</template>

<script>
import { AutomationStoreNamespacedTypes } from "@/store/modules/automation";

import { getProperty } from "@/utils/object";
import { formatDate } from "@/utils/date";

import { ValidationObserver } from "vee-validate";
import { IconEnums } from "@/utils/icons";
import Alert from "@/components/ui/Alert";
import FormInput from "@/components/forms/fields/FormInput";
import FormFieldSet from "@/components/forms/fields/FormFieldSet";
import FormButton, {
    ButtonVariants,
} from "@/components/forms/fields/FormButton";
import FormSelect from "@/components/forms/fields/FormSelect";
import FormToggle from "@/components/forms/fields/FormToggle";
import FormDateInput from "@/components/forms/fields/FormDateInput";
import FormFieldOutline from "@/components/forms/fields/FormFieldOutline";

export default {
    name: "AutomationSettingsForm",
    components: {
        FormFieldOutline,
        FormDateInput,
        FormToggle,
        FormSelect,
        FormButton,
        FormFieldSet,
        Alert,
        ValidationObserver,
        FormInput,
    },
    data: () => ({
        ButtonVariants,
        IconEnums,
        loading: false,
        use_segment: false,
        show_advanced_settings: false,
        form: {
            name: null,
            trigger: null,
            repeat_enabled: false,
            repeat_only_after_halt: true,
            relative_deadlines_enabled: true,
            relative_progression_enabled: false,
            repeatable_assignments_enabled: false,
            notification_flow_enabled: true,
            notification_prelude_enabled: true,
            notification_frequency: "normal",
            group_id: null,
            segment_id: null,
            date: null,
            tag_id: null,
        },
    }),
    watch: {
        "form.group_id": function (newValue, oldValue) {
            if (oldValue === null) {
                return;
            }

            if (newValue !== oldValue) {
                this.use_segment = false;
                this.form.segment_id = null;
            }
        },
        use_segment: function (newValue) {
            if (!newValue) {
                this.form.segment_id = null;
            }
        },
        "form.repeatable_assignments_enabled": function (newValue) {
            if (newValue) {
                this.form.relative_deadlines_enabled = true;
            }
        },
    },
    computed: {
        /**
         * @type {Array}
         */
        automation() {
            return this.$store.getters[
                AutomationStoreNamespacedTypes.getters.AUTOMATION
            ]();
        },

        /**
         * @type {Object}
         */
        error() {
            return this.$store.getters[
                AutomationStoreNamespacedTypes.getters.AUTOMATION_ERROR
            ]();
        },

        /**
         * @type {Array}
         */
        groups() {
            return this.$store.getters[
                AutomationStoreNamespacedTypes.getters.GROUPS
            ]();
        },

        /**
         * @type {Array}
         */
        segments() {
            return this.$store.getters[
                AutomationStoreNamespacedTypes.getters.SEGMENTS
            ](getProperty(this.form, "group_id"));
        },

        /**
         * @type {Boolean}
         */
        hasSegments() {
            return this.segments.length > 0;
        },

        /**
         * @type {Array}
         */
        triggers() {
            return [
                "date_trigger",
                "employee_created_trigger",
                "employee_added_to_audience_trigger",
                "simulation_link_clicked_trigger",
                "tag_added_trigger",
                "tag_removed_trigger",
            ];
        },

        frequencies() {
            return ["normal", "loose", "intense"];
        },

        /**
         * @type {Boolean}
         */
        hasDateTrigger() {
            return this.form.trigger === "date_trigger";
        },

        /**
         * @type {Array}
         */
        tags() {
            return this.$store.getters[
                AutomationStoreNamespacedTypes.getters.TAGS
            ]();
        },

        /**
         * @type {Boolean}
         */
        hasTagTrigger() {
            return (
                this.form.trigger === "tag_added_trigger" ||
                this.form.trigger === "tag_removed_trigger"
            );
        },

        /**
         * @type {Number}
         */
        audienceSize() {
            if (this.form.segment_id === null && this.form.group_id === null) {
                return 0;
            }

            if (this.form.segment_id !== null && this.segments.length > 0) {
                return this.segments.find(
                    (segment) => segment.id === this.form.segment_id
                ).employees_count;
            }

            if (this.form.group_id !== null && this.groups.length > 0) {
                return this.groups.find(
                    (group) => group.id === this.form.group_id
                ).employees_count;
            }

            return 0;
        },
    },
    methods: {
        handleDateSelect(date) {
            this.form.date = date;
        },

        /**
         * Sends authentication credentials to the server.
         */
        updateAutomation() {
            this.loading = true;
            if (!this.use_segment) {
                this.form.segment_id = null;
            }
            this.$store
                .dispatch(
                    AutomationStoreNamespacedTypes.actions.UPDATE_AUTOMATION,
                    { id: this.$route.params.id, data: this.form }
                )
                .then(() => {
                    this.loading = false;
                    if (!this.error) {
                        this.$emit("stored");
                    }
                });
        },
    },
    created() {
        this.form = {
            name: getProperty(this.automation, "name"),
            trigger: getProperty(
                this.automation,
                "trigger.type",
                "employee_created_trigger"
            ),
            repeat_enabled: getProperty(
                this.automation,
                "repeat_enabled",
                false
            ),
            repeat_only_after_halt: getProperty(
                this.automation,
                "repeat_only_after_halt",
                true
            ),
            relative_deadlines_enabled: getProperty(
                this.automation,
                "relative_deadlines_enabled",
                true
            ),
            relative_progression_enabled: getProperty(
                this.automation,
                "relative_progression_enabled",
                false
            ),
            repeatable_assignments_enabled: getProperty(
                this.automation,
                "repeatable_assignments_enabled",
                false
            ),
            notification_flow_enabled: getProperty(
                this.automation,
                "notification_flow_enabled",
                true
            ),
            notification_prelude_enabled: getProperty(
                this.automation,
                "notification_prelude_enabled",
                true
            ),
            notification_frequency: getProperty(
                this.automation,
                "notification_frequency",
                "normal"
            ),
            group_id: getProperty(this.automation, "group_id"),
            segment_id: getProperty(this.automation, "segment_id"),
        };

        if (this.form.trigger === "date_trigger") {
            let date = getProperty(this.automation, "trigger.date");
            if (date) {
                this.form.date = formatDate(date);
            }
        }

        if (
            this.form.trigger === "tag_added_trigger" ||
            this.form.trigger === "tag_removed_trigger"
        ) {
            this.form.tag_id = getProperty(this.automation, "trigger.tag");
        }

        if (this.form.segment_id !== null) {
            this.use_segment = true;
        }
    },
};
</script>
