<template>
    <WorkspaceSettingsPage>
        <Prompt :active="showPrompt" @close="showPrompt = false"
            ><h3 slot="header">
                {{ $t("settings.workspace.users.send_invite") }}
            </h3>
            <div slot="content" class="-mb-3">
                <CreateUserForm @saved="handleUserSaved" /></div
        ></Prompt>
        <div class="card mb-4">
            <CardHeading
                :title="$t('settings.workspace.locale.heading').toString()"
                :text="$t('settings.workspace.locale.text').toString()"
            />
            <div class="p-4">
                <FormSelect
                    v-model="fakeLocale"
                    name="locale"
                    @input="handleChoice"
                >
                    <option
                        v-for="locale in locales"
                        :key="locale"
                        :value="locale"
                    >
                        {{ $t("language." + locale) }}
                    </option>
                </FormSelect>
            </div>
        </div>
        <div class="card mb-4">
            <CardHeading
                :title="$t('settings.workspace.heading').toString()"
                :text="$t('settings.workspace.text').toString()"
            />

            <div class="p-4">
                <WorkspaceSettingsForm @saved="handleSettingsSaved" />
            </div>
        </div>
        <div class="card">
            <CardHeading
                :title="$t('settings.workspace.users.heading').toString()"
                :text="$t('settings.workspace.users.text').toString()"
            >
                <FormButton
                    @click="showPrompt = true"
                    :variant="ButtonVariants.SECONDARY"
                    >{{ $t("settings.workspace.users.add_user") }}</FormButton
                >
            </CardHeading>
            <UserTable :rows="users" :loading="loading" :error="null" />
        </div>
    </WorkspaceSettingsPage>
</template>

<script>
import { WorkspaceStoreNamespacedTypes } from "@/store/modules/workspace";
import { LocaleStoreNamespacedTypes } from "@/store/modules/locale";

import { getProperty } from "@/utils/object";

import Bus from "@/bus";
import WorkspaceSettingsForm from "@/components/forms/WorkspaceSettingsForm";
import WorkspaceSettingsPage from "@/components/layout/WorkspaceSettingsPage";
import FormButton, {
    ButtonVariants,
} from "@/components/forms/fields/FormButton";
import UserTable from "@/components/display/settings/UserTable";
import Prompt from "@/components/ui/Prompt";
import CreateUserForm from "@/components/forms/CreateUserForm";
import CardHeading from "@/components/ui/CardHeading";
import FormSelect from "@/components/forms/fields/FormSelect";
import { localeDa, localeEn } from "@/utils/enums";

export default {
    name: "WorkspaceSettings",
    components: {
        FormSelect,
        CardHeading,
        CreateUserForm,
        Prompt,
        UserTable,
        FormButton,
        WorkspaceSettingsPage,
        WorkspaceSettingsForm,
    },
    data: () => ({
        showPrompt: false,
        ButtonVariants,
        fakeLocale: "en_US",
    }),
    computed: {
        /**
         * @type {Boolean}
         */
        loading() {
            return this.$store.getters[
                WorkspaceStoreNamespacedTypes.getters.WORKSPACE_LOADING
            ]();
        },

        /**
         * @type {Object}
         */
        workspace() {
            return this.$store.getters[
                WorkspaceStoreNamespacedTypes.getters.WORKSPACE
            ]();
        },

        /**
         * @type {Array}
         */
        users() {
            return getProperty(this.workspace, "users", []);
        },

        /**
         * Current locale.
         * @type {String}
         */
        locale() {
            return this.$store.getters[
                LocaleStoreNamespacedTypes.getters.LOCALE
            ]();
        },

        /**
         * @type {Array}
         */
        locales() {
            return [localeDa, localeEn];
        },
    },
    methods: {
        handleChoice(item) {
            if (item !== this.locale) {
                this.$store.dispatch(
                    LocaleStoreNamespacedTypes.actions.UPDATE_LOCALE,
                    item
                );
            }
        },
        handleSettingsSaved() {
            this.$store
                .dispatch(WorkspaceStoreNamespacedTypes.actions.GET_WORKSPACE, {
                    force: true,
                })
                .then(() => {
                    Bus.$emit("flash-message", {
                        text: this.$t("settings.saved"),
                        type: "success",
                    });
                });
        },
        handleUserSaved() {
            this.$store
                .dispatch(WorkspaceStoreNamespacedTypes.actions.GET_WORKSPACE, {
                    force: true,
                })
                .then(() => {
                    Bus.$emit("flash-message", {
                        text: this.$t("settings.domains.domain_created"),
                        type: "success",
                    });
                    this.showPrompt = false;
                });
        },
    },
    created() {
        this.fakeLocale = this.locale;
    },
};
</script>
