<template>
    <div
        v-if="hasRecords"
        class="mb-4 overflow-hidden rounded-xl border border-slate-200"
    >
        <div
            class="flex items-center gap-x-4 border-b border-slate-900/5 bg-slate-50 px-4 py-3"
        >
            <div class="text-sm font-medium leading-6 text-slate-900">
                {{ $t("threats.analysis.sender.mx") }}
            </div>
        </div>
        <dl class="-my-3 divide-y divide-slate-100 px-6 py-4 text-sm leading-6">
            <div
                v-for="record in mx_records"
                :key="record.data + record.priority"
                class="flex items-center justify-between gap-x-4 py-3"
            >
                <dt class="text-slate-500" v-text="record.name"></dt>
                <dd
                    class="flex flex-row items-center justify-center text-slate-700"
                >
                    {{ record.data }}
                    <span
                        class="text-xxs ml-3 inline-flex flex-shrink items-center rounded border bg-slate-50 px-1.5 font-bold"
                        v-text="record.priority"
                    ></span>
                </dd>
            </div>
        </dl>
    </div>
</template>

<script>
export default {
    name: "DnsRecordsPreview",
    props: {
        mx_records: {
            type: Array,
            default: () => [],
        },
    },
    computed: {
        hasRecords() {
            if (!this.mx_records) return false;

            return this.mx_records.length > 0;
        },
    },
};
</script>
