<template>
    <div class="flex flex-grow flex-col items-center justify-center">
        <LoadingSpinner />
        <p class="mt-4 font-medium text-slate-600">
            {{ $t("oauth.loading") }}...
        </p>
    </div>
</template>

<script>
import { WorkspaceStoreNamespacedTypes } from "@/store/modules/workspace";
import { IntegrationStoreNamespacedTypes } from "@/store/modules/integration";

import { getProperty } from "@/utils/object";

import LoadingSpinner from "@/components/ui/LoadingSpinner";
import Bus from "@/bus";
import { redirectTo } from "@/router";

export default {
    name: "OauthPage",
    components: { LoadingSpinner },
    computed: {
        /**
         * The authentication state for the user model
         * @type {Object}
         */
        workspace() {
            return this.$store.getters[
                WorkspaceStoreNamespacedTypes.getters.WORKSPACE
            ]();
        },

        /**
         * The onboarded state for the user model
         * @type {Boolean}
         */
        onboarded() {
            return getProperty(this.workspace, "onboarded", false);
        },

        /**
         * @type {Object}
         */
        error() {
            return this.$store.getters[
                IntegrationStoreNamespacedTypes.getters.INTEGRATION_ERROR
            ]();
        },
    },
    methods: {
        saveGrant() {
            this.$store
                .dispatch(IntegrationStoreNamespacedTypes.actions.SAVE_GRANT, {
                    code: this.$route.query.code,
                    state: this.$route.query.state,
                    provider: this.$route.params.provider,
                })
                .then(() => {
                    this.redirect();
                    if (this.error) {
                        Bus.$emit("flash-message", {
                            text: this.$t("oauth.error"),
                            type: "error",
                        });
                    } else {
                        Bus.$emit("flash-message", {
                            text: this.$t("oauth.success"),
                            type: "success",
                        });
                    }
                });
        },
        redirect() {
            this.$store
                .dispatch(WorkspaceStoreNamespacedTypes.actions.GET_WORKSPACE, {
                    force: true,
                })
                .then(() => {
                    redirectTo("settings.integrations");
                });
        },
    },
    created() {
        if (this.$route.query.code && this.$route.query.state) {
            this.saveGrant();
        } else {
            Bus.$emit("flash-message", {
                text: this.$t("oauth.error"),
                type: "error",
            });
            this.redirect();
        }
    },
};
</script>
