<template>
    <div class="relative flex h-full flex-col overflow-y-auto px-4 pb-4">
        <div class="mb-4 flex items-center justify-center">
            <ProgressIndicator :current="step" :total="3" />
        </div>
        <div class="flex flex-col space-y-4 rounded-xl bg-white p-4">
            <ValidationObserver
                v-if="step === 0"
                ref="metaForm"
                v-slot="{ passes }"
            >
                <form @submit.prevent="passes(storeMeta)">
                    <FormFieldSet>
                        <Alert :error="error" />
                        <FormInput
                            v-model="form.title"
                            type="text"
                            rules="required"
                            name="title"
                        />
                        <FormTextarea
                            v-model="form.description"
                            rules="required"
                            name="description"
                        />
                        <FormSelect
                            v-model="form.locale"
                            type="text"
                            name="locale"
                            rules="required"
                        >
                            <option
                                v-for="locale in locales"
                                :key="locale.value"
                                :value="locale.value"
                            >
                                {{ $t("locale." + locale.text) }}
                            </option>
                        </FormSelect>
                        <div class="flex justify-end">
                            <FormButton type="submit" :loading="loading">
                                {{ $t("forms.buttons.create_field") }}
                            </FormButton>
                        </div>
                    </FormFieldSet>
                </form>
            </ValidationObserver>
            <div
                v-else-if="step === 1"
                class="relative flex h-64 w-full flex-col items-center justify-center rounded-lg border-2 border-dashed border-slate-300 bg-slate-50 hover:bg-slate-100"
            >
                <div
                    class="relative flex flex-col items-center justify-center pb-6 pt-5"
                >
                    <Icon
                        class="mb-3 h-10 w-10 text-slate-500"
                        :icon="IconEnums.UPLOAD"
                    />
                    <p class="mb-2 text-sm text-slate-500">
                        <span class="font-semibold">{{
                            $t("settings.content.import.button")
                        }}</span>
                        {{ $t("settings.content.import.drop") }}
                    </p>
                    <p class="text-xs text-slate-500">
                        {{ $t("settings.content.import.formats") }}
                    </p>
                </div>
                <input
                    id="file-upload"
                    name="file-upload"
                    type="file"
                    class="absolute left-0 top-0 h-full w-full cursor-pointer opacity-0"
                    :disabled="loading"
                    @change="uploadVideo"
                    accept=".xlsx, .xls, .csv"
                />
            </div>
            <div class="lol" v-else>Saved</div>
        </div>
    </div>
</template>

<script>
import { WorkspaceStoreNamespacedTypes } from "@/store/modules/workspace";

import { ValidationObserver } from "vee-validate";
import Alert from "@/components/ui/Alert";
import FormInput from "@/components/forms/fields/FormInput";
import FormButton from "@/components/forms/fields/FormButton";
import FormFieldSet from "@/components/forms/fields/FormFieldSet";
import { locales } from "@/utils/enums";
import FormSelect from "@/components/forms/fields/FormSelect";
import FormTextarea from "@/components/forms/fields/FormTextarea";
import Icon from "@/components/icons/BaseIcon";
import { IconEnums } from "@/utils/icons";
import ProgressIndicator from "@/components/ui/ProgressIndicator";
import { getProperty } from "@/utils/object";

export default {
    name: "UploadVideoForm",
    components: {
        ProgressIndicator,
        Icon,
        FormTextarea,
        FormSelect,
        FormFieldSet,
        FormButton,
        Alert,
        ValidationObserver,
        FormInput,
    },
    data: () => ({
        loading: false,
        locales,
        IconEnums,
        step: 0,
        id: null,
        form: {
            title: null,
            description: null,
            locale: locales[0].value,
        },
        file: null,
    }),
    computed: {
        /**
         * @type {Object}
         */
        error() {
            return this.$store.getters[
                WorkspaceStoreNamespacedTypes.getters.VIDEOS_ERROR
            ]();
        },
    },
    methods: {
        /**
         * Sends authentication credentials to the server.
         */
        storeMeta() {
            this.loading = true;
            this.$store
                .dispatch(
                    WorkspaceStoreNamespacedTypes.actions.STORE_VIDEO_META,
                    this.form
                )
                .then((response) => {
                    this.loading = false;
                    if (!this.error) {
                        this.id = getProperty(response, "id");
                        this.step++;
                    }
                });
        },
        uploadVideo() {
            if (!this.id) {
                return this.$store.commit(
                    WorkspaceStoreNamespacedTypes.mutations.SET_VIDEOS_ERROR,
                    { message: "Please save the video first" }
                );
            }

            this.loading = true;
            this.$store
                .dispatch(WorkspaceStoreNamespacedTypes.actions.UPLOAD_VIDEO, {
                    id: this.id,
                    file: this.file,
                })
                .then(() => {
                    this.loading = false;
                    if (!this.error) {
                        this.step++;
                    }
                });
        },
    },
    mounted() {
        this.$store.commit(
            WorkspaceStoreNamespacedTypes.mutations.SET_VIDEOS_ERROR,
            null
        );
    },
};
</script>
