<template>
    <g>
        <path
            d="M187.432 271c-29.538 0-53.568 24.03-53.568 53.568v133.863c0 29.538 24.03 53.568 53.568 53.568C216.97 512 241 487.97 241 458.432V324.568C241 295.03 216.97 271 187.432 271zM103.863 271h-51.86c-28.596 0-51.86 23.265-51.86 51.86 0 28.596 23.265 51.86 51.86 51.86s51.86-23.265 51.86-51.86z"
            fill="#ff4340"
        /><path
            d="M0 187.432C0 216.97 24.03 241 53.568 241h133.863C216.97 241 241 216.97 241 187.432s-24.03-53.568-53.568-53.568H53.568C24.03 133.863 0 157.894 0 187.432zM189.14 103.863H241v-51.86c0-28.596-23.265-51.86-51.86-51.86-28.596 0-51.86 23.265-51.86 51.86s23.264 51.86 51.86 51.86z"
            fill="#36c6ff"
        /><path
            d="M324.568 241c29.538 0 53.568-24.03 53.568-53.568V53.568C378.137 24.03 354.106 0 324.568 0S271 24.03 271 53.568v133.863C271 216.97 295.03 241 324.568 241zM408.137 241h51.86c28.596 0 51.86-23.265 51.86-51.86 0-28.596-23.265-51.86-51.86-51.86s-51.86 23.265-51.86 51.86z"
            fill="#0dc46e"
        /><g fill="#fdcb02"
            ><path
                d="M512 324.568C512 295.03 487.97 271 458.432 271H324.568C295.03 271 271 295.03 271 324.568s24.03 53.568 53.568 53.568h133.863c29.539.001 53.569-24.03 53.569-53.568zM322.86 408.137H271v51.86c0 28.596 23.265 51.86 51.86 51.86 28.596 0 51.86-23.265 51.86-51.86s-23.264-51.86-51.86-51.86z"
        /></g>
    </g>
</template>

<script>
import IconSvgBase from "./IconSvgBase";

export default {
    extends: IconSvgBase,
    name: "IconSlack",
    data: () => ({
        viewBoxHeight: 512,
        viewBoxWidth: 512,
    }),
};
</script>
