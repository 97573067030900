<template>
    <div class="v-snack">
        <Transition name="v-snack-transition">
            <div v-if="message" class="v-snack__wrapper v-snack--top">
                <div class="v-snack__content">
                    <div
                        v-if="message.type === 'error'"
                        class="v-icon bg-red-600 p-1 text-red-50"
                    >
                        <svg
                            class="svg-icon"
                            fill="none"
                            stroke="currentColor"
                            viewBox="0 0 24 24"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                stroke-width="4"
                                d="M6 18L18 6M6 6l12 12"
                            ></path>
                        </svg>
                    </div>
                    <div
                        v-if="message.type === 'success'"
                        class="v-icon bg-indigo-600 p-1 text-indigo-50"
                    >
                        <svg
                            class="svg-icon"
                            fill="none"
                            stroke="currentColor"
                            viewBox="0 0 24 24"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                stroke-width="4"
                                d="M5 13l4 4L19 7"
                            ></path>
                        </svg>
                    </div>
                    <p class="mb-0">{{ message.text }}</p>
                </div>
            </div>
        </Transition>
    </div>
</template>

<script>
import Bus from "@/bus";

export default {
    data: () => ({
        message: null,
    }),
    mounted() {
        let timer;
        Bus.$on("flash-message", (message) => {
            clearTimeout(timer);
            this.message = message;
            timer = setTimeout(() => {
                this.message = null;
            }, 5000);
        });
    },
};
</script>
