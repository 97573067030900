<template>
    <ValidationObserver ref="resetPasswordForm" v-slot="{ passes }">
        <form @submit.prevent="passes(validateResetPassword)">
            <FormFieldSet>
                <Alert :error="error" />
                <FormInput
                    v-model="email"
                    type="email"
                    rules="required"
                    name="email"
                />
                <FormPassword
                    v-model="password"
                    type="password"
                    rules="required|min:8"
                    name="password"
                />
                <FormInput
                    v-model="password_confirmation"
                    type="password"
                    rules="required|min:8"
                    name="password_confirmation"
                />
                <div class="flex justify-end">
                    <FormButton type="submit" :loading="loading">
                        {{ $t("forms.buttons.reset_password") }}
                    </FormButton>
                </div>
            </FormFieldSet>
        </form>
    </ValidationObserver>
</template>

<script>
import { AuthStoreNamespacedTypes } from "@/store/modules/auth";

import { getProperty } from "@/utils/object";

import { ValidationObserver } from "vee-validate";
import Alert from "@/components/ui/Alert";
import FormInput from "@/components/forms/fields/FormInput";
import FormFieldSet from "@/components/forms/fields/FormFieldSet";
import FormButton from "@/components/forms/fields/FormButton";
import FormPassword from "@/components/forms/fields/FormPassword";

export default {
    components: {
        ValidationObserver,
        FormPassword,
        FormButton,
        FormFieldSet,
        Alert,
        FormInput,
    },
    data: () => ({
        email: null,
        password: null,
        password_confirmation: null,
    }),
    computed: {
        /**
         * The loading state of the reset request.
         * @type {boolean}
         */
        loading() {
            return this.$store.getters[
                AuthStoreNamespacedTypes.getters.RESET_PASSWORD_LOADING
            ]();
        },

        /**
         * Errors while processing reset request.
         * @type {Object}
         */
        error() {
            return this.$store.getters[
                AuthStoreNamespacedTypes.getters.RESET_PASSWORD_ERROR
            ]();
        },

        /**
         * Errors while processing reset request.
         * @type {Object}
         */
        token() {
            return getProperty(this.$route.params, "token", null);
        },
    },
    methods: {
        /**
         * Validates the password reset token and stores password.
         */
        validateResetPassword() {
            this.$store
                .dispatch(
                    AuthStoreNamespacedTypes.actions.VALIDATE_RESET_PASSWORD,
                    {
                        token: this.token,
                        email: this.email,
                        password: this.password,
                        password_confirmation: this.password_confirmation,
                    }
                )
                .then(() => {
                    if (!this.error) {
                        this.$emit("reset");
                    }
                });
        },
    },
    mounted() {
        if (this.$route.query.email) {
            this.email = this.$route.query.email;
        }
    },
};
</script>
