<template>
    <div class="h-full overflow-auto px-4 pb-4">
        <div class="card mb-4 p-4">
            <div class="flex w-full flex-row items-center">
                <Avatar
                    :size="SizeVariants.BIG"
                    :image="avatar"
                    :name="full_name"
                />
                <div class="ml-3 flex flex-col">
                    <p class="truncate font-medium leading-tight">
                        {{ full_name }}
                    </p>
                    <p class="text-slate-500">{{ email }}</p>
                </div>
            </div>
            <div class="mt-4 flex flex-col">
                <FormButton
                    :variant="ButtonVariants.SECONDARY"
                    @click.native="goToProfile"
                >
                    {{ $t("employees.preview.profile") }}
                </FormButton>
            </div>
        </div>
        <EmailPreview :email="preview" class="mb-4" height="h-96" />
        <div class="card overflow-hidden">
            <div class="flex w-full items-center justify-between p-4">
                <p class="font-medium leading-tight text-slate-500">
                    {{ $t("simulations.preview.activity") }}
                </p>
            </div>
            <ul role="list" class="space-y-6 px-4 pb-4">
                <li
                    v-for="activity in activities"
                    :key="
                        'activity-' + activity.type + '-' + activity.created_at
                    "
                    class="relative flex gap-x-4"
                >
                    <div
                        class="absolute -bottom-6 left-0 top-0 flex w-6 justify-center"
                    >
                        <div class="w-px bg-slate-200"></div>
                    </div>
                    <div
                        class="relative flex h-6 w-6 flex-none items-center justify-center bg-white"
                    >
                        <div
                            class="h-1.5 w-1.5 rounded-full bg-slate-100 ring-1 ring-slate-300"
                        ></div>
                    </div>
                    <p
                        class="flex-auto py-0.5 text-xs leading-5 text-slate-500"
                    >
                        <span class="font-medium text-slate-900">{{
                            first_name
                        }}</span>
                        {{ $t("simulations.preview." + activity.type) }}
                    </p>
                    <time
                        :datetime="activity.created_at"
                        class="flex-none py-0.5 text-xs leading-5 text-slate-500"
                        >{{ activity.created_at | formatDateTime }}</time
                    >
                </li>
            </ul>
        </div>
        <div class="p-4 text-center">
            <p class="text-xxs text-slate-500">Ref. {{ id }}</p>
        </div>
    </div>
</template>

<script>
import { getProperty } from "@/utils/object";
import { initials } from "@/utils/filters";
import { formatDateTime } from "@/utils/date";
import EmailPreview from "@/components/ui/EmailPreview";
import Avatar, { SizeVariants } from "@/components/shared/Avatar";
import FormButton, {
    ButtonVariants,
} from "@/components/forms/fields/FormButton";

export default {
    name: "SimulationPreview",
    components: { FormButton, Avatar, EmailPreview },
    props: {
        simulation: {
            type: Object,
            required: true,
        },
    },
    filters: {
        initials,
        formatDateTime,
    },
    data: () => ({
        SizeVariants,
        ButtonVariants,
    }),
    computed: {
        id() {
            return getProperty(this.simulation, "sendable_id", null);
        },
        employee() {
            return getProperty(this.simulation, "employee", {
                avatar: null,
                full_name: null,
                email: null,
            });
        },
        employee_id() {
            return getProperty(this.employee, "id", null);
        },
        avatar() {
            return getProperty(this.employee, "avatar", null);
        },
        full_name() {
            return getProperty(this.employee, "full_name", null);
        },
        first_name() {
            return getProperty(this.employee, "first_name", null);
        },
        email() {
            return getProperty(this.employee, "email", null);
        },
        sentAt() {
            return getProperty(this.simulation, "sent_at", null);
        },
        preview() {
            return getProperty(this.simulation, "preview", null);
        },
        events() {
            return getProperty(this.simulation, "events", []);
        },
        activities() {
            let events = structuredClone(this.events);
            // prepend the sent_at event
            events.unshift({
                type: "CampaignSent",
                created_at: this.sentAt,
            });
            return events.sort((a, b) => {
                return new Date(a.created_at) - new Date(b.created_at);
            });
        },
    },
    methods: {
        goToProfile() {
            this.$router
                .push({
                    name: "employee.single.index",
                    params: { id: this.employee_id },
                })
                .then(() => {
                    this.$emit("close");
                });
        },
    },
};
</script>
