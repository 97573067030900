import Vue from "vue";
import VueRouter from "vue-router";
import updatePageTags from "./utils/updatePageTags";
import { getProperty } from "@/utils/object";

const routes = {
    base: "/",
    mode: "history",
    linkExactActiveClass: "active",
    scrollBehavior(to, from, savedPosition) {
        if (savedPosition) {
            return savedPosition;
        } else {
            return { x: 0, y: 0 };
        }
    },
    routes: [
        {
            path: "/",
            name: "login",
            component: require("./views/auth/Login").default,
            meta: {
                auth: false,
                manager: false,
                title: "Sign in - Moxso",
            },
        },
        {
            path: "/register",
            name: "register",
            component: require("./views/auth/Register").default,
            meta: {
                auth: false,
                manager: false,
                title: "Create an account - Moxso",
            },
        },
        {
            path: "/forgot-password",
            name: "forgot.password",
            component: require("./views/auth/ForgotPassword").default,
            meta: {
                auth: false,
                manager: false,
                title: "Reset password - Moxso",
            },
        },
        {
            path: "/reset-password/:token",
            name: "reset.password",
            component: require("./views/auth/ResetPassword").default,
            meta: {
                auth: false,
                manager: false,
                title: "Reset password - Moxso",
            },
        },
        {
            path: "/invite",
            name: "invite",
            component: require("./views/auth/Invite").default,
            meta: {
                auth: false,
                manager: false,
                title: "Accept invite - Moxso",
            },
        },
        {
            path: "/invite/:id",
            name: "invite.id",
            component: require("./views/auth/ValidateInvite").default,
            meta: {
                auth: false,
                manager: false,
                title: "Accept invite - Moxso",
            },
        },
        {
            path: "/validate/domain/:id",
            name: "validate.domain",
            component: require("./views/auth/ValidateDomain").default,
            meta: {
                auth: undefined,
                manager: undefined,
                title: "Validate domain - Moxso",
            },
        },
        {
            path: "/impersonate",
            name: "impersonate",
            component: require("./views/auth/Impersonate").default,
            meta: {
                auth: false,
                manager: false,
                title: "Impersonate - Moxso",
            },
        },
        {
            path: "/onboarding",
            name: "onboarding",
            component: require("./views/Onboarding").default,
            meta: {
                auth: true,
                manager: true,
                title: "Onboarding - Moxso",
            },
        },
        {
            path: "/dashboard",
            name: "dashboard.overview",
            component: require("./views/dashboard/Index").default,
            meta: {
                auth: true,
                manager: false,
                title: "Dashboard - Moxso",
                group: "dashboard",
            },
        },
        {
            path: "/dashboard/resilience",
            name: "dashboard.resilience",
            component: require("./views/dashboard/Resilience").default,
            meta: {
                auth: true,
                manager: false,
                title: "Dashboard - Moxso",
                group: "dashboard",
            },
        },
        {
            path: "/dashboard/simulations",
            name: "dashboard.simulations",
            component: require("./views/dashboard/Simulations").default,
            meta: {
                auth: true,
                manager: false,
                title: "Dashboard - Moxso",
                group: "dashboard",
            },
        },
        {
            path: "/dashboard/training",
            name: "dashboard.training",
            component: require("./views/dashboard/Training").default,
            meta: {
                auth: true,
                manager: false,
                title: "Dashboard - Training - Moxso",
                group: "dashboard",
            },
        },
        {
            path: "/dashboard/threats",
            name: "dashboard.threats",
            component: require("./views/dashboard/Threats").default,
            meta: {
                auth: true,
                manager: false,
                title: "Dashboard - Threats - Moxso",
                group: "dashboard",
            },
        },
        {
            path: "/dashboard/breaches",
            name: "dashboard.breaches",
            component: require("./views/dashboard/Breaches").default,
            meta: {
                auth: true,
                manager: false,
                title: "Dashboard - Breaches - Moxso",
                group: "dashboard",
            },
        },
        {
            path: "/simulation/campaigns",
            name: "campaign.index",
            component: require("./views/simulation/Index").default,
            meta: {
                auth: true,
                manager: false,
                title: "Campaigns - Moxso",
                group: "simulation",
            },
        },
        {
            path: "/simulation/campaigns/:id",
            name: "campaign.single",
            component: require("./views/simulation/Single").default,
            meta: {
                auth: true,
                manager: false,
                title: "Campaign - Moxso",
                group: "simulation",
            },
        },
        {
            path: "/training",
            name: "training.index",
            component: require("@/views/training/lessons/Index").default,
            meta: {
                auth: true,
                manager: false,
                title: "Training - Moxso",
                group: "training",
            },
        },
        {
            path: "/training/:type/:id",
            name: "training.single",
            component: require("@/views/training/lessons/Single").default,
            meta: {
                auth: true,
                manager: false,
                title: "Training - Moxso",
                group: "training",
            },
        },
        {
            path: "/training/deadlines",
            name: "training.deadlines",
            component: require("@/views/training/Deadlines").default,
            meta: {
                auth: true,
                manager: false,
                title: "Deadlines - Moxso",
                group: "training",
            },
        },
        {
            path: "/threats",
            name: "threat.index",
            component: require("./views/threats/Index").default,
            meta: {
                auth: true,
                manager: false,
                title: "Threats - Moxso",
                group: "threats",
            },
        },
        {
            path: "/threats/:id",
            name: "threat.single",
            component: require("./views/threats/Single").default,
            meta: {
                auth: true,
                manager: false,
                title: "Threat - Moxso",
                group: "threats",
            },
        },
        {
            path: "/automation",
            name: "automation.index",
            component: require("./views/automation/Index").default,
            meta: {
                auth: true,
                manager: false,
                title: "Automations - Moxso",
                group: "automation",
            },
        },
        {
            path: "/automation/:id",
            name: "automation.single",
            component: require("./views/automation/Single").default,
            meta: {
                auth: true,
                manager: false,
                title: "Automation - Moxso",
                group: "automation",
            },
        },
        {
            path: "/automation/:id/edit",
            name: "automation.edit",
            component: require("./views/automation/Edit").default,
            meta: {
                auth: true,
                manager: true,
                title: "Automation - Moxso",
                group: "automation",
            },
        },
        {
            path: "/automation/:id/action/:actionId",
            name: "automation.action",
            component: require("./views/automation/Action").default,
            meta: {
                auth: true,
                manager: false,
                title: "Automation - Moxso",
                group: "automation",
            },
        },
        {
            path: "/breaches",
            name: "breach.index",
            component: require("./views/breaches/Index").default,
            meta: {
                auth: true,
                manager: false,
                title: "Breaches - Moxso",
                group: "breaches",
            },
        },
        {
            path: "/breaches/:id",
            name: "breach.single",
            component: require("./views/breaches/Single").default,
            meta: {
                auth: true,
                manager: false,
                title: "Breach - Moxso",
                group: "breaches",
            },
        },
        {
            path: "/settings",
            name: "settings",
            component: require("./views/settings/Settings").default,
            meta: {
                auth: true,
                manager: true,
                title: "Workspace - Moxso",
                group: "settings",
            },
        },
        {
            path: "/settings/threats",
            name: "settings.threats",
            component: require("./views/settings/Threats").default,
            meta: {
                auth: true,
                manager: true,
                title: "Threats - Moxso",
                group: "settings",
            },
        },
        {
            path: "/settings/simulations",
            name: "settings.simulations",
            component: require("./views/settings/Simulations").default,
            meta: {
                auth: true,
                manager: true,
                title: "Simulations - Moxso",
                group: "settings",
            },
        },
        {
            path: "/settings/export",
            name: "settings.export",
            component: require("./views/settings/Export").default,
            meta: {
                auth: true,
                manager: false,
                title: "Export data to CSV - Moxso",
                group: "settings",
            },
        },
        {
            path: "/settings/import",
            name: "settings.import",
            component: require("./views/settings/Import").default,
            meta: {
                auth: true,
                manager: false,
                title: "Import from CSV - Moxso",
                group: "settings",
            },
        },
        {
            path: "/settings/fields",
            name: "settings.fields",
            component: require("./views/settings/Fields").default,
            meta: {
                auth: true,
                manager: true,
                title: "Fields - Moxso",
                group: "settings",
            },
        },

        {
            path: "/settings/integrations",
            name: "settings.integrations",
            component: require("./views/settings/integrations/Index").default,
            meta: {
                auth: true,
                manager: true,
                title: "Integrations - Moxso",
                group: "settings",
            },
        },
        {
            path: "/settings/integrations/:id",
            name: "settings.integrations.single",
            component: require("./views/settings/integrations/Edit").default,
            meta: {
                auth: true,
                manager: true,
                title: "Edit integration - Moxso",
                group: "settings",
            },
        },
        {
            path: "/settings/content",
            name: "settings.content",
            component: require("./views/settings/Content").default,
            meta: {
                auth: true,
                manager: true,
                title: "Content - Moxso",
                group: "settings",
            },
        },
        {
            path: "/settings/domains",
            name: "settings.domains",
            component: require("./views/settings/Domains").default,
            meta: {
                auth: true,
                manager: true,
                title: "Domains - Moxso",
                group: "settings",
            },
        },
        {
            path: "/team/employees",
            name: "employee.index",
            component: require("./views/employees/Index").default,
            meta: {
                auth: true,
                manager: false,
                title: "Employees - Moxso",
                group: "team",
            },
        },
        {
            path: "/team/employees/:id/overview",
            name: "employee.single.index",
            component: require("./views/employees/single/Index").default,
            meta: {
                auth: true,
                manager: false,
                title: "Employee - Moxso",
                group: "team",
            },
        },
        {
            path: "/team/employees/:id/simulations",
            name: "employee.single.simulations",
            component: require("./views/employees/single/Simulations").default,
            meta: {
                auth: true,
                manager: false,
                title: "Employee - Moxso",
                group: "team",
            },
        },
        {
            path: "/team/employees/:id/training",
            name: "employee.single.training",
            component: require("./views/employees/single/Training").default,
            meta: {
                auth: true,
                manager: false,
                title: "Employee - Moxso",
                group: "team",
            },
        },
        {
            path: "/team/employees/:id/breaches",
            name: "employee.single.breaches",
            component: require("./views/employees/single/Breaches").default,
            meta: {
                auth: true,
                manager: false,
                title: "Employee - Moxso",
                group: "team",
            },
        },
        {
            path: "/team/employees/:id/groups",
            name: "employee.single.groups",
            component: require("./views/employees/single/Groups").default,
            meta: {
                auth: true,
                manager: false,
                title: "Employee - Moxso",
                group: "team",
            },
        },
        {
            path: "/team/employees/:id/settings",
            name: "employee.single.settings",
            component: require("./views/employees/single/Settings").default,
            meta: {
                auth: true,
                manager: true,
                title: "Employee - Moxso",
                group: "team",
            },
        },
        {
            path: "/team/groups",
            name: "group.index",
            component: require("./views/groups/Index").default,
            meta: {
                auth: true,
                manager: false,
                title: "Groups - Moxso",
                group: "team",
            },
        },
        {
            path: "/team/groups/:id",
            name: "group.single",
            component: require("./views/groups/Single").default,
            meta: {
                auth: true,
                manager: false,
                title: "Group - Moxso",
                group: "team",
            },
        },
        {
            path: "/integration/:provider",
            name: "integration.grant",
            component: require("./views/auth/Oauth").default,
            meta: {
                auth: true,
                manager: true,
                title: "Integration - Moxso",
                group: "team",
            },
        },
    ],
};

Vue.use(VueRouter);

const router = new VueRouter(routes);

router.beforeEach(updatePageTags);

export function redirectTo(name) {
    if (router.currentRoute.name !== name) {
        router.push({ name: name });
    }
}
export function redirectWithParamsTo(object) {
    if (router.currentRoute.name !== getProperty(object, "name")) {
        router.push(object);
    }
}

export default router;
