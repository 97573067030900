<template>
    <div class="group-scope relative ml-8 flex h-[160px] pt-2">
        <BarChartLoadingItem
            v-for="(x, index) in loadingArray"
            :key="'loading-' + x.key"
            :index="index"
            :value="x.value"
        />
    </div>
</template>

<script>
import BarChartLoadingItem from "@/components/charts/BarChart/partials/BarChartLoadingItem";

export default {
    name: "BarChartLoading",
    components: { BarChartLoadingItem },
    data: () => ({
        loadingArray: [
            { key: 1, value: 16 },
            { key: 2, value: 12 },
            { key: 3, value: 8 },
            { key: 4, value: 16 },
            { key: 5, value: 12 },
            { key: 6, value: 8 },
            { key: 7, value: 16 },
            { key: 8, value: 12 },
            { key: 9, value: 8 },
            { key: 10, value: 16 },
            { key: 11, value: 12 },
            { key: 12, value: 8 },
            { key: 13, value: 21 },
        ],
    }),
};
</script>
