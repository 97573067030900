<template>
    <path
        d="m8.8 20.307 1.52.867a3 3 0 0 0 2.915.032l1.49-.806a11 11 0 0 0 5.728-10.516l-.073-.942m-2.127-4.6-5.465-1.974a3 3 0 0 0-2.038 0L5.363 4.313a3 3 0 0 0-1.98 2.707l-.126 3.308a11 11 0 0 0 2.73 7.686M21 3 5.986 18.014m0 0L3 21"
        fill="none"
        stroke="currentColor"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
    />
</template>

<script>
import IconSvgBase from "./IconSvgBase";

export default {
    extends: IconSvgBase,
    name: "IconBreach",
};
</script>
