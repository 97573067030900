<template>
    <DropdownMenu :items="items" @choose="handleChoice">
        <FormButton :variant="ButtonVariants.SECONDARY">
            <Icon :icon="localeIcon" class="size-4 pointer-events-none" />
            <span class="block pl-3 text-sm font-medium">{{
                $t("locale.current")
            }}</span>
        </FormButton>
    </DropdownMenu>
</template>

<script>
import { LocaleStoreNamespacedTypes } from "@/store/modules/locale";

import { localeEn, localeDa } from "@/utils/enums";
import DropdownMenu from "@/components/ui/DropdownMenu";
import FormButton, {
    ButtonVariants,
} from "@/components/forms/fields/FormButton";
import Icon from "@/components/icons/BaseIcon";
import { IconEnums } from "@/utils/icons";

export default {
    name: "LocaleSwitcher",
    components: { DropdownMenu, FormButton, Icon },
    data: () => ({
        ButtonVariants,
        IconEnums,
        localeEn,
        localeDa,
    }),
    computed: {
        items() {
            return [
                {
                    id: localeDa,
                    label: this.$t("locale.danish"),
                    icon: IconEnums.DANISH,
                },
                {
                    id: localeEn,
                    label: this.$t("locale.english"),
                    icon: IconEnums.ENGLISH,
                },
            ];
        },

        /**
         * Current locale.
         * @type {String}
         */
        locale() {
            return this.$store.getters[
                LocaleStoreNamespacedTypes.getters.LOCALE
            ]();
        },
        localeIcon() {
            switch (this.locale) {
                case localeEn:
                    return IconEnums.ENGLISH;
                case localeDa:
                    return IconEnums.DANISH;
                default:
                    return IconEnums.ENGLISH;
            }
        },
    },
    methods: {
        handleChoice(item) {
            this.$store.dispatch(
                LocaleStoreNamespacedTypes.actions.UPDATE_LOCALE,
                item.id
            );
        },
    },
};
</script>
