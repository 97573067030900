<template>
    <div class="flex flex-col p-4">
        <div class="mb-4 flex flex-row justify-between">
            <h3 class="font-medium text-slate-500" v-text="title"></h3>
            <div>
                <LoadingSpinner v-if="loading" class="size-5" />
                <div v-else class="flex flex-row space-x-2">
                    <div
                        v-if="hasLink || hasEmitter"
                        @click="navigateToResource"
                        class="flex h-6 cursor-pointer items-center justify-center gap-x-1 rounded-full bg-slate-50/50 px-2 text-xs font-medium text-slate-700 ring-1 ring-inset ring-slate-500/20 transition hover:bg-slate-100"
                    >
                        {{ $t("statistics.show_more") }}
                        <Icon :icon="IconEnums.EXPAND" class="h-3.5 w-3.5" />
                    </div>
                </div>
            </div>
        </div>
        <div class="flex flex-grow flex-col justify-between">
            <div class="flex flex-row space-x-2">
                <div class="relative w-48">
                    <GaugeChart
                        :value="value"
                        :max="100"
                        :min="0"
                        :separator-thickness="4"
                        :separator-step="40"
                        :inner-radius="75"
                        :scale-interval="5"
                        base-color="#e3e8ef"
                        :gauge-color="fillColor"
                    />
                    <p
                        class="absolute -bottom-1.5 left-1/2 flex w-full -translate-x-1/2 transform items-center justify-center text-center"
                    >
                        <span
                            class="text-4xl font-bold text-slate-800"
                            v-text="value"
                        ></span>
                        <span class="ml-1 font-bold text-slate-500">%</span>
                    </p>
                </div>
            </div>
            <div
                v-if="text"
                class="flex items-center space-x-2 pt-4 text-slate-400"
            >
                <div class="h-6 w-6">
                    <Icon :icon="IconEnums.INFO" class="h-6 w-6" />
                </div>
                <span class="text-xs leading-4" v-text="text"></span>
            </div>
        </div>
    </div>
</template>

<script>
import StatisticsCardMixin from "@/mixins/statistics/StatisticsCardMixin";
import ActionableDataMixin from "@/mixins/statistics/ActionableDataMixin";
import GaugeChart from "@/components/charts/GaugeChart";

export default {
    name: "AvgScoreCard",
    mixins: [StatisticsCardMixin, ActionableDataMixin],
    components: {
        GaugeChart,
    },
    props: {
        value: {
            type: Number,
            required: true,
        },
        min: {
            type: Number,
            required: true,
        },
        max: {
            type: Number,
            required: true,
        },
        unit: {
            type: String,
            required: false,
        },
    },
    computed: {
        fillColor() {
            if (this.value < 30) {
                return "#de524c";
            }

            if (this.value < 65) {
                return "#ea7a35";
            }

            return "#65d1bf";
        },
    },
};
</script>
