<template>
    <path
        fill="none"
        stroke="currentColor"
        stroke-linecap="round"
        stroke-linejoin="round"
        stroke-width="2"
        d="M21 6H9m12 6H9m12 6H9M4 7a1 1 0 1 1 0-2 1 1 0 0 1 0 2Zm0 6a1 1 0 1 1 0-2 1 1 0 0 1 0 2Zm0 6a1 1 0 1 1 0-2 1 1 0 0 1 0 2Z"
    />
</template>

<script>
import IconSvgBase from "@/components/icons/IconSvgBase";

export default {
    extends: IconSvgBase,
    name: "IconBulletList",
};
</script>
