<template>
    <div class="relative mb-6 overflow-hidden">
        <div
            class="pointer-events-none absolute inset-0 border-b border-slate-200"
        ></div>
        <nav class="relative top-0 -mx-20 flex gap-x-4 px-20" aria-label="Tabs">
            <slot />
        </nav>
    </div>
</template>

<script>
export default {
    name: "TabNavigation",
};
</script>
