<template>
    <div class="h-full overflow-auto px-4">
        <div class="card mb-4 flex flex-col space-y-4 p-4">
            <div class="flex w-full flex-row items-center">
                <Avatar
                    :size="SizeVariants.BIG"
                    :image="avatar"
                    :name="full_name"
                />
                <div class="ml-3 flex min-w-0 flex-col">
                    <p
                        class="truncate font-medium leading-tight"
                        v-text="full_name"
                    ></p>
                    <p
                        class="truncate text-slate-500"
                        v-text="department ? department : email"
                    ></p>
                </div>
            </div>
            <div class="flex items-center space-x-2">
                <FormButton
                    class="flex-grow"
                    :variant="ButtonVariants.SECONDARY"
                    @click.native="goToProfile"
                >
                    {{ $t("employees.preview.profile") }}
                </FormButton>
                <FormButton
                    :variant="ButtonVariants.SECONDARY"
                    @click.native="toggleForm"
                    :icon="viewForm ? IconEnums.CLOSE : IconEnums.SETTINGS"
                    :loading="loading"
                >
                    {{
                        viewForm
                            ? $t("employees.preview.close_edit")
                            : $t("employees.preview.edit")
                    }}
                </FormButton>
            </div>
        </div>
        <AvgScoreCard
            v-if="!viewForm"
            :loading="loading"
            :title="$t('employees.resilience_score.title').toString()"
            :text="$t('employees.resilience_score.text').toString()"
            :value="score"
            :max="100"
            :min="0"
            class="card mb-4"
        />
        <div class="card mb-4 flex flex-col space-y-4">
            <div
                v-if="!viewForm"
                class="flex flex-col divide-y divide-slate-100"
            >
                <div v-if="isInactive" class="relative px-4 py-2.5">
                    <div
                        class="flex items-center whitespace-pre-wrap rounded-lg bg-yellow-50/50 p-2 text-xs text-warning-dark ring-1 ring-inset ring-yellow-500/20"
                        v-html="
                            $t(
                                'employees.deactivation_notice.' +
                                    deactivation_reason,
                                { date: formatDateTime(deactivated_at) }
                            )
                        "
                    ></div>
                </div>
                <ListItem :title="$t('employees.preview.status').toString()">
                    <Badge
                        :variant="
                            status === 'active'
                                ? BadgeVariants.SUCCESS
                                : BadgeVariants.ERROR
                        "
                        :text="$t(`employees.${status}`).toString()"
                    />
                </ListItem>
                <ListItem
                    :title="$t('employees.preview.email').toString()"
                    :text="email"
                />
                <ListItem
                    v-if="provider"
                    :title="$t('employees.preview.provider').toString()"
                    :text="provider"
                />
                <ListItem
                    v-if="provider"
                    :title="$t('employees.preview.provider_id').toString()"
                    :text="provider_id"
                />
                <ListItem
                    :title="$t('employees.preview.locale').toString()"
                    :text="$t(`language.${locale}`).toString()"
                />
                <ListItem
                    v-if="group_count !== null"
                    :title="$t('employees.preview.groups').toString()"
                    :text="group_count"
                />
                <ListItem :title="$t('employees.preview.tags').toString()">
                    <ul
                        v-if="hasTags"
                        class="flex flex-wrap gap-2 text-xs font-medium text-slate-600 *:rounded-full *:bg-slate-50 *:px-3 *:py-1 *:ring-1 *:ring-inset *:ring-slate-200"
                    >
                        <li
                            v-for="tag in tags"
                            :key="tag.id"
                            v-text="tag.name"
                        ></li>
                    </ul>
                    <div v-else>-</div>
                </ListItem>
            </div>
            <div v-else class="px-4 pt-4">
                <EmployeeSettingsForm :cols="1" @saved="handleSaved" />
            </div>
        </div>
    </div>
</template>

<script>
import { TeamStoreNamespacedTypes } from "@/store/modules/team";

import { getProperty } from "@/utils/object";
import { initials } from "@/utils/filters";
import { formatDateTime } from "@/utils/date";

import FormButton, {
    ButtonVariants,
} from "@/components/forms/fields/FormButton";
import { IconEnums } from "@/utils/icons";
import EmployeeSettingsForm from "@/components/forms/EmployeeSettingsForm";
import Bus from "@/bus";
import Badge, { BadgeVariants } from "@/components/shared/Badge";
import Avatar, { SizeVariants } from "@/components/shared/Avatar";
import AvgScoreCard from "@/components/statistics/AvgScoreCard";
import ListItem from "@/components/ui/ListItem";

export default {
    name: "EmployeePreview",
    components: {
        ListItem,
        AvgScoreCard,
        Avatar,
        Badge,
        EmployeeSettingsForm,
        FormButton,
    },
    props: {
        employee: {
            type: Object,
            required: true,
        },
    },
    filters: {
        initials,
    },
    data: () => ({
        SizeVariants,
        BadgeVariants,
        ButtonVariants,
        IconEnums,
        viewForm: false,
    }),
    computed: {
        /**
         * @type {Object}
         */
        loading() {
            return this.$store.getters[
                TeamStoreNamespacedTypes.getters.LOADING
            ]();
        },
        id() {
            return getProperty(this.employee, "id");
        },
        avatar() {
            return getProperty(this.employee, "avatar");
        },
        full_name() {
            return getProperty(this.employee, "full_name");
        },
        status() {
            return getProperty(this.employee, "status");
        },
        isInactive() {
            return this.status === "inactive";
        },
        deactivated_at() {
            return getProperty(this.employee, "deactivated_at");
        },
        deactivation_reason() {
            return getProperty(this.employee, "deactivation_reason");
        },
        group_count() {
            return getProperty(this.employee, "group_count");
        },
        email() {
            return getProperty(this.employee, "email");
        },
        department() {
            return getProperty(this.employee, "department");
        },
        provider() {
            return getProperty(this.employee, "provider");
        },
        /**
         * @type {Object}
         */
        icon() {
            return this.IconEnums[this.provider.toUpperCase()];
        },
        provider_id() {
            return getProperty(this.employee, "provider_id");
        },
        locale() {
            return getProperty(this.employee, "locale");
        },
        score() {
            return getProperty(this.employee, "score", 0);
        },
        tags() {
            return getProperty(this.employee, "tags", []);
        },
        hasTags() {
            return this.tags.length > 0;
        },
    },
    methods: {
        formatDateTime,
        goToProfile() {
            this.$router
                .push({
                    name: "employee.single.index",
                    params: { id: this.id },
                })
                .then(() => {
                    this.$emit("close");
                });
        },
        toggleForm() {
            if (this.viewForm) {
                this.viewForm = false;
                return;
            }

            this.$store
                .dispatch(
                    TeamStoreNamespacedTypes.actions.GET_EMPLOYEE,
                    this.id
                )
                .then(() => {
                    this.viewForm = true;
                });
        },
        /**
         * Handle the saved event from the form.
         */
        handleSaved() {
            Bus.$emit("flash-message", {
                text: this.$t("settings.saved"),
                type: "success",
            });
            this.viewForm = false;
            this.$emit("saved");
        },
    },
};
</script>
