<template>
    <DataTable
        name="action.participants"
        :headers="headers"
        :rows="rows"
        :meta="meta"
        :loading="loading"
        :error="error"
        :emit="true"
        @emit="$emit('emit', $event)"
        @update="refresh"
    >
        <template v-slot:employee="{ item }">
            {{ item.employee.full_name }}
        </template>
        <template v-slot:completed_at="{ item }">
            <span v-if="item.completed_at">
                {{ item.completed_at | formatDate }}
            </span>
            <span v-else>-</span>
        </template>
        <template v-slot:overdue="{ item }">
            <div
                v-if="item.overdue"
                class="h-2 w-2 rounded-full bg-error-normal"
            ></div>
            <div v-else class="h-2 w-2 rounded-full bg-success-normal"></div>
        </template>
        <template v-slot:created_at="{ item }">
            {{ item.created_at | formatDate }}
        </template>
    </DataTable>
</template>

<script>
import { formatDate } from "@/utils/date";

import DataTableMixin from "@/components/tables/DataTableMixin";

export default {
    name: "ParticipantTable",
    mixins: [DataTableMixin],
    data: () => ({
        headers: [
            {
                title: "employee",
                key: "employee",
                sortable: false,
            },
            {
                title: "completed_at",
                key: "completed_at",
                sortable: true,
            },
            {
                title: "overdue",
                key: "overdue",
                sortable: false,
            },
            {
                title: "enrolled_at",
                key: "created_at",
                sortable: true,
            },
        ],
    }),
    filters: {
        formatDate,
    },
};
</script>
