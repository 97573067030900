var render = function render(){var _vm=this,_c=_vm._self._c;return _c('EmployeePage',[_c('div',{staticClass:"flex flex-col items-stretch justify-evenly xl:flex-row xl:space-x-4"},[_c('div',{staticClass:"mb-4 flex w-full items-start justify-start xl:mb-0 xl:w-1/2"},[_c('div',{staticClass:"grid w-full grid-cols-1 gap-4 xl:grid-cols-2"},[_c('ValueCard',{staticClass:"card",attrs:{"loading":_vm.loading,"title":_vm.$t(
                            'employees.simulations.received.title'
                        ).toString(),"text":_vm.$t('employees.simulations.received.text').toString(),"unit":_vm.$t('statistics.simulations.count.unit').toString(),"value":_vm.count}}),_c('ValueCard',{staticClass:"card",attrs:{"loading":_vm.loading,"title":_vm.$t(
                            'employees.simulations.open_rate.title'
                        ).toString(),"text":_vm.$t(
                            'employees.simulations.open_rate.text'
                        ).toString(),"value":_vm.openRate,"pct":true,"lower-threshold":7000}}),_c('ValueCard',{staticClass:"card",attrs:{"loading":_vm.loading,"title":_vm.$t(
                            'employees.simulations.click_rate.title'
                        ).toString(),"text":_vm.$t(
                            'employees.simulations.click_rate.text'
                        ).toString(),"value":_vm.clickRate,"pct":true,"lower-threshold":500}})],1)]),_c('div',{staticClass:"w-full xl:w-1/2"},[_c('RecentSimulations',{staticClass:"card",attrs:{"data":_vm.recentSimulations}})],1)])])
}
var staticRenderFns = []

export { render, staticRenderFns }