<template>
    <div class="flex animate-pulse flex-col justify-between p-4">
        <div class="mb-6 block h-3 w-full rounded-full bg-slate-200"></div>
        <div
            class="flex flex-row items-stretch space-x-1 divide-x divide-slate-300"
        >
            <div
                v-for="x in count"
                :key="x"
                class="flex flex-1 flex-col items-center justify-center"
            >
                <div class="mb-4 flex space-x-2">
                    <div class="h-4 w-4 rounded bg-slate-300"></div>
                    <div class="h-4 w-16 rounded bg-slate-300"></div>
                </div>
                <div class="h-4 w-12 rounded bg-slate-300"></div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "GroupLoadingSkeleton",
    props: {
        count: {
            type: Number,
            default: 2,
        },
    },
};
</script>
