<template>
    <ValidationObserver ref="generateEmployeeReportForm" v-slot="{ passes }">
        <form @submit.prevent="passes(generateReport)">
            <FormFieldSet>
                <Alert :error="error" />
                <div
                    class="flex flex-col items-stretch justify-evenly xl:flex-row xl:space-x-4"
                >
                    <FormDateInput
                        v-model="form.date_from"
                        name="date_from"
                        rules="required"
                        class="w-full xl:w-1/2"
                    />
                    <FormDateInput
                        v-model="form.date_to"
                        name="date_to"
                        rules="required"
                        class="w-full xl:w-1/2"
                    />
                </div>
                <FormSelect
                    v-model="form.group_id"
                    name="group"
                    rules="required"
                >
                    <option
                        v-for="group in rows"
                        :key="group.id"
                        :value="group.id"
                    >
                        {{ group.name }}
                    </option>
                </FormSelect>
                <FormSelect
                    v-model="form.status"
                    name="status"
                    rules="required"
                >
                    <option value="all">
                        {{ $t("settings.export.form.status.all") }}
                    </option>
                    <option value="active">
                        {{ $t("settings.export.form.status.active") }}
                    </option>
                    <option value="inactive">
                        {{ $t("settings.export.form.status.inactive") }}
                    </option>
                </FormSelect>
                <FormSelect v-model="custom_fields" name="fields">
                    <option value="default">
                        {{ $t("settings.export.form.fields.default") }} ({{
                            selectedFieldsCount
                        }})
                    </option>
                    <option value="custom">
                        {{ $t("settings.export.form.fields.custom") }}
                    </option>
                </FormSelect>
                <div class="col-span-6">
                    <p class="text-xs font-medium text-slate-500">
                        {{ $t("settings.export.fields") }}:
                        {{ activeFieldsString }}
                    </p>
                </div>
            </FormFieldSet>
            <div v-if="custom_fields !== 'default'">
                <div class="mb-4 flex justify-end">
                    <FormButton
                        :variant="ButtonVariants.SMALL"
                        @click.native="toggleAllFields"
                    >
                        {{
                            allFieldsAreSelected
                                ? $t("settings.export.form.deselect_all")
                                : $t("settings.export.form.select_all")
                        }}
                    </FormButton>
                </div>
                <div
                    class="flex flex-col items-start justify-evenly xl:flex-row xl:space-x-4"
                >
                    <fieldset
                        class="w-full overflow-hidden rounded border bg-white xl:w-1/2"
                    >
                        <div class="divide-y divide-slate-200 border-slate-200">
                            <DataFieldCheckbox
                                v-for="field in employee_fields"
                                :key="field.key"
                                :name="field.key"
                                :field="field"
                                v-model="form.fields[field.key]"
                            />
                        </div>
                    </fieldset>
                    <fieldset
                        class="w-full overflow-hidden rounded border bg-white xl:w-1/2"
                    >
                        <div class="divide-y divide-slate-200 border-slate-200">
                            <DataFieldCheckbox
                                v-for="field in misc_fields"
                                :key="field.key"
                                :name="field.key"
                                :field="field"
                                v-model="form.fields[field.key]"
                            />
                        </div>
                    </fieldset>
                </div>
            </div>
            <FormFieldSet>
                <div class="flex justify-end">
                    <FormButton type="submit" :loading="loading">
                        {{ $t("settings.export.form.button") }}
                    </FormButton>
                </div>
            </FormFieldSet>
        </form>
    </ValidationObserver>
</template>

<script>
import { ReportStoreNamespacedTypes } from "@/store/modules/report";
import { TeamStoreNamespacedTypes } from "@/store/modules/team";

import dayjs from "dayjs";
import { getProperty } from "@/utils/object";

import { ValidationObserver } from "vee-validate";
import Alert from "@/components/ui/Alert";
import FormFieldSet from "@/components/forms/fields/FormFieldSet";
import FormButton, {
    ButtonVariants,
} from "@/components/forms/fields/FormButton";
import FormSelect from "@/components/forms/fields/FormSelect";
import DataFieldCheckbox from "@/components/forms/fields/DataFieldCheckbox";
import FormDateInput from "@/components/forms/fields/FormDateInput";

export default {
    name: "GenerateEmployeeReportForm",
    components: {
        FormDateInput,
        DataFieldCheckbox,
        FormSelect,
        FormButton,
        FormFieldSet,
        Alert,
        ValidationObserver,
    },
    data: () => ({
        ButtonVariants,
        date: null,
        custom_fields: "default",
        employee_fields: [
            {
                key: "first_name",
                model: "employee",
                type: "string",
            },
            {
                key: "last_name",
                model: "employee",
                type: "string",
            },
            {
                key: "email",
                model: "employee",
                type: "string",
            },
            {
                key: "department",
                model: "employee",
                type: "string",
            },
            {
                key: "locale",
                model: "employee",
                type: "string",
            },
            {
                key: "status",
                model: "employee",
                type: "string",
            },
            {
                key: "onboarded",
                model: "employee",
                type: "boolean",
            },
            {
                key: "score",
                model: "employee",
                type: "number",
            },
            {
                key: "point_count",
                model: "employee",
                type: "number",
            },
            {
                key: "provider",
                model: "employee",
                type: "string",
            },
            {
                key: "created_at",
                model: "employee",
                type: "date",
            },
        ],
        misc_fields: [
            {
                key: "received_count",
                model: "simulation",
                type: "number",
            },
            {
                key: "clicked_count",
                model: "simulation",
                type: "number",
            },
            {
                key: "watched_count",
                model: "video",
                type: "number",
            },
            {
                key: "video_quizzes_count",
                model: "quiz",
                type: "number",
            },
            {
                key: "phishing_quizzes_count",
                model: "quiz",
                type: "number",
            },
            {
                key: "dynamic_group_memberships",
                model: "group",
                type: "string",
            },
            {
                key: "group_count",
                model: "group",
                type: "number",
            },
        ],
        form: {
            report_type: "employee",
            group_id: null,
            status: "active",
            date_from: null,
            date_to: null,
            fields: {
                first_name: true,
                last_name: true,
                email: true,
                department: true,
                locale: true,
                status: true,
                onboarded: false,
                score: true,
                point_count: true,
                dynamic_group_memberships: false,
                group_count: false,
                provider: false,
                created_at: false,
                received_count: false,
                clicked_count: false,
                watched_count: false,
                video_quizzes_count: false,
                phishing_quizzes_count: false,
            },
        },
    }),
    computed: {
        /**
         * @type {Boolean}
         */
        loading() {
            return this.$store.getters[
                ReportStoreNamespacedTypes.getters.REPORTS_LOADING
            ]();
        },

        /**
         * @type {Object}
         */
        error() {
            return this.$store.getters[
                ReportStoreNamespacedTypes.getters.REPORTS_ERROR
            ]();
        },
        groups() {
            return this.$store.getters[
                TeamStoreNamespacedTypes.getters.GROUPS
            ]();
        },
        rows() {
            return getProperty(this.groups, "data", []);
        },

        /**
         * @type {number}
         */
        selectedFieldsCount() {
            return Object.values(this.form.fields).filter((field) => field)
                .length;
        },

        /**
         * @type {String}
         */
        activeFieldsString() {
            return Object.keys(this.form.fields)
                .filter((key) => this.form.fields[key])
                .map((key) => this.$t("forms.fields." + key))
                .join(", ");
        },

        allFieldsAreSelected() {
            return Object.values(this.form.fields).every((field) => field);
        },
    },
    methods: {
        /**
         * Sends authentication credentials to the server.
         */
        generateReport() {
            this.$store
                .dispatch(
                    ReportStoreNamespacedTypes.actions.DOWNLOAD_REPORT,
                    this.form
                )
                .then((response) => {
                    if (!this.error) {
                        this.$emit("saved", response.data);
                    }
                });
        },
        toggleAllFields() {
            const allFieldsAreSelected = this.allFieldsAreSelected;
            Object.keys(this.form.fields).forEach((key) => {
                this.form.fields[key] = !allFieldsAreSelected;
            });
        },
    },
    beforeCreate() {
        this.$store.commit(
            ReportStoreNamespacedTypes.mutations.SET_REPORTS_ERROR,
            null
        );
    },
    created() {
        this.form.date_from = dayjs().subtract(1, "month").format("DD-MM-YYYY");
    },
};
</script>
