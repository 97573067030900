<template>
    <nav class="flex items-center justify-center" aria-label="Progress">
        <ol class="flex items-center space-x-5">
            <li
                v-for="(indicator, index) in total"
                :key="`progress-item-${index}`"
            >
                <div
                    class="size-2.5 block rounded-full"
                    :class="[
                        {
                            'bg-slate-800': isActiveOrPassed(index),
                        },
                        {
                            'bg-slate-300': !isActiveOrPassed(index),
                        },
                    ]"
                >
                    <span class="sr-only">step {{ index }}</span>
                </div>
            </li>
        </ol>
    </nav>
</template>

<script>
import { isNegative } from "@/utils/math";

export default {
    name: "ProgressIndicator",
    props: {
        current: {
            type: Number,
            required: true,
        },
        total: {
            type: Number,
            required: true,
        },
    },
    methods: {
        isActiveOrPassed(index) {
            if (isNegative(this.current)) {
                return true;
            }

            return this.current >= index;
        },
    },
};
</script>
