<template>
    <ValidationObserver ref="commentForm" v-slot="{ passes }">
        <form @submit.prevent="passes(emitComment)">
            <ValidationProvider
                tag="div"
                class="form-field form-field-input"
                vid="comment"
                rules="required|min:3|max:255"
                name="comment"
                v-slot="{ errors, ariaMsg }"
            >
                <div class="mt-6 flex gap-x-3">
                    <div class="relative flex-auto">
                        <div
                            class="overflow-hidden rounded-lg pb-12 ring-1 ring-inset ring-slate-200 focus-within:ring-2 focus-within:ring-slate-300"
                        >
                            <label for="comment" class="sr-only">{{
                                $t("forms.fields.comment")
                            }}</label>
                            <textarea
                                rows="2"
                                name="comment"
                                id="comment"
                                v-model="comment"
                                class="block w-full resize-none border-0 bg-transparent py-1.5 text-sm leading-6 text-slate-900 placeholder:text-slate-400 focus:ring-0"
                                :placeholder="
                                    $t('forms.fields.comment_placeholder')
                                "
                            ></textarea>
                        </div>
                        <div
                            class="absolute inset-x-0 bottom-0 flex justify-end py-2 pl-3 pr-2"
                        >
                            <FormButton
                                type="submit"
                                :variant="ButtonVariants.SECONDARY"
                                :loading="loading"
                            >
                                {{ $t("forms.fields.save") }}
                            </FormButton>
                        </div>
                    </div>
                </div>
                <span
                    class="mt-3 text-sm text-red-600"
                    v-bind="ariaMsg"
                    v-if="errors[0]"
                    >{{ errors[0] }}</span
                >
            </ValidationProvider>
        </form>
    </ValidationObserver>
</template>

<script>
import { ValidationObserver, ValidationProvider } from "vee-validate";
import FormButton, {
    ButtonVariants,
} from "@/components/forms/fields/FormButton";

export default {
    name: "CommentForm",
    props: {
        loading: {
            type: Boolean,
            default: false,
        },
    },
    components: {
        ValidationObserver,
        ValidationProvider,
        FormButton,
    },
    data: () => ({
        ButtonVariants,
        comment: null,
    }),
    methods: {
        emitComment() {
            this.$emit("comment", this.comment);
            this.comment = null;
        },
    },
};
</script>
