<template>
    <ValidationObserver ref="createTagForm" v-slot="{ passes }">
        <Alert :error="error" />
        <form @submit.prevent="passes(createTag)" class="-mb-2">
            <FormFieldSet>
                <FormInput
                    v-model="form.name"
                    type="text"
                    rules="required"
                    name="name"
                />
                <div class="flex justify-end">
                    <FormButton type="submit" :loading="loading">
                        {{ $t("groups.single.tags.create") }}
                    </FormButton>
                </div>
            </FormFieldSet>
        </form>
    </ValidationObserver>
</template>

<script>
import { TeamStoreNamespacedTypes } from "@/store/modules/team";

import { ValidationObserver } from "vee-validate";
import Alert from "@/components/ui/Alert";
import FormInput from "@/components/forms/fields/FormInput";
import FormFieldSet from "@/components/forms/fields/FormFieldSet";
import FormButton from "@/components/forms/fields/FormButton";

export default {
    name: "CreateTagForm",
    components: {
        FormButton,
        FormFieldSet,
        Alert,
        ValidationObserver,
        FormInput,
    },
    data: () => ({
        loading: false,
        form: {
            name: null,
        },
    }),
    computed: {
        /**
         * The error state of the API request.
         * @type {Object}
         */
        error() {
            return this.$store.getters[
                TeamStoreNamespacedTypes.getters.ERROR
            ]();
        },

        /**
         * @type {Array}
         */
        group() {
            return this.$store.getters[
                TeamStoreNamespacedTypes.getters.GROUP
            ]();
        },
    },
    methods: {
        /**
         * Sends authentication credentials to the server.
         */
        createTag() {
            this.loading = true;
            this.$store
                .dispatch(
                    TeamStoreNamespacedTypes.actions.CREATE_TAG,
                    this.form
                )
                .then(() => {
                    this.loading = false;
                    if (!this.error) {
                        this.form.name = null;
                        this.$emit("saved");
                    }
                });
        },
    },
    beforeCreate() {
        this.$store.commit(TeamStoreNamespacedTypes.mutations.SET_ERROR, null);
    },
};
</script>
