<template>
    <ValidationObserver ref="generateTrainingReportForm" v-slot="{ passes }">
        <form @submit.prevent="passes(generateReport)">
            <FormFieldSet>
                <Alert :error="error" />
                <div
                    class="flex flex-col items-stretch justify-evenly xl:flex-row xl:space-x-4"
                >
                    <FormDateInput
                        v-model="form.date_from"
                        name="date_from"
                        rules="required"
                        class="w-full xl:w-1/2"
                    />
                    <FormDateInput
                        v-model="form.date_to"
                        name="date_to"
                        rules="required"
                        class="w-full xl:w-1/2"
                    />
                </div>
                <FormSelect v-model="form.group_id" name="group">
                    <option
                        v-for="group in rows"
                        :key="group.id"
                        :value="group.id"
                    >
                        {{ group.name }}
                    </option>
                </FormSelect>
                <FormSelect v-model="form.status" name="status">
                    <option value="all">
                        {{ $t("settings.export.form.status.all") }}
                    </option>
                    <option value="active">
                        {{ $t("settings.export.form.status.active") }}
                    </option>
                    <option value="inactive">
                        {{ $t("settings.export.form.status.inactive") }}
                    </option>
                </FormSelect>
                <FormSelect v-model="form.training_type" name="training_type">
                    <option value="video">
                        {{ $t("settings.export.form.training_type.video") }}
                    </option>
                    <option value="course">
                        {{ $t("settings.export.form.training_type.course") }}
                    </option>
                </FormSelect>
                <div class="flex justify-end">
                    <FormButton type="submit" :loading="loading">
                        {{ $t("settings.export.form.button") }}
                    </FormButton>
                </div>
            </FormFieldSet>
        </form>
    </ValidationObserver>
</template>

<script>
import { ReportStoreNamespacedTypes } from "@/store/modules/report";
import { TeamStoreNamespacedTypes } from "@/store/modules/team";

import { getProperty } from "@/utils/object";

import { ValidationObserver } from "vee-validate";
import Alert from "@/components/ui/Alert";
import FormFieldSet from "@/components/forms/fields/FormFieldSet";
import FormButton from "@/components/forms/fields/FormButton";
import FormSelect from "@/components/forms/fields/FormSelect";
import FormDateInput from "@/components/forms/fields/FormDateInput";
import dayjs from "dayjs";

export default {
    name: "GenerateTrainingReportForm",
    components: {
        FormDateInput,
        FormSelect,
        FormButton,
        FormFieldSet,
        Alert,
        ValidationObserver,
    },
    data: () => ({
        form: {
            report_type: "training",
            group_id: null,
            status: "active",
            date_from: null,
            date_to: null,
            training_type: "video",
        },
    }),
    computed: {
        /**
         * @type {Boolean}
         */
        loading() {
            return this.$store.getters[
                ReportStoreNamespacedTypes.getters.REPORTS_LOADING
            ]();
        },

        /**
         * @type {Object}
         */
        error() {
            return this.$store.getters[
                ReportStoreNamespacedTypes.getters.REPORTS_ERROR
            ]();
        },
        groups() {
            return this.$store.getters[
                TeamStoreNamespacedTypes.getters.GROUPS
            ]();
        },
        rows() {
            return getProperty(this.groups, "data", []);
        },
    },
    methods: {
        /**
         * Sends authentication credentials to the server.
         */
        generateReport() {
            this.$store
                .dispatch(
                    ReportStoreNamespacedTypes.actions.DOWNLOAD_REPORT,
                    this.form
                )
                .then((response) => {
                    if (!this.error) {
                        this.$emit("saved", response.data);
                    }
                });
        },
    },
    beforeCreate() {
        this.$store.commit(
            ReportStoreNamespacedTypes.mutations.SET_REPORTS_ERROR,
            null
        );
    },
    created() {
        this.form.date_from = dayjs().subtract(1, "month").format("DD-MM-YYYY");
    },
};
</script>
