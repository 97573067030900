/**
 * A store for integrations.
 *
 * @typedef {{ integrationLoading: boolean, integrationError: ErrorOrObject, integration: Object }} IntegrationStoreState
 */
import { addNamespace } from "./namespace";
import { getData, postData } from "@/api";

/**
 * The types used in this store
 * @enum {string}
 */
export const IntegrationStoreTypes = {
    getters: {
        INTEGRATION_LOADING: "integrationLoading",
        INTEGRATION_ERROR: "integrationError",
        INTEGRATION: "integration",
    },
    actions: {
        GET_GRANT: "getGrant",
        SAVE_GRANT: "saveGrant",
        GET_INTEGRATION: "getIntegration",
        UPDATE_INTEGRATION_SETTINGS: "updateIntegrationSettings",
        SYNC_INTEGRATION_GROUPS: "syncIntegrationGroups",
    },
    mutations: {
        SET_INTEGRATION_LOADING: "setIntegrationLoading",
        SET_INTEGRATION_ERROR: "setIntegrationError",
        SET_INTEGRATION: "setIntegration",
    },
};

/**
 * A namespaced version of the types used in this store
 * @enum {string}
 */
export const IntegrationStoreNamespacedTypes = addNamespace(
    "integration",
    IntegrationStoreTypes
);

/**
 * @returns {IntegrationStoreState}
 */
export function state() {
    return {
        integrationLoading: false,
        integrationError: null,
        integration: {},
    };
}

export const getters = {
    [IntegrationStoreTypes.getters.INTEGRATION_LOADING]: (state) => () => {
        return state.integrationLoading;
    },
    [IntegrationStoreTypes.getters.INTEGRATION_ERROR]: (state) => () => {
        return state.integrationError;
    },
    [IntegrationStoreTypes.getters.INTEGRATION]: (state) => () => {
        return state.integration;
    },
};

export const actions = {
    /**
     * Sends the given integration to the server.
     *
     * @param {VuexCommit} commit
     * @param provider
     * @return {Promise}
     */
    [IntegrationStoreTypes.actions.GET_GRANT]({ commit }, provider) {
        commit(IntegrationStoreTypes.mutations.SET_INTEGRATION_ERROR, null);

        return getData("/workspace/integrations/grant/" + provider).catch(
            (errors) => {
                commit(
                    IntegrationStoreTypes.mutations.SET_INTEGRATION_ERROR,
                    errors
                );
            }
        );
    },

    /**
     * Sends the given integration to the server.
     *
     * @param {VuexCommit} commit
     * @param {Object} grant
     * @return {Promise}
     */
    [IntegrationStoreTypes.actions.SAVE_GRANT]({ commit }, grant) {
        commit(IntegrationStoreTypes.mutations.SET_INTEGRATION_LOADING, true);
        commit(IntegrationStoreTypes.mutations.SET_INTEGRATION_ERROR, null);

        return postData("/workspace/integrations/authorise", grant)
            .catch((errors) => {
                commit(
                    IntegrationStoreTypes.mutations.SET_INTEGRATION_ERROR,
                    errors
                );
            })
            .finally(() => {
                commit(
                    IntegrationStoreTypes.mutations.SET_INTEGRATION_LOADING,
                    false
                );
            });
    },

    /**
     * Sends the given integration to the server.
     *
     * @param {VuexCommit} commit
     * @param id
     * @return {Promise}
     */
    [IntegrationStoreTypes.actions.GET_INTEGRATION]({ commit }, id) {
        commit(IntegrationStoreTypes.mutations.SET_INTEGRATION_LOADING, true);
        commit(IntegrationStoreTypes.mutations.SET_INTEGRATION_ERROR, null);

        return getData("/workspace/integrations/" + id)
            .then((response) => {
                commit(
                    IntegrationStoreTypes.mutations.SET_INTEGRATION,
                    response.data
                );
            })
            .catch((errors) => {
                commit(
                    IntegrationStoreTypes.mutations.SET_INTEGRATION_ERROR,
                    errors
                );
            })
            .finally(() => {
                commit(
                    IntegrationStoreTypes.mutations.SET_INTEGRATION_LOADING,
                    false
                );
            });
    },

    /**
     * Sends the given integration to the server.
     *
     * @param {VuexCommit} commit
     * @param {string} integration
     * @param {Object} form
     * @return {Promise}
     */
    [IntegrationStoreTypes.actions.UPDATE_INTEGRATION_SETTINGS](
        { commit },
        { integration, form }
    ) {
        commit(IntegrationStoreTypes.mutations.SET_INTEGRATION_ERROR, null);

        return postData("/workspace/integrations/" + integration, form)
            .then((response) => {
                commit(
                    IntegrationStoreTypes.mutations.SET_INTEGRATION,
                    response.data
                );
            })
            .catch((errors) => {
                commit(
                    IntegrationStoreTypes.mutations.SET_INTEGRATION_ERROR,
                    errors
                );
            });
    },
};

export const mutations = {
    /**
     * Sets the loading state for integration.
     *
     * @param {IntegrationStoreState} state
     * @param {boolean} loading
     */
    [IntegrationStoreTypes.mutations.SET_INTEGRATION_LOADING](state, loading) {
        state.integrationLoading = loading;
    },

    /**
     * Sets a new integration error.
     *
     * @param {IntegrationStoreState} state
     * @param {ErrorOrObject} error
     */
    [IntegrationStoreTypes.mutations.SET_INTEGRATION_ERROR](state, error) {
        state.integrationError = error;
    },

    /**
     * Sets a new integration error.
     *
     * @param {IntegrationStoreState} state
     * @param {Object} integration
     */
    [IntegrationStoreTypes.mutations.SET_INTEGRATION](state, integration) {
        state.integration = integration;
    },
};

export default {
    namespaced: true,
    Types: IntegrationStoreTypes,
    NamespacedTypes: IntegrationStoreNamespacedTypes,
    state,
    getters,
    actions,
    mutations,
};
