<template>
    <ValidationObserver ref="loginForm" v-slot="{ passes }">
        <form @submit.prevent="passes(login)">
            <FormFieldSet>
                <Alert :error="csrfError || loginError" />
                <FormInput
                    v-model="email"
                    type="email"
                    rules="required|email"
                    name="email"
                />
                <FormInput
                    v-model="password"
                    type="password"
                    rules="required|min:8"
                    name="password"
                />
                <p>
                    <router-link
                        :to="{ name: 'forgot.password' }"
                        class="text-base font-medium text-indigo-600"
                        >{{ $t("login.forgot_password") }}</router-link
                    >
                </p>
                <div class="flex justify-end">
                    <FormButton type="submit" :loading="loading">
                        {{ $t("forms.buttons.login") }}
                    </FormButton>
                </div>
            </FormFieldSet>
        </form>
    </ValidationObserver>
</template>

<script>
import { AuthStoreNamespacedTypes } from "@/store/modules/auth";
import { WorkspaceStoreNamespacedTypes } from "@/store/modules/workspace";

import { ValidationObserver } from "vee-validate";
import Alert from "@/components/ui/Alert";
import FormInput from "@/components/forms/fields/FormInput";
import FormButton from "@/components/forms/fields/FormButton";
import FormFieldSet from "@/components/forms/fields/FormFieldSet";

export default {
    components: {
        ValidationObserver,
        FormFieldSet,
        FormButton,
        Alert,
        FormInput,
    },
    data: () => ({
        email: null,
        password: null,
    }),
    computed: {
        /**
         * The request loading state
         * @type {Boolean}
         */
        loading() {
            return this.$store.getters[
                AuthStoreNamespacedTypes.getters.LOGIN_LOADING
            ]();
        },

        /**
         * The request error state
         * @type {ErrorOrObject}
         */
        csrfError() {
            return this.$store.getters[
                AuthStoreNamespacedTypes.getters.CSRF_ERROR
            ]();
        },

        /**
         * The request error state
         * @type {ErrorOrObject}
         */
        loginError() {
            return this.$store.getters[
                AuthStoreNamespacedTypes.getters.LOGIN_ERROR
            ]();
        },
    },
    methods: {
        /**
         * Sends authentication credentials to the server.
         */
        login() {
            this.$store
                .dispatch(AuthStoreNamespacedTypes.actions.LOGIN, {
                    email: this.email,
                    password: this.password,
                })
                .then(() => {
                    if (!this.loginError) {
                        this.$emit("authenticated");

                        this.$store.dispatch(
                            WorkspaceStoreNamespacedTypes.actions.GET_WORKSPACE,
                            {
                                force: true,
                            }
                        );
                    }
                });
        },
    },
    beforeCreate() {
        this.$store.commit(
            AuthStoreNamespacedTypes.mutations.SET_LOGIN_ERROR,
            null
        );
    },
};
</script>
