<template>
    <ValidationObserver ref="createUserForm" v-slot="{ passes }">
        <Alert :error="error" class="mb-4" />
        <form @submit.prevent="passes(inviteUser)">
            <div
                class="flex flex-col items-stretch justify-evenly xl:flex-row xl:space-x-4"
            >
                <FormFieldSet class="w-full xl:w-1/2">
                    <FormInput
                        v-model="form.first_name"
                        type="text"
                        rules="required"
                        name="first_name"
                    />
                    <FormInput
                        v-model="form.last_name"
                        type="text"
                        rules="required"
                        name="last_name"
                    />
                </FormFieldSet>
                <FormFieldSet class="w-full xl:w-1/2">
                    <FormInput
                        v-model="form.email"
                        type="email"
                        rules="required"
                        name="email"
                    />
                    <FormSelect
                        v-model="form.role"
                        name="role"
                        rules="required"
                    >
                        <option value="analyst">
                            {{
                                $t(
                                    "settings.workspace.users.role.analyst.title"
                                )
                            }}
                        </option>
                        <option value="manager">
                            {{
                                $t(
                                    "settings.workspace.users.role.manager.title"
                                )
                            }}
                        </option>
                    </FormSelect>
                </FormFieldSet>
            </div>
            <FormFieldSet>
                <div
                    class="flex items-center whitespace-pre-wrap rounded-lg bg-slate-50/50 p-2 text-xs text-slate-600 ring-1 ring-inset ring-slate-500/20"
                    v-html="
                        $t(`settings.workspace.users.role.${form.role}.text`)
                    "
                ></div>
                <div class="col-span-6 flex justify-end">
                    <FormButton type="submit" :loading="loading">
                        {{ $t("settings.workspace.users.add_user") }}
                    </FormButton>
                </div>
            </FormFieldSet>
        </form>
    </ValidationObserver>
</template>

<script>
import { WorkspaceStoreNamespacedTypes } from "@/store/modules/workspace";

import { ValidationObserver } from "vee-validate";
import Alert from "@/components/ui/Alert";
import FormInput from "@/components/forms/fields/FormInput";
import FormFieldSet from "@/components/forms/fields/FormFieldSet";
import FormButton from "@/components/forms/fields/FormButton";
import FormSelect from "@/components/forms/fields/FormSelect";

export default {
    name: "CreateUserForm",
    components: {
        FormSelect,
        FormButton,
        FormFieldSet,
        Alert,
        ValidationObserver,
        FormInput,
    },
    data: () => ({
        loading: false,
        form: {
            first_name: null,
            last_name: null,
            email: null,
            role: "analyst",
        },
    }),
    computed: {
        /**
         * @type {Object}
         */
        error() {
            return this.$store.getters[
                WorkspaceStoreNamespacedTypes.getters.WORKSPACE_ERROR
            ]();
        },
    },
    methods: {
        /**
         * Sends authentication credentials to the server.
         */
        inviteUser() {
            this.loading = true;
            this.$store
                .dispatch(
                    WorkspaceStoreNamespacedTypes.actions.INVITE_USER,
                    this.form
                )
                .then(() => {
                    this.loading = false;
                    if (!this.error) {
                        this.$emit("saved");
                    }
                });
        },
    },
};
</script>
