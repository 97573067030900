<template>
    <DataTable
        :headers="headers"
        :rows="rows"
        :meta="meta"
        :loading="loading"
        :loadingRows="2"
        :error="error"
        @update="refresh"
    >
        <template v-slot:first_name="{ item }">
            <div class="flex items-center">
                <div class="h-10 w-10 flex-shrink-0">
                    <span
                        class="inline-flex h-10 w-10 items-center justify-center rounded-full bg-indigo-500"
                    >
                        <span
                            class="text-sm font-medium leading-none text-white"
                            >{{ item.first_name | initials }}</span
                        >
                    </span>
                </div>
                <div class="ml-4">
                    <div class="text-sm font-medium text-slate-900">
                        {{ item.first_name }} {{ item.last_name }}
                    </div>
                    <div class="text-sm text-slate-500">
                        {{ item.email || "No email found" }}
                    </div>
                </div>
            </div>
        </template>
        <template v-slot:role="{ item }">
            <Badge
                :text="
                    $t(
                        `settings.workspace.users.role.${item.role}.title`
                    ).toString()
                "
            />
        </template>
        <template v-slot:verified_at="{ item }">
            <Badge
                :variant="
                    item.verified_at
                        ? BadgeVariants.SUCCESS
                        : BadgeVariants.WARNING
                "
                :text="
                    item.verified_at
                        ? $t(`settings.verified`).toString()
                        : $t(`settings.verification_required`).toString()
                "
            />
        </template>
        <template v-slot:created_at="{ item }">
            {{ item.created_at | formatDate }}
        </template>
        <template v-slot:action="{ item }">
            <div class="flex justify-end">
                <FormButton
                    @click.native="revokeAccess(item.id)"
                    :variant="ButtonVariants.SECONDARY"
                    :icon="IconEnums.ERROR"
                    :loading="formLoading"
                    >{{
                        $t("settings.workspace.users.remove_user")
                    }}</FormButton
                >
            </div>
        </template>
    </DataTable>
</template>

<script>
import { WorkspaceStoreNamespacedTypes } from "@/store/modules/workspace";

import { IconEnums } from "@/utils/icons";
import { initials } from "@/utils/filters";
import { formatDate } from "@/utils/date";

import DataTableMixin from "@/components/tables/DataTableMixin";
import FormButton, {
    ButtonVariants,
} from "@/components/forms/fields/FormButton";
import Badge, { BadgeVariants } from "@/components/shared/Badge";

export default {
    name: "UserTable",
    mixins: [DataTableMixin],
    components: {
        Badge,
        FormButton,
    },
    filters: {
        /**
         * @type {String}
         */
        initials,

        /**
         * @type {String}
         */
        formatDate,
    },
    data: () => ({
        BadgeVariants,
        IconEnums,
        ButtonVariants,
        formLoading: false,
        headers: [
            {
                title: "user",
                key: "first_name",
                sortable: true,
            },
            {
                title: "role",
                key: "role",
                sortable: false,
            },
            {
                title: "status",
                key: "verified_at",
                sortable: false,
            },
            {
                title: "created",
                key: "created_at",
                sortable: true,
            },
            {
                title: "action",
                key: "action",
                sortable: false,
            },
        ],
    }),
    methods: {
        /**
         * @param {String} id
         */
        revokeAccess(id) {
            this.formLoading = true;
            this.$store
                .dispatch(WorkspaceStoreNamespacedTypes.actions.REVOKE_ACCESS, {
                    id,
                })
                .then(() => {
                    this.formLoading = false;
                });
        },
    },
};
</script>
