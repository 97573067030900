<template>
    <div
        class="flex h-96 flex-col items-center justify-center rounded-xl border bg-slate-50 p-4"
    >
        <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            fill="currentColor"
            class="mx-auto h-12 w-12 text-slate-400"
        >
            <path
                fill-rule="evenodd"
                d="M5.625 1.5H9a3.75 3.75 0 013.75 3.75v1.875c0 1.036.84 1.875 1.875 1.875H16.5a3.75 3.75 0 013.75 3.75v7.875c0 1.035-.84 1.875-1.875 1.875H5.625a1.875 1.875 0 01-1.875-1.875V3.375c0-1.036.84-1.875 1.875-1.875zM9.75 17.25a.75.75 0 00-1.5 0V18a.75.75 0 001.5 0v-.75zm2.25-3a.75.75 0 01.75.75v3a.75.75 0 01-1.5 0v-3a.75.75 0 01.75-.75zm3.75-1.5a.75.75 0 00-1.5 0V18a.75.75 0 001.5 0v-5.25z"
                clip-rule="evenodd"
            />
            <path
                d="M14.25 5.25a5.23 5.23 0 00-1.279-3.434 9.768 9.768 0 016.963 6.963A5.23 5.23 0 0016.5 7.5h-1.875a.375.375 0 01-.375-.375V5.25z"
            />
        </svg>
        <h2 class="mt-3 text-base font-semibold leading-6 text-slate-900">
            {{ $t("automation.placeholder.heading") }}
        </h2>
        <p class="mb-3 mt-1 text-sm text-slate-500">
            {{ $t("automation.placeholder.text") }}
        </p>
        <FormButton
            :to="{
                name: 'automation.edit',
                params: { id: id },
            }"
            :variant="ButtonVariants.SECONDARY"
            :icon="IconEnums.SETTINGS"
        >
            {{ $t("automation.placeholder.button") }}</FormButton
        >
    </div>
</template>

<script>
import FormButton, {
    ButtonVariants,
} from "@/components/forms/fields/FormButton";
import { IconEnums } from "@/utils/icons";

export default {
    name: "AutomationFlowPlaceholder",
    components: { FormButton },
    props: {
        id: {
            type: String,
            required: true,
        },
    },
    data: () => ({
        IconEnums,
        ButtonVariants,
    }),
};
</script>
