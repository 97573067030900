<template>
    <EmployeePage>
        <div
            class="flex flex-col items-stretch justify-evenly xl:flex-row xl:space-x-4"
        >
            <div
                class="mb-4 flex w-full items-start justify-start xl:mb-0 xl:w-1/2"
            >
                <div class="grid w-full grid-cols-1 gap-4 xl:grid-cols-2">
                    <ValueCard
                        :loading="loading"
                        :title="
                            $t(
                                'employees.simulations.received.title'
                            ).toString()
                        "
                        :text="
                            $t('employees.simulations.received.text').toString()
                        "
                        :unit="
                            $t('statistics.simulations.count.unit').toString()
                        "
                        :value="count"
                        class="card"
                    />
                    <ValueCard
                        :loading="loading"
                        :title="
                            $t(
                                'employees.simulations.open_rate.title'
                            ).toString()
                        "
                        :text="
                            $t(
                                'employees.simulations.open_rate.text'
                            ).toString()
                        "
                        :value="openRate"
                        :pct="true"
                        :lower-threshold="7000"
                        class="card"
                    />
                    <ValueCard
                        :loading="loading"
                        :title="
                            $t(
                                'employees.simulations.click_rate.title'
                            ).toString()
                        "
                        :text="
                            $t(
                                'employees.simulations.click_rate.text'
                            ).toString()
                        "
                        :value="clickRate"
                        :pct="true"
                        :lower-threshold="500"
                        class="card"
                    />
                </div>
            </div>
            <div class="w-full xl:w-1/2">
                <RecentSimulations :data="recentSimulations" class="card" />
            </div>
        </div>
    </EmployeePage>
</template>

<script>
import { TeamStoreNamespacedTypes } from "@/store/modules/team";

import { getProperty } from "@/utils/object";

import RecentSimulations from "@/components/display/employee/RecentSimulations";
import EmployeePage from "@/components/layout/EmployeePage";
import FeatureFlagMixin from "@/mixins/FeatureFlagMixin";
import ValueCard from "@/components/statistics/ValueCard";

export default {
    name: "EmployeeSingle",
    mixins: [FeatureFlagMixin],
    components: {
        ValueCard,
        EmployeePage,
        RecentSimulations,
    },
    computed: {
        /**
         * The loading state for the API request.
         * @type {Boolean}
         */
        loading() {
            return this.$store.getters[
                TeamStoreNamespacedTypes.getters.LOADING
            ]();
        },

        /**
         * @type {Object}
         */
        employee() {
            return this.$store.getters[
                TeamStoreNamespacedTypes.getters.EMPLOYEE
            ]();
        },

        /**
         * @type {Array}
         */
        report() {
            return getProperty(this.employee, "report", []);
        },

        /**
         * @type {Object}
         */
        recentSimulations() {
            return getProperty(this.employee, "simulations", []);
        },

        /**
         * @type {Object}
         */
        campaignStats() {
            return getProperty(this.report, "campaigns", {
                click_rate: 0,
                count: 0,
                open_rate: 0,
                click_count: 0,
                open_count: 0,
                send_count: 0,
                unique_click_count: 0,
                unique_open_count: 0,
            });
        },

        /**
         * @type {Number}
         */
        count() {
            return getProperty(this.campaignStats, "send_count", 0);
        },

        /**
         * @type {Number}
         */
        openRate() {
            return getProperty(this.campaignStats, "open_rate", 0);
        },

        /**
         * @type {Number}
         */
        clickRate() {
            return getProperty(this.campaignStats, "click_rate", 0);
        },
    },
    mounted() {
        this.$store.dispatch(
            TeamStoreNamespacedTypes.actions.GET_EMPLOYEE,
            this.$route.params.id
        );
    },
};
</script>
