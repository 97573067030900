import dayjs from "dayjs";
import localeDa from "dayjs/locale/da";

export function formatDateTime(date) {
    return dayjs(date).locale(localeDa).format("DD-MM-YYYY HH:mm");
}

export function formatDate(date) {
    return dayjs(date).locale(localeDa).format("DD-MM-YYYY");
}

export function isRecentEvent(date) {
    return dayjs(date).isAfter(dayjs().subtract(2, "minutes"));
}
