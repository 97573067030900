<template>
    <div class="h-full overflow-auto px-4">
        <div class="mb-4 flex flex-col space-y-4 rounded-xl bg-white p-4">
            <div class="flex w-full flex-row items-center">
                <Avatar
                    :size="SizeVariants.BIG"
                    :image="avatar"
                    :name="full_name"
                />
                <div class="ml-3 flex flex-col">
                    <p class="truncate font-medium leading-6">
                        {{ full_name }}
                    </p>
                    <p class="text-slate-500">{{ email }}</p>
                </div>
            </div>
            <FormButton
                :variant="ButtonVariants.SECONDARY"
                @click.native="goToProfile"
            >
                {{ $t("employees.preview.profile") }}
            </FormButton>
        </div>
        <div class="card mb-4 flex flex-col space-y-4">
            <div class="flex flex-col divide-y divide-slate-100">
                <ListItem :title="$t('employees.preview.status').toString()">
                    <Badge
                        :variant="
                            status === 'active'
                                ? BadgeVariants.SUCCESS
                                : BadgeVariants.ERROR
                        "
                        :text="$t(`employees.${status}`).toString()"
                    />
                </ListItem>
            </div>
        </div>
    </div>
</template>

<script>
import { TeamStoreNamespacedTypes } from "@/store/modules/team";

import { getProperty } from "@/utils/object";
import FormButton, {
    ButtonVariants,
} from "@/components/forms/fields/FormButton";
import Badge, { BadgeVariants } from "@/components/shared/Badge";
import Avatar, { SizeVariants } from "@/components/shared/Avatar";
import ListItem from "@/components/ui/ListItem";

export default {
    name: "ParticipationPreview",
    components: {
        ListItem,
        Avatar,
        Badge,
        FormButton,
    },
    props: {
        employee: {
            type: Object,
            required: true,
        },
    },
    data: () => ({
        SizeVariants,
        BadgeVariants,
        ButtonVariants,
    }),
    computed: {
        /**
         * @type {Object}
         */
        loading() {
            return this.$store.getters[
                TeamStoreNamespacedTypes.getters.LOADING
            ]();
        },

        id() {
            return getProperty(this.employee, "id", null);
        },
        avatar() {
            return getProperty(this.employee, "avatar", null);
        },
        full_name() {
            return getProperty(this.employee, "full_name", null);
        },
        status() {
            return getProperty(this.employee, "status", null);
        },
        group_count() {
            return getProperty(this.employee, "group_count", null);
        },
        email() {
            return getProperty(this.employee, "email", null);
        },
    },
    methods: {
        goToProfile() {
            this.$router
                .push({
                    name: "employee.single.index",
                    params: { id: this.id },
                })
                .then(() => {
                    this.$emit("close");
                });
        },
    },
};
</script>
