<template>
    <ValidationObserver ref="campaignCreateForm" v-slot="{ passes }">
        <form @submit.prevent="passes(createCampaign)">
            <div v-if="step === 1">
                <div class="mb-6 text-center">
                    <h4 class="mb-2 text-lg font-bold text-slate-800">
                        {{ $t("simulations.create.type.title") }}
                    </h4>
                    <p class="text-slate-500">
                        {{ $t("simulations.create.type.text") }}
                    </p>
                </div>
                <FormFieldSet>
                    <FormRadioGroup
                        v-model="form.delivery"
                        :options="deliveryOptions"
                        :inline="true"
                        :showLabels="false"
                        rules="required"
                        name="delivery"
                    />
                </FormFieldSet>
                <div class="flex flex-col">
                    <FormButton @click="step++">
                        {{ $t("simulations.create.type.button") }}</FormButton
                    >
                </div>
            </div>
            <div v-else>
                <div class="mb-6 text-center">
                    <h4 class="mb-2 text-lg font-bold text-slate-800">
                        {{ $t("simulations.create.configuration.title") }}
                    </h4>
                    <p class="text-slate-500">
                        {{ $t("simulations.create.type.text") }}
                    </p>
                </div>
                <FormFieldSet>
                    <Alert :error="error" />
                    <FormInput
                        v-model="form.name"
                        rules="required"
                        name="name"
                    />
                    <FormSelect v-model="form.group_id" name="audience">
                        <option
                            v-for="group in rows"
                            :key="group.id"
                            :value="group.id"
                        >
                            {{ group.name }}
                        </option>
                    </FormSelect>
                    <FormToggle
                        v-model="form.token_header"
                        type="text"
                        name="token_header"
                    />
                </FormFieldSet>
                <FormFieldSet v-if="form.token_header">
                    <FormInput v-model="form.token" name="token" />
                    <div class="col-span-6 block w-full text-sm text-slate-500">
                        {{ $t("simulations.create.configuration.token") }}

                        <div
                            class="inline-block rounded-full bg-slate-50/50 px-2 py-0.5 text-xs font-medium text-slate-700 ring-1 ring-inset ring-slate-500/20"
                        >
                            X-MOXSO-CAMPAIGN-TOKEN
                        </div>
                        {{ $t("simulations.create.configuration.token_text") }}
                    </div>
                </FormFieldSet>
                <div class="flex flex-col">
                    <FormButton
                        :loading="creationLoading"
                        @click="createCampaign"
                    >
                        {{ $t("forms.buttons.create_campaign") }}
                    </FormButton>
                    <p
                        class="mx-1 mt-4 flex cursor-pointer flex-row items-center justify-center text-slate-800 hover:text-slate-700"
                        @click="step = 1"
                    >
                        <Icon
                            :icon="IconEnums.BACK"
                            class="mr-2 size-5 text-slate-400"
                        />
                        {{ $t("simulations.create.configuration.choose_type") }}
                    </p>
                </div>
            </div>
        </form>
    </ValidationObserver>
</template>

<script>
import { CampaignStoreNamespacedTypes } from "@/store/modules/campaign";
import { TeamStoreNamespacedTypes } from "@/store/modules/team";

import { getProperty } from "@/utils/object";

import { ValidationObserver } from "vee-validate";
import { IconEnums } from "@/utils/icons";
import FormButton from "@/components/forms/fields/FormButton";
import FormInput from "@/components/forms/fields/FormInput";
import FormSelect from "@/components/forms/fields/FormSelect";
import FormRadioGroup from "@/components/forms/fields/FormRadioGroup";
import FormFieldSet from "@/components/forms/fields/FormFieldSet";
import FormToggle from "@/components/forms/fields/FormToggle";
import Icon from "@/components/icons/BaseIcon";
import Alert from "@/components/ui/Alert";

export default {
    components: {
        FormToggle,
        FormFieldSet,
        FormRadioGroup,
        FormSelect,
        FormInput,
        FormButton,
        ValidationObserver,
        Icon,
        Alert,
    },
    data: () => ({
        IconEnums,
        step: 1,
        creationLoading: false,
        form: {
            name: null,
            group_id: null,
            delivery: "one_off",
            token_header: false,
            token: null,
        },
    }),
    computed: {
        /**
         * @type {Object}
         */
        error() {
            return this.$store.getters[
                CampaignStoreNamespacedTypes.getters.CAMPAIGNS_ERROR
            ]();
        },
        groups() {
            return this.$store.getters[
                TeamStoreNamespacedTypes.getters.GROUPS
            ]();
        },
        /**
         * @type {Array}
         */
        rows() {
            return getProperty(this.groups, "data", []);
        },
        /**
         * @type {Array}
         */
        deliveryOptions() {
            return [
                {
                    icon: IconEnums.SIMULATION,
                    title: this.$t("forms.options.delivery.one_off.title"),
                    text: this.$t("forms.options.delivery.one_off.text"),
                    value: "one_off",
                },
                {
                    icon: IconEnums.REFRESH,
                    title: this.$t("forms.options.delivery.recurring.title"),
                    text: this.$t("forms.options.delivery.recurring.text"),
                    value: "recurring",
                },
            ];
        },
    },
    methods: {
        createCampaign() {
            this.creationLoading = true;

            this.$store
                .dispatch(
                    CampaignStoreNamespacedTypes.actions.CREATE_CAMPAIGN,
                    this.form
                )
                .then((campaign) => {
                    this.$emit("saved", campaign);
                })
                .finally(() => {
                    this.creationLoading = false;
                });
        },
    },
    mounted() {
        this.$store.dispatch(TeamStoreNamespacedTypes.actions.GET_ALL_GROUPS);
    },
};
</script>
