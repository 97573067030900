<template>
    <div
        ref="modal-backdrop"
        class="fixed inset-0 z-50 overflow-y-auto bg-black bg-opacity-50"
        v-show="active"
    >
        <div
            class="flex min-h-screen items-start justify-center py-5 text-center"
        >
            <transition
                enter-active-class="transition ease-out duration-100"
                enter-class="transform opacity-0 scale-95"
                enter-to-class="transform opacity-100 scale-100"
                leave-active-class="transition ease-in duration-75"
                leave-class="transform opacity-100 scale-100"
                leave-to-class="transform opacity-0 scale-95"
            >
                <div
                    class="relative mb-0 inline-flex max-h-full w-full max-w-xl flex-col overflow-hidden rounded-t-2xl bg-white text-left shadow-xl transition-all sm:rounded-xl"
                    :class="size"
                    role="dialog"
                    ref="modal"
                    aria-modal="true"
                    v-show="showPrompt"
                    aria-labelledby="modal-headline"
                >
                    <div
                        class="flex items-center justify-between px-6 py-4 text-lg font-medium"
                    >
                        <slot name="header" />
                        <div
                            class="ml-3 inline-flex cursor-pointer items-center justify-center rounded-full bg-slate-200 p-1.5 text-slate-500 transition-all duration-300 hover:rotate-90 hover:bg-slate-300 hover:text-slate-600 focus:outline-none focus:ring-2 focus:ring-offset-2 sm:text-sm"
                            @click="close"
                        >
                            <Icon :icon="IconEnums.CLOSE" class="size-5" />
                        </div>
                    </div>
                    <div :class="{ 'px-6 py-4': gutter }">
                        <slot name="content" />
                    </div>
                </div>
            </transition>
        </div>
    </div>
</template>

<script>
import { IconEnums } from "@/utils/icons";
import Icon from "@/components/icons/BaseIcon";

export default {
    name: "ModalPrompt",
    components: { Icon },
    props: {
        active: {
            type: Boolean,
            default: false,
        },
        size: {
            type: String,
            default: "w-full",
        },
        gutter: {
            type: Boolean,
            default: true,
        },
    },
    data: () => ({
        IconEnums,
        showPrompt: false,
    }),
    watch: {
        active(newValue) {
            setTimeout(() => {
                this.showPrompt = newValue;
            }, 200);
        },
    },
    methods: {
        close() {
            this.$emit("close");
        },
    },
    mounted: function () {
        document.addEventListener("keydown", (e) => {
            if (e.keyCode === 27) {
                this.close();
            }
        });
    },
};
</script>
