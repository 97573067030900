/**
 * A store domain.
 *
 * @typedef {{ domainsLoading: boolean, domainsError: ErrorOrObject }} DomainStoreState
 */
import { addNamespace } from "./namespace";
import { postData } from "@/api";

/**
 * The types used in this store
 * @enum {string}
 */
export const DomainStoreTypes = {
    getters: {
        DOMAINS_LOADING: "domainsLoading",
        DOMAINS_ERROR: "domainsError",
    },
    actions: {
        CREATE_DOMAIN: "createDomain",
        VERIFY_DOMAIN: "verifyDomain",
    },
    mutations: {
        SET_DOMAINS_LOADING: "setDomainsLoading",
        SET_DOMAINS_ERROR: "setDomainsError",
    },
};

/**
 * A namespaced version of the types used in this store
 * @enum {string}
 */
export const DomainStoreNamespacedTypes = addNamespace(
    "domain",
    DomainStoreTypes
);

/**
 * @returns {DomainStoreState}
 */
export function state() {
    return {
        domainsLoading: false,
        domainsError: null,
    };
}

export const getters = {
    [DomainStoreTypes.getters.DOMAINS_LOADING]: (state) => () => {
        return state.domainsLoading;
    },
    [DomainStoreTypes.getters.DOMAINS_ERROR]: (state) => () => {
        return state.domainsError;
    },
};

export const actions = {
    /**
     * Sends the given domains to the server.
     *
     * @param {VuexCommit} commit
     * @param {Object} data
     * @return {Promise}
     */
    [DomainStoreTypes.actions.CREATE_DOMAIN]({ commit }, data) {
        commit(DomainStoreTypes.mutations.SET_DOMAINS_LOADING, true);
        commit(DomainStoreTypes.mutations.SET_DOMAINS_ERROR, null);

        return postData("/workspace/domains", data)
            .catch((errors) => {
                commit(DomainStoreTypes.mutations.SET_DOMAINS_ERROR, errors);
            })
            .finally(() => {
                commit(DomainStoreTypes.mutations.SET_DOMAINS_LOADING, false);
            });
    },

    /**
     * Sends the given domains to the server.
     *
     * @param {VuexCommit} commit
     * @param {String} domain
     * @return {Promise}
     */
    [DomainStoreTypes.actions.VERIFY_DOMAIN]({ commit }, domain) {
        commit(DomainStoreTypes.mutations.SET_DOMAINS_ERROR, null);

        return postData("/workspace/domains/" + domain).catch((errors) => {
            commit(DomainStoreTypes.mutations.SET_DOMAINS_ERROR, errors);
        });
    },
};

export const mutations = {
    /**
     * Sets the loading state for domains.
     *
     * @param {DomainStoreState} state
     * @param {boolean} loading
     */
    [DomainStoreTypes.mutations.SET_DOMAINS_LOADING](state, loading) {
        state.domainsLoading = loading;
    },

    /**
     * Sets a new domains error.
     *
     * @param {DomainStoreState} state
     * @param {ErrorOrObject} error
     */
    [DomainStoreTypes.mutations.SET_DOMAINS_ERROR](state, error) {
        state.domainsError = error;
    },
};

export default {
    namespaced: true,
    Types: DomainStoreTypes,
    NamespacedTypes: DomainStoreNamespacedTypes,
    state,
    getters,
    actions,
    mutations,
};
