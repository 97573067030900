<template>
    <ValidationObserver ref="resetPasswordForm" v-slot="{ passes }">
        <form @submit.prevent="passes(resetPassword)">
            <FormFieldSet>
                <Alert :error="error" />
                <FormInput
                    v-model="email"
                    type="email"
                    rules="required"
                    name="email"
                />
                <div class="flex justify-end">
                    <FormButton type="submit" :loading="loading">
                        {{ $t("forms.buttons.forgot_password") }}
                    </FormButton>
                </div>
            </FormFieldSet>
        </form>
    </ValidationObserver>
</template>

<script>
import { AuthStoreNamespacedTypes } from "@/store/modules/auth";

import { ValidationObserver } from "vee-validate";
import Alert from "@/components/ui/Alert";
import FormInput from "@/components/forms/fields/FormInput";
import FormFieldSet from "@/components/forms/fields/FormFieldSet";
import FormButton from "@/components/forms/fields/FormButton";

export default {
    components: {
        ValidationObserver,
        FormButton,
        FormFieldSet,
        Alert,
        FormInput,
    },
    data: () => ({
        email: null,
    }),
    computed: {
        /**
         * The loading state of the login request.
         * @type {boolean}
         */
        loading() {
            return this.$store.getters[
                AuthStoreNamespacedTypes.getters.RESET_PASSWORD_LOADING
            ]();
        },

        /**
         * Errors while processing login request.
         * @type {Object}
         */
        error() {
            return this.$store.getters[
                AuthStoreNamespacedTypes.getters.RESET_PASSWORD_ERROR
            ]();
        },
    },
    methods: {
        /**
         * Sends password reset credentials to the server.
         */
        resetPassword() {
            this.$store
                .dispatch(
                    AuthStoreNamespacedTypes.actions.RESET_PASSWORD,
                    this.email
                )
                .then(() => {
                    if (!this.error) {
                        this.$emit("sent");
                    }
                });
        },
    },
    beforeCreate() {
        this.$store.commit(
            AuthStoreNamespacedTypes.mutations.SET_RESET_PASSWORD_ERROR,
            null
        );
    },
};
</script>
