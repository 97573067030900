<template>
    <ValidationObserver ref="createGroupForm" v-slot="{ passes }">
        <Alert :error="error" />
        <form @submit.prevent="passes(createGroup)" class="-mb-2">
            <FormFieldSet>
                <FormInput
                    v-model="form.name"
                    type="text"
                    rules="required"
                    name="name"
                />
                <FormFieldOutline>
                    <div class="flex flex-row items-center justify-between">
                        <FormToggle
                            v-model="form.import_from_provider"
                            name="import_from_provider"
                        />
                        <div class="flex flex-row justify-end">
                            <Icon :icon="IconEnums.GOOGLE" class="h-4 w-4" />
                            <Icon
                                :icon="IconEnums.MICROSOFT"
                                class="mx-3 h-4 w-4"
                            />
                            <Icon :icon="IconEnums.SLACK" class="h-4 w-4" />
                        </div>
                    </div>
                    <fieldset
                        class="mb-4 mt-2 flex flex-col space-y-4 md:flex-row md:space-x-4 md:space-y-0"
                        v-if="form.import_from_provider"
                    >
                        <FormSelect
                            class="md:w-1/2"
                            v-model="form.provider"
                            rules="required"
                            name="provider"
                        >
                            <option
                                v-for="integration in integrations"
                                :key="integration.key"
                                :value="integration.key"
                            >
                                {{
                                    $t(
                                        "integrations.provider." +
                                            integration.key +
                                            ".title"
                                    )
                                }}
                            </option>
                        </FormSelect>
                        <FormInput
                            class="md:w-1/2"
                            v-model="form.provider_id"
                            type="text"
                            rules="required"
                            name="provider_id"
                        />
                    </fieldset>
                </FormFieldOutline>
                <div class="flex justify-end">
                    <FormButton type="submit" :loading="loading">
                        {{ $t("forms.buttons.create_group") }}
                    </FormButton>
                </div>
            </FormFieldSet>
        </form>
    </ValidationObserver>
</template>

<script>
import { TeamStoreNamespacedTypes } from "@/store/modules/team";

import { ValidationObserver } from "vee-validate";
import { integrations } from "@/utils/enums";
import { IconEnums } from "@/utils/icons";
import Icon from "@/components/icons/BaseIcon";
import Alert from "@/components/ui/Alert";
import FormInput from "@/components/forms/fields/FormInput";
import FormFieldSet from "@/components/forms/fields/FormFieldSet";
import FormButton from "@/components/forms/fields/FormButton";
import FormToggle from "@/components/forms/fields/FormToggle";
import FormSelect from "@/components/forms/fields/FormSelect";
import FormFieldOutline from "@/components/forms/fields/FormFieldOutline";

export default {
    components: {
        FormFieldOutline,
        FormSelect,
        FormToggle,
        FormButton,
        FormFieldSet,
        Alert,
        ValidationObserver,
        FormInput,
        Icon,
    },
    data: () => ({
        integrations,
        IconEnums,
        form: {
            name: null,
            import_from_provider: false,
            provider: "microsoft",
            provider_id: null,
        },
    }),
    computed: {
        /**
         * The loading state for the API request.
         * @type {Boolean}
         */
        loading() {
            return this.$store.getters[
                TeamStoreNamespacedTypes.getters.LOADING
            ]();
        },

        /**
         * The error state of the API request.
         * @type {Object}
         */
        error() {
            return this.$store.getters[
                TeamStoreNamespacedTypes.getters.ERROR
            ]();
        },
    },
    methods: {
        /**
         * Sends authentication credentials to the server.
         */
        createGroup() {
            this.$store
                .dispatch(
                    TeamStoreNamespacedTypes.actions.CREATE_GROUP,
                    this.form
                )
                .then(() => {
                    if (!this.error) {
                        this.$emit("stored");
                    }
                });
        },
    },
    beforeCreate() {
        this.$store.commit(TeamStoreNamespacedTypes.mutations.SET_ERROR, null);
    },
};
</script>
