<template>
    <path
        fill="none"
        stroke="currentColor"
        stroke-linecap="round"
        stroke-linejoin="round"
        stroke-width="2"
        d="M15.97 4a20.758 20.758 0 0 1 3.885 3.679.64.64 0 0 1 .145.404m-4.03 4.084a20.76 20.76 0 0 0 3.885-3.68.64.64 0 0 0 .145-.404m0 0H9.037C6.255 8.083 4 10.303 4 13.042 4 15.78 6.255 18 9.037 18H12"
    />
</template>

<script>
import IconSvgBase from "@/components/icons/IconSvgBase";

export default {
    extends: IconSvgBase,
    name: "IconRedo",
};
</script>
