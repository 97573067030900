<template>
    <ValidationProvider
        tag="div"
        class="form-field form-field-input"
        :vid="vid"
        :rules="rules"
        :name="name || label"
        v-slot="{ errors, ariaInput, ariaMsg }"
    >
        <label
            class="pointer-events-none mb-2 block text-sm font-medium text-slate-900"
            @click="$refs.input.focus()"
            :for="id || name"
            v-if="label"
        >
            {{ label || name }}
        </label>
        <FormSelect
            :class="{ 'is-invalid': errors[0] }"
            :id="id || name"
            ref="input"
            v-model="innerValue"
            v-bind="ariaInput"
        >
            <option value="TODAY">
                {{ $t("settings.export.form.dates.today") }}
            </option>
            <option value="7-DAYS">
                {{ $t("settings.export.form.dates.days", { days: 7 }) }}
            </option>
            <option value="4-WEEKS">
                {{ $t("settings.export.form.dates.weeks", { weeks: 4 }) }}
            </option>
            <option value="3-MONTHS">
                {{ $t("settings.export.form.dates.months", { months: 3 }) }}
            </option>
            <option value="6-MONTHS">
                {{
                    $t("settings.export.form.dates.months", {
                        months: 6,
                    })
                }}
            </option>
            <option value="12-MONTHS">
                {{ $t("settings.export.form.dates.months", { months: 12 }) }}
            </option>
            <option value="MTD">
                {{ $t("settings.export.form.dates.mtd") }}
            </option>
            <option value="QTD">
                {{ $t("settings.export.form.dates.qtd") }}
            </option>
            <option value="YTD">
                {{ $t("settings.export.form.dates.ytd") }}
            </option>
            <option v-if="isExport" value="DEFAULT">
                {{ $t("settings.export.form.dates.default") }}
            </option>
        </FormSelect>
        <span
            class="mt-3 text-sm text-red-600"
            v-bind="ariaMsg"
            v-if="errors[0]"
            >{{ errors[0] }}</span
        >
    </ValidationProvider>
</template>

<script>
import FieldMixin from "./FieldMixin";
import FormSelect from "@/components/forms/fields/FormSelect";

export default {
    name: "FormDateRangeSelect",
    components: { FormSelect },
    mixins: [FieldMixin],
    props: {
        type: {
            type: String,
            required: false,
            default: "EXPORT",
        },
    },
    computed: {
        isExport() {
            return this.type === "EXPORT";
        },
    },
};
</script>
