<template>
    <ValidationObserver ref="RemoveTagActionForm" v-slot="{ passes }">
        <form @submit.prevent="passes(saveAction)">
            <FormFieldSet>
                <FormSelect v-model="form.tag_id" rules="required" name="tag">
                    <option v-for="tag in tags" :key="tag.id" :value="tag.id">
                        {{ tag.name }}
                    </option>
                </FormSelect>
            </FormFieldSet>
            <div class="flex justify-end">
                <FormButton
                    type="submit"
                    :variant="ButtonVariants.SECONDARY"
                    :loading="loading"
                >
                    {{ $t("automation.save_step") }}
                </FormButton>
            </div>
        </form>
    </ValidationObserver>
</template>

<script>
import { AutomationStoreNamespacedTypes } from "@/store/modules/automation";

import { getProperty } from "@/utils/object";

import AutomationActionMixin from "@/mixins/AutomationActionMixin";
import FormSelect from "@/components/forms/fields/FormSelect";

export default {
    name: "RemoveTagAction",
    components: { FormSelect },
    mixins: [AutomationActionMixin],
    data: () => ({
        form: {
            tag_id: null,
        },
    }),
    computed: {
        /**
         * @type {Array}
         */
        tags() {
            return this.$store.getters[
                AutomationStoreNamespacedTypes.getters.TAGS
            ]();
        },
    },
    created() {
        this.form = {
            tag_id: getProperty(this.action, "attributes.tag_id", null),
        };
    },
};
</script>
