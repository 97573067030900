<template>
    <g>
        <defs
            ><linearGradient x1="50%" y1="0%" x2="50%" y2="100.002%" id="fsdf2"
                ><stop stop-color="#00AEEF" offset="0%" /><stop
                    stop-color="#0097DC"
                    offset="21.2%" /><stop
                    stop-color="#007CC5"
                    offset="51.9%" /><stop
                    stop-color="#006CB8"
                    offset="79.2%" /><stop
                    stop-color="#0066B3"
                    offset="100%" /></linearGradient></defs
        ><g fill="none" fill-rule="evenodd"
            ><path
                fill="url(#fsdf2)"
                d="M396.7 512 512 399.653V0H0v512z" /><path
                d="M403.155 178.408h-62.744l.21 147.607-54.637-147.663H231.81L185.17 301.67c-4.958-31.38-37.392-42.207-62.912-50.318-16.852-5.413-34.738-13.377-34.557-22.18.14-7.222 9.568-13.922 28.307-12.923 12.57.678 23.675 1.69 45.766 12.365l21.72-37.862C163.352 180.497 135.5 174.02 112.662 174h-.14c-26.63 0-48.804 8.627-62.548 22.843-9.582 9.92-14.75 22.536-14.96 36.486-.35 19.197 6.684 32.805 21.462 43.682 12.487 9.151 28.46 15.089 42.532 19.448 17.356 5.379 31.533 10.06 31.359 20.02-.14 3.633-1.509 7.029-4.12 9.767-4.331 4.47-10.966 6.147-20.15 6.329-17.718.377-30.848-2.41-51.772-14.782L35 356.145C55.903 368.035 78.091 374 103.304 374l5.67-.042c21.944-.398 39.754-5.658 53.903-17.045.81-.65 1.537-1.306 2.291-1.97l-2.375 12.239 52.94-.168 9.497-24.324c9.988 3.41 21.344 5.295 33.398 5.295 11.747 0 22.796-1.788 32.594-5.001l6.621 24.03 94.983.091.23-55.452h20.212c48.853 0 77.732-24.87 77.732-66.574-.016-46.447-28.09-66.678-87.845-66.678v.007ZM258.628 304.583c-7.298 0-14.143-1.271-20.03-3.507l19.806-62.556h.384l19.486 62.731c-5.867 2.096-12.55 3.332-19.653 3.332h.007Zm148.2-35.941h-13.786v-50.416h13.794c18.367 0 33.034 6.12 33.034 24.883-.016 19.42-14.667 25.533-33.034 25.533"
                fill="#FFF"
        /></g>
    </g>
</template>

<script>
import IconSvgBase from "./IconSvgBase";

export default {
    extends: IconSvgBase,
    name: "IconSap",
    data: () => ({
        viewBoxHeight: 512,
        viewBoxWidth: 512,
    }),
};
</script>
