<template>
    <div class="flex flex-col items-stretch space-y-4 px-6 pb-4">
        <div
            class="flex flex-col divide-y divide-slate-200 rounded-xl border bg-slate-50"
        >
            <div
                class="flex items-center space-x-14 px-4 py-2.5 text-sm font-medium"
            >
                <span class="text-slate-500">{{
                    $t("threats.browser_isolation.scheme")
                }}</span>
                <div
                    class="flex-grow truncate text-right text-slate-900"
                    v-text="scheme"
                ></div>
            </div>
            <div
                class="flex items-center space-x-14 px-4 py-2.5 text-sm font-medium"
            >
                <span class="text-slate-500">{{
                    $t("threats.browser_isolation.host")
                }}</span>
                <div
                    class="flex-grow truncate text-right text-slate-900"
                    v-text="host"
                ></div>
            </div>
            <div
                class="flex items-center space-x-14 px-4 py-2.5 text-sm font-medium"
            >
                <span class="text-slate-500">{{
                    $t("threats.browser_isolation.path")
                }}</span>
                <div
                    class="flex-grow truncate text-right text-slate-900"
                    v-text="path"
                ></div>
            </div>
        </div>
        <FormButton
            :variant="ButtonVariants.PRIMARY"
            :href="url"
            target="_blank"
        >
            {{ $t("threats.browser_isolation.button") }}
        </FormButton>
        <div
            class="flex items-center whitespace-pre-wrap rounded-lg bg-yellow-50/50 p-2 text-xs text-warning-dark ring-1 ring-inset ring-yellow-500/20"
            v-text="$t('threats.browser_isolation.notice')"
        ></div>
        <div class="flex items-center justify-center space-x-2 pt-2">
            <p class="text-sm font-medium text-slate-500">
                {{ $t("threats.browser_isolation.secured_by") }}
            </p>
            <svg
                class="size-5"
                viewBox="0 0 256 229"
                xmlns="http://www.w3.org/2000/svg"
            >
                <path
                    d="M130.27 120.093c-7.733.287-15.332.434-22.702.434-50.408 0-90.229-6.837-90.796-22.086-.441-11.846 20.008-20.871 52.095-26.162l-.059 5.011-.13 10.967 9.998 4.51c15.607 7.041 31.205 10.611 46.359 10.611 17.03 0 33.747-4.484 49.687-13.328l8.382-4.651.243-9.583.186-7.351c32.283 2.784 55.282 9.426 55.695 20.547.65 17.479-48.336 28.828-108.958 31.081zM85.657 69.985l.136-.017.198-16.889-.179.022.044-.022.062-5.276c2.938-20.906 22.029-31.04 40.995-31.04 19.37 0 38.611 10.573 40.375 31.04l-.072 2.848-.424 16.745-.203 7.996c-13.719 7.612-27.573 11.223-41.554 11.223-13.05 0-26.211-3.148-39.466-9.127l.088-7.503zm128.61-14.139c-8.66-1.818-18.878-3.209-30.309-4.164l.087-3.454.024-.934-.08-.93C181.673 19.499 157.669 0 126.913 0 96.764 0 73.08 18.698 69.319 45.471l-.15 1.062-.012 1.073-.09 7.665c-10.231 1.588-19.461 3.523-27.463 5.794C13.372 69.074-.619 81.859.021 99.064c.969 26.079 35.143 38.225 107.547 38.225 7.528 0 15.376-.149 23.325-.445 32.263-1.199 60.336-4.793 81.182-10.393 29.774-7.999 44.546-20.805 43.905-38.062-.892-23.976-31.617-30.424-41.713-32.543zm-81.906 172.507a8.382 8.382 0 0 1-8.375-8.152l-1.746-63.908a8.384 8.384 0 0 1 8.15-8.608c4.598-.077 8.482 3.522 8.608 8.149l1.746 63.908a8.383 8.383 0 0 1-8.383 8.61zm53.436-23.745a8.385 8.385 0 0 1-7.897-5.577l-16.763-47.145a8.382 8.382 0 0 1 15.794-5.615l16.763 47.145a8.381 8.381 0 0 1-5.09 10.705 8.359 8.359 0 0 1-2.807.487zM78.242 207.75a8.381 8.381 0 0 1-8.141-10.402l11.524-46.447a8.38 8.38 0 0 1 10.154-6.115 8.38 8.38 0 0 1 6.115 10.153L86.37 201.386a8.385 8.385 0 0 1-8.128 6.364z"
                    fill="#677489"
                />
            </svg>
        </div>
    </div>
</template>

<script>
import FormButton, {
    ButtonVariants,
} from "@/components/forms/fields/FormButton";
import { getProperty } from "@/utils/object";

export default {
    name: "LinkPreview",
    components: { FormButton },
    props: {
        link: {
            type: Object,
            required: true,
        },
    },
    data: () => ({
        ButtonVariants,
    }),
    computed: {
        destination() {
            return getProperty(this.link, "text");
        },
        url() {
            return (
                "https://www.browserling.com/browse/win10/chrome116/" +
                this.destination
            );
        },
        scheme() {
            return getProperty(this.link, "scheme");
        },
        host() {
            return getProperty(this.link, "host");
        },
        path() {
            return getProperty(this.link, "path");
        },
    },
};
</script>
