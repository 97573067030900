/**
 * A store lesson.
 *
 * @typedef {{ lessonLoading: boolean, lessonError: ErrorOrObject, lessons: Object, lesson: Object, participationLoading: boolean, participationError: ErrorOrObject, participation: Object, deadlines: Object }} TrainingStoreState
 */
import { addNamespace } from "./namespace";
import { getData, postData } from "@/api";
import { getProperty } from "@/utils/object";
import { getLocale } from "@/lang";

/**
 * The types used in this store
 * @enum {string}
 */
export const TrainingStoreTypes = {
    getters: {
        LESSON_LOADING: "lessonLoading",
        LESSON_ERROR: "lessonError",
        LESSONS: "lessons",
        LESSON: "lesson",
        PARTICIPATION_LOADING: "participationLoading",
        PARTICIPATION_ERROR: "participationError",
        PARTICIPATION: "participation",
        DEADLINES: "deadlines",
    },
    actions: {
        GET_LESSONS: "getLessons",
        GET_LESSON: "getLesson",
        GET_PARTICIPATION: "getParticipation",
        EXPORT_LIST: "exportList",
        GET_DEADLINES: "getDeadlines",
        DELETE_DEADLINE: "deleteDeadline",
        SEND_REMINDER: "sendReminder",
        SEND_BULK_REMINDER: "sendBulkReminder",
        EXPORT_DEADLINE_LIST: "exportDeadlineList",
    },
    mutations: {
        SET_LESSON_LOADING: "setLessonLoading",
        SET_LESSON_ERROR: "setLessonError",
        SET_LESSONS: "setLessons",
        SET_LESSON: "setLesson",
        SET_PARTICIPATION_LOADING: "setParticipationLoading",
        SET_PARTICIPATION_ERROR: "setParticipationError",
        SET_PARTICIPATION: "setParticipation",
        SET_DEADLINES: "setDeadlines",
        REMOVE_DEADLINE: "removeDeadline",
        UPDATE_DEADLINE_REMINDER_TIMESTAMP: "updateDeadlineReminderTimestamp",
        UPDATE_DEADLINE_REMINDER_TIMESTAMPS: "updateDeadlineReminderTimestamps",
    },
};

/**
 * A namespaced version of the types used in this store
 * @enum {string}
 */
export const TrainingStoreNamespacedTypes = addNamespace(
    "training",
    TrainingStoreTypes
);

/**
 * @returns {TrainingStoreState}
 */
export function state() {
    return {
        lessonLoading: false,
        lessonError: null,
        lessons: {
            data: [],
            meta: {
                current_page: 1,
                from: 0,
                last_page: 1,
                per_page: 10,
                to: 10,
                total: 0,
            },
        },
        lesson: {
            title: null,
            description: null,
            image: null,
        },
        participationLoading: false,
        participationError: null,
        participation: {
            data: [],
            meta: {
                current_page: 1,
                from: 0,
                last_page: 1,
                per_page: 10,
                to: 10,
                total: 0,
            },
        },
        deadlines: {
            data: [],
            meta: {
                current_page: 1,
                from: 0,
                last_page: 1,
                per_page: 10,
                to: 10,
                total: 0,
            },
        },
    };
}

export const getters = {
    [TrainingStoreTypes.getters.LESSON_LOADING]: (state) => () => {
        return state.lessonLoading;
    },
    [TrainingStoreTypes.getters.LESSON_ERROR]: (state) => () => {
        return state.lessonError;
    },
    [TrainingStoreTypes.getters.LESSONS]: (state) => () => {
        return state.lessons;
    },
    [TrainingStoreTypes.getters.LESSON]: (state) => () => {
        return state.lesson;
    },
    [TrainingStoreTypes.getters.PARTICIPATION_LOADING]: (state) => () => {
        return state.participationLoading;
    },
    [TrainingStoreTypes.getters.PARTICIPATION_ERROR]: (state) => () => {
        return state.participationError;
    },
    [TrainingStoreTypes.getters.PARTICIPATION]: (state) => () => {
        return state.participation;
    },
    [TrainingStoreTypes.getters.DEADLINES]: (state) => () => {
        return state.deadlines;
    },
};

export const actions = {
    /**
     * Sends the given lesson to the server.
     *
     * @param {VuexCommit} commit
     * @param {Object} data
     * @return {Promise}
     */
    [TrainingStoreTypes.actions.GET_LESSONS]({ commit }, data) {
        commit(TrainingStoreTypes.mutations.SET_LESSON_LOADING, true);
        commit(TrainingStoreTypes.mutations.SET_LESSON_ERROR, null);

        return postData("/training", data)
            .then((response) => {
                commit(TrainingStoreTypes.mutations.SET_LESSONS, {
                    data: getProperty(response.data, "data", []),
                    meta: getProperty(response.data, "meta", {
                        current_page: 1,
                        from: 0,
                        last_page: 1,
                        per_page: 10,
                        to: 10,
                        total: 0,
                    }),
                });
            })
            .catch((errors) => {
                commit(TrainingStoreTypes.mutations.SET_LESSON_ERROR, errors);
            })
            .finally(() => {
                commit(TrainingStoreTypes.mutations.SET_LESSON_LOADING, false);
            });
    },

    /**
     * Sends the given lesson to the server.
     *
     * @param {VuexCommit} commit
     * @param {string} type
     * @param {string} id
     * @param {Object} query
     * @return {Promise}
     */
    [TrainingStoreTypes.actions.GET_LESSON]({ commit }, { type, id }) {
        commit(TrainingStoreTypes.mutations.SET_LESSON_LOADING, true);
        commit(TrainingStoreTypes.mutations.SET_LESSON_ERROR, null);

        return postData("/training/" + type + "/" + id, {
            locale: getLocale(),
        })
            .then((response) => {
                commit(TrainingStoreTypes.mutations.SET_LESSON, response.data);
            })
            .catch((errors) => {
                commit(TrainingStoreTypes.mutations.SET_LESSON_ERROR, errors);
            })
            .finally(() => {
                commit(TrainingStoreTypes.mutations.SET_LESSON_LOADING, false);
            });
    },

    /**
     * Sends the given lesson to the server.
     *
     * @param {VuexCommit} commit
     * @param {string} type
     * @param {string} id
     * @param {Object} query
     * @return {Promise}
     */
    [TrainingStoreTypes.actions.GET_PARTICIPATION](
        { commit },
        { type, id, query }
    ) {
        commit(TrainingStoreTypes.mutations.SET_PARTICIPATION_LOADING, true);
        commit(TrainingStoreTypes.mutations.SET_PARTICIPATION_ERROR, null);

        return postData(
            "/training/" + type + "/" + id + "/participation",
            query
        )
            .then((response) => {
                commit(TrainingStoreTypes.mutations.SET_PARTICIPATION, {
                    data: getProperty(response.data, "data", []),
                    meta: getProperty(response.data, "meta", {
                        current_page: 1,
                        from: 0,
                        last_page: 1,
                        per_page: 10,
                        to: 10,
                        total: 0,
                    }),
                });
            })
            .catch((errors) => {
                commit(
                    TrainingStoreTypes.mutations.SET_PARTICIPATION_ERROR,
                    errors
                );
            })
            .finally(() => {
                commit(
                    TrainingStoreTypes.mutations.SET_PARTICIPATION_LOADING,
                    false
                );
            });
    },

    /**
     * Exports the given lesson from the server.
     *
     * @param {VuexCommit} commit
     * @param {string} type
     * @param {string} id
     * @return {Promise}
     */
    [TrainingStoreTypes.actions.EXPORT_LIST]({ commit }, { type, id }) {
        commit(TrainingStoreTypes.mutations.SET_LESSON_ERROR, null);

        return postData("/training/" + type + "/" + id + "/export").catch(
            (errors) => {
                commit(TrainingStoreTypes.mutations.SET_LESSON_ERROR, errors);
            }
        );
    },

    /**
     * Sends the given lesson to the server.
     *
     * @param {VuexCommit} commit
     * @param {Object} query
     * @return {Promise}
     */
    [TrainingStoreTypes.actions.GET_DEADLINES]({ commit }, query) {
        commit(TrainingStoreTypes.mutations.SET_PARTICIPATION_LOADING, true);
        commit(TrainingStoreTypes.mutations.SET_PARTICIPATION_ERROR, null);

        const data = {
            locale: getLocale(),
            ...query,
        };

        return getData("/training/deadlines", data)
            .then((response) => {
                commit(TrainingStoreTypes.mutations.SET_DEADLINES, {
                    data: getProperty(response.data, "data", []),
                    meta: getProperty(response.data, "meta", {
                        current_page: 1,
                        from: 0,
                        last_page: 1,
                        per_page: 10,
                        to: 10,
                        total: 0,
                    }),
                });
            })
            .catch((errors) => {
                commit(
                    TrainingStoreTypes.mutations.SET_PARTICIPATION_ERROR,
                    errors
                );
            })
            .finally(() => {
                commit(
                    TrainingStoreTypes.mutations.SET_PARTICIPATION_LOADING,
                    false
                );
            });
    },

    /**
     * Send a reminder to the given user.
     *
     * @param {VuexCommit} commit
     * @param {string} id
     * @return {Promise}
     */
    [TrainingStoreTypes.actions.DELETE_DEADLINE]({ commit }, id) {
        commit(TrainingStoreTypes.mutations.SET_PARTICIPATION_ERROR, null);

        return postData("/training/deadlines/delete/" + id)
            .then(() => {
                commit(TrainingStoreTypes.mutations.REMOVE_DEADLINE, id);
            })
            .catch((errors) => {
                commit(
                    TrainingStoreTypes.mutations.SET_PARTICIPATION_ERROR,
                    errors
                );
            });
    },

    /**
     * Send a reminder to the given user.
     *
     * @param {VuexCommit} commit
     * @param {string} id
     * @return {Promise}
     */
    [TrainingStoreTypes.actions.SEND_REMINDER]({ commit }, id) {
        commit(TrainingStoreTypes.mutations.SET_PARTICIPATION_ERROR, null);

        return postData("/training/deadlines/reminder/" + id)
            .then(() => {
                commit(
                    TrainingStoreTypes.mutations
                        .UPDATE_DEADLINE_REMINDER_TIMESTAMP,
                    id
                );
            })
            .catch((errors) => {
                commit(
                    TrainingStoreTypes.mutations.SET_PARTICIPATION_ERROR,
                    errors
                );
            });
    },

    /**
     * Send a reminder to the given user.
     *
     * @param {VuexCommit} commit
     * @return {Promise}
     */
    [TrainingStoreTypes.actions.SEND_BULK_REMINDER]({ commit }) {
        commit(TrainingStoreTypes.mutations.SET_PARTICIPATION_ERROR, null);

        return postData("/training/deadlines/reminder/bulk")
            .then(() => {
                commit(
                    TrainingStoreTypes.mutations
                        .UPDATE_DEADLINE_REMINDER_TIMESTAMPS
                );
            })
            .catch((errors) => {
                commit(
                    TrainingStoreTypes.mutations.SET_PARTICIPATION_ERROR,
                    errors
                );
            });
    },

    /**
     * Exports the given lesson from the server.
     *
     * @param {VuexCommit} commit
     * @return {Promise}
     */
    [TrainingStoreTypes.actions.EXPORT_DEADLINE_LIST]({ commit }) {
        commit(TrainingStoreTypes.mutations.SET_PARTICIPATION_ERROR, null);

        return postData("/training/deadlines/export").catch((errors) => {
            commit(
                TrainingStoreTypes.mutations.SET_PARTICIPATION_ERROR,
                errors
            );
        });
    },
};

export const mutations = {
    /**
     * Sets the loading state for lesson.
     *
     * @param {TrainingStoreState} state
     * @param {boolean} loading
     */
    [TrainingStoreTypes.mutations.SET_LESSON_LOADING](state, loading) {
        state.lessonLoading = loading;
    },

    /**
     * Sets a new lesson error.
     *
     * @param {TrainingStoreState} state
     * @param {ErrorOrObject} error
     */
    [TrainingStoreTypes.mutations.SET_LESSON_ERROR](state, error) {
        state.lessonError = error;
    },

    /**
     * Sets a new lesson error.
     *
     * @param {TrainingStoreState} state
     * @param {Object} data
     */
    [TrainingStoreTypes.mutations.SET_LESSONS](state, data) {
        state.lessons = data;
    },

    /**
     * Sets a new lesson error.
     *
     * @param {TrainingStoreState} state
     * @param {Object} data
     */
    [TrainingStoreTypes.mutations.SET_LESSON](state, data) {
        state.lesson = data;
    },

    /**
     * Sets the loading state for lesson.
     *
     * @param {TrainingStoreState} state
     * @param {boolean} loading
     */
    [TrainingStoreTypes.mutations.SET_PARTICIPATION_LOADING](state, loading) {
        state.participationLoading = loading;
    },

    /**
     * Sets a new lesson error.
     *
     * @param {TrainingStoreState} state
     * @param {ErrorOrObject} error
     */
    [TrainingStoreTypes.mutations.SET_PARTICIPATION_ERROR](state, error) {
        state.participationError = error;
    },

    /**
     * Sets a new lesson error.
     *
     * @param {TrainingStoreState} state
     * @param {Object} data
     */
    [TrainingStoreTypes.mutations.SET_PARTICIPATION](state, data) {
        state.participation = data;
    },

    /**
     * Sets data for deadlines table.
     *
     * @param {TrainingStoreState} state
     * @param {Object} data
     */
    [TrainingStoreTypes.mutations.SET_DEADLINES](state, data) {
        state.deadlines = data;
    },

    /**
     * Sets data for deadlines table.
     *
     * @param {TrainingStoreState} state
     * @param {string} id
     */
    [TrainingStoreTypes.mutations.REMOVE_DEADLINE](state, id) {
        const index = state.deadlines.data.findIndex(
            (deadline) => deadline.id === id
        );

        if (index !== -1) {
            state.deadlines.data.splice(index, 1);
        }
    },

    /**
     * Sets data for deadlines table.
     *
     * @param {TrainingStoreState} state
     * @param {string} id
     */
    [TrainingStoreTypes.mutations.UPDATE_DEADLINE_REMINDER_TIMESTAMP](
        state,
        id
    ) {
        const deadline = state.deadlines.data.find((deadline) => {
            return deadline.id === id;
        });

        if (deadline) {
            deadline.reminder_sent_at = new Date();
        }
    },

    /**
     * Sets data for deadlines table.
     *
     * @param {TrainingStoreState} state
     */
    [TrainingStoreTypes.mutations.UPDATE_DEADLINE_REMINDER_TIMESTAMPS](state) {
        state.deadlines.data.forEach((deadline) => {
            deadline.reminder_sent_at = new Date();
        });
    },
};

export default {
    namespaced: true,
    Types: TrainingStoreTypes,
    NamespacedTypes: TrainingStoreNamespacedTypes,
    state,
    getters,
    actions,
    mutations,
};
