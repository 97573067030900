<template>
    <ValidationObserver ref="WaitActionForm" v-slot="{ passes }">
        <form @submit.prevent="passes(saveAction)">
            <fieldset
                class="mb-4 flex flex-col space-y-4 md:flex-row md:space-x-4 md:space-y-0"
            >
                <FormInput
                    class="md:w-1/2"
                    v-model="form.length"
                    type="number"
                    rules="required"
                    name="length"
                />
                <FormSelect
                    class="md:w-1/2"
                    v-model="form.unit"
                    rules="required"
                    name="unit"
                >
                    <option
                        v-for="unit in units"
                        :key="unit.value"
                        :value="unit.value"
                    >
                        {{ unit.title }}
                    </option>
                </FormSelect>
            </fieldset>
            <div class="flex justify-end">
                <FormButton
                    type="submit"
                    :variant="ButtonVariants.SECONDARY"
                    :loading="loading"
                >
                    {{ $t("automation.save_step") }}
                </FormButton>
            </div>
        </form>
    </ValidationObserver>
</template>

<script>
import AutomationActionMixin from "@/mixins/AutomationActionMixin";
import FormInput from "@/components/forms/fields/FormInput";
import FormSelect from "@/components/forms/fields/FormSelect";
import { getProperty } from "@/utils/object";

export default {
    name: "WaitAction",
    components: { FormSelect, FormInput },
    mixins: [AutomationActionMixin],
    data: () => ({
        form: {
            length: 1,
            unit: "days",
        },
    }),
    created() {
        this.form = {
            length: getProperty(this.action, "attributes.length", 1),
            unit: getProperty(this.action, "attributes.unit", "days"),
        };
    },
};
</script>
