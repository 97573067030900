<template>
    <TwoCol>
        <template #content>
            <SlideOver
                :active="showPrompt"
                @close="showPrompt = false"
                :gutter="false"
            >
                <h2
                    slot="title"
                    class="text-base font-semibold leading-6 text-slate-900"
                >
                    {{ $t("automation.enroll_employees") }}
                </h2>
                <template v-slot:content>
                    <SelectMembers
                        :loading="enrollmentLoading"
                        @update="handleEnrollment"
                    />
                </template>
            </SlideOver>
            <Heading
                :title="name || 'N/A'"
                :text="$t('automation.details.heading').toString()"
                :return-to="{
                    name: 'automation.index',
                }"
            >
                <FormButton
                    v-if="isEditable"
                    @click="showPrompt = true"
                    :variant="ButtonVariants.SECONDARY"
                    :icon="IconEnums.TEAM"
                    >{{ $t("automation.enroll_employees") }}</FormButton
                >
            </Heading>
            <div class="card">
                <CardHeading
                    :title="$t('automation.sequence.heading').toString()"
                    :text="$t('automation.sequence.text').toString()"
                />
                <div
                    v-if="funnelData.length > 2"
                    class="hidden max-w-full overflow-hidden px-4 pb-4 md:block md:border-b"
                >
                    <FunnelChart :loading="loading" :data="funnelData" />
                </div>
                <div class="px-4 pt-4">
                    <ContentLoading :loading="loading">
                        <AutomationFlow
                            :automation-id="automationId"
                            v-if="hasActions"
                        />
                        <AutomationFlowPlaceholder v-else :id="automationId" />
                    </ContentLoading>
                </div>
            </div>
        </template>
        <template #sidebar>
            <Heading
                :title="$t('automation.details.heading').toString()"
                :text="$t('automation.details.text').toString()"
            />
            <div class="card mb-4">
                <ContentLoading :loading="loading">
                    <div class="flex flex-col divide-y divide-slate-100">
                        <ListItem
                            :title="$t('automation.details.status').toString()"
                        >
                            <Badge
                                :variant="
                                    status === 'started'
                                        ? BadgeVariants.SUCCESS
                                        : BadgeVariants.WARNING
                                "
                                :text="
                                    $t('automation.status.' + status).toString()
                                "
                                :loading="status === 'started'"
                            />
                        </ListItem>
                        <ListItem
                            :title="$t('automation.details.date').toString()"
                            :text="date || 'N/A'"
                        />
                        <ListItem
                            :title="
                                $t(
                                    'automation.details.notifications'
                                ).toString()
                            "
                        >
                            <Icon
                                v-if="notificationsEnabled"
                                class="mr-2 size-4 flex-none fill-emerald-500 stroke-white"
                                :icon="IconEnums.CHECKMARK"
                            />
                            <Badge :text="notificationFrequency" />
                        </ListItem>
                    </div>
                    <div
                        v-if="hasActions"
                        class="flex flex-col gap-y-4 border-t border-slate-100 p-4"
                    >
                        <FormButton
                            :to="{
                                name: 'automation.edit',
                                params: { id: $route.params.id },
                            }"
                            :variant="ButtonVariants.SECONDARY"
                            >{{ $t("automation.edit_automation") }}</FormButton
                        >
                        <ToggleAutomationButton />
                    </div>
                </ContentLoading>
            </div>
        </template>
    </TwoCol>
</template>

<script>
import { AutomationStoreNamespacedTypes } from "@/store/modules/automation";

import { getProperty } from "@/utils/object";
import { formatDate } from "@/utils/date";

import Heading from "@/components/layout/Heading";
import TwoCol from "@/components/layout/TwoCol";
import AutomationFlow from "@/components/display/automation/AutomationFlow";
import ContentLoading from "@/components/ui/ContentLoading";
import FormButton, {
    ButtonVariants,
} from "@/components/forms/fields/FormButton";
import { IconEnums } from "@/utils/icons";
import Icon from "@/components/icons/BaseIcon";
import CardHeading from "@/components/ui/CardHeading";
import FunnelChart from "@/components/charts/FunnelChart/FunnelChart";
import ToggleAutomationButton from "@/components/display/automation/ToggleAutomationButton";
import AutomationFlowPlaceholder from "@/components/display/automation/AutomationFlowPlaceholder";
import Badge, { BadgeVariants } from "@/components/shared/Badge";
import SlideOver from "@/components/ui/SlideOver";
import SelectMembers from "@/components/display/group/SelectMembers";
import { TeamStoreNamespacedTypes } from "@/store/modules/team";
import Bus from "@/bus";
import ListItem from "@/components/ui/ListItem";

export default {
    name: "AutomationSingle",
    components: {
        ListItem,
        SelectMembers,
        SlideOver,
        Badge,
        AutomationFlowPlaceholder,
        ToggleAutomationButton,
        FunnelChart,
        CardHeading,
        Icon,
        FormButton,
        ContentLoading,
        AutomationFlow,
        TwoCol,
        Heading,
    },
    data: () => ({
        BadgeVariants,
        ButtonVariants,
        IconEnums,
        showPrompt: false,
        enrollmentLoading: false,
    }),
    computed: {
        /**
         * @type {Boolean}
         */
        loading() {
            return this.$store.getters[
                AutomationStoreNamespacedTypes.getters.AUTOMATION_LOADING
            ]();
        },

        /**
         * @type {Object}
         */
        error() {
            return this.$store.getters[
                AutomationStoreNamespacedTypes.getters.AUTOMATION_ERROR
            ]();
        },

        /**
         * @type {Array}
         */
        automation() {
            return this.$store.getters[
                AutomationStoreNamespacedTypes.getters.AUTOMATION
            ]();
        },

        /**
         * Automation ID
         * @type {string}
         */
        automationId() {
            return getProperty(this.automation, "id");
        },

        /**
         * If the automation has actions
         * @type {boolean}
         */
        hasActions() {
            return getProperty(this.automation, "actions", []).length > 0;
        },

        /**
         * @type {string}
         */
        name() {
            return getProperty(this.automation, "name", "N/A");
        },

        /**
         * Funnel data
         * @type {Array}
         */
        funnelData() {
            return getProperty(this.automation, "actions", []).map((action) => {
                return {
                    title:
                        this.$t("automation.flow.actions.step").toString() +
                        " " +
                        action.order,
                    value: getProperty(action, "statistics.total", 0),
                };
            });
        },

        /**
         * @type {string}
         */
        status() {
            return getProperty(this.automation, "status", "paused");
        },

        /**
         * @type {Array}
         */
        group() {
            return this.$store.getters[
                TeamStoreNamespacedTypes.getters.GROUP
            ]();
        },

        /**
         * @type {Array}
         */
        employees() {
            return getProperty(this.group, "employees.data", []);
        },

        /**
         * @type {Boolean}
         */
        isEditable() {
            if (this.loading) {
                return false;
            }

            if (!this.group) {
                return false;
            }

            return this.status === "started";
        },

        /**
         * @type {string|null}
         */
        date() {
            let date = getProperty(this.automation, "created_at");

            if (date) {
                return formatDate(date);
            }

            return null;
        },

        /**
         * @type {boolean}
         */
        notificationsEnabled() {
            return getProperty(
                this.automation,
                "notification_flow_enabled",
                false
            );
        },

        /**
         * @type {string}
         */
        notificationFrequency() {
            return getProperty(
                this.automation,
                "notification_frequency",
                "normal"
            );
        },
    },
    methods: {
        /**
         * Get automation
         * @returns {Promise}
         */
        getAutomation() {
            return this.$store.dispatch(
                AutomationStoreNamespacedTypes.actions.GET_AUTOMATION,
                this.$route.params.id
            );
        },

        /**
         * Handle enrollment
         * @param {Array} selected
         */
        handleEnrollment(selected) {
            this.enrollmentLoading = true;
            this.$store
                .dispatch(
                    AutomationStoreNamespacedTypes.actions
                        .MANUALLY_ENROLL_EMPLOYEES,
                    {
                        id: this.automationId,
                        employees: selected,
                    }
                )
                .then(() => {
                    this.enrollmentLoading = false;
                    this.showPrompt = false;
                    Bus.$emit("flash-message", {
                        text: this.$t("automation.enrolled_employees"),
                        type: "success",
                    });

                    this.getAutomation();
                });
        },
    },
    beforeCreate() {
        this.$store.dispatch(
            AutomationStoreNamespacedTypes.actions.RESET_AUTOMATION
        );
    },
    mounted() {
        this.getAutomation().then(() => {
            this.$store.dispatch(
                TeamStoreNamespacedTypes.actions.GET_AUTOMATION_AUDIENCE,
                this.automationId
            );
        });
    },
};
</script>
