<template>
    <div class="flex items-center justify-between gap-x-4 py-3">
        <dt class="text-slate-700">
            {{ title }}
            <span class="block text-xs text-slate-400" v-text="text"></span>
        </dt>
        <dd>
            <div
                v-if="loading"
                class="h-5 w-24 animate-pulse rounded-full bg-slate-200"
            ></div>
            <Badge
                v-else-if="hasAnalysis"
                :variant="
                    detected ? BadgeVariants.ERROR : BadgeVariants.SUCCESS
                "
                :icon="detected ? IconEnums.SIGNAL : null"
                :text="label"
            />
            <div v-else class="h-5 w-24 rounded-full bg-slate-200"></div>
        </dd>
    </div>
</template>

<script>
import { IconEnums } from "@/utils/icons";
import { getProperty } from "@/utils/object";
import { ThreatStoreNamespacedTypes } from "@/store/modules/threat";
import Badge, { BadgeVariants } from "@/components/shared/Badge";

export default {
    name: "ThreatIndicator",
    components: { Badge },
    props: {
        title: {
            type: String,
            required: true,
        },
        text: {
            type: String,
            required: true,
        },
        loading: {
            type: Boolean,
            required: true,
        },
        detected: {
            type: Boolean,
            required: true,
        },
    },
    data: () => ({
        BadgeVariants,
        IconEnums,
    }),
    computed: {
        /**
         * @type {Object}
         */
        threat() {
            return this.$store.getters[
                ThreatStoreNamespacedTypes.getters.THREAT
            ]();
        },

        /**
         * @type {Object}
         */
        analysis() {
            return getProperty(this.threat, "analysis");
        },

        /**
         * @type {boolean}
         */
        hasAnalysis() {
            return !!this.analysis;
        },

        /**
         * @type {String}
         */
        label() {
            if (this.detected) {
                return this.$t("threats.analysis.indicators.detected");
            }

            return this.$t("threats.analysis.indicators.not_detected");
        },
    },
};
</script>
