<template>
    <li class="bg-white">
        <div
            class="relative flex items-center space-x-3 px-3.5 py-2.5 hover:bg-slate-50"
        >
            <Avatar
                :size="SizeVariants.BIG"
                :image="avatar"
                :name="full_name"
            />
            <div class="min-w-0 flex-1">
                <span class="absolute inset-0" aria-hidden="true"></span>
                <span class="block text-sm font-medium text-slate-900">
                    {{ full_name }}
                </span>
                <span class="truncate text-sm text-slate-500">
                    {{ email }}
                </span>
            </div>
        </div>
    </li>
</template>

<script>
import { getProperty } from "@/utils/object";
import { initials } from "@/utils/filters";
import Avatar, { SizeVariants } from "@/components/shared/Avatar";

export default {
    name: "ReporterRow",
    components: { Avatar },
    props: {
        employee: {
            type: Object,
            required: true,
        },
    },
    data: () => ({
        SizeVariants,
    }),
    filters: {
        initials,
    },
    computed: {
        avatar() {
            return getProperty(this.employee, "avatar");
        },
        full_name() {
            return getProperty(this.employee, "full_name");
        },
        email() {
            return getProperty(this.employee, "email");
        },
    },
};
</script>
