/**
 * A store employee.
 *
 * @typedef {{ loading: boolean, error: ErrorOrObject, employees: Array, employee: Object, groups: Array, group: Object, selected: Array }} TeamStoreState
 */
import { addNamespace } from "./namespace";
import { getData, postData } from "@/api";
import { getProperty } from "@/utils/object";

/**
 * The types used in this store
 * @enum {string}
 */
export const TeamStoreTypes = {
    getters: {
        LOADING: "loading",
        ERROR: "error",
        EMPLOYEES: "employees",
        SEARCH_EMPLOYEES: "searchEmployees",
        CSV_IMPORT: "csvImport",
        EMPLOYEE: "employee",
        GROUPS: "groups",
        GROUP: "group",
        SELECTED: "selected",
    },
    actions: {
        GET_EMPLOYEES: "getEmployees",
        GET_EMPLOYEE: "getEmployee",
        GET_EMPLOYEES_FOR_SEARCH: "getEmployeesForSearch",
        CREATE_EMPLOYEES: "createEmployees",
        UPLOAD_CSV: "uploadCsv",
        GET_CSV_IMPORT_STATUS: "getCsvImportStatus",
        UPDATE_EMPLOYEE_SETTINGS: "updateEmployeeSettings",
        SEND_TRAINING: "SEND_TRAINING",
        GET_GROUPS: "getGroups",
        GET_ALL_GROUPS: "getAllGroups",
        GET_GROUP: "getGroup",
        GET_AUTOMATION_AUDIENCE: "getAutomationAudience",
        CREATE_GROUP: "createGroup",
        SYNC_PROVIDER_GROUP: "syncProviderGroup",
        UPDATE_MEMBERS: "updateMembers",
        DESTROY_GROUP: "destroyGroup",
        CREATE_TAG: "createTag",
        DESTROY_TAG: "destroyTag",
        CREATE_SEGMENT: "createSegment",
        UPDATE_SEGMENT: "updateSegment",
    },
    mutations: {
        SET_LOADING: "setLoading",
        SET_ERROR: "setError",
        SET_EMPLOYEES: "setEmployees",
        SET_SEARCH_EMPLOYEES: "setSearchEmployees",
        SET_CSV_IMPORT: "setCsvImport",
        SET_EMPLOYEE: "setEmployee",
        SET_GROUPS: "setGroups",
        SET_GROUP: "setGroup",
        RESET_GROUP: "resetGroup",
        SET_SELECTED: "setSelected",
        UPDATE_SELECTED: "updateSelected",
        PUSH_TAG: "pushTag",
        REMOVE_TAG: "removeTag",
        PUSH_SEGMENT: "pushSegment",
        UPDATE_SEGMENT: "updateSegment",
    },
};

/**
 * A namespaced version of the types used in this store
 * @enum {string}
 */
export const TeamStoreNamespacedTypes = addNamespace("team", TeamStoreTypes);

/**
 * @returns {TeamStoreState}
 */
export function state() {
    return {
        loading: false,
        error: null,
        employees: [],
        searchEmployees: [],
        csvImport: null,
        employee: {
            id: null,
            first_name: null,
            last_name: null,
            full_name: null,
            email: null,
            department: null,
            score: 0,
            point_count: 0,
            status: null,
            deactivated_at: null,
            deactivation_reason: null,
            locale: null,
            activities: [],
            groups: [],
            breaches: [],
            simulations: [],
            training: [],
            report: null,
            created_at: null,
        },
        groups: [],
        allGroups: [],
        group: {
            default: false,
            employee_active_count: 0,
            id: null,
            last_sync_at: null,
            name: null,
            provider: null,
            provider_id: null,
            tags: [],
            segments: [],
            employees: {
                selected: [],
                data: [],
                all: [],
                count: 0,
            },
        },
        selected: [],
    };
}

export const getters = {
    [TeamStoreTypes.getters.LOADING]: (state) => () => {
        return state.loading;
    },
    [TeamStoreTypes.getters.ERROR]: (state) => () => {
        return state.error;
    },
    [TeamStoreTypes.getters.EMPLOYEES]: (state) => () => {
        return state.employees;
    },
    [TeamStoreTypes.getters.SEARCH_EMPLOYEES]: (state) => () => {
        return state.searchEmployees;
    },
    [TeamStoreTypes.getters.CSV_IMPORT]: (state) => () => {
        return state.csvImport;
    },
    [TeamStoreTypes.getters.EMPLOYEE]: (state) => () => {
        return state.employee;
    },
    [TeamStoreTypes.getters.GROUPS]: (state) => () => {
        return state.groups;
    },
    [TeamStoreTypes.getters.GROUP]: (state) => () => {
        return state.group;
    },
    [TeamStoreTypes.getters.SELECTED]: (state) => () => {
        return state.selected;
    },
};

export const actions = {
    /**
     * Sends the given employees to the server.
     *
     * @param {VuexCommit} commit
     * @param {Object} query
     * @return {Promise}
     */
    [TeamStoreTypes.actions.GET_EMPLOYEES]({ commit }, query) {
        commit(TeamStoreTypes.mutations.SET_LOADING, true);
        commit(TeamStoreTypes.mutations.SET_ERROR, null);

        return getData("/workspace/employees", query)
            .then((response) => {
                commit(TeamStoreTypes.mutations.SET_EMPLOYEES, {
                    data: getProperty(response.data, "data", []),
                    meta: getProperty(response.data, "meta", {
                        current_page: 1,
                        from: 0,
                        last_page: 1,
                        per_page: 10,
                        to: 10,
                        total: 0,
                    }),
                });
            })
            .catch((errors) => {
                commit(TeamStoreTypes.mutations.SET_ERROR, errors);
            })
            .finally(() => {
                commit(TeamStoreTypes.mutations.SET_LOADING, false);
            });
    },

    /**
     * Get the given employee from the server.
     *
     * @param {TeamStoreState} state
     * @param {VuexCommit} commit
     * @param {string} id
     * @return {Promise}
     */
    [TeamStoreTypes.actions.GET_EMPLOYEE]({ state, commit }, id) {
        if (!id || id === state.employee.id) {
            return Promise.resolve();
        }

        commit(TeamStoreTypes.mutations.SET_LOADING, true);
        commit(TeamStoreTypes.mutations.SET_ERROR, null);

        return getData("/workspace/employees/" + id)
            .then((response) => {
                commit(TeamStoreTypes.mutations.SET_EMPLOYEE, response.data);
            })
            .catch((errors) => {
                commit(TeamStoreTypes.mutations.SET_ERROR, errors);
            })
            .finally(() => {
                commit(TeamStoreTypes.mutations.SET_LOADING, false);
            });
    },

    /**
     * Sends the given employees to the server.
     *
     * @param {VuexCommit} commit
     * @return {Promise}
     */
    [TeamStoreTypes.actions.GET_EMPLOYEES_FOR_SEARCH]({ commit }) {
        return getData("/workspace/employees/search").then((response) => {
            commit(
                TeamStoreTypes.mutations.SET_SEARCH_EMPLOYEES,
                response.data
            );
        });
    },

    /**
     * Sends the given employees to the server.
     *
     * @param {VuexCommit} commit
     * @param formData
     * @return {Promise}
     */
    [TeamStoreTypes.actions.UPLOAD_CSV]({ commit }, formData) {
        commit(TeamStoreTypes.mutations.SET_LOADING, true);
        commit(TeamStoreTypes.mutations.SET_ERROR, null);

        return postData("/workspace/employees/csv/upload", formData)
            .then((response) => {
                commit(TeamStoreTypes.mutations.SET_CSV_IMPORT, response.data);
            })
            .catch((errors) => {
                commit(TeamStoreTypes.mutations.SET_ERROR, errors);
            })
            .finally(() => {
                commit(TeamStoreTypes.mutations.SET_LOADING, false);
            });
    },

    /**
     * Sends the given employees to the server.
     *
     * @param {TeamStoreState} state
     * @param {VuexCommit} commit
     * @param formData
     * @return {Promise}
     */
    [TeamStoreTypes.actions.GET_CSV_IMPORT_STATUS]({ state, commit }) {
        return postData("/workspace/employees/csv/status", {
            id: getProperty(state.csvImport, "id", null),
        })
            .then((response) => {
                commit(TeamStoreTypes.mutations.SET_CSV_IMPORT, response.data);
            })
            .catch((errors) => {
                commit(TeamStoreTypes.mutations.SET_ERROR, errors);
            });
    },

    /**
     * Sends the given employees to the server.
     *
     * @param {VuexCommit} commit
     * @param employees
     * @return {Promise}
     */
    [TeamStoreTypes.actions.CREATE_EMPLOYEES]({ commit }, employees) {
        commit(TeamStoreTypes.mutations.SET_LOADING, true);
        commit(TeamStoreTypes.mutations.SET_ERROR, null);

        return postData("/workspace/employees", employees)
            .catch((errors) => {
                commit(TeamStoreTypes.mutations.SET_ERROR, errors);
            })
            .finally(() => {
                commit(TeamStoreTypes.mutations.SET_LOADING, false);
            });
    },

    /**
     * Sends the given employees to the server.
     *
     * @param {VuexCommit} commit
     * @param {string} employee
     * @param {Object} form
     * @return {Promise}
     */
    [TeamStoreTypes.actions.UPDATE_EMPLOYEE_SETTINGS](
        { commit },
        { employee, form }
    ) {
        commit(TeamStoreTypes.mutations.SET_ERROR, null);

        return postData("/workspace/employees/" + employee, form)
            .then((response) => {
                commit(TeamStoreTypes.mutations.SET_EMPLOYEE, response.data);
            })
            .catch((errors) => {
                commit(TeamStoreTypes.mutations.SET_ERROR, errors);
            });
    },

    /**
     * Sends the given some training exercises.
     *
     * @param {VuexCommit} commit
     * @param {string} employee
     * @return {Promise}
     */
    [TeamStoreTypes.actions.SEND_TRAINING]({ commit }, employee) {
        return postData("/workspace/employees/" + employee + "/training").catch(
            (errors) => {
                commit(TeamStoreTypes.mutations.SET_ERROR, errors);
            }
        );
    },

    /**
     * Sends the given employees to the server.
     *
     * @param {VuexCommit} commit
     * @param {Object} query
     * @return {Promise}
     */
    [TeamStoreTypes.actions.GET_GROUPS]({ commit }, query) {
        commit(TeamStoreTypes.mutations.SET_LOADING, true);
        commit(TeamStoreTypes.mutations.SET_ERROR, null);

        return getData("/workspace/groups", query)
            .then((response) => {
                commit(TeamStoreTypes.mutations.SET_GROUPS, {
                    data: getProperty(response.data, "data", []),
                    meta: getProperty(response.data, "meta", {
                        current_page: 1,
                        from: 0,
                        last_page: 1,
                        per_page: 10,
                        to: 10,
                        total: 0,
                    }),
                });
            })
            .catch((errors) => {
                commit(TeamStoreTypes.mutations.SET_ERROR, errors);
            })
            .finally(() => {
                commit(TeamStoreTypes.mutations.SET_LOADING, false);
            });
    },

    /**
     * Sends the given employees to the server.
     *
     * @param {VuexCommit} commit
     * @param {Object} query
     * @return {Promise}
     */
    [TeamStoreTypes.actions.GET_ALL_GROUPS]({ commit }, query) {
        commit(TeamStoreTypes.mutations.SET_LOADING, true);
        commit(TeamStoreTypes.mutations.SET_ERROR, null);

        return getData("/workspace/groups/all", query)
            .then((response) => {
                commit(TeamStoreTypes.mutations.SET_GROUPS, {
                    data: response.data,
                });
            })
            .catch((errors) => {
                commit(TeamStoreTypes.mutations.SET_ERROR, errors);
            })
            .finally(() => {
                commit(TeamStoreTypes.mutations.SET_LOADING, false);
            });
    },

    /**
     * Sends the given employees to the server.
     *
     * @param {VuexCommit} commit
     * @param group
     * @return {Promise}
     */
    [TeamStoreTypes.actions.GET_GROUP]({ commit }, group) {
        commit(TeamStoreTypes.mutations.SET_LOADING, true);
        commit(TeamStoreTypes.mutations.SET_ERROR, null);

        return getData("/workspace/groups/" + group)
            .then((response) => {
                commit(
                    TeamStoreTypes.mutations.SET_GROUP,
                    getProperty(response, "data")
                );
                commit(
                    TeamStoreTypes.mutations.SET_SELECTED,
                    getProperty(response, "data.employees.selected", [])
                );
            })
            .catch((errors) => {
                commit(TeamStoreTypes.mutations.SET_ERROR, errors);
            })
            .finally(() => {
                commit(TeamStoreTypes.mutations.SET_LOADING, false);
            });
    },

    /**
     * Sends the given employees to the server.
     *
     * @param {VuexCommit} commit
     * @param {string} id
     * @return {Promise}
     */
    [TeamStoreTypes.actions.GET_AUTOMATION_AUDIENCE]({ commit }, id) {
        commit(TeamStoreTypes.mutations.SET_LOADING, true);
        commit(TeamStoreTypes.mutations.SET_ERROR, null);

        return getData("/automations/" + id + "/audience")
            .then((response) => {
                commit(TeamStoreTypes.mutations.SET_GROUP, {
                    employees: getProperty(response, "data.employees"),
                });
                commit(
                    TeamStoreTypes.mutations.SET_SELECTED,
                    getProperty(response, "data.selected", [])
                );
            })
            .catch((errors) => {
                commit(TeamStoreTypes.mutations.SET_ERROR, errors);
            })
            .finally(() => {
                commit(TeamStoreTypes.mutations.SET_LOADING, false);
            });
    },

    /**
     * Sends the given employees to the server.
     *
     * @param {VuexCommit} commit
     * @param {Object} data
     * @return {Promise}
     */
    [TeamStoreTypes.actions.CREATE_GROUP]({ commit }, data) {
        commit(TeamStoreTypes.mutations.SET_LOADING, true);
        commit(TeamStoreTypes.mutations.SET_ERROR, null);

        return postData("/workspace/groups", data)
            .catch((errors) => {
                commit(TeamStoreTypes.mutations.SET_ERROR, errors);
            })
            .finally(() => {
                commit(TeamStoreTypes.mutations.SET_LOADING, false);
            });
    },

    /**
     * Sends groups to the server to be synced.
     *
     * @param {VuexCommit} commit
     * @param {string} group
     * @return {Promise}
     */
    [TeamStoreTypes.actions.SYNC_PROVIDER_GROUP]({ commit }, group) {
        commit(TeamStoreTypes.mutations.SET_ERROR, null);

        return postData("/workspace/groups/" + group + "/sync").catch(
            (errors) => {
                commit(TeamStoreTypes.mutations.SET_ERROR, errors);
            }
        );
    },

    /**
     * Sends the given employees to the server.
     *
     * @param {VuexCommit} commit
     * @param group
     * @param {Array} selected
     * @return {Promise}
     */
    [TeamStoreTypes.actions.UPDATE_MEMBERS]({ commit }, { group, selected }) {
        commit(TeamStoreTypes.mutations.SET_ERROR, null);

        return postData("/workspace/groups/" + group, {
            selected: selected,
        }).catch((errors) => {
            commit(TeamStoreTypes.mutations.SET_ERROR, errors);
        });
    },

    /**
     * Sends the given employees to the server.
     *
     * @param {VuexCommit} commit
     * @return {Promise}
     */
    [TeamStoreTypes.actions.DESTROY_GROUP]({ commit }) {
        commit(TeamStoreTypes.mutations.SET_LOADING, true);
        commit(TeamStoreTypes.mutations.SET_ERROR, null);

        return postData("/workspace/groups")
            .then((response) => {
                commit(TeamStoreTypes.mutations.SET_GROUPS, response.data);
            })
            .catch((errors) => {
                commit(TeamStoreTypes.mutations.SET_ERROR, errors);
            })
            .finally(() => {
                commit(TeamStoreTypes.mutations.SET_LOADING, false);
            });
    },

    /**
     * Sends the given employees to the server.
     *
     * @param {TeamStoreState} state
     * @param {VuexCommit} commit
     * @param {Object} data
     * @return {Promise}
     */
    [TeamStoreTypes.actions.CREATE_TAG]({ state, commit }, data) {
        commit(TeamStoreTypes.mutations.SET_ERROR, null);
        let groupId = getProperty(state, "group.id");

        return postData("/workspace/groups/" + groupId + "/tags", data)
            .then((response) => {
                commit(TeamStoreTypes.mutations.PUSH_TAG, response.data);
            })
            .catch((errors) => {
                commit(TeamStoreTypes.mutations.SET_ERROR, errors);
            });
    },

    /**
     * Sends the given employees to the server.
     *
     * @param {TeamStoreState} state
     * @param {VuexCommit} commit
     * @param {string} id
     * @return {Promise}
     */
    [TeamStoreTypes.actions.DESTROY_TAG]({ state, commit }, id) {
        commit(TeamStoreTypes.mutations.SET_ERROR, null);
        let groupId = getProperty(state, "group.id");

        return postData("/workspace/groups/" + groupId + "/tags/delete", {
            id: id,
        })
            .then(() => {
                commit(TeamStoreTypes.mutations.REMOVE_TAG, id);
            })
            .catch((errors) => {
                commit(TeamStoreTypes.mutations.SET_ERROR, errors);
            });
    },

    /**
     * Sends the given employees to the server.
     *
     * @param {TeamStoreState} state
     * @param {VuexCommit} commit
     * @param {Object} data
     * @return {Promise}
     */
    [TeamStoreTypes.actions.CREATE_SEGMENT]({ state, commit }, data) {
        commit(TeamStoreTypes.mutations.SET_ERROR, null);
        let groupId = getProperty(state, "group.id");

        return postData("/workspace/groups/" + groupId + "/segments", data)
            .then((response) => {
                commit(TeamStoreTypes.mutations.PUSH_SEGMENT, response.data);
            })
            .catch((errors) => {
                commit(TeamStoreTypes.mutations.SET_ERROR, errors);
            });
    },

    /**
     * Sends the given employees to the server.
     *
     * @param {TeamStoreState} state
     * @param {VuexCommit} commit
     * @param {string} id
     * @param {Object} data
     * @return {Promise}
     */
    [TeamStoreTypes.actions.UPDATE_SEGMENT]({ state, commit }, { id, data }) {
        commit(TeamStoreTypes.mutations.SET_ERROR, null);
        let groupId = getProperty(state, "group.id");

        return postData(
            "/workspace/groups/" + groupId + "/segments/" + id,
            data
        )
            .then((response) => {
                commit(TeamStoreTypes.mutations.UPDATE_SEGMENT, {
                    id: id,
                    data: response.data,
                });
            })
            .catch((errors) => {
                commit(TeamStoreTypes.mutations.SET_ERROR, errors);
            });
    },
};

export const mutations = {
    /**
     * Sets the loading state for employees.
     *
     * @param {TeamStoreState} state
     * @param {boolean} loading
     */
    [TeamStoreTypes.mutations.SET_LOADING](state, loading) {
        state.loading = loading;
    },

    /**
     * Sets a new employees' error.
     *
     * @param {TeamStoreState} state
     * @param {ErrorOrObject} error
     */
    [TeamStoreTypes.mutations.SET_ERROR](state, error) {
        state.error = error;
    },

    /**
     * Sets a new employees' error.
     *
     * @param {TeamStoreState} state
     * @param {Object} data
     */
    [TeamStoreTypes.mutations.SET_EMPLOYEES](state, data) {
        state.employees = data;
    },

    /**
     * Sets a new employees' error.
     *
     * @param {TeamStoreState} state
     * @param {Object} data
     */
    [TeamStoreTypes.mutations.SET_SEARCH_EMPLOYEES](state, data) {
        state.searchEmployees = data;
    },

    /**
     * Sets a new CSV import.
     *
     * @param {TeamStoreState} state
     * @param {Object} data
     */
    [TeamStoreTypes.mutations.SET_CSV_IMPORT](state, data) {
        state.csvImport = data;
    },

    /**
     * Sets a new employees' error.
     *
     * @param {TeamStoreState} state
     * @param {Array} data
     */
    [TeamStoreTypes.mutations.SET_EMPLOYEE](
        state,
        {
            id = null,
            first_name = null,
            last_name = null,
            full_name = null,
            email = null,
            department = null,
            score = 0,
            point_count = 0,
            status = null,
            deactivated_at = null,
            deactivation_reason = null,
            locale = null,
            activities = [],
            groups = [],
            breaches = [],
            simulations = [],
            training = [],
            report = null,
            created_at = null,
        }
    ) {
        state.employee.id = id;
        state.employee.first_name = first_name;
        state.employee.last_name = last_name;
        state.employee.full_name = full_name;
        state.employee.email = email;
        state.employee.department = department;
        state.employee.score = score;
        state.employee.point_count = point_count;
        state.employee.status = status;
        state.employee.deactivated_at = deactivated_at;
        state.employee.deactivation_reason = deactivation_reason;
        state.employee.locale = locale;
        state.employee.activities = activities;
        state.employee.groups = groups;
        state.employee.breaches = breaches;
        state.employee.simulations = simulations;
        state.employee.training = training;
        state.employee.report = report;
        state.employee.created_at = created_at;
    },

    /**
     * Sets a new employees' error.
     *
     * @param {TeamStoreState} state
     * @param {Array} data
     */
    [TeamStoreTypes.mutations.SET_GROUPS](state, data) {
        state.groups = data;
    },

    /**
     * Sets a new employees' error.
     *
     * @param {TeamStoreState} state
     * @param {Array} data
     */
    [TeamStoreTypes.mutations.SET_GROUP](state, data) {
        state.group = data;
    },

    /**
     * Sets a new employees' error.
     *
     * @param {TeamStoreState} state
     */
    [TeamStoreTypes.mutations.RESET_GROUP](state) {
        state.group = {
            default: false,
            employee_active_count: 0,
            id: null,
            last_sync_at: null,
            name: null,
            provider: null,
            provider_id: null,
            tags: [],
            segments: [],
            employees: {
                selected: [],
                data: [],
                all: [],
                count: 0,
            },
        };
    },

    /**
     * Sets a new employees' error.
     *
     * @param {TeamStoreState} state
     * @param {Array} data
     */
    [TeamStoreTypes.mutations.SET_SELECTED](state, data) {
        state.selected = data;
    },

    /**
     * Update array of selected ids
     *
     * @param {TeamStoreState} state
     * @param {string} id
     */
    [TeamStoreTypes.mutations.UPDATE_SELECTED](state, id) {
        state.selected.indexOf(id) === -1
            ? state.selected.push(id)
            : state.selected.splice(state.selected.indexOf(id), 1);
    },

    /**
     * Sets a new employees' error.
     *
     * @param {TeamStoreState} state
     * @param {Object} data
     */
    [TeamStoreTypes.mutations.PUSH_TAG](state, data) {
        state.group.tags.push(data);
    },

    /**
     * Sets a new employees' error.
     *
     * @param {TeamStoreState} state
     * @param {string} id
     */
    [TeamStoreTypes.mutations.REMOVE_TAG](state, id) {
        state.group.tags.forEach((tag, index) => {
            if (tag.id === id) {
                state.group.tags.splice(index, 1);
            }
        });
    },

    /**
     * Sets a new employees' error.
     *
     * @param {TeamStoreState} state
     * @param {Array} data
     */
    [TeamStoreTypes.mutations.PUSH_SEGMENT](state, data) {
        state.group.segments.push(data);
    },

    /**
     * Sets a new employees' error.
     *
     * @param {TeamStoreState} state
     * @param id
     * @param {Array} data
     */
    [TeamStoreTypes.mutations.UPDATE_SEGMENT](state, { id, data }) {
        state.group.segments.forEach((segment, index) => {
            if (segment.id === id) {
                state.group.segments[index] = data;
            }
        });
    },
};

export default {
    namespaced: true,
    Types: TeamStoreTypes,
    NamespacedTypes: TeamStoreNamespacedTypes,
    state,
    getters,
    actions,
    mutations,
};
