<template>
    <div class="mb-4 overflow-hidden rounded-xl border border-slate-200">
        <div
            v-if="hasProvider"
            class="flex items-center gap-x-4 border-b border-slate-900/5 bg-slate-50 px-4 py-3"
        >
            <img
                v-if="image"
                :src="image"
                :alt="title"
                class="size-10 flex-none rounded-lg bg-white object-cover ring-1 ring-slate-900/10"
            />
            <div class="text-sm font-medium leading-6 text-slate-900">
                {{ title }}
            </div>
        </div>
        <dl class="-my-3 divide-y divide-slate-100 px-6 py-4 text-sm leading-6">
            <div v-if="hasProvider" class="flex justify-between gap-x-4 py-3">
                <dt class="text-slate-500">
                    {{ $t("threats.analysis.sender.type") }}
                </dt>
                <dd>
                    <Badge
                        :variant="
                            isPublic
                                ? BadgeVariants.ERROR
                                : BadgeVariants.SUCCESS
                        "
                        :text="
                            isPublic
                                ? $t(
                                      'threats.analysis.sender.type_text.free'
                                  ).toString()
                                : $t(
                                      'threats.analysis.sender.type_text.paid'
                                  ).toString()
                        "
                    />
                </dd>
            </div>
            <div v-if="sender_email" class="flex justify-between gap-x-4 py-3">
                <dt class="text-slate-500">
                    {{ $t("threats.analysis.sender.email") }}
                </dt>
                <dd class="text-slate-700" v-text="sender_email"></dd>
            </div>
            <div v-if="sender_domain" class="flex justify-between gap-x-4 py-3">
                <dt class="text-slate-500">
                    {{ $t("threats.analysis.sender.domain") }}
                </dt>
                <dd class="text-slate-700" v-text="sender_domain"></dd>
            </div>
            <div v-if="sender_ip" class="flex justify-between gap-x-4 py-3">
                <dt class="text-slate-500">
                    {{ $t("threats.analysis.sender.ip") }}
                </dt>
                <dd class="text-slate-700" v-text="sender_ip"></dd>
            </div>
        </dl>
    </div>
</template>

<script>
import { getProperty } from "@/utils/object";
import Badge, { BadgeVariants } from "@/components/shared/Badge";

export default {
    name: "EmailProviderPreview",
    components: { Badge },
    props: {
        analysis: {
            type: Object,
            required: true,
        },
    },
    data: () => ({
        BadgeVariants,
    }),
    computed: {
        /**
         * Get the image url
         * @returns {string|null}
         */
        image() {
            const image = getProperty(this.provider, "image");
            return image
                ? process.env.VUE_APP_CDN_URL + "/threats/service/logo/" + image
                : null;
        },
        provider() {
            return getProperty(this.analysis, "sender_email_service", {
                image: null,
                title: null,
                mx: [],
                domains: [],
                public: false,
            });
        },
        hasProvider() {
            return getProperty(this.provider, "title", null) !== null;
        },
        title() {
            return getProperty(this.provider, "title");
        },
        isPublic() {
            return getProperty(this.provider, "public", false);
        },
        sender_email() {
            return getProperty(this.analysis, "sender_email");
        },
        sender_ip() {
            return getProperty(this.analysis, "sender_ip");
        },
        sender_domain() {
            return getProperty(this.analysis, "sender_domain");
        },
    },
};
</script>
