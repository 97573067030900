/**
 * A store campaign.
 *
 * @typedef {{ campaigns: Object, campaignsLoading: boolean, campaignsError: ErrorOrObject, campaign: Object, events: Object, eventsLoading: boolean }} CampaignStoreState
 */
import { addNamespace } from "./namespace";
import { getData, postData } from "@/api";
import { getProperty } from "@/utils/object";

/**
 * The types used in this store
 * @enum {string}
 */
export const CampaignStoreTypes = {
    getters: {
        CAMPAIGNS_LOADING: "campaignsLoading",
        CAMPAIGNS_ERROR: "campaignsError",
        CAMPAIGNS: "campaigns",
        CAMPAIGN: "campaign",
        CAMPAIGN_EVENTS: "campaignEvents",
        CAMPAIGNS_EVENTS_LOADING: "campaignEventsLoading",
    },
    actions: {
        GET_CAMPAIGNS: "getCampaigns",
        GET_CAMPAIGN: "getCampaign",
        GET_CAMPAIGN_EVENTS: "getCampaignEvents",
        CREATE_CAMPAIGN: "createCampaign",
        UPDATE_CAMPAIGN: "updateCampaign",
        SEND: "send",
        SEND_TEST: "sendTest",
        TOGGLE_CAMPAIGN_STATE: "toggleCampaignState",
    },
    mutations: {
        SET_CAMPAIGNS_LOADING: "setCampaignsLoading",
        SET_CAMPAIGNS_ERROR: "setCampaignsError",
        SET_CAMPAIGNS: "setCampaigns",
        SET_CAMPAIGN: "setCampaign",
        SET_CAMPAIGN_EVENTS: "setCampaignEvents",
        SET_CAMPAIGN_EVENTS_LOADING: "setCampaignEventsLoading",
    },
};

/**
 * A namespaced version of the types used in this store
 * @enum {string}
 */
export const CampaignStoreNamespacedTypes = addNamespace(
    "campaign",
    CampaignStoreTypes
);

/**
 * @returns {CampaignStoreState}
 */
export function state() {
    return {
        campaigns: {
            data: [],
            meta: {
                current_page: 1,
                from: 0,
                last_page: 1,
                per_page: 10,
                to: 10,
                total: 0,
            },
        },
        campaignsLoading: false,
        campaignsError: null,
        campaign: {},
        events: {
            data: [],
            meta: {
                current_page: 1,
                from: 0,
                last_page: 1,
                per_page: 10,
                to: 10,
                total: 0,
            },
        },
        eventsLoading: false,
    };
}

export const getters = {
    [CampaignStoreTypes.getters.CAMPAIGNS_LOADING]: (state) => () => {
        return state.campaignsLoading;
    },
    [CampaignStoreTypes.getters.CAMPAIGNS_ERROR]: (state) => () => {
        return state.campaignsError;
    },
    [CampaignStoreTypes.getters.CAMPAIGNS]: (state) => () => {
        return state.campaigns;
    },
    [CampaignStoreTypes.getters.CAMPAIGN]: (state) => () => {
        return state.campaign;
    },
    [CampaignStoreTypes.getters.CAMPAIGN_EVENTS]: (state) => () => {
        return state.events;
    },
    [CampaignStoreTypes.getters.CAMPAIGNS_EVENTS_LOADING]: (state) => () => {
        return state.eventsLoading;
    },
};

export const actions = {
    /**
     * Sends the given campaigns to the server.
     *
     * @param {VuexCommit} commit
     * @param {Object} query
     * @return {Promise}
     */
    [CampaignStoreTypes.actions.GET_CAMPAIGNS]({ commit }, query) {
        commit(CampaignStoreTypes.mutations.SET_CAMPAIGNS_LOADING, true);
        commit(CampaignStoreTypes.mutations.SET_CAMPAIGNS_ERROR, null);

        return getData("/campaigns", query)
            .then((response) => {
                commit(CampaignStoreTypes.mutations.SET_CAMPAIGNS, {
                    data: getProperty(response.data, "data", []),
                    meta: getProperty(response.data, "meta", {
                        current_page: 1,
                        from: 0,
                        last_page: 1,
                        per_page: 10,
                        to: 10,
                        total: 0,
                    }),
                });
            })
            .catch((errors) => {
                commit(
                    CampaignStoreTypes.mutations.SET_CAMPAIGNS_ERROR,
                    errors
                );
            })
            .finally(() => {
                commit(
                    CampaignStoreTypes.mutations.SET_CAMPAIGNS_LOADING,
                    false
                );
            });
    },

    /**
     * Sends the given campaigns to the server.
     *
     * @param {VuexCommit} commit
     * @param {VuexDispatch} dispatch
     * @param {String} campaign
     * @return {Promise}
     */
    [CampaignStoreTypes.actions.GET_CAMPAIGN]({ commit, dispatch }, campaign) {
        commit(CampaignStoreTypes.mutations.SET_CAMPAIGNS_LOADING, true);
        commit(CampaignStoreTypes.mutations.SET_CAMPAIGNS_ERROR, null);

        return getData("/campaigns/" + campaign)
            .then((response) => {
                commit(
                    CampaignStoreTypes.mutations.SET_CAMPAIGN,
                    response.data
                );

                // Load events for sent campaigns
                if (getProperty(response.data, "status") !== "draft") {
                    dispatch(CampaignStoreTypes.actions.GET_CAMPAIGN_EVENTS, {
                        campaign: campaign,
                        query: {},
                    }).then(() => {});
                }
            })
            .catch((errors) => {
                commit(
                    CampaignStoreTypes.mutations.SET_CAMPAIGNS_ERROR,
                    errors
                );
            })
            .finally(() => {
                commit(
                    CampaignStoreTypes.mutations.SET_CAMPAIGNS_LOADING,
                    false
                );
            });
    },

    /**
     * Sends the given campaigns to the server.
     *
     * @param {VuexCommit} commit
     * @param {String} campaign
     * @param {Object} query
     * @return {Promise}
     */
    [CampaignStoreTypes.actions.GET_CAMPAIGN_EVENTS](
        { commit },
        { campaign, query }
    ) {
        commit(CampaignStoreTypes.mutations.SET_CAMPAIGN_EVENTS_LOADING, true);
        commit(CampaignStoreTypes.mutations.SET_CAMPAIGNS_ERROR, null);

        return postData("/campaigns/" + campaign + "/events", query)
            .then((response) => {
                commit(CampaignStoreTypes.mutations.SET_CAMPAIGN_EVENTS, {
                    data: getProperty(response.data, "data", []),
                    meta: getProperty(response.data, "meta", {
                        current_page: 1,
                        from: 0,
                        last_page: 1,
                        per_page: 10,
                        to: 10,
                        total: 0,
                    }),
                });
            })
            .catch((errors) => {
                commit(
                    CampaignStoreTypes.mutations.SET_CAMPAIGNS_ERROR,
                    errors
                );
            })
            .finally(() => {
                commit(
                    CampaignStoreTypes.mutations.SET_CAMPAIGN_EVENTS_LOADING,
                    false
                );
            });
    },

    /**
     * Sends the given campaigns to the server.
     *
     * @param {VuexCommit} commit
     * @param {Object} form
     * @return {Promise}
     */
    [CampaignStoreTypes.actions.CREATE_CAMPAIGN]({ commit }, form) {
        commit(CampaignStoreTypes.mutations.SET_CAMPAIGNS_ERROR, null);

        return postData("/campaigns", form).catch((errors) => {
            commit(CampaignStoreTypes.mutations.SET_CAMPAIGNS_ERROR, errors);
        });
    },

    /**
     * Sends the given campaigns to the server.
     *
     * @param {VuexCommit} commit
     * @param {String} campaign
     * @param {Object} form
     * @param {Object} data
     * @return {Promise}
     */
    [CampaignStoreTypes.actions.UPDATE_CAMPAIGN](
        { commit },
        { campaign, form }
    ) {
        commit(CampaignStoreTypes.mutations.SET_CAMPAIGNS_ERROR, null);

        return postData("/campaigns/" + campaign, form)
            .catch((errors) => {
                commit(
                    CampaignStoreTypes.mutations.SET_CAMPAIGNS_ERROR,
                    errors
                );
            })
            .then((response) => {
                commit(
                    CampaignStoreTypes.mutations.SET_CAMPAIGN,
                    response.data
                );
            });
    },

    /**
     * Sends the given campaigns to the server.
     *
     * @param {VuexCommit} commit
     * @param {String} campaign
     * @param {Object} data
     * @return {Promise}
     */
    [CampaignStoreTypes.actions.SEND]({ commit }, campaign) {
        return postData("/campaigns/" + campaign + "/send").then((response) => {
            commit(CampaignStoreTypes.mutations.SET_CAMPAIGN, response.data);
        });
    },

    /**
     * Sends the given campaigns to the server.
     *
     * @param {VuexCommit} commit
     * @param {String} campaign
     * @return {Promise}
     */
    [CampaignStoreTypes.actions.SEND_TEST]({ commit }, campaign) {
        return postData("/campaigns/" + campaign + "/test").catch((errors) => {
            commit(CampaignStoreTypes.mutations.SET_CAMPAIGNS_ERROR, errors);
        });
    },

    /**
     * Toggles the play/pause campaign state.
     *
     * @param {VuexCommit} commit
     * @param {String} campaign
     * @param {Object} form
     * @return {Promise}
     */
    [CampaignStoreTypes.actions.TOGGLE_CAMPAIGN_STATE]({ commit }, campaign) {
        commit(CampaignStoreTypes.mutations.SET_CAMPAIGNS_LOADING, true);
        commit(CampaignStoreTypes.mutations.SET_CAMPAIGNS_ERROR, null);

        return postData("/campaigns/" + campaign + "/toggle-state")
            .catch((errors) => {
                commit(
                    CampaignStoreTypes.mutations.SET_CAMPAIGNS_ERROR,
                    errors
                );
            })
            .then((response) => {
                commit(
                    CampaignStoreTypes.mutations.SET_CAMPAIGN,
                    response.data
                );
            })
            .finally(() => {
                commit(
                    CampaignStoreTypes.mutations.SET_CAMPAIGNS_LOADING,
                    false
                );
            });
    },
};

export const mutations = {
    /**
     * Sets the loading state for campaigns.
     *
     * @param {CampaignStoreState} state
     * @param {boolean} loading
     */
    [CampaignStoreTypes.mutations.SET_CAMPAIGNS_LOADING](state, loading) {
        state.campaignsLoading = loading;
    },

    /**
     * Sets a new campaigns error.
     *
     * @param {CampaignStoreState} state
     * @param {ErrorOrObject} error
     */
    [CampaignStoreTypes.mutations.SET_CAMPAIGNS_ERROR](state, error) {
        state.campaignsError = error;
    },

    /**
     * Sets a new campaigns error.
     *
     * @param {CampaignStoreState} state
     * @param {Array} data
     */
    [CampaignStoreTypes.mutations.SET_CAMPAIGNS](state, data) {
        state.campaigns = data;
    },

    /**
     * Sets a new campaigns error.
     *
     * @param {CampaignStoreState} state
     * @param {Object} data
     */
    [CampaignStoreTypes.mutations.SET_CAMPAIGN](state, data) {
        state.campaign = data;
    },

    /**
     * Sets a new campaigns error.
     *
     * @param {CampaignStoreState} state
     * @param {Object} events
     */
    [CampaignStoreTypes.mutations.SET_CAMPAIGN_EVENTS](state, events) {
        state.events = events;
    },

    /**
     * Sets a new campaigns error.
     *
     * @param {CampaignStoreState} state
     * @param {Boolean} loading
     */
    [CampaignStoreTypes.mutations.SET_CAMPAIGN_EVENTS_LOADING](state, loading) {
        state.eventsLoading = loading;
    },
};

export default {
    namespaced: true,
    Types: CampaignStoreTypes,
    NamespacedTypes: CampaignStoreNamespacedTypes,
    state,
    getters,
    actions,
    mutations,
};
