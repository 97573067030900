<template>
    <div class="funnel-chart-item group relative flex w-full flex-col">
        <div class="relative flex w-full flex-full justify-start px-2">
            <div class="absolute inset-0 flex flex-col justify-between py-px">
                <div class="relative flex w-full justify-end">
                    <div
                        class="flex-1 border-b border-dashed border-slate-300"
                    ></div>
                </div>
                <div class="relative flex w-full justify-end">
                    <div
                        class="flex-1 border-b border-dashed border-slate-300"
                    ></div>
                </div>
                <div class="relative flex w-full justify-end">
                    <div
                        class="flex-1 border-b border-dashed border-slate-300"
                    ></div>
                </div>
            </div>
            <div class="relative flex h-full min-w-full flex-col text-center">
                <div class="chart-item-value relative flex-full transition">
                    <div
                        class="flex h-full animate-pulse flex-col items-center justify-center rounded bg-slate-200"
                    ></div>
                </div>
            </div>
        </div>
        <div
            class="relative mt-4 h-10 w-full min-w-full border-b border-t border-slate-200 bg-slate-100"
            :class="isLast ? 'rounded-r-md border-r' : ''"
        ></div>
    </div>
</template>

<script>
export default {
    name: "FunnelChartLoadingItem",
    props: {
        index: {
            type: Number,
            default: undefined,
        },
    },
    computed: {
        isLast() {
            return this.index === 4;
        },
    },
};
</script>
