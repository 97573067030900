<template>
    <OneCol>
        <DynamicTabs :tabs="tabs" />
        <Heading
            :title="$t('training.deadlines.heading').toString()"
            :text="$t('training.deadlines.text').toString()"
            :loading="loading"
        >
            <FormButton
                @click.native="exportToCsv"
                :variant="ButtonVariants.SECONDARY"
                :icon="IconEnums.DOWNLOAD"
                :loading="exportLoading"
            >
                Download CSV
            </FormButton>
            <FormButton
                @click.native="sendBulkReminder"
                :variant="ButtonVariants.PRIMARY"
                :icon="IconEnums.ANNOUNCEMENT"
                :loading="bulkReminderLoading"
            >
                {{ $t("training.deadlines.send_bulk_reminder") }}
            </FormButton>
        </Heading>
        <div class="card">
            <FilterLoading :loading="groupsLoading">
                <SelectFilter v-model="status" name="status">
                    <option value="all">
                        {{ $t("employees.filters.status.all") }}
                    </option>
                    <option value="active">
                        {{ $t("employees.filters.status.active") }}
                    </option>
                    <option value="inactive">
                        {{ $t("employees.filters.status.inactive") }}
                    </option>
                </SelectFilter>
                <SelectFilter v-model="group" name="group">
                    <option
                        v-for="group in groupRows"
                        :key="group.id"
                        :value="group.id"
                    >
                        {{ group.name }}
                    </option>
                </SelectFilter>
            </FilterLoading>
            <DeadlineTable
                :rows="rows"
                :meta="meta"
                :loading="loading"
                :error="error"
                @refresh="handleRefresh"
            />
        </div>
    </OneCol>
</template>

<script>
import { TrainingStoreNamespacedTypes } from "@/store/modules/training";
import { TeamStoreNamespacedTypes } from "@/store/modules/team";

import { getProperty } from "@/utils/object";

import OneCol from "@/components/layout/OneCol";
import Heading from "@/components/layout/Heading";
import DynamicTabs from "@/components/tabs/DynamicTabs";
import DeadlineTable from "@/components/display/training/DeadlineTable";
import { IconEnums } from "@/utils/icons";
import FormButton, {
    ButtonVariants,
} from "@/components/forms/fields/FormButton";
import SelectFilter from "@/components/forms/fields/SelectFilter";
import FilterLoading from "@/components/tables/FilterLoading";

export default {
    name: "DeadlinesIndex",
    components: {
        FilterLoading,
        SelectFilter,
        DeadlineTable,
        DynamicTabs,
        Heading,
        OneCol,
        FormButton,
    },
    data: () => ({
        ButtonVariants,
        IconEnums,
        status: "active",
        group: null,
        exportLoading: false,
        bulkReminderLoading: false,
    }),
    computed: {
        /**
         * Navigation tabs.
         * @type {Array}
         */
        tabs() {
            return [
                {
                    text: "lessons",
                    route: { name: "training.index" },
                },
                {
                    text: "deadlines",
                    route: { name: "training.deadlines" },
                },
            ];
        },

        /**
         * @type {Boolean}
         */
        loading() {
            return this.$store.getters[
                TrainingStoreNamespacedTypes.getters.PARTICIPATION_LOADING
            ]();
        },

        /**
         * @type {Object}
         */
        error() {
            return this.$store.getters[
                TrainingStoreNamespacedTypes.getters.PARTICIPATION_ERROR
            ]();
        },

        /**
         * @type {Array}
         */
        deadlines() {
            return this.$store.getters[
                TrainingStoreNamespacedTypes.getters.DEADLINES
            ]();
        },

        /**
         * @type {Array}
         */
        rows() {
            return getProperty(this.deadlines, "data", []);
        },

        /**
         * @type {Object}
         */
        filters() {
            return {
                "filter[status]": this.status,
                "filter[group]": this.group,
            };
        },

        /**
         * @type {Object}
         */
        meta() {
            return getProperty(this.deadlines, "meta", null);
        },

        /**
         * @type {Boolean}
         */
        groupsLoading() {
            return this.$store.getters[
                TeamStoreNamespacedTypes.getters.LOADING
            ]();
        },

        groups() {
            return this.$store.getters[
                TeamStoreNamespacedTypes.getters.GROUPS
            ]();
        },

        /**
         * @type {Array}
         */
        groupRows() {
            return getProperty(this.groups, "data", []);
        },
    },
    watch: {
        filters: {
            handler() {
                this.handleRefresh();
            },
            deep: true,
        },
    },
    methods: {
        handleRefresh(payload = {}) {
            const data = {
                ...this.filters,
                ...payload,
            };

            this.$store.dispatch(
                TrainingStoreNamespacedTypes.actions.GET_DEADLINES,
                data
            );
        },
        sendBulkReminder() {
            this.bulkReminderLoading = true;
            this.$store
                .dispatch(
                    TrainingStoreNamespacedTypes.actions.SEND_BULK_REMINDER
                )
                .then(() => {
                    this.bulkReminderLoading = false;
                });
        },
        exportToCsv() {
            this.exportLoading = true;
            this.$store
                .dispatch(
                    TrainingStoreNamespacedTypes.actions.EXPORT_DEADLINE_LIST
                )
                .then((response) => {
                    this.exportLoading = false;
                    const url = getProperty(response.data, "url");
                    if (url) {
                        window.location.href = url;
                    }
                });
        },
    },
    mounted() {
        this.handleRefresh();
        this.$store.dispatch(TeamStoreNamespacedTypes.actions.GET_ALL_GROUPS);
    },
};
</script>
