<template>
    <DataTable
        :headers="headers"
        :rows="rows"
        :loading="loading"
        :loadingRows="6"
        :error="error"
    >
        <template v-slot:identifier="{ item }">
            <div class="flex">
                <div
                    class="text-xxs mr-2 flex items-center justify-center rounded border border-slate-200 bg-slate-50 px-1 py-0.5 font-bold uppercase text-slate-600"
                >
                    <Icon :icon="IconEnums.DATABASE" class="mr-1 h-3 w-3" />
                    {{ item.identifier }}
                </div>
            </div>
        </template>
        <template v-slot:type="{ item }">
            <div class="flex">
                <div
                    class="text-xxs mr-2 flex items-center justify-center rounded border border-slate-200 bg-slate-50 px-1 py-0.5 font-bold uppercase text-slate-600"
                >
                    <Icon :icon="IconEnums.INTEGRATIONS" class="mr-1 h-3 w-3" />
                    {{ $t("settings.fields.types." + item.type) }}
                </div>
            </div>
        </template>
        <template v-slot:action="{ item }">
            <div class="flex justify-end">
                <FormButton
                    :variant="ButtonVariants.SECONDARY"
                    @click.native="deleteField(item)"
                    :icon="IconEnums.TRASH"
                />
            </div>
        </template>
    </DataTable>
</template>

<script>
import { getProperty } from "@/utils/object";

import DataTableMixin from "@/components/tables/DataTableMixin";
import Icon from "@/components/icons/BaseIcon";
import { IconEnums } from "@/utils/icons";
import FormButton, {
    ButtonVariants,
} from "@/components/forms/fields/FormButton";

export default {
    name: "FieldTable",
    components: { FormButton, Icon },
    mixins: [DataTableMixin],
    data: () => ({
        IconEnums,
        ButtonVariants,
        headers: [
            {
                title: "identifier",
                key: "identifier",
                sortable: false,
            },
            {
                title: "type",
                key: "type",
                sortable: false,
            },
            {
                title: "action",
                key: "action",
                sortable: false,
            },
        ],
    }),
    methods: {
        deleteField(item) {
            this.$emit("delete", getProperty(item, "identifier"));
        },
    },
};
</script>
