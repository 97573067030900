<template>
    <ValidationProvider
        tag="div"
        class="form-field form-field-radio-group"
        :vid="vid"
        :rules="rules"
        :name="name || label"
        v-slot="{ ariaInput }"
    >
        <p
            v-if="showLabels"
            class="mb-2 block text-sm font-medium text-slate-900"
        >
            {{ label || name }}
        </p>
        <p v-if="help && showLabels" class="mb-4 block text-sm text-slate-500">
            {{ help }}
        </p>
        <div
            class="-space-y-px rounded-xl bg-white"
            :class="{ 'flex flex-row justify-between gap-4': inline }"
        >
            <label
                v-for="(option, index) in options"
                :key="option.value"
                :for="option.value"
                :class="[
                    'relative flex cursor-pointer border p-4',
                    {
                        'border-indigo-200 bg-indigo-50':
                            innerValue === option.value,
                    },
                    { 'border-slate-200': innerValue !== option.value },
                    { 'rounded-tl-xl rounded-tr-xl': index === 0 && !inline },
                    {
                        'rounded-bl-xl rounded-br-xl':
                            index === Object.keys(options).length - 1 &&
                            !inline,
                    },
                    { 'rounded-xl text-center': inline },
                ]"
            >
                <input
                    :id="option.value"
                    type="radio"
                    v-model="innerValue"
                    :value="option.value"
                    :class="[
                        {
                            'mt-0.5 h-4 w-4 cursor-pointer border-slate-300 text-indigo-600 focus:ring-indigo-500':
                                !inline,
                        },
                        {
                            hidden: inline,
                        },
                    ]"
                    v-bind="ariaInput"
                />
                <span
                    class="flex flex-col"
                    :class="[{ 'ml-3': !inline }, { 'items-center': inline }]"
                >
                    <span
                        v-if="option.icon"
                        class="purple-gradient mb-3 rounded-full p-3 text-white"
                    >
                        <Icon class="h-6 w-6" :icon="option.icon" />
                    </span>
                    <span class="mb-1 block text-sm font-medium text-slate-900">
                        {{ option.title }}
                    </span>
                    <span
                        v-if="option.text"
                        class="block text-sm text-slate-500"
                    >
                        {{ option.text }}
                    </span>
                </span>
            </label>
        </div>
    </ValidationProvider>
</template>

<script>
import FieldMixin from "./FieldMixin";
import Icon from "@/components/icons/BaseIcon";

export default {
    name: "FormRadioGroup",
    mixins: [FieldMixin],
    components: { Icon },
    props: {
        options: {
            type: Array,
            required: true,
        },
        showLabels: {
            type: Boolean,
            default: true,
        },
        inline: {
            type: Boolean,
            default: false,
        },
    },
};
</script>
