<template>
    <OneCol>
        <TeamTabs />
        <EmployeeSearch v-if="showSearch" @close="showSearch = false" />
        <SlideOver
            :active="showEmployeePrompt"
            @close="showEmployeePrompt = false"
            :gutter="false"
        >
            <h2
                slot="title"
                class="text-base font-semibold leading-6 text-slate-900"
            >
                {{ $t("employees.preview.title") }}
            </h2>
            <template v-slot:content>
                <EmployeePreview
                    :employee="employee"
                    @saved="handleEmployeeUpdated"
                />
            </template>
        </SlideOver>
        <Prompt :active="showPrompt" @close="showPrompt = false" :gutter="false"
            ><h3 slot="header">{{ $t("employees.add") }}</h3>
            <div slot="content">
                <CreateEmployeesForm @saved="handleEmployeeCreated" />
            </div>
        </Prompt>
        <Heading
            :title="$t('employees.heading').toString()"
            :text="$t('employees.text').toString()"
            :loading="loading"
        >
            <FormButton
                :variant="ButtonVariants.SECONDARY"
                @click.native="showSearch = true"
                :icon="IconEnums.SEARCH"
            >
                {{ $t("employees.search.button") }}
            </FormButton>
            <FormButton v-if="isManager" @click.native="showPrompt = true">{{
                $t("employees.add")
            }}</FormButton>
        </Heading>
        <div class="card">
            <FilterLoading :loading="loading">
                <SelectFilter v-model="status" name="status">
                    <option value="all">
                        {{ $t("employees.filters.status.all") }}
                    </option>
                    <option value="active">
                        {{ $t("employees.filters.status.active") }}
                    </option>
                    <option value="inactive">
                        {{ $t("employees.filters.status.inactive") }}
                    </option>
                </SelectFilter>
            </FilterLoading>
            <EmployeeTable
                :rows="rows"
                :meta="meta"
                :loading="loading"
                :error="error"
                @refresh="handleRefresh"
                @emit="handleOpen"
                class="card"
            />
        </div>
    </OneCol>
</template>

<script>
import { AuthStoreNamespacedTypes } from "@/store/modules/auth";
import { TeamStoreNamespacedTypes } from "@/store/modules/team";

import { getProperty } from "@/utils/object";

import { IconEnums } from "@/utils/icons";
import Bus from "@/bus";
import OneCol from "@/components/layout/OneCol";
import Heading from "@/components/layout/Heading";
import TeamTabs from "@/components/tabs/TeamTabs";
import FormButton, {
    ButtonVariants,
} from "@/components/forms/fields/FormButton";
import EmployeeTable from "@/components/display/employee/EmployeeTable";
import CreateEmployeesForm from "@/components/forms/CreateEmployeesForm";
import Prompt from "@/components/ui/Prompt";
import SlideOver from "@/components/ui/SlideOver";
import EmployeePreview from "@/components/display/employee/EmployeePreview";
import EmployeeSearch from "@/components/search/EmployeeSearch";
import FilterLoading from "@/components/tables/FilterLoading";
import SelectFilter from "@/components/forms/fields/SelectFilter";

export default {
    name: "EmployeeIndex",
    components: {
        SelectFilter,
        FilterLoading,
        EmployeeSearch,
        EmployeePreview,
        SlideOver,
        Prompt,
        CreateEmployeesForm,
        EmployeeTable,
        FormButton,
        TeamTabs,
        Heading,
        OneCol,
    },
    data: () => ({
        IconEnums,
        ButtonVariants,
        showSearch: false,
        showPrompt: false,
        showEmployeePrompt: false,
        employee: null,
        status: "active",
    }),
    computed: {
        /**
         * The user model
         * @type {Boolean}
         */
        isManager() {
            return this.$store.getters[
                AuthStoreNamespacedTypes.getters.IS_MANAGER
            ]();
        },

        /**
         * The loading state for the API request.
         * @type {Boolean}
         */
        loading() {
            return this.$store.getters[
                TeamStoreNamespacedTypes.getters.LOADING
            ]();
        },

        /**
         * The error state of the API request.
         * @type {Object}
         */
        error() {
            return this.$store.getters[
                TeamStoreNamespacedTypes.getters.ERROR
            ]();
        },

        /**
         * @type {Object}
         */
        employees() {
            return this.$store.getters[
                TeamStoreNamespacedTypes.getters.EMPLOYEES
            ]();
        },

        /**
         * @type {Array}
         */
        rows() {
            return getProperty(this.employees, "data", []);
        },

        /**
         * @type {Object}
         */
        meta() {
            return getProperty(this.employees, "meta", null);
        },
    },
    watch: {
        status() {
            this.handleRefresh();
        },
    },
    methods: {
        handleEmployeeCreated() {
            this.showPrompt = false;
            setTimeout(() => {
                this.handleRefresh();
                Bus.$emit("flash-message", {
                    text: this.$t("employees.created"),
                    type: "success",
                });
            }, 500);
        },
        handleEmployeeUpdated() {
            this.showEmployeePrompt = false;
            this.handleRefresh();
        },
        handleRefresh(payload = {}) {
            const data = {
                status: this.status,
                ...payload,
            };

            this.$store.dispatch(
                TeamStoreNamespacedTypes.actions.GET_EMPLOYEES,
                data
            );
        },
        handleOpen(employee) {
            this.employee = employee;
            this.showEmployeePrompt = true;
        },
    },
    mounted() {
        this.handleRefresh();
    },
};
</script>
