<template>
    <div class="funnel-chart-item group relative flex w-full flex-col">
        <div
            class="relative flex w-full justify-start px-1.5"
            style="flex: 1 1 100%"
        >
            <div class="absolute inset-0 flex flex-col justify-between py-px">
                <div class="relative flex w-full justify-end">
                    <div class="flex-1 border-b border-slate-200"></div>
                </div>
                <div class="relative flex w-full justify-end">
                    <div class="flex-1 border-b border-slate-200"></div>
                </div>
                <div class="relative flex w-full justify-end">
                    <div class="flex-1 border-b border-slate-200"></div>
                </div>
            </div>
            <div
                class="relative flex h-full min-w-full flex-col text-center text-sm"
            >
                <div
                    v-if="hasDropOff"
                    class="chart-item-drop-off relative transition"
                    :style="{
                        flex: dropOffHeight + ' 1 0%',
                    }"
                >
                    <div
                        class="relative flex h-full transform flex-col items-center justify-center rounded border border-slate-300 text-slate-600 group-hover:border-slate-400"
                    >
                        <div
                            class="absolute inset-0 rounded bg-gradient-to-b from-slate-200 to-white opacity-75"
                        ></div>
                        <div class="flex items-end">
                            <span class="transform transition-transform"
                                >-{{ dropOffFromPrevValue }}%</span
                            >
                        </div>
                    </div>
                </div>
                <div
                    class="chart-item-value relative transition"
                    :style="{
                        flex: valueHeight + ' 1 0%',
                    }"
                >
                    <div
                        class="flex h-full flex-col items-stretch justify-between rounded bg-slate-500 py-2 text-center font-medium text-white ring-inset group-hover:shadow-md"
                    >
                        <span class="transform transition-transform">{{
                            value
                        }}</span>
                        <span v-if="!isFirst" class="text-xs text-slate-200"
                            >-{{ dropOffValue }}%</span
                        >
                    </div>
                </div>
            </div>
        </div>
        <div
            class="text-xxs relative mt-4 w-full min-w-full border-b border-t border-slate-200 bg-slate-100 px-1 py-0.5 text-center text-slate-800"
            :class="isLast ? 'rounded-r-md border-r' : ''"
        >
            <div class="w-full truncate">
                <span>{{ title }}</span>
            </div>
            <div
                class="absolute bottom-0 left-0 top-0 z-10 min-w-full scale-100 items-center justify-center whitespace-nowrap opacity-0 transition-transform group-hover:scale-[1.06] group-hover:opacity-100"
            >
                <div
                    class="flex h-full items-center justify-center rounded border border-slate-300 bg-white px-3"
                >
                    <span class="relative w-full truncate">{{ title }}</span>
                </div>
            </div>
            <svg
                fill="none"
                class="absolute left-0 top-1 h-3 w-3 -translate-x-1/2 transform text-slate-600"
            >
                <path
                    opacity="0.3"
                    d="M1.333 12.5l4-6-4-6"
                    stroke="#2D3748"
                ></path>
            </svg>
        </div>
    </div>
</template>

<script>
import { formatPercent } from "@/utils/number";

export default {
    name: "FunnelChartItem",
    props: {
        index: {
            type: Number,
            default: undefined,
        },
        title: {
            type: String,
            default: undefined,
        },
        value: {
            type: Number,
            default: 0,
        },
        maxValue: {
            type: Number,
            default: undefined,
        },
    },
    computed: {
        valueHeight() {
            return this.value / this.maxValue;
        },
        dropOffHeight() {
            if (this.value === 0) {
                return 1;
            }

            return 1 - this.valueHeight;
        },
        previousValue() {
            if (this.index === 0) {
                return 0;
            }

            return this.$parent.data[this.index - 1].value;
        },
        hasDropOff() {
            return this.value === 0 || this.previousValue !== 0;
        },
        dropOffValue() {
            if (this.value === 0) {
                return formatPercent(10000, 2);
            }

            let pct = ((this.maxValue - this.value) / this.maxValue) * 10000;

            return formatPercent(pct, 2);
        },
        dropOffFromPrevValue() {
            if (this.value === 0) {
                return formatPercent(10000, 2);
            }

            if (this.previousValue === 0) {
                return formatPercent(0, 2);
            }

            let pct =
                ((this.previousValue - this.value) / this.previousValue) *
                10000;

            return formatPercent(pct, 2);
        },
        isFirst() {
            return this.index === 0;
        },
        isLast() {
            return this.index === this.$parent.data.length - 1;
        },
    },
};
</script>
