/**
 * A store for handling the different statistics in the app.
 *
 * @typedef {{statistics: Object, error: ErrorOrObject}} StatisticsStoreState
 */

import { addNamespace } from "./namespace";
import { postData } from "@/api";
import { getProperty } from "@/utils/object";

/**
 * The types used in this store
 * @enum {string}
 */
export const StatisticsStoreTypes = {
    getters: {
        STATISTICS: "statistics",
        SCORE: "score",
        SCORE_EMPLOYEES: "scoreEmployees",
        ERROR: "error",
    },
    actions: {
        GET_DASHBOARD_STATISTICS: "getDashboardStatistics",
        GET_CAMPAIGN_STATISTICS: "getCampaignStatistics",
        GET_TRAINING_STATISTICS: "getTrainingStatistics",
        GET_THREAT_STATISTICS: "getThreatStatistics",
        GET_BREACH_STATISTICS: "getBreachStatistics",
        GET_SCORE: "getScore",
        GET_SCORE_EMPLOYEES: "getScoreEmployees",
    },
    mutations: {
        SET_DASHBOARD_STATISTICS: "setDashboardStatistics",
        SET_CAMPAIGN_STATISTICS: "setCampaignStatistics",
        SET_TRAINING_STATISTICS: "setTrainingStatistics",
        SET_VIDEO_STATISTICS: "setVideosStatistics",
        SET_COURSE_STATISTICS: "setCourseStatistics",
        SET_THREAT_STATISTICS: "setThreatStatistics",
        SET_BREACH_STATISTICS: "setBreachStatistics",
        SET_SCORE: "setScore",
        SET_SCORE_EMPLOYEES: "setScoreEmployees",
        SET_ERROR: "setError",
    },
};

/**
 * A namespaced version of the types used in this store
 * @enum {string}
 */
export const StatisticsStoreNamespacedTypes = addNamespace(
    "statistics",
    StatisticsStoreTypes
);

/**
 * @returns {StatisticsStoreState}
 */
export function state() {
    return {
        statistics: {
            score: {
                avg: 0,
                max: 0,
                min: 0,
            },
            training_coverage: {},
            simulation_coverage: {},
            employees: {},
            active_employees: 0,
            onboarding: {},
            deadlines: {
                total: 0,
            },
            campaigns: {
                data: {},
                graph: [],
            },
            quizzes: {
                data: {},
                graph: [],
            },
            videos: {},
            courses: {},
            threats: {},
            breaches: {},
        },
        score: {
            avg: 0,
            max: 0,
            min: 0,
            pillars: {
                competence: 0, // @TODO: Update this to the correct value
            },
            activity: {
                clicked: 0, // @TODO: Update this to the correct value
            },
        },
        scoreEmployees: {
            data: [],
            meta: {
                current_page: 1,
                from: 0,
                last_page: 1,
                per_page: 10,
                to: 10,
                total: 0,
            },
        },
        error: null,
    };
}

export const getters = {
    [StatisticsStoreTypes.getters.STATISTICS]: (state) => () => {
        return state.statistics;
    },
    [StatisticsStoreTypes.getters.SCORE]: (state) => () => {
        return state.score;
    },
    [StatisticsStoreTypes.getters.SCORE_EMPLOYEES]: (state) => () => {
        return state.scoreEmployees;
    },
    [StatisticsStoreTypes.getters.ERROR]: (state) => () => {
        return state.error;
    },
};

export const actions = {
    /**
     * @param {VuexCommit} commit
     * @return {Promise}
     */
    [StatisticsStoreTypes.actions.GET_DASHBOARD_STATISTICS]({ commit }) {
        return postData("/workspace/statistics/dashboard")
            .then((response) => {
                commit(
                    StatisticsStoreTypes.mutations.SET_DASHBOARD_STATISTICS,
                    response.data
                );
            })
            .catch((errors) => {
                commit(StatisticsStoreTypes.mutations.SET_ERROR, errors);
            });
    },

    /**
     * @param {VuexCommit} commit
     * @param {Object} filters
     * @return {Promise}
     */
    [StatisticsStoreTypes.actions.GET_CAMPAIGN_STATISTICS](
        { commit },
        filters
    ) {
        return postData("/workspace/statistics/campaigns", filters)
            .then((response) => {
                commit(
                    StatisticsStoreTypes.mutations.SET_CAMPAIGN_STATISTICS,
                    response.data
                );
            })
            .catch((errors) => {
                commit(StatisticsStoreTypes.mutations.SET_ERROR, errors);
            });
    },

    /**
     * @param {VuexCommit} commit
     * @param {Object} filters
     * @return {Promise}
     */
    [StatisticsStoreTypes.actions.GET_TRAINING_STATISTICS](
        { commit },
        filters
    ) {
        return postData("/workspace/statistics/training", filters)
            .then((response) => {
                commit(
                    StatisticsStoreTypes.mutations.SET_TRAINING_STATISTICS,
                    response.data
                );
            })
            .catch((errors) => {
                commit(StatisticsStoreTypes.mutations.SET_ERROR, errors);
            });
    },

    /**
     * @param {VuexCommit} commit
     * @param {Object} filters
     * @return {Promise}
     */
    [StatisticsStoreTypes.actions.GET_THREAT_STATISTICS]({ commit }, filters) {
        return postData("/workspace/statistics/threats", filters)
            .then((response) => {
                commit(
                    StatisticsStoreTypes.mutations.SET_THREAT_STATISTICS,
                    response.data
                );
            })
            .catch((errors) => {
                commit(StatisticsStoreTypes.mutations.SET_ERROR, errors);
            });
    },

    /**
     * @param {VuexCommit} commit
     * @param {Object} filters
     * @return {Promise}
     */
    [StatisticsStoreTypes.actions.GET_BREACH_STATISTICS]({ commit }, filters) {
        return postData("/workspace/statistics/breaches", filters)
            .then((response) => {
                commit(
                    StatisticsStoreTypes.mutations.SET_BREACH_STATISTICS,
                    response.data
                );
            })
            .catch((errors) => {
                commit(StatisticsStoreTypes.mutations.SET_ERROR, errors);
            });
    },

    /**
     * @param {VuexCommit} commit
     * @param {Object} filters
     * @return {Promise}
     */
    [StatisticsStoreTypes.actions.GET_SCORE]({ commit }, filters) {
        return postData("/workspace/statistics/score", filters)
            .then((response) => {
                commit(StatisticsStoreTypes.mutations.SET_SCORE, response.data);
            })
            .catch((errors) => {
                commit(StatisticsStoreTypes.mutations.SET_ERROR, errors);
            });
    },

    /**
     * @param {VuexCommit} commit
     * @param {Object} filters
     * @return {Promise}
     */
    [StatisticsStoreTypes.actions.GET_SCORE_EMPLOYEES]({ commit }, filters) {
        return postData("/workspace/statistics/score/employees", filters)
            .then((response) => {
                commit(StatisticsStoreTypes.mutations.SET_SCORE_EMPLOYEES, {
                    data: getProperty(response.data, "data", []),
                    meta: getProperty(response.data, "meta", {
                        current_page: 1,
                        from: 0,
                        last_page: 1,
                        per_page: 10,
                        to: 10,
                        total: 0,
                    }),
                });
            })
            .catch((errors) => {
                commit(StatisticsStoreTypes.mutations.SET_ERROR, errors);
            });
    },
};

export const mutations = {
    /**
     * Sets the dashboard statistics data.
     *
     * @param {StatisticsStoreState} state
     * @param {Object} data
     */
    [StatisticsStoreTypes.mutations.SET_DASHBOARD_STATISTICS](state, data) {
        state.statistics.score = getProperty(data, "score");
        state.statistics.training_coverage = getProperty(
            data,
            "training_coverage"
        );
        state.statistics.simulation_coverage = getProperty(
            data,
            "simulation_coverage"
        );
        state.statistics.employees = getProperty(data, "employees");
        state.statistics.active_employees = getProperty(
            data,
            "active_employees",
            0
        );
        state.statistics.onboarding = getProperty(data, "onboarding");
        state.statistics.deadlines = getProperty(data, "deadlines");
    },

    /**
     * Sets the campaign statistics data.
     *
     * @param {StatisticsStoreState} state
     * @param {Object} data
     */
    [StatisticsStoreTypes.mutations.SET_CAMPAIGN_STATISTICS](state, data) {
        state.statistics.campaigns.data = getProperty(data, "data");
        state.statistics.campaigns.graph = getProperty(data, "graph");
    },

    /**
     * Sets the training statistics data.
     *
     * @param {StatisticsStoreState} state
     * @param {Object} data
     */
    [StatisticsStoreTypes.mutations.SET_TRAINING_STATISTICS](state, data) {
        state.statistics.quizzes = getProperty(data, "quizzes");
        state.statistics.videos = getProperty(data, "videos");
        state.statistics.courses = getProperty(data, "courses");
    },

    /**
     * Sets the quiz statistics data.
     *
     * @param {StatisticsStoreState} state
     * @param {Object} data
     */
    [StatisticsStoreTypes.mutations.SET_THREAT_STATISTICS](state, data) {
        state.statistics.threats = getProperty(data, "data");
    },

    /**
     * Sets the quiz statistics data.
     *
     * @param {StatisticsStoreState} state
     * @param {Object} data
     */
    [StatisticsStoreTypes.mutations.SET_BREACH_STATISTICS](state, data) {
        state.statistics.breaches = getProperty(data, "data");
    },

    /**
     * Sets the quiz statistics data.
     *
     * @param {StatisticsStoreState} state
     * @param {Object} data
     */
    [StatisticsStoreTypes.mutations.SET_SCORE](state, data) {
        state.score = getProperty(data, "data");
    },

    /**
     * Sets the quiz statistics data.
     *
     * @param {StatisticsStoreState} state
     * @param {Object} data
     */
    [StatisticsStoreTypes.mutations.SET_SCORE_EMPLOYEES](state, data) {
        state.scoreEmployees = data;
    },

    /**
     * Sets the error state for the statistics
     *
     * @param {StatisticsStoreState} state
     * @param {ErrorOrObject} error
     */
    [StatisticsStoreTypes.mutations.SET_ERROR](state, error) {
        state.error = error;
    },
};

export default {
    namespaced: true,
    Types: StatisticsStoreTypes,
    NamespacedTypes: StatisticsStoreNamespacedTypes,
    state,
    getters,
    actions,
    mutations,
};
