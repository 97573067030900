<template>
    <g
        fill="none"
        stroke="currentColor"
        stroke-linecap="round"
        stroke-linejoin="round"
        stroke-width="2"
    >
        <path
            d="M3 12c0-2.796 0-4.193.457-5.296a6 6 0 0 1 3.247-3.247C7.807 3 9.204 3 12 3c2.796 0 4.194 0 5.296.457a6 6 0 0 1 3.247 3.247C21 7.807 21 9.204 21 12c0 2.796 0 4.194-.457 5.296a6 6 0 0 1-3.247 3.247C16.194 21 14.796 21 12 21c-2.796 0-4.193 0-5.296-.457a6 6 0 0 1-3.247-3.247C3 16.194 3 14.796 3 12Z"
        />
        <path
            d="M9.5 11.896c0-1.432 0-2.148.3-2.547a1.5 1.5 0 0 1 1.093-.598c.498-.035 1.1.352 2.305 1.126l.162.104c1.045.672 1.567 1.008 1.748 1.435a1.5 1.5 0 0 1 0 1.168c-.18.427-.703.763-1.748 1.435l-.162.104c-1.205.774-1.807 1.161-2.305 1.126A1.5 1.5 0 0 1 9.8 14.65c-.299-.4-.299-1.115-.299-2.547v-.208Z"
        />
    </g>
</template>

<script>
import IconSvgBase from "./IconSvgBase";

export default {
    extends: IconSvgBase,
    name: "IconVideo",
};
</script>
