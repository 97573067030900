<template>
    <WorkspaceSettingsPage>
        <div class="rounded-xl bg-white shadow-sm">
            <CardHeading
                :title="$t('settings.export.heading').toString()"
                :text="$t('settings.export.text').toString()"
            />
            <div class="p-4">
                <FormFieldSet>
                    <FormFieldOutline>
                        <FormSelect v-model="report" name="report">
                            <option
                                v-for="group in rows"
                                :key="group.id"
                                :value="group.id"
                            >
                                {{ group.name }}
                            </option>
                        </FormSelect>
                    </FormFieldOutline>
                </FormFieldSet>
                <GenerateEmployeeReportForm
                    v-if="report === 'employee'"
                    @saved="handleSaved"
                />
                <GenerateTrainingReportForm
                    v-if="report === 'training'"
                    @saved="handleSaved"
                />
                <GenerateComplianceReportForm
                    v-if="report === 'compliance'"
                    @saved="handleSaved"
                />
            </div>
        </div>
    </WorkspaceSettingsPage>
</template>

<script>
import { TeamStoreNamespacedTypes } from "@/store/modules/team";

import { getProperty } from "@/utils/object";

import GenerateEmployeeReportForm from "@/components/forms/GenerateEmployeeReportForm";
import FormSelect from "@/components/forms/fields/FormSelect";
import GenerateTrainingReportForm from "@/components/forms/GenerateTrainingReportForm";
import WorkspaceSettingsPage from "@/components/layout/WorkspaceSettingsPage";
import CardHeading from "@/components/ui/CardHeading";
import GenerateComplianceReportForm from "@/components/forms/GenerateComplianceReportForm";
import FormFieldOutline from "@/components/forms/fields/FormFieldOutline";
import FormFieldSet from "@/components/forms/fields/FormFieldSet";

export default {
    name: "ExportPage",
    components: {
        FormFieldSet,
        FormFieldOutline,
        GenerateComplianceReportForm,
        CardHeading,
        WorkspaceSettingsPage,
        GenerateTrainingReportForm,
        FormSelect,
        GenerateEmployeeReportForm,
    },
    data: () => ({
        report: "employee",
    }),
    computed: {
        rows() {
            return [
                {
                    id: "employee",
                    name: this.$t("settings.export.report.employee"),
                },
                {
                    id: "training",
                    name: this.$t("settings.export.report.training"),
                },
                /*{
                    id: "compliance",
                    name: this.$t("settings.export.report.compliance"),
                },*/
            ];
        },
    },
    methods: {
        handleSaved(data) {
            const url = getProperty(data, "url");
            if (url) {
                window.location.href = url;
            }
        },
    },
    mounted() {
        this.$store.dispatch(TeamStoreNamespacedTypes.actions.GET_ALL_GROUPS);
    },
};
</script>
