<template>
    <div class="h-full overflow-auto px-4">
        <div class="mb-4 flex flex-col space-y-4 rounded-xl bg-white p-4">
            <div class="flex flex-row items-center justify-between">
                <div class="flex flex-row items-center gap-x-2">
                    <h3 class="font-bold text-slate-800">{{ name }}</h3>
                    <Badge
                        :variant="badgeVariant"
                        :text="$t('automation.status.' + status).toString()"
                        :loading="status === 'started'"
                    />
                </div>
                <div class="flex flex-row items-stretch justify-end gap-x-2">
                    <FormButton
                        :to="{
                            name: 'automation.single',
                            params: { id: id },
                        }"
                        :variant="ButtonVariants.SECONDARY"
                        >{{ $t("automation.flow.actions.preview") }}</FormButton
                    >
                    <FormButton
                        @click.native="duplicate"
                        :variant="ButtonVariants.SECONDARY"
                        :icon="IconEnums.DUPLICATE"
                        :loading="duplicateLoading"
                    />
                    <FormButton
                        :to="{
                            name: 'automation.edit',
                            params: { id: id },
                        }"
                        :variant="ButtonVariants.SECONDARY"
                        :icon="IconEnums.SETTINGS"
                    />
                </div>
            </div>
        </div>
        <div v-if="funnelData.length > 2" class="card mb-4">
            <CardHeading :title="$t('automation.funnel.heading').toString()" />
            <div class="p-4">
                <FunnelChart :loading="false" :data="funnelData" />
            </div>
        </div>
        <div class="relative mb-4 flex flex-col space-y-4 rounded-xl bg-white">
            <CardHeading
                :title="$t('automation.sequence.heading').toString()"
                :text="$t('automation.sequence.text').toString()"
            />
            <div class="px-4">
                <ul v-if="hasActions" role="list" class="mb-2">
                    <AutomationFlowStep
                        v-for="(action, index) in actions"
                        :key="action.id"
                        :automation-id="id"
                        :action-id="action.id"
                        :index="index"
                        :variant="StepVariants.SMALL"
                    />
                </ul>
                <AutomationFlowPlaceholder v-else :id="id" class="mb-4" />
            </div>
        </div>
    </div>
</template>

<script>
import { AutomationStoreNamespacedTypes } from "@/store/modules/automation";

import AutomationFlowStep, {
    StepVariants,
} from "@/components/display/automation/AutomationFlowStep";
import { getProperty } from "@/utils/object";
import FormButton, {
    ButtonVariants,
} from "@/components/forms/fields/FormButton";
import { IconEnums } from "@/utils/icons";
import FunnelChart from "@/components/charts/FunnelChart/FunnelChart";
import CardHeading from "@/components/ui/CardHeading";
import AutomationFlowPlaceholder from "@/components/display/automation/AutomationFlowPlaceholder";
import Badge, { BadgeVariants } from "@/components/shared/Badge";

export default {
    name: "AutomationPreview",
    components: {
        Badge,
        AutomationFlowPlaceholder,
        CardHeading,
        FunnelChart,
        FormButton,
        AutomationFlowStep,
    },
    data: () => ({
        BadgeVariants,
        IconEnums,
        ButtonVariants,
        StepVariants,
        duplicateLoading: false,
    }),
    computed: {
        /**
         * @type {Object}
         */
        error() {
            return this.$store.getters[
                AutomationStoreNamespacedTypes.getters.AUTOMATION_ERROR
            ]();
        },

        /**
         * @type {Array}
         */
        automation() {
            return this.$store.getters[
                AutomationStoreNamespacedTypes.getters.AUTOMATION
            ]();
        },

        /**
         * @type {String}
         */
        id() {
            return getProperty(this.automation, "id");
        },

        /**
         * @type {String}
         */
        name() {
            return getProperty(this.automation, "name");
        },

        /**
         * @type {String}
         */
        badgeVariant() {
            return this.status === "started"
                ? this.BadgeVariants.SUCCESS
                : this.BadgeVariants.WARNING;
        },

        /**
         * @type {String}
         */
        status() {
            return getProperty(this.automation, "status");
        },

        /**
         * @type {Array}
         */
        actions() {
            return getProperty(this.automation, "actions", []);
        },

        /**
         * @type {Boolean}
         */
        hasActions() {
            return getProperty(this.automation, "actions", []).length > 0;
        },

        /**
         * @type {Array}
         */
        funnelData() {
            return getProperty(this.automation, "actions", []).map((action) => {
                return {
                    title:
                        this.$t("automation.flow.actions.step").toString() +
                        " " +
                        action.order,
                    value: getProperty(action, "statistics.total", 0),
                };
            });
        },
    },
    methods: {
        duplicate() {
            this.duplicateLoading = true;
            this.$store
                .dispatch(
                    AutomationStoreNamespacedTypes.actions.DUPLICATE_AUTOMATION,
                    this.id
                )
                .then(() => {
                    if (!this.error) {
                        this.$emit("duplicated");
                    }
                    this.duplicateLoading = false;
                });
        },
    },
    mounted() {
        this.$store.dispatch(
            AutomationStoreNamespacedTypes.actions.GET_AUTOMATION,
            this.id
        );
    },
};
</script>
