<template>
    <DashboardPage>
        <template>
            <ValueCard
                :loading="threatsStatisticsLoading"
                :title="$t('statistics.threat_count.title').toString()"
                :text="$t('statistics.threat_count.text').toString()"
                :value="count"
                class="card"
            />
            <ValueCard
                :loading="threatsStatisticsLoading"
                :title="$t('statistics.threat_handle_rate.title').toString()"
                :text="$t('statistics.threat_handle_rate.text').toString()"
                :value="handleRate"
                :pct="true"
                :upper-threshold="7500"
                class="card"
            />
            <GroupChartCard
                :loading="threatsStatisticsLoading"
                :title="
                    $t('statistics.threat_status_partition.title').toString()
                "
                identifier="threat_status_partition"
                :data="statusPartition"
                :rows="3"
                class="card"
            />
            <ValueCard
                :loading="threatsStatisticsLoading"
                :title="$t('statistics.fraudulent_count.title').toString()"
                :text="$t('statistics.fraudulent_count.text').toString()"
                :value="fraudulentCount"
                class="card"
            />
            <BarChartCard
                :loading="threatsStatisticsLoading"
                :title="$t('statistics.threats_reported.title').toString()"
                :unit="$t('statistics.ninety_days').toString()"
                :data="graph"
                class="card col-span-1 md:col-span-2"
            />
        </template>
    </DashboardPage>
</template>

<script>
import { StatisticsStoreNamespacedTypes } from "@/store/modules/statistics";

import { getProperty } from "@/utils/object";

import GroupChartCard from "@/components/statistics/GroupChartCard";
import DashboardPage from "@/components/layout/DashboardPage";
import ValueCard from "@/components/statistics/ValueCard";
import BarChartCard from "@/components/statistics/BarChartCard";

export default {
    name: "DashboardThreatsPage",
    components: {
        BarChartCard,
        ValueCard,
        DashboardPage,
        GroupChartCard,
    },
    data: () => ({
        threatsStatisticsLoading: true,
    }),
    computed: {
        /**
         * @type {Object}
         */
        error() {
            return this.$store.getters[
                StatisticsStoreNamespacedTypes.getters.ERROR
            ]();
        },

        /**
         * @type {Object}
         */
        statistics() {
            return this.$store.getters[
                StatisticsStoreNamespacedTypes.getters.STATISTICS
            ]();
        },

        threats() {
            return getProperty(this.statistics, "threats");
        },

        count() {
            return getProperty(this.threats, "count", 0);
        },

        fraudulentCount() {
            return getProperty(this.threats, "fraudulent_count", 0);
        },

        handleRate() {
            return getProperty(this.threats, "handle_rate", 0);
        },

        statusPartition() {
            return getProperty(this.threats, "status_partition", {
                count: 0,
                groups: [
                    {
                        label: "fraudulent",
                        count: 0,
                        pct: 0,
                        color: "success-dark",
                    },
                    {
                        label: "legitimate",
                        count: 0,
                        pct: 0,
                        color: "success-dark",
                    },
                    {
                        label: "spam",
                        count: 0,
                        pct: 0,
                        color: "success-dark",
                    },
                ],
            });
        },
        graph() {
            return getProperty(this.threats, "graph", []);
        },
    },
    watch: {
        range() {
            this.getThreatStatistics();
        },
    },
    methods: {
        getThreatStatistics() {
            this.threatsStatisticsLoading = true;
            this.$store
                .dispatch(
                    StatisticsStoreNamespacedTypes.actions.GET_THREAT_STATISTICS
                )
                .then(() => {
                    this.threatsStatisticsLoading = false;
                });
        },
    },
    mounted() {
        this.$store.commit(
            StatisticsStoreNamespacedTypes.mutations.SET_ERROR,
            null
        );
        this.getThreatStatistics();
    },
};
</script>
