<template>
    <div class="p-4">
        <div class="flex w-full">
            <h3
                class="mb-4 flex-1 font-medium leading-tight text-slate-500"
                v-text="$t('employees.recent_simulations').toString()"
            ></h3>
        </div>
        <div class="mt-4 flow-root">
            <LoadingSpinner v-if="loading" />
            <ul v-else class="-mb-8">
                <CampaignSendablePreview
                    v-for="simulation in data"
                    :key="simulation.id"
                    :simulation="simulation"
                />
                <li>
                    <div class="relative pb-8">
                        <span
                            class="absolute left-5 top-5 -ml-px h-full w-0.5 bg-slate-200"
                            aria-hidden="true"
                        ></span>
                        <div class="relative flex items-start space-x-3">
                            <div>
                                <div class="relative px-1">
                                    <div
                                        class="flex h-8 w-8 items-center justify-center rounded-full bg-slate-100 ring-8 ring-white"
                                    >
                                        <svg
                                            class="h-5 w-5 text-slate-500"
                                            fill="none"
                                            stroke="currentColor"
                                            viewBox="0 0 24 24"
                                            xmlns="http://www.w3.org/2000/svg"
                                        >
                                            <path
                                                stroke-linecap="round"
                                                stroke-linejoin="round"
                                                stroke-width="2"
                                                d="M5.121 17.804A13.937 13.937 0 0112 16c2.5 0 4.847.655 6.879 1.804M15 10a3 3 0 11-6 0 3 3 0 016 0zm6 2a9 9 0 11-18 0 9 9 0 0118 0z"
                                            ></path>
                                        </svg>
                                    </div>
                                </div>
                            </div>
                            <div class="min-w-0 flex-1 py-1.5">
                                <div class="text-sm text-slate-500">
                                    {{ $t("employees.email_validated") }}:
                                    <span class="font-medium text-slate-900">{{
                                        employee.created_at | formatDateTime
                                    }}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </li>
            </ul>
        </div>
    </div>
</template>

<script>
import { TeamStoreNamespacedTypes } from "@/store/modules/team";
import { formatDateTime } from "@/utils/date";
import CampaignSendablePreview from "@/components/display/campaign/CampaignSendablePreview";
import LoadingSpinner from "@/components/ui/LoadingSpinner";

export default {
    name: "RecentSimulations",
    components: { LoadingSpinner, CampaignSendablePreview },
    props: {
        data: {
            type: Array,
            required: true,
        },
    },
    filters: { formatDateTime },
    computed: {
        /**
         * The loading state for the API request.
         * @type {Boolean}
         */
        loading() {
            return this.$store.getters[
                TeamStoreNamespacedTypes.getters.LOADING
            ]();
        },

        /**
         * @type {Object}
         */
        employee() {
            return this.$store.getters[
                TeamStoreNamespacedTypes.getters.EMPLOYEE
            ]();
        },
    },
};
</script>
