var render = function render(){var _vm=this,_c=_vm._self._c;return _c('DashboardPage',{attrs:{"grid":false},scopedSlots:_vm._u([{key:"sidebar",fn:function(){return [_c('FormDateRangeSelect',{attrs:{"type":"STATISTICS"},model:{value:(_vm.range),callback:function ($$v) {_vm.range=$$v},expression:"range"}})]},proxy:true}])},[[_c('SlideOver',{attrs:{"active":_vm.showPreviewPrompt,"gutter":false},on:{"close":function($event){_vm.showPreviewPrompt = false}},scopedSlots:_vm._u([{key:"content",fn:function(){return [_c('div',{staticClass:"px-4"},[_c('ScoreCard',{attrs:{"title":_vm.$t(
                                'statistics.factor.competence.heading'
                            ).toString(),"text":_vm.$t(
                                'statistics.factor.competence.text'
                            ).toString(),"value":89,"factors":[
                            {
                                title: 'Quiz score',
                                value: 0,
                                failure: false,
                            },
                            {
                                title: 'Threats reported',
                                value: 0,
                                failure: false,
                            },
                            { title: 'Ting', value: 0, failure: true },
                        ]}}),_c('div',{staticClass:"card mt-4 p-4"},[_vm._v("fs")])],1)]},proxy:true}])},[_c('h2',{staticClass:"text-base font-semibold leading-6 text-slate-900",attrs:{"slot":"title"},slot:"title"},[_vm._v(" "+_vm._s(_vm.$t("employees.preview.title"))+" ")])]),_c('div',{staticClass:"mb-4 grid grid-cols-1 gap-4 md:grid-cols-2 xl:grid-cols-3 2xl:grid-cols-4"},[_c('AvgScoreCard',{staticClass:"card",attrs:{"loading":_vm.loading,"title":_vm.$t('statistics.resilience_score.title').toString(),"text":_vm.$t('statistics.resilience_score.text').toString(),"value":_vm.currentScore,"max":100,"min":0,"unit":_vm.$t('statistics.ninety_days').toString()}}),_c('BenchmarkCard',{staticClass:"card flex xl:hidden",attrs:{"loading":_vm.loading,"value":_vm.currentScore,"network":_vm.networkAverage}}),_c('LineChartCard',{staticClass:"card col-span-1 md:col-span-2",attrs:{"loading":_vm.loading,"title":_vm.$t(
                        'statistics.resilience_score_progression.title'
                    ).toString(),"data":_vm.progressionSeries,"labels":_vm.progressionLabels,"legends":[
                    {
                        title: _vm.$t(
                            'statistics.resilience_score_progression.score'
                        ),
                        color: '#8462ee',
                    },
                ]}}),_c('BenchmarkCard',{staticClass:"card hidden xl:flex",attrs:{"loading":_vm.loading,"value":_vm.currentScore,"network":_vm.networkAverage}}),_c('ScoreCard',{attrs:{"loading":_vm.loading,"title":_vm.$t('statistics.factor.culture.title').toString(),"text":_vm.$t('statistics.factor.culture.text').toString(),"value":_vm.cultureScore,"factors":_vm.cultureFactors}}),_c('ScoreCard',{attrs:{"loading":_vm.loading,"title":_vm.$t('statistics.factor.competence.title').toString(),"text":_vm.$t('statistics.factor.competence.text').toString(),"value":_vm.competenceScore,"factors":_vm.competenceFactors}}),_c('ScoreCard',{attrs:{"loading":_vm.loading,"title":_vm.$t('statistics.factor.threats.title').toString(),"text":_vm.$t('statistics.factor.threats.text').toString(),"value":_vm.threatScore,"factors":_vm.threatFactors}}),_c('ActivityByTypeCard',{attrs:{"loading":_vm.loading,"simulationsDelivered":_vm.simulationsDelivered,"videosWatched":_vm.videosWatched,"coursesCompleted":_vm.coursesCompleted,"threatsReported":_vm.threatsReported,"quizAnswers":_vm.quizAnswersTotal}})],1),_c('div',{staticClass:"mb-8 flex justify-end"},[_c('p',{staticClass:"text-xxs flex flex-row items-center font-medium text-slate-600"},[_vm._v(" "+_vm._s(_vm.$t("statistics.calculated_at"))+": "),(_vm.loading)?_c('span',{staticClass:"ml-1 h-3 w-24 animate-pulse rounded bg-slate-200"}):_c('span',{staticClass:"ml-1",domProps:{"textContent":_vm._s(_vm.calculatedAt)}})])]),_c('Tabs',[_c('TabItem',{attrs:{"title":"at_risk","active":_vm.currentTab === 1},on:{"clicked":function($event){_vm.currentTab = 1}}}),_c('TabItem',{attrs:{"title":"insights","active":_vm.currentTab === 2},on:{"clicked":function($event){_vm.currentTab = 2}}})],1),_c('div',{staticClass:"card mb-4"},[(_vm.currentTab === 1)?_c('EmployeeTable',{staticClass:"card",attrs:{"rows":_vm.rows,"meta":_vm.meta,"loading":_vm.employeesLoading},on:{"refresh":_vm.getEmployees}}):(_vm.currentTab === 2)?_c('InsightsEmptyState'):_vm._e()],1)]],2)
}
var staticRenderFns = []

export { render, staticRenderFns }