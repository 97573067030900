<template>
    <div
        class="relative z-50"
        aria-labelledby="slide-over-title"
        role="dialog"
        aria-modal="true"
        v-if="active"
    >
        <div
            :class="showPrompt ? 'opacity-100' : 'opacity-0'"
            class="fixed inset-0 bg-black bg-opacity-25 backdrop-blur-[1px] transition-opacity"
            aria-hidden="true"
        ></div>
        <div class="fixed inset-0 overflow-hidden">
            <div
                class="pointer-events-none fixed inset-y-0 right-0 flex max-w-full"
            >
                <div
                    :class="[
                        { 'max-w-[34rem]': SizeVariants.NORMAL === variant },
                        { 'max-w-[68rem]': SizeVariants.BIG === variant },
                        { 'translate-x-0': showPrompt },
                        { 'translate-x-full': !showPrompt },
                    ]"
                    class="pointer-events-auto w-screen transform p-2 transition duration-200 ease-in-out sm:duration-300 md:p-4"
                >
                    <div
                        class="flex h-full flex-col overflow-hidden rounded-xl bg-slate-100 shadow-xl"
                    >
                        <div
                            class="flex items-center justify-between rounded-t-2xl p-4"
                        >
                            <slot name="title" />
                            <div
                                class="ml-3 inline-flex cursor-pointer items-center justify-center rounded-full bg-slate-200 p-1.5 text-slate-500 transition-all duration-300 hover:rotate-90 hover:bg-slate-300 hover:text-slate-600 focus:outline-none focus:ring-2 focus:ring-offset-2 sm:text-sm"
                                @click="close"
                            >
                                <Icon :icon="IconEnums.CLOSE" class="size-5" />
                            </div>
                        </div>
                        <slot
                            name="content"
                            class="relative flex h-full flex-col overflow-hidden"
                        ></slot>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { IconEnums } from "@/utils/icons";
import Icon from "@/components/icons/BaseIcon";

export const SizeVariants = {
    NORMAL: "normal",
    BIG: "big",
};

export default {
    name: "SlideOver",
    components: { Icon },
    props: {
        active: {
            type: Boolean,
            default: false,
        },
        gutter: {
            type: Boolean,
            default: true,
        },
        variant: {
            type: String,
            default: SizeVariants.NORMAL,
            validator(type) {
                return Object.values(SizeVariants).includes(type);
            },
        },
    },
    data: () => ({
        showPrompt: false,
        SizeVariants,
        IconEnums,
    }),
    watch: {
        active(newValue) {
            setTimeout(() => {
                this.showPrompt = newValue;
            }, 200);
        },
    },
    methods: {
        close() {
            this.showPrompt = false;
            setTimeout(() => {
                this.$emit("close");
            }, 200);
        },
    },
    mounted() {
        document.addEventListener("keydown", (e) => {
            if (e.keyCode === 27) {
                this.close();
            }
        });
    },
};
</script>
