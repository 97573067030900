<template>
    <ValidationObserver ref="workspaceSettingsForm" v-slot="{ passes }">
        <form @submit.prevent="passes(updateSettings)" class="-mb-4">
            <FormFieldSet>
                <FormInput
                    v-model="form.hotline"
                    type="email"
                    placeholder="it@domain.com"
                    name="hotline"
                    rules="required"
                />
                <FormInput
                    v-model="form.name"
                    type="text"
                    name="company"
                    rules="required"
                />
                <FormSelect v-model="form.industry" name="industry">
                    <option
                        v-for="industry in industries"
                        :key="industry"
                        :value="industry"
                    >
                        {{ $t("industries." + industry) }}
                    </option>
                </FormSelect>
                <div class="flex justify-end">
                    <FormButton type="submit" :loading="formLoading">
                        {{ $t("forms.buttons.save_settings") }}
                    </FormButton>
                </div>
            </FormFieldSet>
        </form>
    </ValidationObserver>
</template>

<script>
import { WorkspaceStoreNamespacedTypes } from "@/store/modules/workspace";

import { getProperty } from "@/utils/object";

import { ValidationObserver } from "vee-validate";
import { industries } from "@/utils/enums";

import FormInput from "@/components/forms/fields/FormInput";
import FormFieldSet from "@/components/forms/fields/FormFieldSet";
import FormButton from "@/components/forms/fields/FormButton";
import FormSelect from "@/components/forms/fields/FormSelect";

export default {
    components: {
        FormSelect,
        FormButton,
        FormFieldSet,
        ValidationObserver,
        FormInput,
    },
    data: () => ({
        industries,
        formLoading: false,
        form: {
            name: null,
            industry: null,
            hotline: null,
        },
    }),
    computed: {
        /**
         * @type {Object}
         */
        error() {
            return this.$store.getters[
                WorkspaceStoreNamespacedTypes.getters.WORKSPACE_ERROR
            ]();
        },

        /**
         * @type {Object}
         */
        workspace() {
            return this.$store.getters[
                WorkspaceStoreNamespacedTypes.getters.WORKSPACE
            ]();
        },
    },
    methods: {
        getProperty,
        updateSettings() {
            this.formLoading = true;
            this.$store
                .dispatch(
                    WorkspaceStoreNamespacedTypes.actions.UPDATE_SETTINGS,
                    this.form
                )
                .then(() => {
                    this.formLoading = false;
                    if (!this.error) {
                        this.$emit("saved");
                    }
                });
        },
    },
    mounted() {
        this.form = {
            name: this.getProperty(this.workspace, "name", null),
            industry: this.getProperty(this.workspace, "industry", null),
            hotline: getProperty(this.workspace, "hotline", null),
        };
    },
    beforeCreate() {
        this.$store.commit(
            WorkspaceStoreNamespacedTypes.mutations.SET_WORKSPACE_ERROR,
            null
        );
    },
};
</script>
