<template>
    <div class="border-b p-4 sm:flex sm:items-center sm:justify-between">
        <div class="sm:w-0 sm:flex-1">
            <h3
                class="flex justify-start align-middle font-bold text-slate-800"
            >
                {{ title }}
            </h3>
            <div
                v-if="text"
                class="mt-1 overflow-hidden overflow-ellipsis text-sm text-slate-500"
                v-html="text"
            ></div>
        </div>
        <div
            class="flex items-center gap-4 sm:ml-6 sm:mt-0 sm:flex-shrink-0 sm:justify-start"
        >
            <LoadingSpinner v-if="loading" />
            <slot />
        </div>
    </div>
</template>

<script>
import LoadingSpinner from "@/components/ui/LoadingSpinner";

export default {
    name: "CardHeading",
    components: { LoadingSpinner },
    props: {
        title: {
            type: String,
            default: "",
        },
        text: {
            type: String,
            default: "",
        },
        loading: {
            type: Boolean,
            required: false,
            default: false,
        },
    },
};
</script>
