<template>
    <div
        class="flex flex-col rounded-xl border border-slate-200 bg-slate-50 p-4"
    >
        <slot />
    </div>
</template>

<script>
export default {
    name: "FormFieldOutline",
};
</script>
