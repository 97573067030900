export const mathMax = Math.max;

export const mathPow = Math.pow;

export const toInteger = (value, defaultValue = NaN) => {
    const integer = parseInt(value, 10);
    return isNaN(integer) ? defaultValue : integer;
};

export const toFloat = (value, defaultValue = NaN) => {
    const float = parseFloat(value);
    return isNaN(float) ? defaultValue : float;
};

export const toFixed = (val, precision) =>
    toFloat(val).toFixed(toInteger(precision, 0));

export function isNegative(number) {
    return toInteger(number) < 0;
}

export function pct(value, total) {
    if (total === 0) return 0;

    return (value / total) * 100;
}

function int(value) {
    return parseInt(value, 10);
}

export function checkCollinear(p0, p1, p2) {
    return (
        int(p0.x + p2.x) === int(2 * p1.x) && int(p0.y + p2.y) === int(2 * p1.y)
    );
}

export function getDistance(p1, p2) {
    return Math.sqrt(Math.pow(p2.x - p1.x, 2) + Math.pow(p2.y - p1.y, 2));
}

export function moveTo(to, from, radius) {
    const vector = { x: to.x - from.x, y: to.y - from.y };
    const length = Math.sqrt(vector.x * vector.x + vector.y * vector.y);
    const unitVector = { x: vector.x / length, y: vector.y / length };

    return {
        x: from.x + unitVector.x * radius,
        y: from.y + unitVector.y * radius,
    };
}
