<template>
    <TwoCol>
        <div slot="content">
            <Prompt
                :active="showTagCreatePrompt"
                @close="showTagCreatePrompt = false"
                ><h3 slot="header">{{ $t("groups.single.tags.create") }}</h3>
                <div slot="content">
                    <CreateTagForm @saved="showTagCreatePrompt = false" /></div
            ></Prompt>
            <SlideOver
                :active="showPrompt"
                @close="showPrompt = false"
                :gutter="false"
            >
                <h2
                    slot="title"
                    class="text-base font-semibold leading-6 text-slate-900"
                >
                    {{ $t("groups.edit_group") }}
                </h2>
                <template v-slot:content>
                    <SelectMembers
                        :loading="updateLoading"
                        @update="handleUpdate"
                    />
                </template>
            </SlideOver>
            <SlideOver
                :active="showSegmentCreatePrompt"
                @close="showSegmentCreatePrompt = false"
                :gutter="false"
            >
                <h2
                    slot="title"
                    class="text-base font-semibold leading-6 text-slate-900"
                >
                    {{ $t("groups.single.segments.create") }}
                </h2>
                <template v-slot:content>
                    <div
                        class="relative flex h-full flex-col overflow-y-auto bg-white p-4"
                    >
                        <CreateSegmentForm
                            @saved="showSegmentCreatePrompt = false"
                        />
                    </div>
                </template>
            </SlideOver>
            <SlideOver
                :active="showSegmentEditPrompt"
                @close="showSegmentEditPrompt = false"
                :gutter="false"
            >
                <h2
                    slot="title"
                    class="text-base font-semibold leading-6 text-slate-900"
                >
                    {{ $t("groups.single.segments.update") }}
                </h2>
                <template v-slot:content>
                    <div
                        class="relative flex h-full flex-col overflow-y-auto p-4"
                    >
                        <SegmentSettingsForm
                            :segment="segment"
                            @saved="handleSegmentUpdated"
                        />
                    </div>
                </template>
            </SlideOver>
            <Heading
                :title="name || 'N/A'"
                :text="$t('groups.single.text', { count: count }).toString()"
                :loading="loading"
                :returnTo="{ name: 'group.index' }"
            >
                <FormButton v-if="isEditable" @click="showPrompt = true">{{
                    $t("groups.manage_group")
                }}</FormButton>
            </Heading>
            <GroupEmployeeTable
                :rows="employees"
                :loading="loading"
                :error="error"
                class="card"
            />
        </div>
        <div slot="sidebar">
            <Heading
                :title="$t('groups.single.sidebar.heading').toString()"
                :text="$t('groups.single.sidebar.text').toString()"
            />
            <div class="card mb-4">
                <div class="flex flex-col border-b p-4">
                    <p
                        class="truncate text-sm font-medium capitalize text-slate-900"
                    >
                        {{ $t("groups.single.tags.heading") }}
                    </p>
                    <p class="truncate text-sm text-slate-500">
                        {{ $t("groups.single.tags.text") }}
                    </p>
                </div>
                <ContentLoading :loading="loading">
                    <ul v-if="hasTags" class="divide-y divide-slate-200">
                        <TagItem
                            v-for="tag in tags"
                            :key="tag.id"
                            :tag="tag"
                            @deleteTag="handleDeleteTag(tag)"
                        />
                    </ul>
                    <div
                        v-else
                        class="flex flex-col items-center justify-center py-6"
                    >
                        <Icon
                            :icon="IconEnums.TAG"
                            class="mb-4 size-8 text-slate-500"
                        />
                        <p class="text-sm text-slate-500">
                            {{ $t("groups.single.tags.empty") }}
                        </p>
                    </div>
                </ContentLoading>
                <div class="flex flex-col border-t p-4">
                    <FormButton
                        :variant="ButtonVariants.SECONDARY"
                        @click="showTagCreatePrompt = true"
                        >{{ $t("groups.single.tags.create") }}</FormButton
                    >
                </div>
            </div>
            <div class="card mb-4">
                <div class="flex flex-col border-b p-4">
                    <p
                        class="truncate text-sm font-medium capitalize text-slate-900"
                    >
                        {{ $t("groups.single.segments.heading") }}
                    </p>
                    <p class="truncate text-sm text-slate-500">
                        {{ $t("groups.single.segments.text") }}
                    </p>
                </div>
                <ContentLoading :loading="loading">
                    <ul v-if="hasSegments" class="divide-y divide-slate-200">
                        <SegmentItem
                            v-for="segment in segments"
                            :key="segment.id"
                            :segment="segment"
                            @editSegment="handleEditSegment(segment)"
                        />
                    </ul>
                    <div
                        v-else
                        class="flex flex-col items-center justify-center py-6"
                    >
                        <Icon
                            :icon="IconEnums.HALT"
                            class="mb-4 size-8 text-slate-500"
                        />
                        <p class="text-sm text-slate-500">
                            {{ $t("groups.single.segments.empty") }}
                        </p>
                    </div>
                </ContentLoading>
                <div class="flex flex-col border-t p-4">
                    <FormButton
                        v-if="hasTags"
                        :icon="IconEnums.SIGNAL"
                        :variant="ButtonVariants.SECONDARY"
                        @click="showSegmentCreatePrompt = true"
                        >{{ $t("groups.single.segments.create") }}</FormButton
                    >
                    <p v-else class="block text-center text-sm text-slate-500">
                        {{ $t("groups.single.segments.missing_tags") }}
                    </p>
                </div>
            </div>
            <div v-if="hasProvider" class="card">
                <div class="flex items-center space-x-4 border-b p-4">
                    <div class="flex-shrink-0">
                        <Icon
                            :icon="IconEnums[provider.toUpperCase()]"
                            class="size-8"
                        />
                    </div>
                    <div class="min-w-0 flex-1">
                        <p
                            class="truncate text-sm font-medium capitalize text-slate-900"
                        >
                            {{ provider }}
                        </p>
                        <p class="truncate text-sm text-slate-500">
                            {{ $t("groups.single.provider.text") }}
                        </p>
                    </div>
                </div>
                <dl
                    class="-my-3 divide-y divide-slate-100 p-4 text-sm leading-6"
                >
                    <div class="flex items-center justify-between gap-x-4 py-3">
                        <dt class="text-slate-500">
                            {{ $t("groups.single.provider.id") }}
                        </dt>
                        <dd class="flex items-start gap-x-2">
                            <FormButton
                                :icon="
                                    showProviderId
                                        ? IconEnums.CLOSE
                                        : IconEnums.EYE
                                "
                                :variant="ButtonVariants.OUTLINE"
                                @click="showProviderId = !showProviderId"
                                >{{
                                    showProviderId
                                        ? $t("groups.single.provider.hide_id")
                                        : $t("groups.single.provider.show_id")
                                }}</FormButton
                            >
                        </dd>
                    </div>
                    <div
                        v-if="showProviderId"
                        class="flex flex-row justify-between gap-x-4 py-3"
                    >
                        <span class="text-slate-500">ID</span>
                        <span class="font-medium text-slate-900">{{
                            providerId
                        }}</span>
                    </div>
                    <div class="flex justify-between gap-x-4 py-3">
                        <dt class="text-slate-500">
                            {{ $t("groups.single.provider.latest_sync") }}
                        </dt>
                        <dd class="flex items-start gap-x-2">
                            <div class="font-medium text-slate-900">
                                {{
                                    lastSynced ||
                                    $t("groups.single.provider.never_synced")
                                }}
                            </div>
                        </dd>
                    </div>
                </dl>
                <div class="flex flex-col p-4">
                    <FormButton
                        :loading="syncGroupLoading"
                        :icon="IconEnums.REFRESH"
                        @click="startGroupSync"
                        :variant="ButtonVariants.PRIMARY"
                        >{{ $t("integrations.refresh") }}</FormButton
                    >
                </div>
            </div>
        </div>
    </TwoCol>
</template>

<script>
import { TeamStoreNamespacedTypes } from "@/store/modules/team";

import { getProperty } from "@/utils/object";
import { formatDateTime } from "@/utils/date";

import Bus from "@/bus";
import Heading from "@/components/layout/Heading";
import FormButton from "@/components/forms/fields/FormButton";
import SelectMembers from "@/components/display/group/SelectMembers";
import { IconEnums } from "@/utils/icons";
import { ButtonVariants } from "@/components/forms/fields/FormButton";
import SlideOver from "@/components/ui/SlideOver";
import GroupEmployeeTable from "@/components/display/group/GroupEmployeeTable";
import TwoCol from "@/components/layout/TwoCol";
import SegmentItem from "@/components/display/group/SegmentItem";
import Icon from "@/components/icons/BaseIcon";
import ContentLoading from "@/components/ui/ContentLoading";
import CreateSegmentForm from "@/components/forms/CreateSegmentForm";
import CreateTagForm from "@/components/forms/CreateTagForm";
import Prompt from "@/components/ui/Prompt";
import TagItem from "@/components/display/group/TagItem";
import SegmentSettingsForm from "@/components/forms/SegmentSettingsForm";

export default {
    name: "GroupSingle",
    components: {
        SegmentSettingsForm,
        TagItem,
        Prompt,
        CreateTagForm,
        CreateSegmentForm,
        ContentLoading,
        Icon,
        SegmentItem,
        TwoCol,
        GroupEmployeeTable,
        SlideOver,
        SelectMembers,
        FormButton,
        Heading,
    },
    data: () => ({
        IconEnums,
        ButtonVariants,
        showPrompt: false,
        updateLoading: false,
        showSegmentCreatePrompt: false,
        showSegmentEditPrompt: false,
        syncGroupLoading: false,
        showProviderId: false,
        showTagCreatePrompt: false,
        segment: {
            name: null,
            positive_tags: [],
            all_positive_tags_required: "no",
            negative_tags: [],
            all_negative_tags_required: "no",
        },
    }),
    computed: {
        /**
         * The loading state for the API request.
         * @type {Boolean}
         */
        loading() {
            return this.$store.getters[
                TeamStoreNamespacedTypes.getters.LOADING
            ]();
        },

        /**
         * The error state of the API request.
         * @type {Object}
         */
        error() {
            return this.$store.getters[
                TeamStoreNamespacedTypes.getters.ERROR
            ]();
        },

        /**
         * @type {Array}
         */
        group() {
            return this.$store.getters[
                TeamStoreNamespacedTypes.getters.GROUP
            ]();
        },

        /**
         * @type {Boolean}
         */
        isEditable() {
            if (this.loading) {
                return false;
            }

            if (!this.group) {
                return false;
            }

            return (
                !getProperty(this.group, "default", true) &&
                getProperty(this.group, "provider") === null
            );
        },

        /**
         * @type {Array}
         */
        employees() {
            return getProperty(this.group, "employees.data", []);
        },

        /**
         * @type {string}
         */
        name() {
            return getProperty(
                this.group,
                "name",
                this.$t("groups.single.heading").toString()
            );
        },

        /**
         * @type {Array}
         */
        count() {
            return getProperty(this.group, "employee_active_count", 0);
        },

        /**
         * @type {string|null}
         */
        provider() {
            return getProperty(this.group, "provider");
        },

        /**
         * @type {Boolean}
         */
        hasProvider() {
            return this.provider !== null;
        },

        /**
         * @type {string|null}
         */
        providerId() {
            return getProperty(this.group, "provider_id", null);
        },

        /**
         * @type {string|null}
         */
        lastSynced() {
            let date = getProperty(this.group, "last_sync_at", null);
            return date ? formatDateTime(date) : null;
        },

        /**
         * @type {Array}
         */
        tags() {
            return getProperty(this.group, "tags", []);
        },

        /**
         * @type {Boolean}
         */
        hasTags() {
            return this.tags.length > 0;
        },

        /**
         * @type {Array}
         */
        segments() {
            return getProperty(this.group, "segments", []);
        },

        /**
         * @type {Boolean}
         */
        hasSegments() {
            return this.segments.length > 0;
        },
    },
    methods: {
        getGroup() {
            this.$store.dispatch(
                TeamStoreNamespacedTypes.actions.GET_GROUP,
                this.$route.params.id
            );
        },
        handleUpdate(selected) {
            this.updateLoading = true;
            this.$store
                .dispatch(TeamStoreNamespacedTypes.actions.UPDATE_MEMBERS, {
                    group: this.$route.params.id,
                    selected: selected,
                })
                .then(() => {
                    this.updateLoading = false;
                    this.showPrompt = false;
                    if (!this.error) {
                        Bus.$emit("flash-message", {
                            text: this.$t("groups.group_updated"),
                            type: "success",
                        });
                        this.getGroup();
                    }
                });
        },
        startGroupSync() {
            this.syncGroupLoading = true;
            this.$store
                .dispatch(
                    TeamStoreNamespacedTypes.actions.SYNC_PROVIDER_GROUP,
                    getProperty(this.$route.params, "id")
                )
                .then(() => {
                    Bus.$emit("flash-message", {
                        text: this.$t("integrations.sync_started"),
                        type: "success",
                    });
                    this.syncGroupLoading = false;
                });
        },
        handleDeleteTag(tag) {
            this.$store
                .dispatch(TeamStoreNamespacedTypes.actions.DESTROY_TAG, tag.id)
                .then(() => {
                    Bus.$emit("flash-message", {
                        text: this.$t("groups.tag_deleted"),
                        type: "success",
                    });
                });
        },
        handleEditSegment(segment) {
            this.segment = segment;
            this.showSegmentEditPrompt = true;
        },
        handleSegmentUpdated() {
            this.showSegmentEditPrompt = false;
            Bus.$emit("flash-message", {
                text: this.$t("groups.segment_updated"),
                type: "success",
            });
        },
    },
    mounted() {
        this.getGroup();
    },
    beforeCreate() {
        this.$store.commit(TeamStoreNamespacedTypes.mutations.RESET_GROUP);
        this.$store.commit(
            TeamStoreNamespacedTypes.mutations.SET_LOADING,
            true
        );
    },
};
</script>
