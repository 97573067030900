var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"h-full overflow-auto px-4"},[_c('div',{staticClass:"mb-4 flex flex-col space-y-4 rounded-xl bg-white p-4"},[_c('div',{staticClass:"flex flex-row items-center justify-between"},[_c('div',{staticClass:"flex flex-row items-center gap-x-2"},[_c('h3',{staticClass:"font-bold text-slate-800"},[_vm._v(_vm._s(_vm.name))]),_c('Badge',{attrs:{"variant":_vm.badgeVariant,"text":_vm.$t('automation.status.' + _vm.status).toString(),"loading":_vm.status === 'started'}})],1),_c('div',{staticClass:"flex flex-row items-stretch justify-end gap-x-2"},[_c('FormButton',{attrs:{"to":{
                        name: 'automation.single',
                        params: { id: _vm.id },
                    },"variant":_vm.ButtonVariants.SECONDARY}},[_vm._v(_vm._s(_vm.$t("automation.flow.actions.preview")))]),_c('FormButton',{attrs:{"variant":_vm.ButtonVariants.SECONDARY,"icon":_vm.IconEnums.DUPLICATE,"loading":_vm.duplicateLoading},nativeOn:{"click":function($event){return _vm.duplicate.apply(null, arguments)}}}),_c('FormButton',{attrs:{"to":{
                        name: 'automation.edit',
                        params: { id: _vm.id },
                    },"variant":_vm.ButtonVariants.SECONDARY,"icon":_vm.IconEnums.SETTINGS}})],1)])]),(_vm.funnelData.length > 2)?_c('div',{staticClass:"card mb-4"},[_c('CardHeading',{attrs:{"title":_vm.$t('automation.funnel.heading').toString()}}),_c('div',{staticClass:"p-4"},[_c('FunnelChart',{attrs:{"loading":false,"data":_vm.funnelData}})],1)],1):_vm._e(),_c('div',{staticClass:"relative mb-4 flex flex-col space-y-4 rounded-xl bg-white"},[_c('CardHeading',{attrs:{"title":_vm.$t('automation.sequence.heading').toString(),"text":_vm.$t('automation.sequence.text').toString()}}),_c('div',{staticClass:"px-4"},[(_vm.hasActions)?_c('ul',{staticClass:"mb-2",attrs:{"role":"list"}},_vm._l((_vm.actions),function(action,index){return _c('AutomationFlowStep',{key:action.id,attrs:{"automation-id":_vm.id,"action-id":action.id,"index":index,"variant":_vm.StepVariants.SMALL}})}),1):_c('AutomationFlowPlaceholder',{staticClass:"mb-4",attrs:{"id":_vm.id}})],1)],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }