<template>
    <div>
        <Alert :error="error" />
        <table
            role="grid"
            class="min-w-full divide-y divide-slate-200 border-t"
        >
            <thead class="bg-slate-50">
                <tr>
                    <DataTableHeader
                        v-for="header in headers"
                        :header="header"
                        :key="`row-${header.key}`"
                        :active="activeColumnSortKey === header.key"
                        @sort="handleSorting"
                    />
                </tr>
            </thead>
            <tbody>
                <DataTableRow
                    v-for="(item, index) in rows"
                    :key="`row-${index}`"
                    :resource="resource"
                    :resource-params="resourceParams"
                    :item="item"
                    :emit="emit"
                    @emit="$emit('emit', $event)"
                >
                    <td
                        class="whitespace-nowrap px-4 py-3 text-sm font-medium text-slate-700"
                        v-for="header in headers"
                        :key="`td-${header.key}`"
                    >
                        <slot :name="header.key" :item="item">{{
                            item[header.key]
                        }}</slot>
                    </td>
                </DataTableRow>
            </tbody>
        </table>
        <div
            v-if="loading && rows.length === 0"
            class="divide-y divide-slate-200"
        >
            <div
                v-for="x in loadingRows"
                :key="x"
                class="flex flex-row space-x-4 px-6 py-3"
            >
                <div
                    v-for="header in headers"
                    :key="`loading-${header.key}`"
                    class="h-6 flex-grow"
                >
                    <div class="w-100 h-6 rounded bg-slate-50"></div>
                </div>
            </div>
        </div>
        <div
            v-if="!loading && rows.length === 0"
            class="flex flex-col items-center justify-center space-x-4 px-6 py-8 text-center"
        >
            <Icon
                :icon="IconEnums.GROUP"
                class="mx-auto mb-2 h-10 w-10 text-slate-300"
            />
            <h3 class="mt-2 text-sm font-medium text-slate-600">
                {{ $t("tables.empty") }}
            </h3>
        </div>
        <div
            v-if="hasPagination"
            class="flex items-center justify-between bg-white px-4 py-3 sm:px-6"
        >
            <div class="flex sm:flex-1 sm:items-center sm:justify-between">
                <div>
                    <p
                        class="text-sm text-slate-700"
                        v-html="$t('tables.pagination', paginationInformation)"
                    ></p>
                </div>
                <div>
                    <nav class="pagination" aria-label="Pagination">
                        <button
                            :disabled="!hasPreviousPage"
                            @click="handlePreviousPage"
                            class="previous"
                        >
                            <span class="sr-only">Previous</span>
                            <svg
                                class="h-5 w-5"
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 20 20"
                                fill="currentColor"
                                aria-hidden="true"
                            >
                                <path
                                    fill-rule="evenodd"
                                    d="M12.79 5.23a.75.75 0 01-.02 1.06L8.832 10l3.938 3.71a.75.75 0 11-1.04 1.08l-4.5-4.25a.75.75 0 010-1.08l4.5-4.25a.75.75 0 011.06.02z"
                                    clip-rule="evenodd"
                                />
                            </svg>
                        </button>
                        <button
                            :disabled="!hasNextPage"
                            @click="handleNextPage"
                            class="next"
                        >
                            <span class="sr-only">Next</span>
                            <svg
                                class="h-5 w-5"
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 20 20"
                                fill="currentColor"
                                aria-hidden="true"
                            >
                                <path
                                    fill-rule="evenodd"
                                    d="M7.21 14.77a.75.75 0 01.02-1.06L11.168 10 7.23 6.29a.75.75 0 111.04-1.08l4.5 4.25a.75.75 0 010 1.08l-4.5 4.25a.75.75 0 01-1.06-.02z"
                                    clip-rule="evenodd"
                                />
                            </svg>
                        </button>
                    </nav>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Alert from "@/components/ui/Alert";
import DataTableHeader from "./DataTableHeader";
import DataTableRow from "./DataTableRow";
import Icon from "@/components/icons/BaseIcon";
import { IconEnums } from "@/utils/icons";
import { getProperty } from "@/utils/object";

export default {
    name: "FilterableDataTable",
    components: {
        Alert,
        DataTableHeader,
        DataTableRow,
        Icon,
    },
    props: {
        headers: {
            type: Array,
            required: true,
            default: () => [],
        },
        rows: {
            type: Array,
            default: () => [],
        },
        meta: {
            type: Object,
            required: false,
        },
        resource: {
            type: String,
            default: null,
        },
        emit: {
            type: Boolean,
            default: false,
        },
        resourceParams: {
            type: Array,
            default: () => [],
        },
        loading: {
            type: Boolean,
            default: true,
        },
        error: {
            type: Object,
            default: () => {},
        },
        searchable: {
            type: Array,
            default: () => [],
        },
        loadingRows: {
            type: Number,
            default: 6,
        },
        defaultSortKey: {
            type: String,
            default: null,
        },
        defaultSortDir: {
            type: String,
            default: "desc",
        },
    },
    data: () => ({
        IconEnums,
        activeColumnSortKey: false,
        sort: {
            key: null,
            direction: "desc",
        },
    }),
    computed: {
        hasPagination() {
            return getProperty(this.meta, "last_page", 1) > 1;
        },
        currentPage() {
            return getProperty(this.meta, "current_page", 1);
        },
        lastPage() {
            return getProperty(this.meta, "last_page", 1);
        },
        hasPreviousPage() {
            return this.currentPage > 1;
        },
        hasNextPage() {
            return this.currentPage < this.lastPage;
        },
        paginationInformation() {
            return {
                from: getProperty(this.meta, "from", 0),
                to: getProperty(this.meta, "to", 0),
                total: getProperty(this.meta, "total", 0),
            };
        },
        computedSort() {
            if (!this.sort.key) {
                return null;
            }

            if (this.sort.direction === "asc") {
                return this.sort.key;
            }

            return "-" + this.sort.key;
        },
    },
    methods: {
        handleNextPage() {
            let payload = {
                page: this.currentPage + 1,
                sort: this.computedSort,
            };

            this.$emit("update", payload);
        },
        handlePreviousPage() {
            let payload = {
                page: this.currentPage - 1,
                sort: this.computedSort,
            };

            this.$emit("update", payload);
        },
        handleSorting(payload) {
            this.activeColumnSortKey = payload.key;
            this.sort = payload;

            this.$emit("update", {
                page: 1,
                sort: this.computedSort,
            });
        },
    },
    mounted() {
        this.activeColumnSortKey = this.defaultSortKey;
        this.sort.key = this.defaultSortKey;
        this.sort.direction = this.defaultSortDir;
    },
};
</script>
