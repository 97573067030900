<template>
    <ValidationObserver ref="registerForm" v-slot="{ passes }">
        <form @submit.prevent="passes(register)">
            <FormFieldSet>
                <Alert :error="error" />
                <FormInput
                    v-model="company"
                    type="text"
                    rules="required|min:2"
                    name="company"
                />
                <FormInput
                    v-model="email"
                    type="email"
                    rules="required"
                    name="email"
                />
                <FormPassword
                    v-model="password"
                    type="password"
                    rules="required|min:8"
                    name="password"
                />
                <div class="flex justify-end">
                    <FormButton type="submit" :loading="loading">
                        {{ $t("forms.buttons.register") }}
                    </FormButton>
                </div>
            </FormFieldSet>
        </form>
    </ValidationObserver>
</template>

<script>
import { AuthStoreNamespacedTypes } from "@/store/modules/auth";

import { ValidationObserver } from "vee-validate";
import Alert from "@/components/ui/Alert";
import FormInput from "@/components/forms/fields/FormInput";
import FormButton from "@/components/forms/fields/FormButton";
import FormFieldSet from "@/components/forms/fields/FormFieldSet";
import FormPassword from "@/components/forms/fields/FormPassword";

export default {
    components: {
        ValidationObserver,
        FormPassword,
        FormFieldSet,
        FormButton,
        Alert,
        FormInput,
    },
    data: () => ({
        company: null,
        email: null,
        password: null,
    }),
    computed: {
        /**
         * The loading state of the register request.
         * @type {boolean}
         */
        loading() {
            return this.$store.getters[
                AuthStoreNamespacedTypes.getters.REGISTER_LOADING
            ]();
        },

        /**
         * Errors while processing the register request.
         * @type {Object}
         */
        error() {
            return this.$store.getters[
                AuthStoreNamespacedTypes.getters.REGISTER_ERROR
            ]();
        },
    },
    methods: {
        /**
         * Sends registration credentials to the server.
         */
        register() {
            this.$store
                .dispatch(AuthStoreNamespacedTypes.actions.REGISTER, {
                    company: this.company,
                    email: this.email,
                    password: this.password,
                })
                .then(() => {
                    if (!this.error) {
                        this.$emit("authenticated");
                    }
                });
        },
    },
    beforeCreate() {
        this.$store.commit(
            AuthStoreNamespacedTypes.mutations.SET_REGISTER_ERROR,
            null
        );
    },
};
</script>
