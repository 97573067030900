<template>
    <ValidationObserver ref="campaignPeopleForm" v-slot="{ passes }">
        <form @submit.prevent="passes(updateSettings)" class="-mb-4">
            <FormFieldSet>
                <FormFieldOutline>
                    <FormToggle
                        v-model="form.ceo_fraud_enabled"
                        name="enable_module"
                    />
                </FormFieldOutline>
            </FormFieldSet>
            <FormFieldSet>
                <div
                    class="flex items-center rounded-lg bg-yellow-50/50 px-3 py-2 text-sm text-warning-dark ring-1 ring-inset ring-yellow-500/20"
                >
                    {{ $t("settings.simulation.ceo_fraud.info") }}
                </div>
                <div
                    class="mb-4 flex flex-col rounded-xl border border-slate-200 bg-slate-50"
                >
                    <div class="border-b px-4 py-3">
                        <p
                            class="mb-1 block text-sm font-medium text-slate-900"
                        >
                            {{ $t("settings.simulation.ceo_fraud.ceo.title") }}
                        </p>
                        <p class="text-sm text-slate-500">
                            {{ $t("settings.simulation.ceo_fraud.ceo.text") }}
                        </p>
                    </div>
                    <div
                        class="flex flex-col space-y-4 p-4 md:flex-row md:space-x-4 md:space-y-0"
                    >
                        <FormInput
                            class="md:w-1/2"
                            id="ceo-name"
                            v-model="form.people.ceo.name"
                            type="text"
                            name="ceo-name"
                            rules="required"
                        />
                        <FormInput
                            class="md:w-1/2"
                            id="ceo-email"
                            v-model="form.people.ceo.email"
                            type="email"
                            name="ceo-email"
                            rules="required|email"
                        />
                    </div>
                </div>
                <div
                    class="mb-4 flex flex-col rounded-xl border border-slate-200 bg-slate-50"
                >
                    <div class="border-b px-4 py-3">
                        <p
                            class="mb-1 block text-sm font-medium text-slate-900"
                        >
                            {{ $t("settings.simulation.ceo_fraud.cfo.title") }}
                        </p>
                        <p class="text-sm text-slate-500">
                            {{ $t("settings.simulation.ceo_fraud.cfo.text") }}
                        </p>
                    </div>
                    <div
                        class="flex flex-col space-y-4 p-4 md:flex-row md:space-x-4 md:space-y-0"
                    >
                        <FormInput
                            class="md:w-1/2"
                            id="cfo-name"
                            v-model="form.people.cfo.name"
                            type="text"
                            name="cfo-name"
                            rules="required"
                        />
                        <FormInput
                            class="md:w-1/2"
                            id="cfo-email"
                            v-model="form.people.cfo.email"
                            type="email"
                            name="cfo-email"
                            rules="required|email"
                        />
                    </div>
                </div>
                <div
                    class="mb-4 flex flex-col rounded-xl border border-slate-200 bg-slate-50"
                >
                    <div class="border-b px-4 py-3">
                        <p
                            class="mb-1 block text-sm font-medium text-slate-900"
                        >
                            {{ $t("settings.simulation.ceo_fraud.cto.title") }}
                        </p>
                        <p class="text-sm text-slate-500">
                            {{ $t("settings.simulation.ceo_fraud.cto.text") }}
                        </p>
                    </div>
                    <div
                        class="flex flex-col space-y-4 p-4 md:flex-row md:space-x-4 md:space-y-0"
                    >
                        <FormInput
                            class="md:w-1/2"
                            id="cto-name"
                            v-model="form.people.cto.name"
                            type="text"
                            name="cto-name"
                            rules="required"
                        />
                        <FormInput
                            class="md:w-1/2"
                            id="cto-email"
                            v-model="form.people.cto.email"
                            type="email"
                            name="cto-email"
                            rules="required|email"
                        />
                    </div>
                </div>
                <div class="flex justify-end">
                    <FormButton type="submit" :loading="formLoading">
                        {{ $t("forms.buttons.save_settings") }}
                    </FormButton>
                </div>
            </FormFieldSet>
        </form>
    </ValidationObserver>
</template>

<script>
import { WorkspaceStoreNamespacedTypes } from "@/store/modules/workspace";

import { getProperty } from "@/utils/object";
import { ValidationObserver } from "vee-validate";
import FormFieldSet from "@/components/forms/fields/FormFieldSet";
import FormButton, {
    ButtonVariants,
} from "@/components/forms/fields/FormButton";
import { IconEnums } from "@/utils/icons";
import FormInput from "@/components/forms/fields/FormInput";
import FormFieldOutline from "@/components/forms/fields/FormFieldOutline";
import FormToggle from "@/components/forms/fields/FormToggle";

export default {
    name: "CampaignPeopleForm",
    components: {
        FormToggle,
        FormFieldOutline,
        FormInput,
        FormButton,
        FormFieldSet,
        ValidationObserver,
    },
    data: () => ({
        ButtonVariants,
        IconEnums,
        formLoading: false,
        form: {
            ceo_fraud_enabled: false,
            ceo: {
                name: null,
                email: null,
            },
            cfo: {
                name: null,
                email: null,
            },
            cto: {
                name: null,
                email: null,
            },
        },
    }),
    computed: {
        /**
         * @type {Object}
         */
        workspace() {
            return this.$store.getters[
                WorkspaceStoreNamespacedTypes.getters.WORKSPACE
            ]();
        },

        /**
         * @type {Object}
         */
        error() {
            return this.$store.getters[
                WorkspaceStoreNamespacedTypes.getters.WORKSPACE_ERROR
            ]();
        },
    },
    methods: {
        /**
         * Sends authentication credentials to the server.
         */
        updateSettings() {
            this.formLoading = true;
            this.$store
                .dispatch(
                    WorkspaceStoreNamespacedTypes.actions
                        .UPDATE_CAMPAIGN_SETTINGS,
                    this.form
                )
                .then(() => {
                    this.formLoading = false;
                    if (!this.error) {
                        this.$emit("saved");
                    }
                });
        },
    },
    created() {
        let people = getProperty(this.workspace, "simulation_settings.people");

        this.form = {
            ceo_fraud_enabled: getProperty(
                this.workspace,
                "simulation_settings.ceo_fraud_enabled",
                false
            ),
            people: {
                ceo: {
                    name: getProperty(people, "ceo.name", null),
                    email: getProperty(people, "ceo.email", null),
                },
                cfo: {
                    name: getProperty(people, "cfo.name", null),
                    email: getProperty(people, "cfo.email", null),
                },
                cto: {
                    name: getProperty(people, "cto.name", null),
                    email: getProperty(people, "cto.email", null),
                },
            },
        };
    },
    beforeCreate() {
        this.$store.commit(
            WorkspaceStoreNamespacedTypes.mutations.SET_WORKSPACE_ERROR,
            null
        );
    },
};
</script>
