export function camelToSnake(string) {
    return string
        .replace(/[A-Z]|\d+/g, (match) => {
            return `_${match.toLowerCase()}`;
        })
        .replace(/^_/, "");
}

export function camelToKebab(string) {
    return string
        .replace(/[A-Z]|\d+/g, (match) => {
            return `-${match.toLowerCase()}`;
        })
        .replace(/^-/, "");
}

export function snakeToCamel(string) {
    return string.replace(/_[\w\d]/g, (match) => {
        return match.substr(1).toUpperCase();
    });
}

export function capitalizeFirstLetter(string) {
    if (typeof string !== "string") return string;

    return string.charAt(0).toUpperCase() + string.slice(1);
}

export function uuid() {
    let dt = new Date().getTime();
    return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(
        /[xy]/g,
        function (c) {
            const r = (dt + Math.random() * 16) % 16 | 0;
            dt = Math.floor(dt / 16);
            return (c === "x" ? r : (r & 0x3) | 0x8).toString(16);
        }
    );
}
