<template>
    <path
        d="m6 22 2-4m10 4-2-4M12 3V2M7 13.454c1.09-1.451 2.315-2.726 3.905-3.641a.37.37 0 0 1 .497.12l1.705 2.65c.111.175.35.213.51.083C15.169 11.415 16.209 9.804 17 8M8.4 18h7.2c2.24 0 3.36 0 4.216-.436a4 4 0 0 0 1.748-1.748C22 14.96 22 13.84 22 11.6V9.4c0-2.24 0-3.36-.436-4.216a4 4 0 0 0-1.748-1.748C18.96 3 17.84 3 15.6 3H8.4c-2.24 0-3.36 0-4.216.436a4 4 0 0 0-1.748 1.748C2 6.04 2 7.16 2 9.4v2.2c0 2.24 0 3.36.436 4.216a4 4 0 0 0 1.748 1.748C5.04 18 6.16 18 8.4 18Z"
        fill="none"
        stroke="currentColor"
        stroke-linecap="round"
        stroke-linejoin="round"
        stroke-width="2"
    />
</template>

<script>
import IconSvgBase from "./IconSvgBase";

export default {
    extends: IconSvgBase,
    name: "IconGraph",
};
</script>
