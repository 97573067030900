<template>
    <DataTable
        :headers="headers"
        :rows="rows"
        :meta="meta"
        :loading="loading"
        :error="error"
        resource="training.single"
        :resourceParams="['type', 'id']"
        @update="refresh"
    >
        <template v-slot:title="{ item }">
            <div class="flex items-center space-x-3">
                <div class="flex-shrink-0">
                    <div class="media w-32 rounded-xl ring-1 ring-slate-200">
                        <img
                            :src="item.image"
                            class="media-figure"
                            alt="media"
                        />
                    </div>
                </div>
                <div class="min-w-0 flex-1">
                    <p class="text-sm font-medium text-slate-900">
                        {{ item.title }}
                    </p>
                    <p class="truncate text-sm text-slate-500">
                        {{ $t("training.type." + item.type) }}
                    </p>
                </div>
            </div>
        </template>
    </DataTable>
</template>

<script>
import DataTableMixin from "@/components/tables/DataTableMixin";
import { formatDate } from "@/utils/date";

export default {
    name: "EmployeeTrainingTable",
    mixins: [DataTableMixin],
    filters: {
        formatDate,
    },
    data: () => ({
        headers: [
            {
                title: "title",
                key: "title",
                sortable: true,
            },
        ],
    }),
};
</script>
