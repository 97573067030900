<template>
    <path
        d="M20.335 15.574A9.044 9.044 0 1 1 8.426 3.665m3.618.604v6.33c0 .75.607 1.357 1.356 1.357h6.33c.75 0 1.368-.61 1.257-1.351a9.05 9.05 0 0 0-7.591-7.592c-.741-.111-1.352.507-1.352 1.256Z"
        fill="none"
        stroke="currentColor"
        stroke-linecap="round"
        stroke-linejoin="round"
        stroke-width="2"
    />
</template>

<script>
import IconSvgBase from "./IconSvgBase";

export default {
    extends: IconSvgBase,
    name: "IconReport",
};
</script>
