<template>
    <ValidationObserver ref="inviteForm" v-slot="{ passes }">
        <form @submit.prevent="passes(acceptInvite)">
            <FormFieldSet>
                <Alert :error="csrfError || formError" />
                <FormPassword
                    v-model="form.password"
                    type="password"
                    rules="required|min:8"
                    name="password"
                />
                <div class="flex justify-end">
                    <FormButton type="submit" :loading="loading">
                        {{ $t("invite.save_button") }}
                    </FormButton>
                </div>
            </FormFieldSet>
        </form>
    </ValidationObserver>
</template>

<script>
import { AuthStoreNamespacedTypes } from "@/store/modules/auth";
import { WorkspaceStoreNamespacedTypes } from "@/store/modules/workspace";

import { getProperty } from "@/utils/object";

import { ValidationObserver } from "vee-validate";
import Alert from "@/components/ui/Alert";
import FormButton from "@/components/forms/fields/FormButton";
import FormFieldSet from "@/components/forms/fields/FormFieldSet";
import FormPassword from "@/components/forms/fields/FormPassword";

export default {
    name: "InviteForm",
    components: {
        FormPassword,
        ValidationObserver,
        FormFieldSet,
        FormButton,
        Alert,
    },
    data: () => ({
        loading: false,
        form: {
            id: null,
            password: null,
        },
    }),
    computed: {
        /**
         * The request error state
         * @type {ErrorOrObject}
         */
        csrfError() {
            return this.$store.getters[
                AuthStoreNamespacedTypes.getters.CSRF_ERROR
            ]();
        },

        /**
         * The request error state
         * @type {ErrorOrObject}
         */
        formError() {
            return this.$store.getters[
                AuthStoreNamespacedTypes.getters.INVITE_ERROR
            ]();
        },
    },
    methods: {
        /**
         * Sends authentication credentials to the server.
         */
        acceptInvite() {
            this.loading = true;
            this.$store
                .dispatch(
                    AuthStoreNamespacedTypes.actions.ACCEPT_INVITE,
                    this.form
                )
                .then(() => {
                    this.loading = false;
                    if (!this.formError) {
                        this.$emit("authenticated");

                        this.$store.dispatch(
                            WorkspaceStoreNamespacedTypes.actions.GET_WORKSPACE,
                            {
                                force: true,
                            }
                        );
                    }
                });
        },
    },
    beforeCreate() {
        this.$store.commit(
            AuthStoreNamespacedTypes.mutations.SET_INVITE_ERROR,
            null
        );
    },
    mounted() {
        if (getProperty(this.$route.query, "st")) {
            this.form.id = getProperty(this.$route.query, "st");
        }
    },
};
</script>
