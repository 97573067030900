<template>
    <path
        fill="none"
        stroke="currentColor"
        stroke-linecap="round"
        stroke-linejoin="round"
        stroke-width="2"
        d="m14 15-1.707-1.707a1 1 0 0 1-.293-.707V9m6.845-5.604c.328 1.254.492 2.545.488 3.84-.001.338-.284.448-.56.509h-.002m-3.66.348a15 15 0 0 0 3.66-.348m0 0a8 8 0 1 0 1.06 5.908"
    />
</template>

<script>
import IconSvgBase from "./IconSvgBase";

export default {
    extends: IconSvgBase,
    name: "IconReminder",
};
</script>
