var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"flex flex-col p-4"},[_c('div',{staticClass:"mb-4 flex w-full flex-row justify-between"},[_c('h3',{staticClass:"flex-1 font-medium text-slate-500",domProps:{"textContent":_vm._s(_vm.$t('statistics.benchmarks.title').toString())}}),_c('div',[(_vm.loading)?_c('LoadingSpinner',{staticClass:"size-5"}):_c('div',{staticClass:"flex h-6 w-6 items-center justify-center rounded-full ring-1 ring-inset",class:_vm.badgeClass},[_c('Icon',{staticClass:"h-3 w-3",attrs:{"icon":_vm.badgeIcon}})],1)],1)]),_c('div',{staticClass:"flex flex-grow flex-col justify-between"},[_c('div',{staticClass:"grid grid-cols-2 gap-4"},[_c('div',{staticClass:"rounded-lg bg-slate-100 px-4 py-3"},[_c('p',{staticClass:"text-sm text-slate-800",domProps:{"textContent":_vm._s(_vm.$t('statistics.benchmarks.score').toString())}}),_c('p',{staticClass:"text-lg font-semibold text-slate-800",domProps:{"textContent":_vm._s(_vm.value + '%')}})]),_c('div',{staticClass:"rounded-lg bg-slate-100 px-4 py-3"},[_c('p',{staticClass:"text-sm text-slate-800",domProps:{"textContent":_vm._s(_vm.$t('statistics.benchmarks.average').toString())}}),_c('p',{staticClass:"text-lg font-semibold text-slate-800",domProps:{"textContent":_vm._s(_vm.network + '%')}})])]),_c('div',{staticClass:"flex items-center space-x-2 pt-4 text-slate-400"},[_c('div',{staticClass:"h-6 w-6"},[_c('Icon',{staticClass:"h-6 w-6",attrs:{"icon":_vm.IconEnums.INFO}})],1),_c('span',{staticClass:"text-xs leading-4",domProps:{"textContent":_vm._s(
                    _vm.valueIsPositive
                        ? _vm.$t('statistics.benchmarks.text_higher', {
                              pct: _vm.valueRelativeToNetwork,
                          }).toString()
                        : _vm.$t('statistics.benchmarks.text_lower', {
                              pct: _vm.valueRelativeToNetwork * -1,
                          }).toString()
                )}})])])])
}
var staticRenderFns = []

export { render, staticRenderFns }