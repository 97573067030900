<template>
    <EmployeePage>
        <GroupTable :rows="groups" :loading="loading" class="card" />
    </EmployeePage>
</template>

<script>
import { TeamStoreNamespacedTypes } from "@/store/modules/team";

import { getProperty } from "@/utils/object";

import EmployeePage from "@/components/layout/EmployeePage";
import GroupTable from "@/components/display/group/GroupTable";

export default {
    name: "EmployeeSingle",
    components: {
        GroupTable,
        EmployeePage,
    },
    computed: {
        /**
         * The loading state for the API request.
         * @type {Boolean}
         */
        loading() {
            return this.$store.getters[
                TeamStoreNamespacedTypes.getters.LOADING
            ]();
        },

        /**
         * @type {Object}
         */
        employee() {
            return this.$store.getters[
                TeamStoreNamespacedTypes.getters.EMPLOYEE
            ]();
        },

        /**
         * @type {Array}
         */
        groups() {
            return getProperty(this.employee, "groups", []);
        },
    },
    mounted() {
        this.$store.dispatch(
            TeamStoreNamespacedTypes.actions.GET_EMPLOYEE,
            this.$route.params.id
        );
    },
};
</script>
