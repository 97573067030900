<template>
    <div
        class="fixed flex h-screen w-full flex-grow flex-col items-center justify-center gap-6"
    >
        <Logo class="size-14" />
        <div>
            <Alert :error="error" />
        </div>
        <LoadingSpinner v-if="loading" />
    </div>
</template>

<script>
import LoadingSpinner from "@/components/ui/LoadingSpinner";
import { getProperty } from "@/utils/object";
import Alert from "@/components/ui/Alert";
import { redirectWithParamsTo } from "@/router";
import Logo from "@/components/ui/Logo";
import { postPublicData } from "@/api";
import { AuthStoreNamespacedTypes } from "@/store/modules/auth";

export default {
    name: "ValidateInvitePage",
    components: { LoadingSpinner, Alert, Logo },
    data: () => ({
        loading: false,
        success: false,
        error: null,
    }),
    computed: {
        expires() {
            return getProperty(this.$route.query, "expires", null);
        },
        signature() {
            return getProperty(this.$route.query, "signature", null);
        },
        id() {
            return getProperty(this.$route.params, "id", null);
        },
        csrfPromise() {
            return this.$store.getters[
                AuthStoreNamespacedTypes.getters.CSRF_PROMISE
            ]();
        },
    },
    methods: {
        async validateInvite() {
            this.loading = true;
            this.success = false;
            if (!this.csrfPromise) {
                this.$store.dispatch(
                    AuthStoreNamespacedTypes.actions.FETCH_CSRF_COOKIE
                );
            }
            await this.csrfPromise;

            postPublicData("/validate/invite", {
                expires: this.expires,
                signature: this.signature,
                id: this.id,
            })
                .then((result) => {
                    if (result.data?.data?.st && !this.error) {
                        this.success = true;
                        redirectWithParamsTo(
                            `/invite?st=${result.data.data.st}`
                        );
                    }
                })
                .catch((errors) => {
                    this.error = errors;
                })
                .finally(() => {
                    this.loading = false;
                });
        },
    },
    created() {
        this.validateInvite();
    },
};
</script>
