<template>
    <TwoCol>
        <div slot="content">
            <Heading
                :title="name || 'N/A'"
                :text="$t('automation.text').toString()"
                :return-to="{
                    name: 'automation.single',
                    params: { id: $route.params.id },
                }"
            />
            <div class="min-h-104 mb-4 rounded-xl bg-white px-4 pt-4 shadow-sm">
                <div
                    v-if="loading"
                    class="grid grid-cols-1 gap-x-4 lg:grid-cols-2"
                >
                    <div class="flex animate-pulse flex-col justify-start">
                        <div
                            class="mb-3 block h-5 w-8 rounded-md bg-slate-200"
                        ></div>
                        <div
                            class="mb-4 block h-10 w-full rounded-md bg-slate-200"
                        ></div>
                        <div
                            class="mb-3 block h-5 w-8 rounded-md bg-slate-200"
                        ></div>
                        <div
                            class="mb-3 block h-10 w-full rounded-md bg-slate-200"
                        ></div>
                        <div
                            class="mb-4 block h-5 w-48 rounded-md bg-slate-200"
                        ></div>
                        <div
                            class="mb-4 block h-44 w-full rounded-md bg-slate-200"
                        ></div>
                        <div
                            class="mb-4 block h-20 w-full rounded-md bg-slate-200"
                        ></div>
                    </div>
                    <div class="flex animate-pulse flex-col justify-start">
                        <div
                            class="mb-4 block h-64 w-full rounded-md bg-slate-200"
                        ></div>
                        <div
                            class="mb-4 block h-20 w-full rounded-md bg-slate-200"
                        ></div>
                        <div
                            class="mb-4 block h-20 w-full rounded-md bg-slate-200"
                        ></div>
                        <div class="flex justify-end">
                            <div
                                class="mb-4 block h-10 w-32 rounded-md bg-slate-200"
                            ></div>
                        </div>
                    </div>
                </div>
                <AutomationSettingsForm v-else @stored="handleSettingsStored" />
            </div>
            <div class="card mb-4">
                <div
                    class="flex flex-row items-center justify-between border-b p-4"
                >
                    <div class="flex flex-col">
                        <h3
                            class="mb-2 flex justify-start align-middle font-bold text-slate-800"
                        >
                            {{ $t("automation.flow.heading") }}
                        </h3>
                        <p class="text-sm text-slate-600">
                            {{ $t("automation.flow.text") }}
                        </p>
                    </div>
                </div>
                <div class="relative px-4 py-8">
                    <div
                        class="absolute inset-0 h-full w-full bg-white bg-[radial-gradient(#e5e7eb_1px,transparent_1px)] [background-size:16px_16px]"
                    ></div>
                    <ContentLoading :loading="loading">
                        <div class="2xl:mx-auto 2xl:w-4/5 3xl:w-3/5">
                            <AutomationFlowBuilder />
                        </div>
                    </ContentLoading>
                </div>
            </div>
        </div>
        <div slot="sidebar">
            <Heading
                :title="$t('automation.details.heading').toString()"
                :text="$t('automation.details.text').toString()"
            />
            <div class="card mb-4">
                <ContentLoading :loading="loading">
                    <div class="flex flex-col divide-y divide-slate-100">
                        <ListItem
                            :title="$t('automation.details.status').toString()"
                        >
                            <Badge
                                :variant="
                                    status === 'started'
                                        ? BadgeVariants.SUCCESS
                                        : BadgeVariants.WARNING
                                "
                                :text="
                                    $t('automation.status.' + status).toString()
                                "
                                :loading="status === 'started'"
                            />
                        </ListItem>
                        <ListItem
                            :title="$t('automation.details.date').toString()"
                            :text="date || 'N/A'"
                        />
                    </div>
                </ContentLoading>
            </div>
            <div class="mb-4 flex">
                <div
                    @click="showDeletePrompt = !showDeletePrompt"
                    class="flex cursor-pointer flex-row items-center justify-start space-x-2 text-error-dark"
                >
                    <Icon class="size-4" :icon="IconEnums.TRASH" />
                    <span
                        class="text-xs font-medium"
                        v-text="$t('automation.delete').toString()"
                    ></span>
                </div>
            </div>
            <div v-if="showDeletePrompt" class="card flex flex-col p-4">
                <p class="mb-2 text-sm text-slate-500">
                    {{ $t("automation.confirm_delete") }}
                </p>
                <FormButton
                    @click.native="handleDelete"
                    :variant="ButtonVariants.SECONDARY"
                    :icon="IconEnums.TRASH"
                    :loading="deleteLoading"
                    >{{ $t("automation.delete") }}</FormButton
                >
            </div>
        </div>
    </TwoCol>
</template>

<script>
import { AutomationStoreNamespacedTypes } from "@/store/modules/automation";

import { getProperty } from "@/utils/object";
import { formatDate } from "@/utils/date";
import { redirectTo } from "@/router";

import Heading from "@/components/layout/Heading";
import TwoCol from "@/components/layout/TwoCol";
import AutomationSettingsForm from "@/components/forms/AutomationSettingsForm";
import ContentLoading from "@/components/ui/ContentLoading";
import Bus from "@/bus";
import AutomationFlowBuilder from "@/components/display/automation/AutomationFlowBuilder";
import FormButton, {
    ButtonVariants,
} from "@/components/forms/fields/FormButton";
import { IconEnums } from "@/utils/icons";
import Icon from "@/components/icons/BaseIcon";
import Badge, { BadgeVariants } from "@/components/shared/Badge";
import ListItem from "@/components/ui/ListItem";

export default {
    name: "AutomationSingle",
    components: {
        ListItem,
        Badge,
        Icon,
        FormButton,
        AutomationFlowBuilder,
        ContentLoading,
        AutomationSettingsForm,
        TwoCol,
        Heading,
    },
    data: () => ({
        BadgeVariants,
        ButtonVariants,
        IconEnums,
        showDeletePrompt: false,
        deleteLoading: false,
    }),
    computed: {
        /**
         * @type {Boolean}
         */
        loading() {
            return this.$store.getters[
                AutomationStoreNamespacedTypes.getters.AUTOMATION_LOADING
            ]();
        },

        /**
         * @type {Object}
         */
        error() {
            return this.$store.getters[
                AutomationStoreNamespacedTypes.getters.AUTOMATION_ERROR
            ]();
        },

        /**
         * @type {Array}
         */
        automation() {
            return this.$store.getters[
                AutomationStoreNamespacedTypes.getters.AUTOMATION
            ]();
        },

        /**
         * @type {string}
         */
        id() {
            return getProperty(this.automation, "id");
        },

        /**
         * @type {string}
         */
        name() {
            return getProperty(this.automation, "name", "N/A");
        },

        /**
         * @type {string}
         */
        status() {
            return getProperty(this.automation, "status", "paused");
        },

        /**
         * @type {string|null}
         */
        date() {
            let date = getProperty(this.automation, "created_at");

            if (date) {
                return formatDate(date);
            }

            return null;
        },
    },
    methods: {
        handleSettingsStored() {
            Bus.$emit("flash-message", {
                text: this.$t("automation.settings_saved"),
                type: "success",
            });
        },
        handleDelete() {
            this.deleteLoading = true;
            this.$store
                .dispatch(
                    AutomationStoreNamespacedTypes.actions.DELETE_AUTOMATION,
                    this.id
                )
                .then(() => {
                    if (!this.error) {
                        redirectTo("automation.index");
                    }
                    this.deleteLoading = false;
                });
        },
    },
    beforeCreate() {
        this.$store.dispatch(
            AutomationStoreNamespacedTypes.actions.RESET_AUTOMATION
        );
    },
    mounted() {
        this.$store.dispatch(
            AutomationStoreNamespacedTypes.actions.GET_AUTOMATION,
            this.$route.params.id
        );
    },
};
</script>
