/**
 * A store report.
 *
 * @typedef {{ reportsLoading: boolean, reportsError: ErrorOrObject }} ReportStoreState
 */
import { addNamespace } from "./namespace";
import { postData } from "@/api";

/**
 * The types used in this store
 * @enum {string}
 */
export const ReportStoreTypes = {
    getters: {
        REPORTS_LOADING: "reportsLoading",
        REPORTS_ERROR: "reportsError",
    },
    actions: {
        DOWNLOAD_REPORT: "downloadReport",
        VERIFY_REPORT: "verifyReport",
    },
    mutations: {
        SET_REPORTS_LOADING: "setReportsLoading",
        SET_REPORTS_ERROR: "setReportsError",
    },
};

/**
 * A namespaced version of the types used in this store
 * @enum {string}
 */
export const ReportStoreNamespacedTypes = addNamespace(
    "report",
    ReportStoreTypes
);

/**
 * @returns {ReportStoreState}
 */
export function state() {
    return {
        reportsLoading: false,
        reportsError: null,
    };
}

export const getters = {
    [ReportStoreTypes.getters.REPORTS_LOADING]: (state) => () => {
        return state.reportsLoading;
    },
    [ReportStoreTypes.getters.REPORTS_ERROR]: (state) => () => {
        return state.reportsError;
    },
};

export const actions = {
    /**
     * Sends the given reports to the server.
     *
     * @param {VuexCommit} commit
     * @param {Object} data
     * @return {Promise}
     */
    [ReportStoreTypes.actions.DOWNLOAD_REPORT]({ commit }, data) {
        commit(ReportStoreTypes.mutations.SET_REPORTS_LOADING, true);
        commit(ReportStoreTypes.mutations.SET_REPORTS_ERROR, null);

        return postData("/workspace/employees/csv/export", data)
            .catch((errors) => {
                commit(ReportStoreTypes.mutations.SET_REPORTS_ERROR, errors);
            })
            .finally(() => {
                commit(ReportStoreTypes.mutations.SET_REPORTS_LOADING, false);
            });
    },
};

export const mutations = {
    /**
     * Sets the loading state for reports.
     *
     * @param {ReportStoreState} state
     * @param {boolean} loading
     */
    [ReportStoreTypes.mutations.SET_REPORTS_LOADING](state, loading) {
        state.reportsLoading = loading;
    },

    /**
     * Sets a new reports error.
     *
     * @param {ReportStoreState} state
     * @param {ErrorOrObject} error
     */
    [ReportStoreTypes.mutations.SET_REPORTS_ERROR](state, error) {
        state.reportsError = error;
    },
};

export default {
    namespaced: true,
    Types: ReportStoreTypes,
    NamespacedTypes: ReportStoreNamespacedTypes,
    state,
    getters,
    actions,
    mutations,
};
