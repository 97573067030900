<template>
    <DataTable
        :headers="headers"
        :rows="rows"
        :meta="meta"
        :loading="loading"
        :error="error"
        resource="group.single"
        @update="refresh"
    >
        <template v-slot:name="{ item }">
            <div class="flex items-center">
                <div
                    v-if="item.provider"
                    class="flex size-8 flex-shrink-0 items-center justify-center rounded border bg-slate-100"
                >
                    <Icon
                        v-if="item.provider"
                        :icon="IconEnums[item.provider.toUpperCase()]"
                        class="size-5"
                    />
                </div>
                <div
                    v-else
                    class="inline-flex size-8 flex-shrink-0 items-center justify-center rounded border bg-slate-100"
                >
                    <div
                        class="text-sm font-medium leading-none text-slate-700"
                    >
                        {{ item.name | initials }}
                    </div>
                </div>
                <div class="ml-3">
                    <div class="text-sm font-medium text-slate-900">
                        {{ item.name }}
                    </div>
                </div>
            </div>
        </template>
        <template v-slot:employee_active_count="{ item }"
            >{{ item.employee_active_count || 0 }}
        </template>
    </DataTable>
</template>

<script>
import DataTableMixin from "@/components/tables/DataTableMixin";
import Icon from "@/components/icons/BaseIcon";
import { IconEnums } from "@/utils/icons";
import { initials } from "@/utils/filters";

export default {
    mixins: [DataTableMixin],
    components: { Icon },
    filters: {
        initials,
    },
    data: () => ({
        IconEnums,
        headers: [
            {
                title: "group",
                key: "name",
                sortable: true,
            },
            {
                title: "employees",
                key: "employee_active_count",
                sortable: false,
            },
        ],
    }),
};
</script>
