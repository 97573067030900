<template>
    <li
        class="group flex cursor-pointer flex-row items-center justify-between px-4 py-1.5 hover:bg-slate-50"
        @click="$emit('editSegment')"
    >
        <div class="flex flex-col">
            <div class="text-sm font-medium text-slate-900">{{ name }}</div>
            <div class="text-xs text-slate-500">{{ count }} members</div>
        </div>
        <Icon
            :icon="IconEnums.SETTINGS"
            class="hidden h-4 w-4 text-slate-500 group-hover:block"
        />
    </li>
</template>

<script>
import { getProperty } from "@/utils/object";
import Icon from "@/components/icons/BaseIcon";
import { IconEnums } from "@/utils/icons";

export default {
    name: "SegmentItem",
    components: { Icon },
    props: {
        segment: {
            type: Object,
            default: () => {},
        },
    },
    data: () => ({
        IconEnums,
    }),
    computed: {
        /**
         * @type {string}
         */
        id() {
            return getProperty(this.segment, "id", null);
        },

        /**
         * @type {string}
         */
        name() {
            return getProperty(this.segment, "name", "Anonymous");
        },

        /**
         * @type {number}
         */
        count() {
            return getProperty(this.segment, "count", 0);
        },
    },
};
</script>
