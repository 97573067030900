<template>
    <li class="relative flex gap-x-4">
        <div class="absolute -bottom-6 left-0 top-0 flex w-6 justify-center">
            <div class="w-px bg-slate-200"></div>
        </div>
        <div
            class="relative flex h-6 w-6 flex-none items-center justify-center bg-white"
        >
            <div
                class="h-1.5 w-1.5 rounded-full bg-slate-100 ring-1 ring-slate-300"
            ></div>
        </div>
        <p class="flex-auto py-0.5 text-xs leading-5 text-slate-500">
            <span class="block font-medium text-slate-900">{{ username }}</span>
            {{ data }}
        </p>
        <time class="flex-none py-0.5 text-xs leading-5 text-slate-500">{{
            timestamp
        }}</time>
    </li>
</template>

<script>
import { formatDateTime } from "@/utils/date";
import { getProperty } from "@/utils/object";

export default {
    name: "ThreatLogs",
    props: {
        log: {
            type: Object,
            required: true,
        },
    },
    computed: {
        timestamp() {
            return formatDateTime(getProperty(this.log, "created_at"));
        },
        username() {
            return getProperty(this.log, "user")
                ? getProperty(this.log, "user.first_name") +
                      " " +
                      getProperty(this.log, "user.last_name")
                : "Moxso";
        },
        type() {
            return getProperty(this.log, "type");
        },
        data() {
            if (this.type === "comment") {
                return getProperty(this.log, "extra_attributes.text");
            }

            if (this.type === "status_transition") {
                return this.$t("threats.activity.status_transition", {
                    from: this.$t(
                        "threats.status." +
                            getProperty(this.log, "extra_attributes.from")
                    ),
                    to: this.$t(
                        "threats.status." +
                            getProperty(this.log, "extra_attributes.to")
                    ),
                });
            }

            if (this.type === "analysis_completed") {
                return this.$t("threats.activity.analysis_completed");
            }

            return null;
        },
    },
};
</script>
