<template>
    <ValidationObserver ref="createEmployeesForm" v-slot="{ passes }">
        <Alert :error="error" />
        <form @submit.prevent="passes(createEmployees)">
            <div
                class="flex flex-col overflow-hidden border-t"
                v-for="(row, index) in form.rows"
                :key="index"
            >
                <div
                    v-if="index !== 0"
                    class="flex w-full justify-end border-b bg-slate-50 px-4 py-3 sm:px-6"
                >
                    <button @click="deleteRow(index)" class="text-error-dark">
                        <svg
                            class="size-4"
                            fill="none"
                            stroke="currentColor"
                            viewBox="0 0 24 24"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                stroke-width="2"
                                d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16"
                            ></path>
                        </svg>
                    </button>
                </div>
                <div
                    class="flex flex-col items-stretch justify-evenly p-4 xl:flex-row xl:space-x-4"
                >
                    <FormFieldSet class="w-full xl:w-1/2">
                        <FormInput
                            :id="`first_name_${index}`"
                            v-model="row.first_name"
                            type="text"
                            rules="required"
                            name="first_name"
                        />
                        <FormInput
                            :id="`last_name_${index}`"
                            v-model="row.last_name"
                            type="text"
                            rules="required"
                            name="last_name"
                        />
                    </FormFieldSet>
                    <FormFieldSet class="w-full xl:w-1/2">
                        <FormInput
                            :id="`email_${index}`"
                            v-model="row.email"
                            type="email"
                            rules="required"
                            name="email"
                        />
                        <FormSelect
                            :id="`locale_${index}`"
                            v-model="row.locale"
                            type="text"
                            name="locale"
                            rules="required"
                        >
                            <option
                                v-for="locale in locales"
                                :key="locale.value"
                                :value="locale.value"
                            >
                                {{ $t("locale." + locale.text) }}
                            </option>
                        </FormSelect>
                    </FormFieldSet>
                </div>
            </div>
            <div class="flex w-full flex-row justify-between border-t p-4">
                <FormButton variant="secondary" @click="addRow">{{
                    $t("employees.add_row")
                }}</FormButton>
                <FormButton type="submit" :loading="loading">
                    {{ $t("employees.create") }}
                </FormButton>
            </div>
        </form>
    </ValidationObserver>
</template>

<script>
import { TeamStoreNamespacedTypes } from "@/store/modules/team";

import { ValidationObserver } from "vee-validate";
import Alert from "@/components/ui/Alert";
import FormInput from "@/components/forms/fields/FormInput";
import FormFieldSet from "@/components/forms/fields/FormFieldSet";
import FormButton from "@/components/forms/fields/FormButton";
import { locales } from "@/utils/enums";
import FormSelect from "@/components/forms/fields/FormSelect";

export default {
    components: {
        FormSelect,
        FormButton,
        FormFieldSet,
        Alert,
        ValidationObserver,
        FormInput,
    },
    data: () => ({
        locales: locales,
        form: {
            rows: [
                {
                    first_name: null,
                    last_name: null,
                    email: null,
                    locale: locales[0].value,
                },
            ],
        },
    }),
    computed: {
        /**
         * @type {Boolean}
         */
        loading() {
            return this.$store.getters[
                TeamStoreNamespacedTypes.getters.LOADING
            ]();
        },

        /**
         * @type {Object}
         */
        error() {
            return this.$store.getters[
                TeamStoreNamespacedTypes.getters.ERROR
            ]();
        },
    },
    methods: {
        /**
         * Add another employee row
         */
        addRow() {
            this.form.rows.push({
                first_name: null,
                last_name: null,
                email: null,
                locale: locales[0].value,
            });
        },
        /**
         * Add another employee row
         */
        deleteRow(row_index) {
            this.form.rows.splice(row_index, 1);
        },

        /**
         * Sends authentication credentials to the server.
         */
        createEmployees() {
            this.$store
                .dispatch(
                    TeamStoreNamespacedTypes.actions.CREATE_EMPLOYEES,
                    this.form
                )
                .then(() => {
                    if (!this.error) {
                        this.$emit("saved");
                    }
                });
        },
    },
    beforeCreate() {
        this.$store.commit(TeamStoreNamespacedTypes.mutations.SET_ERROR, null);
    },
};
</script>
