<template>
    <div>
        <Alert :error="error" />
        <table role="grid" class="min-w-full divide-y divide-slate-200">
            <thead class="bg-slate-50">
                <tr>
                    <th v-if="selectable" class="pl-1 text-center">
                        <input
                            class="size-4 rounded border-slate-300 text-indigo-600 focus:ring-indigo-500"
                            type="checkbox"
                            @change="onSelectAllChange"
                            :checked="allRowsSelected"
                        />
                    </th>
                    <DataTableHeader
                        :class="{ '!pl-2': index == 0 && selectable }"
                        v-for="(header, index) in headers"
                        :header="header"
                        :key="`row-${header.key}`"
                        :active="activeColumnSortKey === header.key"
                        @sort="handleSorting"
                    ></DataTableHeader>
                </tr>
            </thead>
            <tbody>
                <DataTableRow
                    v-for="(item, index) in rows"
                    :key="`row-${index}`"
                    :resource="resource"
                    :resource-params="resourceParams"
                    :item="item"
                    :emit="emit"
                    :selectable="selectable"
                    :value="item.id"
                    :selected="selectedRows.includes(item.id)"
                    @select="onRowSelect"
                    @emit="$emit('emit', $event)"
                >
                    <td
                        class="whitespace-nowrap py-2 text-sm font-medium text-slate-700"
                        :class="{
                            'px-3': i > 0 || !selectable,
                        }"
                        v-for="(header, i) in headers"
                        :key="`td-${header.key}`"
                    >
                        <slot :name="header.key" :item="item">{{
                            item[header.key]
                        }}</slot>
                    </td>
                </DataTableRow>
            </tbody>
        </table>
        <div
            v-if="loading && rows.length === 0"
            class="divide-y divide-slate-200"
        >
            <div
                v-for="x in loadingRows"
                :key="x"
                class="flex flex-row space-x-4 px-6 py-3"
            >
                <div
                    v-for="header in headers"
                    :key="`loading-${header.key}`"
                    class="h-6 flex-grow"
                >
                    <div class="w-100 h-6 rounded bg-slate-50"></div>
                </div>
            </div>
        </div>
        <div
            v-if="!loading && rows.length === 0"
            class="flex flex-col items-center justify-center space-x-4 px-6 py-8 text-center"
        >
            <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                class="mx-auto mb-2 h-10 w-10 text-slate-300"
                fill="none"
                stroke="currentColor"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="1.5"
            >
                <path
                    d="M14 2.058V3.2c0 1.68 0 2.52.327 3.162a3 3 0 0 0 1.311 1.311C16.28 8 17.12 8 18.8 8h1.142M14 2.058C13.607 2 13.136 2 12.349 2H10.4c-2.24 0-3.36 0-4.216.436a4 4 0 0 0-1.748 1.748C4 5.04 4 6.16 4 8.4v7.2c0 2.24 0 3.36.436 4.216a4 4 0 0 0 1.748 1.748C7.04 22 8.16 22 10.4 22h3.2c2.24 0 3.36 0 4.216-.436a4 4 0 0 0 1.748-1.748C20 18.96 20 17.84 20 15.6V9.651c0-.787 0-1.257-.058-1.651M14 2.058c.096.014.187.03.277.053.408.098.798.26 1.156.478.404.248.75.594 1.442 1.286l1.25 1.25c.692.692 1.038 1.038 1.286 1.442a4 4 0 0 1 .479 1.156c.021.09.038.181.052.277M15.5 17.5l-1.379-1.379m0 0A2.998 2.998 0 0 0 12 11c-1.659 0-3 1.341-3 3a2.998 2.998 0 0 0 5.121 2.121Z"
                />
            </svg>
            <h3 class="mt-2 text-sm font-medium text-slate-600">
                {{ $t("tables.empty") }}
            </h3>
        </div>
        <div
            v-if="hasPagination"
            class="flex items-center justify-between bg-white px-4 py-3 sm:px-6"
        >
            <div class="flex sm:flex-1 sm:items-center sm:justify-between">
                <div>
                    <p
                        class="text-sm text-slate-700"
                        v-html="$t('tables.pagination', paginationInformation)"
                    ></p>
                </div>
                <div>
                    <nav class="pagination" aria-label="Pagination">
                        <button
                            :disabled="!hasPreviousPage"
                            @click="handlePreviousPage"
                            class="previous"
                        >
                            <span class="sr-only">Previous</span>
                            <svg
                                class="h-5 w-5"
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 20 20"
                                fill="currentColor"
                                aria-hidden="true"
                            >
                                <path
                                    fill-rule="evenodd"
                                    d="M12.79 5.23a.75.75 0 01-.02 1.06L8.832 10l3.938 3.71a.75.75 0 11-1.04 1.08l-4.5-4.25a.75.75 0 010-1.08l4.5-4.25a.75.75 0 011.06.02z"
                                    clip-rule="evenodd"
                                />
                            </svg>
                        </button>
                        <button
                            :disabled="!hasNextPage"
                            @click="handleNextPage"
                            class="next"
                        >
                            <span class="sr-only">Next</span>
                            <svg
                                class="h-5 w-5"
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 20 20"
                                fill="currentColor"
                                aria-hidden="true"
                            >
                                <path
                                    fill-rule="evenodd"
                                    d="M7.21 14.77a.75.75 0 01.02-1.06L11.168 10 7.23 6.29a.75.75 0 111.04-1.08l4.5 4.25a.75.75 0 010 1.08l-4.5 4.25a.75.75 0 01-1.06-.02z"
                                    clip-rule="evenodd"
                                />
                            </svg>
                        </button>
                    </nav>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Alert from "@/components/ui/Alert";
import DataTableHeader from "./DataTableHeader";
import DataTableRow from "./DataTableRow";
import { getProperty } from "@/utils/object";

export default {
    name: "DataTable",
    components: {
        Alert,
        DataTableHeader,
        DataTableRow,
    },
    model: {
        prop: "selectedRows",
        event: "selection-change",
    },
    props: {
        headers: {
            type: Array,
            required: true,
            default: () => [],
        },
        rows: {
            type: Array,
            default: () => [],
        },
        meta: {
            type: Object,
            required: false,
        },
        resource: {
            type: String,
            default: null,
        },
        emit: {
            type: Boolean,
            default: false,
        },
        resourceParams: {
            type: Array,
            default: () => [],
        },
        loading: {
            type: Boolean,
            default: true,
        },
        error: {
            type: Object,
            default: () => {},
        },
        searchable: {
            type: Array,
            default: () => [],
        },
        loadingRows: {
            type: Number,
            default: 20,
        },
        selectable: {
            type: Boolean,
            default: false,
        },
        selectedRows: {
            type: Array,
            default: () => [],
        },
    },
    data: () => ({
        activeColumnSortKey: false,
        sort: {
            key: null,
            direction: "asc",
        },
    }),
    computed: {
        hasPagination() {
            return getProperty(this.meta, "last_page", 1) > 1;
        },
        currentPage() {
            return getProperty(this.meta, "current_page", 1);
        },
        lastPage() {
            return getProperty(this.meta, "last_page", 1);
        },
        hasPreviousPage() {
            return this.currentPage > 1;
        },
        hasNextPage() {
            return this.currentPage < this.lastPage;
        },
        paginationInformation() {
            return {
                from: getProperty(this.meta, "from", 0),
                to: getProperty(this.meta, "to", 0),
                total: getProperty(this.meta, "total", 0),
            };
        },
        allRowsSelected() {
            return (
                this.rows.length > 0 &&
                this.selectedRows.length === this.rows.length
            );
        },
    },
    methods: {
        handleNextPage() {
            let payload = {
                page: this.currentPage + 1,
                key: this.sort.key,
                direction: this.sort.direction,
            };

            this.$emit("update", payload);
        },
        handlePreviousPage() {
            let payload = {
                page: this.currentPage - 1,
                key: this.sort.key,
                direction: this.sort.direction,
            };

            this.$emit("update", payload);
        },
        handleSorting(payload) {
            this.$nextTick(function () {
                this.activeColumnSortKey = payload.key;
                this.sort = payload;
            });

            this.$emit("update", {
                page: 1,
                ...payload,
            });
        },
        onSelectAllChange(event) {
            const newRows = event.target.checked
                ? this.rows.map((item) => item.id)
                : [];
            this.$emit("selection-change", newRows);
        },
        onRowSelect(itemId, isSelected) {
            const newRows = isSelected
                ? [...this.selectedRows, itemId]
                : this.selectedRows.filter((id) => id !== itemId);
            this.$emit("selection-change", newRows);
        },
    },
};
</script>
