<template>
    <div class="flex items-stretch overflow-hidden rounded-xl bg-white">
        <div
            class="flex h-24 w-36 flex-shrink-0 items-center justify-center rounded-xl bg-slate-200"
        >
            <img
                src="/img/svg/halt-automation.svg"
                alt="AI Lesson"
                class="h-auto w-32"
            />
        </div>
        <div class="px-4">
            <h4 class="text-sm font-medium text-slate-900">
                {{ $t("automation.flow.title.preview.halt_action.title") }}
            </h4>
            <p class="mt-1 text-sm text-slate-500">
                {{ $t("automation.flow.title.preview.halt_action.text") }}
            </p>
            <p class="mt-1 text-sm text-slate-500" v-if="isRepeatable">
                {{ $t("automation.flow.actions.repeat_enabled") }}
            </p>
        </div>
    </div>
</template>

<script>
import { AutomationStoreNamespacedTypes } from "@/store/modules/automation";

import { getProperty } from "@/utils/object";

import AutomationActionMixin from "@/mixins/AutomationActionMixin";

export default {
    name: "HaltAction",
    mixins: [AutomationActionMixin],
    computed: {
        /**
         * @type {Array}
         */
        automation() {
            return this.$store.getters[
                AutomationStoreNamespacedTypes.getters.AUTOMATION
            ]();
        },

        /**
         * @type {Boolean}
         */
        isRepeatable() {
            return getProperty(this.automation, "repeat_enabled", false);
        },
    },
};
</script>
