<template>
    <div class="relative w-10">
        <GaugeChart
            :value="value"
            :max="100"
            :min="0"
            :separator-thickness="0"
            :separator-step="null"
            :inner-radius="75"
            :scale-interval="0"
            :tweened-value="value"
            base-color="#e3e8ef"
            :gauge-color="fillColor"
        />
        <p
            class="text-xxs absolute -bottom-1.5 left-1/2 w-full -translate-x-1/2 transform text-center font-bold text-slate-700"
            v-text="value"
        ></p>
    </div>
</template>

<script>
import GaugeChart from "@/components/charts/GaugeChart";

export default {
    name: "ScoreBadge",
    components: {
        GaugeChart,
    },
    props: {
        value: {
            type: Number,
            default: 0,
        },
    },
    computed: {
        fillColor() {
            if (this.value < 30) {
                return "#de524c";
            }

            if (this.value < 65) {
                return "#ea7a35";
            }

            return "#65d1bf";
        },
    },
};
</script>
