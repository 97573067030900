/**
 * Icon enums
 * @type {Object<{name: String, title: String}>}
 */

export const IconEnums = {
    ALERT: { name: "Alert", title: "icons.alert" },
    ANNOUNCEMENT: { name: "Announcement", title: "icons.announcement" },
    BACK: { name: "Back", title: "icons.back" },
    BOLD: { name: "Bold", title: "icons.bold" },
    BREACH: { name: "Breach", title: "icons.breach" },
    BULLET_LIST: { name: "BulletList", title: "icons.bullet_list" },
    CALENDAR: { name: "Calendar", title: "icons.calendar" },
    CHECKMARK: { name: "Checkmark", title: "icons.checkmark" },
    CLICK: { name: "Click", title: "icons.click" },
    CLOCK: { name: "Clock", title: "icons.clock" },
    CLOSE: { name: "Close", title: "icons.close" },
    COPY: { name: "Copy", title: "icons.copy" },
    DANISH: { name: "Danish", title: "icons.danish" },
    DATABASE: { name: "Database", title: "icons.database" },
    DOMAIN: { name: "Domain", title: "icons.domain" },
    DOWNLOAD: { name: "Download", title: "icons.download" },
    DROPDOWN: { name: "Dropdown", title: "icons.dropdown" },
    DUPLICATE: { name: "Duplicate", title: "icons.duplicate" },
    ENGLISH: { name: "English", title: "icons.english" },
    ERROR: { name: "Error", title: "icons.error" },
    EXPAND: { name: "Expand", title: "icons.expand" },
    EYE: { name: "Eye", title: "icons.eye" },
    GOOGLE: { name: "Google", title: "icons.google" },
    GRAPH: { name: "Graph", title: "icons.graph" },
    GROUP: { name: "Group", title: "icons.group" },
    HALT: { name: "Halt", title: "icons.halt" },
    HEADING: { name: "Heading", title: "icons.heading" },
    HOME: { name: "Home", title: "icons.home" },
    IMAGE: { name: "Image", title: "icons.image" },
    INFO: { name: "Info", title: "icons.info" },
    INTEGRATIONS: { name: "Integrations", title: "icons.integrations" },
    ITALIC: { name: "Italic", title: "icons.italic" },
    LOADING: { name: "Loading", title: "icons.loading" },
    LOCK: { name: "Lock", title: "icons.lock" },
    LOGOUT: { name: "Logout", title: "icons.logout" },
    MAGIC: { name: "Magic", title: "icons.magic" },
    MENU: { name: "Menu", title: "icons.menu" },
    MICROSOFT: { name: "Microsoft", title: "icons.microsoft" },
    NUMBER_LIST: { name: "NumberList", title: "icons.number_list" },
    PARAGRAPH: { name: "Paragraph", title: "icons.paragraph" },
    PAUSE: { name: "Pause", title: "icons.pause" },
    PLAY: { name: "Play", title: "icons.play" },
    REDO: { name: "Redo", title: "icons.redo" },
    REFRESH: { name: "Refresh", title: "icons.refresh" },
    REMINDER: { name: "Reminder", title: "icons.reminder" },
    REPORT: { name: "Report", title: "icons.report" },
    SAP: { name: "Sap", title: "icons.sap" },
    SEARCH: { name: "Search", title: "icons.search" },
    SETTINGS: { name: "Settings", title: "icons.settings" },
    SIGNAL: { name: "Signal", title: "icons.signal" },
    SIMULATION: { name: "Simulation", title: "icons.simulation" },
    SLACK: { name: "Slack", title: "icons.slack" },
    STRIKE: { name: "Strike", title: "icons.strike" },
    TAG: { name: "Tag", title: "icons.tag" },
    TEAM: { name: "Team", title: "icons.team" },
    THREAT: { name: "Threat", title: "icons.threat" },
    TRAINING: { name: "Training", title: "icons.training" },
    TRASH: { name: "Trash", title: "icons.trash" },
    UNDO: { name: "Undo", title: "icons.undo" },
    UPLOAD: { name: "Upload", title: "icons.upload" },
    VIDEO: { name: "Video", title: "icons.video" },
};

/**
 * Icon components
 */
import IconAlert from "@/components/icons/IconAlert";
import IconAnnouncement from "@/components/icons/IconAnnouncement";
import IconBack from "@/components/icons/IconBack";
import IconBold from "@/components/icons/editor/IconBold";
import IconBreach from "@/components/icons/IconBreach";
import IconBulletList from "@/components/icons/editor/IconBulletList";
import IconCalendar from "@/components/icons/IconCalendar";
import IconCheckmark from "@/components/icons/IconCheckmark";
import IconClick from "@/components/icons/IconClick";
import IconClock from "@/components/icons/IconClock";
import IconClose from "@/components/icons/IconClose";
import IconCopy from "@/components/icons/IconCopy";
import IconDanish from "@/components/icons/IconDanish";
import IconDatabase from "@/components/icons/IconDatabase";
import IconDomain from "@/components/icons/IconDomain";
import IconDownload from "@/components/icons/IconDownload";
import IconDropdown from "@/components/icons/IconDropdown";
import IconDuplicate from "@/components/icons/IconDuplicate";
import IconEnglish from "@/components/icons/IconEnglish";
import IconError from "@/components/icons/IconError";
import IconExpand from "@/components/icons/IconExpand";
import IconEye from "@/components/icons/IconEye";
import IconGoogle from "@/components/icons/IconGoogle";
import IconGraph from "@/components/icons/IconGraph";
import IconGroup from "@/components/icons/IconGroup";
import IconHalt from "@/components/icons/IconHalt";
import IconHeading from "@/components/icons/editor/IconHeading";
import IconHome from "@/components/icons/IconHome";
import IconImage from "@/components/icons/IconImage";
import IconInfo from "@/components/icons/IconInfo";
import IconIntegrations from "@/components/icons/IconIntegrations";
import IconItalic from "@/components/icons/editor/IconItalic";
import IconLoading from "@/components/icons/IconLoading";
import IconLock from "@/components/icons/IconLock";
import IconLogout from "@/components/icons/IconLogout";
import IconMagic from "@/components/icons/IconMagic";
import IconMenu from "@/components/icons/IconMenu";
import IconMicrosoft from "@/components/icons/IconMicrosoft";
import IconNumberList from "@/components/icons/editor/IconNumberList";
import IconParagraph from "@/components/icons/editor/IconParagraph";
import IconPause from "@/components/icons/IconPause";
import IconPlay from "@/components/icons/IconPlay";
import IconRedo from "@/components/icons/editor/IconRedo";
import IconRefresh from "@/components/icons/IconRefresh";
import IconReminder from "@/components/icons/IconReminder";
import IconReport from "@/components/icons/IconReport";
import IconSap from "@/components/icons/IconSap";
import IconSearch from "@/components/icons/IconSearch";
import IconSettings from "@/components/icons/IconSettings";
import IconSignal from "@/components/icons/IconSignal";
import IconSimulation from "@/components/icons/IconSimulation";
import IconSlack from "@/components/icons/IconSlack";
import IconStrike from "@/components/icons/editor/IconStrike";
import IconTag from "@/components/icons/IconTag";
import IconTeam from "@/components/icons/IconTeam";
import IconThreat from "@/components/icons/IconThreat";
import IconTraining from "@/components/icons/IconTraining";
import IconTrash from "@/components/icons/IconTrash";
import IconUndo from "@/components/icons/editor/IconUndo";
import IconUpload from "@/components/icons/IconUpload";
import IconVideo from "@/components/icons/IconVideo";

export const IconComponents = {
    IconAlert,
    IconAnnouncement,
    IconBack,
    IconBold,
    IconBreach,
    IconBulletList,
    IconCalendar,
    IconCheckmark,
    IconClick,
    IconClock,
    IconClose,
    IconCopy,
    IconDanish,
    IconDatabase,
    IconDomain,
    IconDownload,
    IconDropdown,
    IconDuplicate,
    IconEnglish,
    IconError,
    IconExpand,
    IconEye,
    IconGoogle,
    IconGraph,
    IconGroup,
    IconHalt,
    IconHeading,
    IconHome,
    IconImage,
    IconInfo,
    IconIntegrations,
    IconItalic,
    IconLoading,
    IconLock,
    IconLogout,
    IconMagic,
    IconMenu,
    IconMicrosoft,
    IconNumberList,
    IconParagraph,
    IconPause,
    IconPlay,
    IconRedo,
    IconRefresh,
    IconReminder,
    IconReport,
    IconSap,
    IconSearch,
    IconSettings,
    IconSignal,
    IconSimulation,
    IconSlack,
    IconStrike,
    IconTag,
    IconTeam,
    IconThreat,
    IconTraining,
    IconTrash,
    IconUndo,
    IconUpload,
    IconVideo,
};
