var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"h-full overflow-auto px-4"},[_c('div',{staticClass:"card mb-4 flex flex-col space-y-4 p-4"},[_c('div',{staticClass:"flex w-full flex-row items-center"},[_c('Avatar',{attrs:{"size":_vm.SizeVariants.BIG,"image":_vm.avatar,"name":_vm.full_name}}),_c('div',{staticClass:"ml-3 flex min-w-0 flex-col"},[_c('p',{staticClass:"truncate font-medium leading-tight",domProps:{"textContent":_vm._s(_vm.full_name)}}),_c('p',{staticClass:"truncate text-slate-500",domProps:{"textContent":_vm._s(_vm.department ? _vm.department : _vm.email)}})])],1),_c('div',{staticClass:"flex items-center space-x-2"},[_c('FormButton',{staticClass:"flex-grow",attrs:{"variant":_vm.ButtonVariants.SECONDARY},nativeOn:{"click":function($event){return _vm.goToProfile.apply(null, arguments)}}},[_vm._v(" "+_vm._s(_vm.$t("employees.preview.profile"))+" ")]),_c('FormButton',{attrs:{"variant":_vm.ButtonVariants.SECONDARY,"icon":_vm.viewForm ? _vm.IconEnums.CLOSE : _vm.IconEnums.SETTINGS,"loading":_vm.loading},nativeOn:{"click":function($event){return _vm.toggleForm.apply(null, arguments)}}},[_vm._v(" "+_vm._s(_vm.viewForm ? _vm.$t("employees.preview.close_edit") : _vm.$t("employees.preview.edit"))+" ")])],1)]),(!_vm.viewForm)?_c('AvgScoreCard',{staticClass:"card mb-4",attrs:{"loading":_vm.loading,"title":_vm.$t('employees.resilience_score.title').toString(),"text":_vm.$t('employees.resilience_score.text').toString(),"value":_vm.score,"max":100,"min":0}}):_vm._e(),_c('div',{staticClass:"card mb-4 flex flex-col space-y-4"},[(!_vm.viewForm)?_c('div',{staticClass:"flex flex-col divide-y divide-slate-100"},[(_vm.isInactive)?_c('div',{staticClass:"relative px-4 py-2.5"},[_c('div',{staticClass:"flex items-center whitespace-pre-wrap rounded-lg bg-yellow-50/50 p-2 text-xs text-warning-dark ring-1 ring-inset ring-yellow-500/20",domProps:{"innerHTML":_vm._s(
                        _vm.$t(
                            'employees.deactivation_notice.' +
                                _vm.deactivation_reason,
                            { date: _vm.formatDateTime(_vm.deactivated_at) }
                        )
                    )}})]):_vm._e(),_c('ListItem',{attrs:{"title":_vm.$t('employees.preview.status').toString()}},[_c('Badge',{attrs:{"variant":_vm.status === 'active'
                            ? _vm.BadgeVariants.SUCCESS
                            : _vm.BadgeVariants.ERROR,"text":_vm.$t(`employees.${_vm.status}`).toString()}})],1),_c('ListItem',{attrs:{"title":_vm.$t('employees.preview.email').toString(),"text":_vm.email}}),(_vm.provider)?_c('ListItem',{attrs:{"title":_vm.$t('employees.preview.provider').toString(),"text":_vm.provider}}):_vm._e(),(_vm.provider)?_c('ListItem',{attrs:{"title":_vm.$t('employees.preview.provider_id').toString(),"text":_vm.provider_id}}):_vm._e(),_c('ListItem',{attrs:{"title":_vm.$t('employees.preview.locale').toString(),"text":_vm.$t(`language.${_vm.locale}`).toString()}}),(_vm.group_count !== null)?_c('ListItem',{attrs:{"title":_vm.$t('employees.preview.groups').toString(),"text":_vm.group_count}}):_vm._e(),_c('ListItem',{attrs:{"title":_vm.$t('employees.preview.tags').toString()}},[(_vm.hasTags)?_c('ul',{staticClass:"flex flex-wrap gap-2 text-xs font-medium text-slate-600 *:rounded-full *:bg-slate-50 *:px-3 *:py-1 *:ring-1 *:ring-inset *:ring-slate-200"},_vm._l((_vm.tags),function(tag){return _c('li',{key:tag.id,domProps:{"textContent":_vm._s(tag.name)}})}),0):_c('div',[_vm._v("-")])])],1):_c('div',{staticClass:"px-4 pt-4"},[_c('EmployeeSettingsForm',{attrs:{"cols":1},on:{"saved":_vm.handleSaved}})],1)])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }