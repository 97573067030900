<template>
    <div class="overflow-hidden rounded-xl border border-slate-200">
        <div
            class="flex items-center gap-x-4 border-b border-slate-900/5 bg-slate-50 px-4 py-3"
        >
            <div class="text-sm font-medium leading-6 text-slate-900">
                {{ $t("threats.analysis.links.heading") }}
            </div>
        </div>
        <ContentLoading :loading="loading">
            <dl
                v-if="hasLinks"
                class="-my-3 divide-y divide-slate-100 px-4 py-3 text-sm leading-6"
            >
                <div
                    v-for="(link, index) in links"
                    :key="link.id"
                    v-show="index < 2 || previewExpanded"
                    class="flex items-center gap-x-4 space-x-6 py-3"
                >
                    <dt
                        class="flex-grow truncate text-slate-500"
                        v-text="link.text"
                    ></dt>
                    <dd class="flex flex-shrink-0 items-center space-x-2">
                        <FormButton
                            :variant="ButtonVariants.SMALL"
                            :icon="IconEnums.COPY"
                            @click.native="copy(link)"
                        />
                        <FormButton
                            :variant="ButtonVariants.SMALL"
                            :icon="IconEnums.DOMAIN"
                            @click.native="() => $emit('open', link)"
                        >
                            {{ $t("threats.analysis.links.button") }}
                        </FormButton>
                    </dd>
                </div>
            </dl>
            <div v-else class="py-6 text-center text-xs text-slate-400">
                {{ $t("threats.analysis.links.empty") }}
            </div>
        </ContentLoading>
        <div
            v-if="links.length > 2"
            class="flex items-center justify-center border-t px-4 py-3"
        >
            <FormButton
                class="pointer-events-auto relative"
                @click.native="previewExpanded = !previewExpanded"
                :variant="ButtonVariants.SMALL"
                >{{
                    previewExpanded
                        ? $t("threats.report.show_less")
                        : $t("threats.report.show_more")
                }}</FormButton
            >
        </div>
    </div>
</template>

<script>
import ContentLoading from "@/components/ui/ContentLoading";
import FormButton, {
    ButtonVariants,
} from "@/components/forms/fields/FormButton";
import { IconEnums } from "@/utils/icons";
import Bus from "@/bus";
import { getProperty } from "@/utils/object";

export default {
    name: "LinksTable",
    components: { FormButton, ContentLoading },
    props: {
        links: {
            type: Array,
            required: true,
        },
        loading: {
            type: Boolean,
            required: true,
        },
    },
    data: () => ({
        IconEnums,
        ButtonVariants,
        previewExpanded: false,
    }),
    computed: {
        hasLinks() {
            return this.links.length > 0;
        },
    },
    methods: {
        copy(link) {
            let destination = getProperty(link, "text", "");

            navigator.clipboard.writeText(destination);

            Bus.$emit("flash-message", {
                text: this.$t("threats.browser_isolation.copied"),
                type: "success",
            });
        },
    },
};
</script>
