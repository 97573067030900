<template>
    <EmployeePage>
        <div
            class="flex flex-col items-stretch justify-evenly xl:flex-row xl:space-x-4"
        >
            <div class="mb-4 flex w-full justify-start xl:mb-0 xl:w-1/2">
                <div class="grid w-full grid-cols-1 gap-4 xl:grid-cols-2">
                    <AvgScoreCard
                        :loading="loading"
                        :title="
                            $t('employees.resilience_score.title').toString()
                        "
                        :text="$t('employees.resilience_score.text').toString()"
                        :value="score"
                        :max="100"
                        :min="0"
                        class="card"
                    />
                    <ValueCard
                        :loading="loading"
                        :title="$t('statistics.videos.title').toString()"
                        :value="videoCount"
                        class="card"
                    />
                    <GroupChartCard
                        :loading="loading"
                        :title="$t('statistics.quizzing.title').toString()"
                        identifier="quizzing"
                        :data="quizzingStats"
                        :rows="2"
                        class="card"
                    />
                    <GroupChartCard
                        v-if="hasFeature('app-breaches')"
                        :loading="loading"
                        :title="$t('statistics.breaches.title').toString()"
                        identifier="breaches"
                        :data="breachStats"
                        :rows="2"
                        :to="{
                            name: 'employee.single.breaches',
                            params: { id: employee.id },
                        }"
                        class="card"
                    />
                </div>
            </div>
            <div class="w-full xl:w-1/2">
                <RecentActivity :data="activities" class="card" />
            </div>
        </div>
    </EmployeePage>
</template>

<script>
import { TeamStoreNamespacedTypes } from "@/store/modules/team";

import { getProperty } from "@/utils/object";

import GroupChartCard from "@/components/statistics/GroupChartCard";
import EmployeePage from "@/components/layout/EmployeePage";
import FeatureFlagMixin from "@/mixins/FeatureFlagMixin";
import ValueCard from "@/components/statistics/ValueCard";
import AvgScoreCard from "@/components/statistics/AvgScoreCard";
import RecentActivity from "@/components/display/employee/RecentActivity";

export default {
    name: "EmployeeSingle",
    mixins: [FeatureFlagMixin],
    components: {
        RecentActivity,
        AvgScoreCard,
        ValueCard,
        EmployeePage,
        GroupChartCard,
    },
    computed: {
        /**
         * The loading state for the API request.
         * @type {Boolean}
         */
        loading() {
            return this.$store.getters[
                TeamStoreNamespacedTypes.getters.LOADING
            ]();
        },

        /**
         * @type {Object}
         */
        employee() {
            return this.$store.getters[
                TeamStoreNamespacedTypes.getters.EMPLOYEE
            ]();
        },

        /**
         * @type {string}
         */
        id() {
            return getProperty(this.employee, "id", null);
        },

        /**
         * @type {Number}
         */
        score() {
            return getProperty(this.employee, "score", 0);
        },

        /**
         * @type {Array}
         */
        report() {
            return getProperty(this.employee, "report", []);
        },

        /**
         * @type {Object}
         */
        breachStats() {
            return getProperty(this.report, "breaches", {
                count: 0,
                groups: [
                    {
                        label: "critical",
                        count: 0,
                        pct: 0,
                        color: "error-dark",
                    },
                    {
                        label: "noncritical",
                        count: 0,
                        pct: 0,
                        color: "success-dark",
                    },
                ],
            });
        },

        /**
         * @type {Object}
         */
        quizzingStats() {
            return getProperty(this.report, "quizzing", {
                count: 0,
                groups: [
                    {
                        label: "incorrect",
                        count: 0,
                        pct: 0,
                        color: "error-dark",
                    },
                    {
                        label: "correct",
                        count: 0,
                        pct: 0,
                        color: "success-dark",
                    },
                ],
            });
        },

        /**
         * @type {Object}
         */
        videoStats() {
            return getProperty(this.report, "videos", {
                count: 0,
            });
        },

        /**
         * The total number of videos watched
         * @returns {number}
         */
        videoCount() {
            return getProperty(this.videoStats, "count", 0);
        },

        /**
         * @type {Array}
         */
        activities() {
            return getProperty(this.employee, "activities", []);
        },
    },
    mounted() {
        this.$store.dispatch(
            TeamStoreNamespacedTypes.actions.GET_EMPLOYEE,
            this.$route.params.id
        );
    },
};
</script>
