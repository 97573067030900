<template>
    <g fill-rule="nonzero" fill="none"
        ><path
            fill="#50E6FF"
            d="m1.347 13.744 10.56 7.531 10.746-7.561L24 15.443 11.907 24 0 15.443z" /><path
            fill="#FFF"
            d="M2.147 12.777 11.907 0l9.96 12.791-9.96 6.916z" /><path
            fill="#50E6FF"
            d="M11.907 0v19.707l-9.76-6.93z" /><path
            fill="#54AEF0"
            d="M11.907 0v19.707l9.96-6.916z" /><path
            fill="#53B1E0"
            d="m11.907 10.183 9.96 2.608-9.96 6.916z" /><path
            fill="#9CEBFF"
            d="m11.907 19.707-9.76-6.93 9.76-2.594z" /><path
            fill="#2A93E0"
            d="M11.907 24 24 15.443l-1.347-1.729-10.746 7.561z"
    /></g>
</template>

<script>
import IconSvgBase from "./IconSvgBase";

export default {
    extends: IconSvgBase,
    name: "IconMicrosoft",
};
</script>
