<template>
    <div>
        <FunnelChartLoading v-if="loading" />
        <div
            v-else
            class="group-scope relative flex h-[240px] overflow-x-auto px-3 pt-6"
        >
            <div class="group relative flex flex-col">
                <div class="relative flex w-full flex-full justify-end px-4">
                    <div
                        class="absolute inset-0 flex flex-col justify-between py-px"
                    >
                        <div class="relative flex w-full justify-end">
                            <div class="w-4 border-b border-slate-200"></div>
                        </div>
                        <div class="relative flex w-full justify-end">
                            <div
                                class="absolute mr-4 flex-1 -translate-x-2 -translate-y-1/2 transform text-right text-xs text-slate-400"
                            >
                                {{ halfValue }}
                            </div>
                            <div class="w-4 border-b border-slate-200"></div>
                        </div>
                        <div class="relative flex w-full justify-end">
                            <div
                                class="absolute mr-4 flex-1 -translate-x-2 -translate-y-1/2 transform text-right text-xs text-slate-400"
                            >
                                0
                            </div>
                            <div class="w-4 border-b border-slate-200"></div>
                        </div>
                    </div>
                    <div class="relative mt-px">
                        <div
                            class="inline-flex -translate-y-1/2 transform items-center space-x-1 rounded bg-slate-500 py-0.5 pl-1 pr-2 text-sm text-white"
                        >
                            <svg
                                viewBox="0 0 20 20"
                                fill="currentColor"
                                class="h-4 w-4 text-white"
                            >
                                <path
                                    fill-rule="evenodd"
                                    d="M10 9a3 3 0 100-6 3 3 0 000 6zm-7 9a7 7 0 1114 0H3z"
                                    clip-rule="evenodd"
                                ></path></svg
                            ><span>{{ maxValue }}</span>
                            <div
                                class="absolute right-4 top-1 z-[-1] h-4 w-4 translate-x-[17px] rotate-45 transform rounded-sm bg-slate-500 text-white"
                            ></div>
                        </div>
                    </div>
                </div>
                <div
                    class="text-xxs relative mt-4 w-[100px] min-w-full rounded-l-md border-b border-l border-t border-slate-200 bg-slate-100 px-1 py-0.5 text-center font-semibold text-slate-800"
                >
                    <div class="w-full truncate"><span>Start</span></div>
                    <div
                        class="absolute bottom-0 left-0 top-0 z-10 min-w-full scale-100 items-center justify-center whitespace-nowrap opacity-0 transition-transform group-hover:scale-[1.06] group-hover:opacity-100"
                    >
                        <div
                            class="flex h-full items-center justify-center rounded border border-slate-300 bg-white px-3"
                        >
                            <span class="relative w-full truncate">Start</span>
                        </div>
                    </div>
                </div>
            </div>
            <FunnelChartItem
                v-for="(item, index) in data"
                :index="index"
                :key="'item-' + index"
                :title="item.title"
                :value="item.value"
                :max-value="maxValue"
            />
        </div>
    </div>
</template>

<script>
import { toFixed } from "@/utils/math";

import FunnelChartItem from "@/components/charts/FunnelChart/partials/FunnelChartItem";
import FunnelChartLoading from "@/components/charts/FunnelChart/partials/FunnelChartLoading";

export default {
    name: "FunnelChart",
    components: { FunnelChartLoading, FunnelChartItem },
    props: {
        loading: {
            type: Boolean,
            required: true,
        },
        data: {
            type: Array,
            default: () => [],
        },
    },
    computed: {
        maxValue() {
            return Math.max(...this.data.map((item) => item.value));
        },
        halfValue() {
            return toFixed(this.maxValue / 2);
        },
    },
};
</script>
