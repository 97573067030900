<template>
    <WorkspaceSettingsPage>
        <Prompt :active="showPrompt" @close="showPrompt = false"
            ><h3 slot="header">{{ $t("forms.buttons.create_field") }}</h3>
            <div slot="content">
                <CreateFieldForm
                    @saved="handleFieldSaved"
                    v-if="showPrompt"
                /></div
        ></Prompt>
        <div class="card">
            <CardHeading
                :title="$t('settings.fields.heading').toString()"
                :text="$t('settings.fields.text').toString()"
                :loading="loading"
            >
                <FormButton
                    @click.native="showPrompt = true"
                    :variant="ButtonVariants.SECONDARY"
                >
                    {{ $t("forms.buttons.create_field") }}
                </FormButton>
            </CardHeading>
            <FieldTable
                :loading="loading"
                :rows="fields"
                @delete="handleDelete"
            />
        </div>
    </WorkspaceSettingsPage>
</template>

<script>
import { WorkspaceStoreNamespacedTypes } from "@/store/modules/workspace";

import { getProperty } from "@/utils/object";

import WorkspaceSettingsPage from "@/components/layout/WorkspaceSettingsPage";
import CreateFieldForm from "@/components/forms/CreateFieldForm";
import FormButton, {
    ButtonVariants,
} from "@/components/forms/fields/FormButton";
import Prompt from "@/components/ui/Prompt";
import Bus from "@/bus";
import FieldTable from "@/components/display/settings/FieldTable";
import CardHeading from "@/components/ui/CardHeading";

export default {
    name: "FieldsSettings",
    components: {
        CardHeading,
        FieldTable,
        Prompt,
        FormButton,
        CreateFieldForm,
        WorkspaceSettingsPage,
    },
    data: () => ({
        ButtonVariants,
        showPrompt: false,
    }),
    computed: {
        /**
         * @type {Boolean}
         */
        loading() {
            return this.$store.getters[
                WorkspaceStoreNamespacedTypes.getters.WORKSPACE_LOADING
            ]();
        },

        /**
         * @type {Object}
         */
        error() {
            return this.$store.getters[
                WorkspaceStoreNamespacedTypes.getters.WORKSPACE_ERROR
            ]();
        },

        /**
         * @type {Object}
         */
        workspace() {
            return this.$store.getters[
                WorkspaceStoreNamespacedTypes.getters.WORKSPACE
            ]();
        },

        /**
         * @type {Array}
         */
        fields() {
            return getProperty(this.workspace, "fields", []);
        },
    },
    methods: {
        getWorkspace() {
            this.$store.dispatch(
                WorkspaceStoreNamespacedTypes.actions.GET_WORKSPACE,
                {
                    force: true,
                }
            );
        },
        handleFieldSaved() {
            this.getWorkspace();
            Bus.$emit("flash-message", {
                text: this.$t("settings.fields.field_created"),
                type: "success",
            });
            this.showPrompt = false;
        },
        handleDelete(identifier) {
            this.$store
                .dispatch(WorkspaceStoreNamespacedTypes.actions.UPDATE_FIELDS, {
                    identifier: identifier,
                    type: null,
                })
                .then(() => {
                    if (!this.error) {
                        this.getWorkspace();
                        Bus.$emit("flash-message", {
                            text: this.$t("settings.fields.field_deleted"),
                            type: "success",
                        });
                    }
                });
        },
    },
};
</script>
