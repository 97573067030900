<template>
    <DashboardPage>
        <template>
            <GroupChartCard
                :loading="breachesStatisticsLoading"
                :title="$t('statistics.breaches.title').toString()"
                identifier="breaches"
                :data="breaches"
                :rows="2"
                class="card"
            />
        </template>
    </DashboardPage>
</template>

<script>
import { StatisticsStoreNamespacedTypes } from "@/store/modules/statistics";

import { getProperty } from "@/utils/object";

import GroupChartCard from "@/components/statistics/GroupChartCard";
import DashboardPage from "@/components/layout/DashboardPage";

export default {
    name: "DashboardBreachesPage",
    components: {
        DashboardPage,
        GroupChartCard,
    },
    data: () => ({
        breachesStatisticsLoading: true,
    }),
    computed: {
        /**
         * @type {Object}
         */
        error() {
            return this.$store.getters[
                StatisticsStoreNamespacedTypes.getters.ERROR
            ]();
        },

        /**
         * @type {Object}
         */
        statistics() {
            return this.$store.getters[
                StatisticsStoreNamespacedTypes.getters.STATISTICS
            ]();
        },

        breaches() {
            return getProperty(this.statistics, "breaches", {
                count: 0,
                groups: [
                    {
                        label: "incorrect",
                        count: 0,
                        pct: 0,
                        color: "error-dark",
                    },
                    {
                        label: "correct",
                        count: 0,
                        pct: 0,
                        color: "success-dark",
                    },
                ],
            });
        },
    },
    watch: {
        range() {
            this.getBreachStatistics();
        },
    },
    methods: {
        getBreachStatistics() {
            this.breachesStatisticsLoading = true;
            this.$store
                .dispatch(
                    StatisticsStoreNamespacedTypes.actions.GET_BREACH_STATISTICS
                )
                .then(() => {
                    this.breachesStatisticsLoading = false;
                });
        },
    },
    mounted() {
        this.$store.commit(
            StatisticsStoreNamespacedTypes.mutations.SET_ERROR,
            null
        );
        this.getBreachStatistics();
    },
};
</script>
