<template>
    <div>
        <div class="p-4">
            <div class="flex items-center justify-between">
                <div class="flex flex-col">
                    <h3
                        class="font-medium leading-7 text-slate-500"
                        v-text="title"
                    ></h3>
                    <p
                        v-if="unit"
                        class="text-xs font-medium text-slate-400"
                        v-text="unit"
                    ></p>
                </div>
                <LoadingSpinner v-if="loading" class="size-5" />
            </div>
        </div>
        <div class="h-full rounded-b bg-slate-50 p-4">
            <BarChart :loading="loading" :data="data" />
        </div>
    </div>
</template>

<script>
import StatisticsCardMixin from "@/mixins/statistics/StatisticsCardMixin";
import ActionableDataMixin from "@/mixins/statistics/ActionableDataMixin";
import ThresholdDataMixin from "@/mixins/statistics/ThresholdDataMixin";
import BarChart from "@/components/charts/BarChart/BarChart";

export default {
    name: "BarChartCard",
    mixins: [StatisticsCardMixin, ActionableDataMixin, ThresholdDataMixin],
    components: { BarChart },
    props: {
        unit: {
            type: String,
            required: false,
        },
        data: {
            type: Array,
            default: () => [],
        },
    },
};
</script>
