var render = function render(){var _vm=this,_c=_vm._self._c;return _c('nav',{staticClass:"flex items-center justify-center",attrs:{"aria-label":"Progress"}},[_c('ol',{staticClass:"flex items-center space-x-5"},_vm._l((_vm.total),function(indicator,index){return _c('li',{key:`progress-item-${index}`},[_c('div',{staticClass:"size-2.5 block rounded-full",class:[
                    {
                        'bg-slate-800': _vm.isActiveOrPassed(index),
                    },
                    {
                        'bg-slate-300': !_vm.isActiveOrPassed(index),
                    },
                ]},[_c('span',{staticClass:"sr-only"},[_vm._v("step "+_vm._s(index))])])])}),0)])
}
var staticRenderFns = []

export { render, staticRenderFns }