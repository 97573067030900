export function buildTooltip(props, options) {
    const {
        title,
        valuePrefix = "",
        isValueDivided = true,
        valuePostfix = "",
        hasTextLabel = false,
        invertGroup = false,
        labelDivider = "",
        wrapperClasses = "ms-0.5 mb-2 bg-white border border-slate-200 text-slate-800 rounded-lg shadow-md",
        wrapperExtClasses = "",
        seriesClasses = "text-[12px]",
        seriesExtClasses = "",
        titleClasses = "font-semibold !text-sm !bg-white !border-slate-200 text-slate-800 rounded-t-lg ",
        titleExtClasses = "",
        markerClasses = "!w-2.5 !h-2.5 !me-1.5",
        markerExtClasses = "!rounded-sm",
        valueClasses = "!font-medium text-slate-500 !ms-auto ",
        valueExtClasses = "",
        labelClasses = "text-slate-500 ",
        labelExtClasses = "",
    } = options;

    const { dataPointIndex } = props;
    const { colors } = props.ctx.opts;
    const series = props.ctx.opts.series;
    let seriesGroups = "";

    series.forEach((single, i) => {
        const val =
            props.series[i][dataPointIndex] ||
            (typeof series[i].data[dataPointIndex] !== "object"
                ? series[i].data[dataPointIndex]
                : props.series[i][dataPointIndex]);
        const label = series[i].name;
        const groupData = invertGroup
            ? {
                  left: `${hasTextLabel ? label : ""}${labelDivider}`,
                  right: `${valuePrefix}${
                      val >= 1000 && isValueDivided ? `${val / 1000}k` : val
                  }${valuePostfix}`,
              }
            : {
                  left: `${valuePrefix}${
                      val >= 1000 && isValueDivided ? `${val / 1000}k` : val
                  }${valuePostfix}`,
                  right: `${hasTextLabel ? label : ""}${labelDivider}`,
              };
        const labelMarkup = `<span class="apexcharts-tooltip-text-y-label ${labelClasses} ${labelExtClasses}">${groupData.left}</span>`;

        seriesGroups += `<div class="apexcharts-tooltip-series-group !flex ${
            hasTextLabel ? "!justify-between" : ""
        } order-${i + 1} ${seriesClasses} ${seriesExtClasses}">
      <span class="flex items-center">
        <span class="apexcharts-tooltip-marker ${markerClasses} ${markerExtClasses}" style="background: ${
            colors[i]
        }"></span>
        <div class="apexcharts-tooltip-text">
          <div class="apexcharts-tooltip-y-group !py-0.5">
            <span class="apexcharts-tooltip-text-y-value ${valueClasses} ${valueExtClasses}">${
            groupData.right
        }</span>
          </div>
        </div>
      </span>
      ${labelMarkup}
    </div>`;
    });

    return `<div class="${wrapperClasses} ${wrapperExtClasses}">
    <div class="apexcharts-tooltip-title ${titleClasses} ${titleExtClasses}">${title}</div>
    ${seriesGroups}
  </div>`;
}
