<template>
    <ValidationObserver ref="threatManagementSettingsForm" v-slot="{ passes }">
        <form @submit.prevent="passes(updateSettings)" class="-mb-4">
            <Alert :error="error" />
            <FormFieldSet>
                <FormFieldOutline>
                    <FormToggle
                        v-model="form.is_enabled"
                        name="enable_threat_management"
                        help="forms.help.enable_threat_management"
                    />
                </FormFieldOutline>
                <FormFieldOutline>
                    <FormToggle
                        v-model="form.enable_notifications"
                        name="enable_threat_notification"
                        help="forms.help.enable_threat_notification"
                    />
                </FormFieldOutline>
                <FormFieldOutline>
                    <FormToggle
                        v-model="form.enable_forwarding"
                        name="enable_forwarding"
                        help="forms.help.enable_forwarding"
                    />
                    <FormInput
                        v-if="form.enable_forwarding"
                        v-model="form.forwarding_email"
                        type="email"
                        placeholder="phishing@domain.com"
                        name="forwarding_email"
                        rules="required"
                    />
                </FormFieldOutline>
                <FormFieldOutline>
                    <FormToggle
                        v-model="form.enable_third_party_submission"
                        name="enable_third_party_submission"
                        help="forms.help.enable_third_party_submission"
                    />
                </FormFieldOutline>
            </FormFieldSet>
            <FormFieldSet>
                <FormInput
                    v-model="inboundEmail"
                    type="email"
                    placeholder="phishing@domain.com"
                    name="inbound_email"
                    help="inbound_email"
                    :disabled="true"
                />
                <FormInput
                    v-model="form.alias_email"
                    type="email"
                    placeholder="phishing@domain.com"
                    name="alias_email"
                    help="alias_email"
                />
                <div class="flex justify-end">
                    <FormButton type="submit" :loading="formLoading">
                        {{ $t("forms.buttons.save_settings") }}
                    </FormButton>
                </div>
            </FormFieldSet>
        </form>
    </ValidationObserver>
</template>

<script>
import { WorkspaceStoreNamespacedTypes } from "@/store/modules/workspace";

import { getProperty } from "@/utils/object";

import { ValidationObserver } from "vee-validate";
import Alert from "@/components/ui/Alert";
import FormInput from "@/components/forms/fields/FormInput";
import FormFieldSet from "@/components/forms/fields/FormFieldSet";
import FormToggle from "@/components/forms/fields/FormToggle";
import FormButton from "@/components/forms/fields/FormButton";
import FormFieldOutline from "@/components/forms/fields/FormFieldOutline";

export default {
    name: "threatManagementSettingsForm",
    components: {
        FormFieldOutline,
        FormButton,
        FormToggle,
        FormFieldSet,
        FormInput,
        Alert,
        ValidationObserver,
    },
    data: () => ({
        formLoading: false,
        inboundEmail: "",
        form: {
            is_enabled: false,
            enable_notifications: false,
            alias_email: null,
            enable_forwarding: false,
            forwarding_email: null,
            enable_third_party_submission: false,
        },
    }),
    computed: {
        /**
         * @type {Object}
         */
        error() {
            return this.$store.getters[
                WorkspaceStoreNamespacedTypes.getters.WORKSPACE_ERROR
            ]();
        },

        /**
         * @type {Object}
         */
        workspace() {
            return this.$store.getters[
                WorkspaceStoreNamespacedTypes.getters.WORKSPACE
            ]();
        },
    },
    methods: {
        /**
         * Sends authentication credentials to the server.
         */
        updateSettings() {
            this.formLoading = true;
            this.$store
                .dispatch(
                    WorkspaceStoreNamespacedTypes.actions
                        .UPDATE_THREAT_MANAGEMENT_SETTINGS,
                    this.form
                )
                .then(() => {
                    this.formLoading = false;
                    if (!this.error) {
                        this.$emit("saved");
                    }
                });
        },
    },
    mounted() {
        const inbound_email_token = getProperty(
            this.workspace,
            "threat_management_settings.inbound_email_token"
        );
        if (inbound_email_token) {
            this.inboundEmail = `${inbound_email_token}@flag.moxso.com`;
        }

        this.form = {
            is_enabled: getProperty(
                this.workspace,
                "threat_management_settings.is_enabled",
                false
            ),
            enable_notifications: getProperty(
                this.workspace,
                "threat_management_settings.enable_notifications",
                false
            ),
            alias_email: getProperty(
                this.workspace,
                "threat_management_settings.alias_email"
            ),
            enable_forwarding: getProperty(
                this.workspace,
                "threat_management_settings.enable_forwarding",
                false
            ),
            forwarding_email: getProperty(
                this.workspace,
                "threat_management_settings.forwarding_email"
            ),
            enable_third_party_submission: getProperty(
                this.workspace,
                "threat_management_settings.enable_third_party_submission",
                false
            ),
        };
    },
    beforeCreate() {
        this.$store.commit(
            WorkspaceStoreNamespacedTypes.mutations.SET_WORKSPACE_ERROR,
            null
        );
    },
};
</script>
