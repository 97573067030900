/**
 * Automation step mixin
 */
import { AutomationStoreNamespacedTypes } from "@/store/modules/automation";

import { getProperty } from "@/utils/object";

import { ValidationObserver } from "vee-validate";
import FormFieldSet from "@/components/forms/fields/FormFieldSet";
import FormButton, {
    ButtonVariants,
} from "@/components/forms/fields/FormButton";

export default {
    components: {
        ValidationObserver,
        FormFieldSet,
        FormButton,
    },
    props: {
        id: {
            type: String,
            default: "",
        },
        index: {
            type: Number,
            default: 0,
        },
    },
    data: () => ({
        ButtonVariants,
        loading: false,
    }),
    computed: {
        action() {
            return this.$store.getters[
                AutomationStoreNamespacedTypes.getters.ACTION
            ](this.id);
        },
        key() {
            return getProperty(this.action, "key", null);
        },
        units() {
            return [
                {
                    title: this.$tc(
                        "automation.flow.actions.wait_units.days",
                        this.form.length
                    ),
                    value: "days",
                },
                {
                    title: this.$tc(
                        "automation.flow.actions.wait_units.weeks",
                        this.form.length
                    ),
                    value: "weeks",
                },
                {
                    title: this.$tc(
                        "automation.flow.actions.wait_units.months",
                        this.form.length
                    ),
                    value: "months",
                },
            ];
        },
    },
    methods: {
        saveAction() {
            this.$emit("save-action", this.id, this.key, this.index, this.form);
        },
    },
};
