<template>
    <div
        class="fixed flex h-screen w-full flex-grow flex-col items-center justify-center gap-6"
    >
        <Logo class="size-14" />
        <div>
            <Alert :error="error" />
        </div>
        <LoadingSpinner v-if="loading" />
        <div v-else-if="success" class="flex flex-col gap-4">
            <div
                class="flex flex-col rounded bg-green-50 p-4 text-slate-800 ring-1 ring-slate-200"
            >
                {{ $t("validate_domain.success") }}
                <span v-if="isLoggedIn">
                    {{ $t("validate_domain.redirecting") }}
                    <span v-for="x in 3" :key="x" class="load_dot">.</span>
                </span>
            </div>
            <FormButton
                to="/"
                :variant="ButtonVariants.PRIMARY"
                v-if="!isLoggedIn"
            >
                {{ $t("login.text") }}
            </FormButton>
        </div>
    </div>
</template>

<script>
import { AuthStoreNamespacedTypes } from "@/store/modules/auth";
import LoadingSpinner from "@/components/ui/LoadingSpinner";
import { getProperty } from "@/utils/object";
import Alert from "@/components/ui/Alert";
import { redirectTo } from "@/router";
import FormButton, {
    ButtonVariants,
} from "@/components/forms/fields/FormButton";
import Logo from "@/components/ui/Logo";
import { postPublicData } from "@/api";

export default {
    name: "ValidateDomainPage",
    components: { FormButton, LoadingSpinner, Alert, Logo },
    data: () => ({
        loading: false,
        success: false,
        ButtonVariants,
        error: null,
    }),
    computed: {
        isLoggedIn() {
            return this.$store.getters[
                AuthStoreNamespacedTypes.getters.IS_LOGGED_IN
            ]();
        },
        expires() {
            return getProperty(this.$route.query, "expires", null);
        },
        signature() {
            return getProperty(this.$route.query, "signature", null);
        },
        id() {
            return getProperty(this.$route.params, "id", null);
        },
    },
    methods: {
        validateDomain() {
            this.loading = true;
            this.success = false;
            postPublicData("/validate/domain", {
                expires: this.expires,
                signature: this.signature,
                id: this.id,
            })
                .then(() => {
                    this.success = true;
                    if (this.isLoggedIn && !this.error) {
                        redirectTo("settings.domains");
                    }
                })
                .catch((errors) => {
                    this.error = errors;
                })
                .finally(() => {
                    this.loading = false;
                });
        },
    },
    created() {
        this.validateDomain();
    },
};
</script>
