<template>
    <ContentLoading :loading="loadingCampaign">
        <Alert :error="error" />
        <ValidationObserver ref="campaignUpdateForm" v-slot="{ passes }">
            <form @submit.prevent="passes(updateCampaign)">
                <FormFieldSet>
                    <FormInput
                        v-model="form.name"
                        rules="required"
                        name="name"
                    />
                    <FormRadioGroup
                        v-model="form.delivery"
                        :options="deliveryOptions"
                        :inline="true"
                        rules="required"
                        name="delivery"
                    />
                    <FormSelect v-model="form.group_id" name="audience">
                        <option
                            v-for="group in rows"
                            :key="group.id"
                            :value="group.id"
                        >
                            {{ group.name }}
                        </option>
                    </FormSelect>
                    <FormToggle
                        v-model="has_token"
                        type="text"
                        name="token_header"
                    />
                </FormFieldSet>
                <FormFieldSet v-if="has_token">
                    <FormInput v-model="form.token" name="token" />
                    <small
                        class="col-span-6 block w-full font-medium text-slate-600"
                    >
                        {{ $t("simulations.create.configuration.token") }}

                        <span
                            class="inline-block rounded border border-slate-400 bg-white p-0.5 font-semibold text-slate-700"
                        >
                            X-MOXSO-CAMPAIGN-TOKEN
                        </span>
                        {{ $t("simulations.create.configuration.token_text") }}
                    </small>
                </FormFieldSet>
                <FormFieldSet>
                    <div class="flex justify-end">
                        <FormButton type="submit" :loading="loading">
                            {{ $t("forms.buttons.save_settings") }}
                        </FormButton>
                    </div>
                </FormFieldSet>
            </form>
        </ValidationObserver>
    </ContentLoading>
</template>

<script>
import { CampaignStoreNamespacedTypes } from "@/store/modules/campaign";
import { TeamStoreNamespacedTypes } from "@/store/modules/team";

import { ValidationObserver } from "vee-validate";
import { IconEnums } from "@/utils/icons";
import { getProperty } from "@/utils/object";
import FormRadioGroup from "@/components/forms/fields/FormRadioGroup";
import FormFieldSet from "@/components/forms/fields/FormFieldSet";
import FormInput from "@/components/forms/fields/FormInput";
import FormSelect from "@/components/forms/fields/FormSelect";
import FormButton from "@/components/forms/fields/FormButton";
import Alert from "@/components/ui/Alert";
import ContentLoading from "@/components/ui/ContentLoading";
import FormToggle from "@/components/forms/fields/FormToggle";

export default {
    components: {
        FormToggle,
        ContentLoading,
        Alert,
        FormButton,
        FormSelect,
        FormInput,
        FormFieldSet,
        FormRadioGroup,
        ValidationObserver,
    },
    data: () => ({
        loading: false,
        has_token: false,
        form: {
            name: null,
            group_id: null,
            delivery: "one_off",
            token: null,
        },
    }),
    computed: {
        /**
         * @type {Array}
         */
        campaign() {
            return this.$store.getters[
                CampaignStoreNamespacedTypes.getters.CAMPAIGN
            ]();
        },

        /**
         * @type {Boolean}
         */
        loadingCampaign() {
            return this.$store.getters[
                CampaignStoreNamespacedTypes.getters.CAMPAIGNS_LOADING
            ]();
        },

        /**
         * @type {Object}
         */
        error() {
            return this.$store.getters[
                CampaignStoreNamespacedTypes.getters.CAMPAIGNS_ERROR
            ]();
        },

        /**
         * @type {Array}
         */
        deliveryOptions() {
            return [
                {
                    icon: IconEnums.GROUP,
                    title: this.$t("forms.options.delivery.one_off.title"),
                    text: this.$t("forms.options.delivery.one_off.text"),
                    value: "one_off",
                },
                {
                    icon: IconEnums.SIMULATION,
                    title: this.$t("forms.options.delivery.recurring.title"),
                    text: this.$t("forms.options.delivery.recurring.text"),
                    value: "recurring",
                },
            ];
        },

        /**
         * @type {Array}
         */
        groups() {
            return this.$store.getters[
                TeamStoreNamespacedTypes.getters.GROUPS
            ]();
        },

        /**
         * @type {Array}
         */
        rows() {
            return getProperty(this.groups, "data", []);
        },
    },
    methods: {
        getProperty,

        /**
         * Sends authentication credentials to the server.
         */
        updateCampaign() {
            this.loading = true;
            this.$store
                .dispatch(
                    CampaignStoreNamespacedTypes.actions.UPDATE_CAMPAIGN,
                    { campaign: this.$route.params.id, form: this.form }
                )
                .then(() => {
                    this.loading = false;
                    if (!this.error) {
                        this.$emit("saved");
                    }
                });
        },
    },
    created() {
        let token = this.getProperty(this.campaign, "token", null);
        this.form = {
            name: getProperty(this.campaign, "name", null),
            group_id: getProperty(this.campaign, "group_id", null),
            delivery: getProperty(this.campaign, "delivery", "one_off"),
            token: token,
        };

        if (token) {
            this.has_token = true;
        }

        this.$store.dispatch(TeamStoreNamespacedTypes.actions.GET_ALL_GROUPS);
    },
};
</script>
