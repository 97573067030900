<template>
    <TwoCol>
        <div slot="content">
            <SlideOver
                :active="showEmployeePrompt"
                @close="showEmployeePrompt = false"
                :gutter="false"
            >
                <h2
                    slot="title"
                    class="text-base font-semibold leading-6 text-slate-900"
                >
                    {{ $t("employees.preview.title") }}
                </h2>
                <template v-slot:content>
                    <ParticipationPreview :employee="employee" />
                </template>
            </SlideOver>
            <Heading
                :title="$t('training.participation.heading').toString()"
                :text="$t('training.participation.text').toString()"
                :loading="loading"
                :returnTo="{ name: 'training.index' }"
            />
            <div class="card">
                <FilterLoading :loading="loading">
                    <SelectFilter v-model="status" name="status">
                        <option value="all">
                            {{ $t("employees.filters.status.all") }}
                        </option>
                        <option value="active">
                            {{ $t("employees.filters.status.active") }}
                        </option>
                        <option value="inactive">
                            {{ $t("employees.filters.status.inactive") }}
                        </option>
                    </SelectFilter>
                </FilterLoading>
                <ParticipationTable
                    :rows="rows"
                    :meta="meta"
                    :loading="participationLoading"
                    :error="participationError"
                    @refresh="handleRefresh"
                    @emit="handleOpen"
                />
            </div>
        </div>
        <div slot="sidebar">
            <Heading
                :title="$t('training.lesson.heading').toString()"
                :text="$t('training.lesson.text').toString()"
            />
            <ContentLoading :loading="loading">
                <div class="card relative flex flex-col overflow-hidden">
                    <div
                        v-if="image"
                        class="aspect-h-2 aspect-w-3 border-b bg-slate-200 sm:aspect-none group-hover:opacity-75 sm:h-44"
                    >
                        <img
                            :src="image"
                            alt="Cover image"
                            class="h-full w-full object-cover object-center sm:h-full sm:w-full"
                        />
                    </div>
                    <div class="flex flex-1 flex-col space-y-2 p-4">
                        <Alert :error="error" />
                        <h3 class="text-sm font-medium text-slate-900">
                            {{ title }}
                        </h3>
                        <p v-if="description" class="text-sm text-slate-500">
                            {{ description }}
                        </p>
                        <div class="flex flex-1 flex-col justify-end">
                            <p class="text-sm italic text-slate-800">
                                {{ type }}
                            </p>
                        </div>
                        <FormButton
                            v-if="isManager"
                            :loading="exportLoading"
                            :variant="ButtonVariants.SECONDARY"
                            :icon="IconEnums.DOWNLOAD"
                            @click="exportList"
                        >
                            Download CSV
                        </FormButton>
                    </div>
                </div>
            </ContentLoading>
        </div>
    </TwoCol>
</template>

<script>
import { AuthStoreNamespacedTypes } from "@/store/modules/auth";
import { TrainingStoreNamespacedTypes } from "@/store/modules/training";
import { LocaleStoreNamespacedTypes } from "@/store/modules/locale";

import { getProperty } from "@/utils/object";

import Heading from "@/components/layout/Heading";
import TwoCol from "@/components/layout/TwoCol";
import ParticipationTable from "@/components/display/training/ParticipationTable";
import ContentLoading from "@/components/ui/ContentLoading";
import FormButton, {
    ButtonVariants,
} from "@/components/forms/fields/FormButton";
import { IconEnums } from "@/utils/icons";
import Alert from "@/components/ui/Alert";
import SlideOver from "@/components/ui/SlideOver";
import ParticipationPreview from "@/components/display/training/ParticipationPreview";
import FilterLoading from "@/components/tables/FilterLoading";
import SelectFilter from "@/components/forms/fields/SelectFilter";

export default {
    name: "LessonSingle",
    components: {
        SelectFilter,
        FilterLoading,
        ParticipationPreview,
        SlideOver,
        Alert,
        ContentLoading,
        ParticipationTable,
        TwoCol,
        Heading,
        FormButton,
    },
    data: () => ({
        ButtonVariants,
        IconEnums,
        employee: null,
        showEmployeePrompt: false,
        exportLoading: false,
        status: "active",
    }),
    computed: {
        /**
         * @type {string}
         */
        type() {
            return this.$route.params.type;
        },

        /**
         * @type {string}
         */
        id() {
            return this.$route.params.id;
        },

        /**
         * Current locale.
         * @type {String}
         */
        locale() {
            return this.$store.getters[
                LocaleStoreNamespacedTypes.getters.LOCALE
            ]();
        },

        /**
         * The user model
         * @type {Boolean}
         */
        isManager() {
            return this.$store.getters[
                AuthStoreNamespacedTypes.getters.IS_MANAGER
            ]();
        },

        /**
         * @type {Boolean}
         */
        loading() {
            return this.$store.getters[
                TrainingStoreNamespacedTypes.getters.LESSON_LOADING
            ]();
        },

        /**
         * @type {Object}
         */
        error() {
            return this.$store.getters[
                TrainingStoreNamespacedTypes.getters.LESSON_ERROR
            ]();
        },

        /**
         * @type {Object}
         */
        lesson() {
            return this.$store.getters[
                TrainingStoreNamespacedTypes.getters.LESSON
            ]();
        },

        /**
         * @type {string}
         */
        image() {
            return getProperty(this.lesson, "image");
        },

        /**
         * @type {string}
         */
        title() {
            return getProperty(this.lesson, "title");
        },

        /**
         * @type {string}
         */
        description() {
            return getProperty(this.lesson, "description");
        },

        /**
         * @type {Boolean}
         */
        participationLoading() {
            return this.$store.getters[
                TrainingStoreNamespacedTypes.getters.PARTICIPATION_LOADING
            ]();
        },

        /**
         * @type {Object}
         */
        participationError() {
            return this.$store.getters[
                TrainingStoreNamespacedTypes.getters.PARTICIPATION_ERROR
            ]();
        },

        /**
         * @type {Object}
         */
        participation() {
            return this.$store.getters[
                TrainingStoreNamespacedTypes.getters.PARTICIPATION
            ]();
        },

        /**
         * @type {Array}
         */
        rows() {
            return getProperty(this.participation, "data", []);
        },

        /**
         * @type {Object}
         */
        meta() {
            return getProperty(this.participation, "meta", null);
        },
    },
    watch: {
        status() {
            this.handleRefresh();
        },
        locale() {
            this.getLesson();
        },
    },
    methods: {
        getLesson() {
            this.$store.dispatch(
                TrainingStoreNamespacedTypes.actions.GET_LESSON,
                {
                    type: this.type,
                    id: this.id,
                }
            );
        },
        handleRefresh(payload = {}) {
            const data = {
                type: this.type,
                id: this.id,
                query: {
                    status: this.status,
                    locale: this.locale,
                    ...payload,
                },
            };

            this.$store.dispatch(
                TrainingStoreNamespacedTypes.actions.GET_PARTICIPATION,
                data
            );
        },
        handleOpen(employee) {
            this.employee = employee;
            this.showEmployeePrompt = true;
        },
        exportList() {
            this.exportLoading = true;
            this.$store
                .dispatch(TrainingStoreNamespacedTypes.actions.EXPORT_LIST, {
                    type: this.type,
                    id: this.id,
                })
                .then((response) => {
                    this.exportLoading = false;
                    const url = getProperty(response.data, "url");
                    if (url) {
                        window.location.href = url;
                    }
                });
        },
    },
    mounted() {
        this.$store.commit(TrainingStoreNamespacedTypes.mutations.SET_LESSON, {
            title: null,
            description: null,
            image: null,
        });
        this.$store.commit(
            TrainingStoreNamespacedTypes.mutations.SET_PARTICIPATION,
            {
                data: [],
                meta: {
                    current_page: 1,
                    from: 0,
                    last_page: 1,
                    per_page: 10,
                    to: 10,
                    total: 0,
                },
            }
        );

        this.getLesson();
        this.handleRefresh();
    },
};
</script>
