<template>
    <g
        fill="none"
        stroke="currentColor"
        stroke-linecap="round"
        stroke-linejoin="round"
        stroke-width="2"
    >
        <path
            d="M10.2 4.12c.632-.618.947-.927 1.31-1.043.319-.103.661-.103.98 0 .363.116.678.425 1.31 1.043l.303.297c.273.267.409.4.567.497.14.085.293.148.453.187.18.044.37.046.752.05l.425.005c.883.009 1.325.013 1.663.188.298.153.54.395.694.693.174.338.178.78.187 1.663l.005.425c.004.382.006.573.05.752.039.16.102.313.187.453.096.158.23.295.497.567l.297.304c.618.63.927.946 1.043 1.309.103.319.103.661 0 .98-.116.363-.425.678-1.043 1.31l-.297.303c-.267.273-.4.409-.497.567-.085.14-.148.293-.187.453-.044.18-.046.37-.05.752l-.005.425c-.009.883-.013 1.325-.188 1.663-.153.298-.395.54-.693.694-.338.174-.78.178-1.663.187l-.425.005c-.382.004-.573.006-.752.05-.16.039-.313.102-.453.187-.158.096-.294.23-.567.497l-.304.297c-.63.618-.946.927-1.309 1.043a1.608 1.608 0 0 1-.98 0c-.363-.116-.678-.425-1.31-1.043l-.303-.297c-.272-.267-.409-.4-.567-.497a1.608 1.608 0 0 0-.453-.187c-.18-.044-.37-.046-.752-.05l-.425-.005c-.883-.009-1.325-.013-1.663-.188a1.607 1.607 0 0 1-.693-.693c-.175-.338-.18-.78-.188-1.663l-.005-.425c-.004-.382-.006-.573-.05-.752a1.606 1.606 0 0 0-.187-.453c-.096-.158-.23-.294-.497-.567l-.297-.304c-.618-.63-.927-.946-1.043-1.309a1.607 1.607 0 0 1 0-.98c.116-.363.425-.678 1.043-1.31l.297-.303c.267-.272.4-.409.497-.567.085-.14.148-.293.187-.453.044-.18.046-.37.05-.752l.005-.425c.009-.883.013-1.325.188-1.663.153-.298.395-.54.693-.693.338-.175.78-.18 1.663-.188l.425-.005c.382-.004.573-.006.752-.05.16-.039.313-.102.453-.187.158-.096.295-.23.567-.497l.304-.297Z"
        />
        <path
            d="M10.991 12c0-.552.457-1 1.01-1 .552 0 1.008.448 1.008 1s-.457 1-1.009 1c-.552 0-1.009-.448-1.009-1Z"
        />
    </g>
</template>

<script>
import IconSvgBase from "./IconSvgBase";

export default {
    extends: IconSvgBase,
    name: "IconSettings",
};
</script>
