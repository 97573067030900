<template>
    <div class="absolute inset-0 z-10 bg-slate-50/60">
        <div class="flex h-full w-full items-center justify-center">
            <div
                class="flex size-10 items-center justify-center rounded-full bg-white shadow-sm ring-1 ring-slate-200"
            >
                <Icon :icon="IconEnums.LOCK" class="size-5 text-slate-700" />
            </div>
        </div>
    </div>
</template>
<script>
import Icon from "@/components/icons/BaseIcon";
import { IconEnums } from "@/utils/icons";

export default {
    name: "LockedFeatureOverlay",
    components: { Icon },
    data: () => ({
        IconEnums,
    }),
};
</script>
