<template>
    <div class="relative flex h-full min-h-screen w-full flex-col bg-slate-100">
        <div
            class="flex h-full w-full flex-grow flex-col items-center justify-center"
        >
            <div class="mb-4 rounded-full bg-white p-2 shadow-xl">
                <LoadingSpinner />
            </div>
            <p class="font-medium text-slate-700">
                Preparing session
                <span v-for="x in 3" :key="x" class="load_dot">.</span>
            </p>
        </div>
    </div>
</template>

<script>
import { WorkspaceStoreNamespacedTypes } from "@/store/modules/workspace";
import { AuthStoreNamespacedTypes } from "@/store/modules/auth";

import { getProperty } from "@/utils/object";
import { redirectTo } from "@/router";

import LoadingSpinner from "@/components/ui/LoadingSpinner";

export default {
    name: "ImpersonatePage",
    components: { LoadingSpinner },
    methods: {
        checkCurrentState() {
            const token = getProperty(this.$route.query, "token", null);
            const wid = getProperty(this.$route.query, "wid", null);

            if (token && wid) {
                this.$store.dispatch(
                    WorkspaceStoreNamespacedTypes.actions.CLEAR_SESSION
                );

                this.$store
                    .dispatch(AuthStoreNamespacedTypes.actions.IMPERSONATE, {
                        token,
                        wid,
                    })
                    .then(() => {
                        this.$store.dispatch(
                            WorkspaceStoreNamespacedTypes.actions.GET_WORKSPACE,
                            {
                                force: true,
                            }
                        );
                    });
            } else {
                redirectTo("login");
            }
        },
    },
    created() {
        this.checkCurrentState();
    },
};
</script>
