<template>
    <Page
        :heading="$t('forgot_password.heading').toString()"
        :text="$t('forgot_password.text').toString()"
    >
        <div v-if="sent" class="rounded-md bg-green-50 p-4">
            <div class="flex">
                <div class="flex-shrink-0">
                    <svg
                        class="size-5 text-green-400"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 20 20"
                        fill="currentColor"
                        aria-hidden="true"
                    >
                        <path
                            fill-rule="evenodd"
                            d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                            clip-rule="evenodd"
                        />
                    </svg>
                </div>
                <div class="ml-3">
                    <h3 class="text-sm font-medium text-green-800">
                        {{ $t("forgot_password.instructions.heading") }}
                    </h3>
                    <div class="mt-2 text-sm text-green-700">
                        <p>
                            {{ $t("forgot_password.instructions.text") }}
                        </p>
                    </div>
                </div>
            </div>
        </div>
        <ForgotPasswordForm v-else @sent="handleSent" />
        <div slot="footer">
            <p class="max-w mt-8 text-center text-slate-600">
                {{ $t("forgot_password.login.text") }}
                <router-link
                    :to="{ name: 'login' }"
                    class="text-base font-medium text-indigo-600"
                    >{{ $t("forgot_password.login.link") }}</router-link
                >
            </p>
        </div>
    </Page>
</template>

<script>
import Page from "@/components/layout/Page";
import ForgotPasswordForm from "@/components/forms/ForgotPasswordForm";

export default {
    name: "ForgotPassword",
    components: { Page, ForgotPasswordForm },
    data: () => ({
        sent: false,
    }),
    methods: {
        handleSent() {
            this.sent = true;
        },
    },
};
</script>
