<template>
    <DataTable
        :headers="headers"
        :rows="rows"
        :meta="meta"
        :loading="loading"
        :error="error"
        resource="campaign.single"
        @update="refresh"
    >
        <template v-slot:name="{ item }">
            <div class="flex flex-col">
                <div class="mb-2 text-sm font-medium text-slate-900">
                    {{ item.name }}
                </div>
                <div class="text-sm text-slate-600">
                    {{ formatDateTime(item.created_at) }}
                </div>
            </div>
        </template>
        <template v-slot:status="{ item }">
            <StatusBadge :status="item.status" />
        </template>
        <template v-slot:group="{ item }">
            <div v-if="item.group" class="flex items-center">
                <span
                    class="inline-flex h-10 w-10 items-center justify-center rounded-full bg-indigo-500"
                >
                    <span class="text-sm font-medium leading-none text-white">{{
                        item.group.name | initials
                    }}</span>
                </span>
                <div class="ml-4">
                    <div class="text-sm font-medium text-slate-900">
                        {{ item.group.name }}
                    </div>
                    <div class="text-sm font-medium text-slate-500">
                        {{
                            $tc(
                                "groups.members_count",
                                item.group.employee_active_count
                            )
                        }}
                    </div>
                </div>
            </div>
        </template>
    </DataTable>
</template>

<script>
import { formatDateTime } from "@/utils/date";
import { initials } from "@/utils/filters";

import DataTableMixin from "@/components/tables/DataTableMixin";
import StatusBadge from "@/components/display/campaign/StatusBadge";

export default {
    mixins: [DataTableMixin],
    components: {
        StatusBadge,
    },
    filters: {
        initials,
    },
    data: () => ({
        headers: [
            {
                title: "name",
                key: "name",
                sortable: true,
            },
            {
                title: "status",
                key: "status",
                sortable: true,
            },
            {
                title: "sent_to_count",
                key: "sent_to_count",
                sortable: true,
            },
            {
                title: "unique_open_count",
                key: "unique_open_count",
                sortable: true,
            },
            {
                title: "unique_click_count",
                key: "unique_click_count",
                sortable: true,
            },
            {
                title: "audience",
                key: "group",
                sortable: false,
            },
        ],
    }),
    methods: {
        formatDateTime,
    },
};
</script>
