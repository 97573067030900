<template>
    <div class="flex flex-col p-4">
        <div class="mb-4 flex w-full flex-row justify-between">
            <h3
                class="flex-1 font-medium text-slate-500"
                v-text="$t('statistics.benchmarks.title').toString()"
            ></h3>
            <div>
                <LoadingSpinner v-if="loading" class="size-5" />
                <div
                    v-else
                    class="flex h-6 w-6 items-center justify-center rounded-full ring-1 ring-inset"
                    :class="badgeClass"
                >
                    <Icon :icon="badgeIcon" class="h-3 w-3" />
                </div>
            </div>
        </div>
        <div class="flex flex-grow flex-col justify-between">
            <div class="grid grid-cols-2 gap-4">
                <div class="rounded-lg bg-slate-100 px-4 py-3">
                    <p
                        class="text-sm text-slate-800"
                        v-text="$t('statistics.benchmarks.score').toString()"
                    ></p>
                    <p
                        class="text-lg font-semibold text-slate-800"
                        v-text="value + '%'"
                    ></p>
                </div>
                <div class="rounded-lg bg-slate-100 px-4 py-3">
                    <p
                        class="text-sm text-slate-800"
                        v-text="$t('statistics.benchmarks.average').toString()"
                    ></p>
                    <p
                        class="text-lg font-semibold text-slate-800"
                        v-text="network + '%'"
                    ></p>
                </div>
            </div>
            <div class="flex items-center space-x-2 pt-4 text-slate-400">
                <div class="h-6 w-6">
                    <Icon :icon="IconEnums.INFO" class="h-6 w-6" />
                </div>
                <span
                    class="text-xs leading-4"
                    v-text="
                        valueIsPositive
                            ? $t('statistics.benchmarks.text_higher', {
                                  pct: valueRelativeToNetwork,
                              }).toString()
                            : $t('statistics.benchmarks.text_lower', {
                                  pct: valueRelativeToNetwork * -1,
                              }).toString()
                    "
                ></span>
            </div>
        </div>
    </div>
</template>

<script>
import { formatNumber } from "@/utils/number";
import { IconEnums } from "@/utils/icons";
import LoadingSpinner from "@/components/ui/LoadingSpinner";
import Icon from "@/components/icons/BaseIcon";

export default {
    name: "BenchmarkCard",
    components: {
        LoadingSpinner,
        Icon,
    },
    props: {
        loading: {
            type: Boolean,
            required: true,
        },
        value: {
            type: Number,
            required: true,
        },
        network: {
            type: Number,
            required: true,
        },
    },
    data: () => ({
        IconEnums,
    }),
    computed: {
        /**
         * The value of the benchmark relative to the network average
         * @returns {string|number}
         */
        valueRelativeToNetwork() {
            if (this.network === 0) {
                return 0;
            }

            return formatNumber(
                Math.round(((this.value - this.network) / this.network) * 100)
            );
        },

        /**
         * Whether the value is positive or negative
         * @returns {boolean}
         */
        valueIsPositive() {
            return this.valueRelativeToNetwork > 0;
        },

        /**
         * The total number of quizzes taken
         * @returns {string}
         */
        badgeClass() {
            return this.valueIsPositive
                ? "bg-emerald-50/50 text-success-normal ring-emerald-500/20"
                : "bg-rose-50/50 text-error-normal ring-rose-500/20";
        },

        /**
         * The icon to display in the badge
         * @returns {Object}
         */
        badgeIcon() {
            return this.valueIsPositive ? IconEnums.SIGNAL : IconEnums.ALERT;
        },
    },
};
</script>
