<template>
    <path
        fill="none"
        stroke="currentColor"
        stroke-linecap="round"
        stroke-linejoin="round"
        stroke-width="2"
        d="M4 12h8m-8 6V6m8 12V6m7 12v-8c-.962.236-1.698.898-2.134 1.771M19 18h-2.5m2.5 0h2.5"
    />
</template>

<script>
import IconSvgBase from "@/components/icons/IconSvgBase";

export default {
    extends: IconSvgBase,
    name: "IconHeading",
};
</script>
