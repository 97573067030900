<template>
    <WorkspaceSettingsPage>
        <div class="card">
            <CardHeading
                :title="$t('settings.threat_management.heading').toString()"
                :text="$t('settings.threat_management.text').toString()"
            />
            <div class="p-4">
                <ThreatManagementSettingsForm @saved="handleSaved" />
            </div>
        </div>
    </WorkspaceSettingsPage>
</template>

<script>
import Bus from "@/bus";
import WorkspaceSettingsPage from "@/components/layout/WorkspaceSettingsPage";
import ThreatManagementSettingsForm from "@/components/forms/ThreatManagementSettingsForm";
import CardHeading from "@/components/ui/CardHeading";

export default {
    name: "ThreatsSettings",
    components: {
        CardHeading,
        ThreatManagementSettingsForm,
        WorkspaceSettingsPage,
    },
    methods: {
        handleSaved() {
            Bus.$emit("flash-message", {
                text: this.$t("settings.saved"),
                type: "success",
            });
        },
    },
};
</script>
