<template>
    <Badge
        :variant="variant"
        :text="$t('integrations.' + status).toString()"
        :loading="loading"
    />
</template>

<script>
import Badge, { BadgeVariants } from "@/components/shared/Badge";

export default {
    components: { Badge },
    props: {
        status: {
            type: String,
            default: undefined,
        },
    },
    data: () => ({
        BadgeVariants,
    }),
    computed: {
        loading() {
            return this.status === "pending";
        },
        variant() {
            if (this.status === "completed") {
                return BadgeVariants.SUCCESS;
            }

            if (this.loading) {
                return BadgeVariants.WARNING;
            }

            return BadgeVariants.ERROR;
        },
    },
};
</script>
