<template>
    <ValidationProvider
        tag="div"
        class="form-field form-field-textarea"
        :vid="vid"
        :rules="rules"
        :name="name || label"
        v-slot="{ errors, ariaInput, ariaMsg }"
    >
        <label
            v-if="label"
            class="pointer-events-none mb-2 block text-sm font-medium text-slate-900"
            @click="$refs.input.focus()"
            :for="id || name"
        >
            {{ label || name }}
        </label>
        <textarea
            class="min-w-form-field block w-full rounded-md border-slate-300 px-2 py-2 text-base text-slate-800 transition-colors placeholder:text-slate-500 hover:bg-slate-50 focus:border-indigo-500 focus:bg-white focus:outline-none focus:ring-2 focus:ring-indigo-500/10 focus:ring-offset-2 focus:ring-offset-slate-50 sm:text-sm"
            :class="[{ 'is-invalid': errors[0] }, { 'bg-slate-50': disabled }]"
            :id="id || name"
            :placeholder="placeholder"
            ref="input"
            v-model="innerValue"
            v-bind="ariaInput"
            autocomplete="off"
            :disabled="disabled"
            rows="4"
        ></textarea>
        <span
            class="mt-3 text-sm text-red-600"
            v-bind="ariaMsg"
            v-if="errors[0]"
            >{{ errors[0] }}</span
        >
    </ValidationProvider>
</template>

<script>
import FieldMixin from "./FieldMixin";

export default {
    name: "FormTextarea",
    mixins: [FieldMixin],
    props: {
        placeholder: {
            type: String,
            default: "",
        },
    },
};
</script>
