<template>
    <div class="funnel-chart-item group relative flex w-full flex-col">
        <div class="relative flex w-full flex-full justify-end px-1">
            <div class="absolute inset-0 flex flex-col justify-between py-px">
                <div class="relative flex w-full justify-end">
                    <div class="flex-1 border-b border-slate-300"></div>
                </div>
                <div class="relative flex w-full justify-end">
                    <div class="flex-1 border-b border-slate-300"></div>
                </div>
                <div class="relative flex w-full justify-end">
                    <div class="flex-1 border-b border-slate-300"></div>
                </div>
            </div>
            <div class="relative flex h-full min-w-full flex-col justify-end">
                <div
                    class="chart-item-value relative flex-full transition"
                    :style="{
                        flex: valueHeight + ' 1 0%',
                    }"
                >
                    <div
                        class="flex h-full animate-pulse rounded bg-slate-200"
                    ></div>
                </div>
            </div>
        </div>
        <div
            class="relative mt-4 h-6 w-full min-w-full border-b border-t border-slate-200 bg-slate-100"
            :class="[
                isFirst ? 'rounded-l-md border-l' : '',
                isLast ? 'rounded-r-md border-r' : '',
            ]"
        ></div>
    </div>
</template>

<script>
export default {
    name: "BarChartLoadingItem",
    props: {
        index: {
            type: Number,
            default: undefined,
        },
        value: {
            type: Number,
            default: 0,
        },
    },
    computed: {
        valueHeight() {
            return this.value / 21;
        },
        isFirst() {
            return this.index === 0;
        },
        isLast() {
            return this.index === 12;
        },
    },
};
</script>
