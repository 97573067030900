<template>
    <FormButton
        @click.native="sendReminder(item)"
        :variant="ButtonVariants.SECONDARY"
        :icon="IconEnums.REMINDER"
        :loading="loading"
    >
        {{ $t("training.deadlines.send_reminder") }}
    </FormButton>
</template>

<script>
import { TrainingStoreNamespacedTypes } from "@/store/modules/training";

import { getProperty } from "@/utils/object";

import { IconEnums } from "@/utils/icons";
import FormButton, {
    ButtonVariants,
} from "@/components/forms/fields/FormButton";
import Bus from "@/bus";

export default {
    name: "DeadlineReminderButton",
    components: {
        FormButton,
    },
    props: {
        item: {
            type: Object,
            required: true,
        },
    },
    data: () => ({
        ButtonVariants,
        IconEnums,
        loading: false,
    }),
    computed: {
        id() {
            return getProperty(this.item, "id");
        },
    },
    methods: {
        sendReminder() {
            this.loading = true;
            this.$store
                .dispatch(
                    TrainingStoreNamespacedTypes.actions.SEND_REMINDER,
                    this.id
                )
                .then(() => {
                    this.loading = false;
                    Bus.$emit("flash-message", {
                        text: this.$t(
                            "training.deadlines.reminder_sent"
                        ).toString(),
                        type: "success",
                    });
                });
        },
    },
};
</script>
