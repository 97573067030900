<template>
    <Page
        :heading="$t('reset_password.heading').toString()"
        :text="$t('reset_password.text').toString()"
    >
        <ResetPasswordForm @reset="handleReset" />
    </Page>
</template>

<script>
import Page from "@/components/layout/Page";
import ResetPasswordForm from "@/components/forms/ResetPasswordForm";
import Bus from "@/bus";
import { redirectTo } from "@/router";

export default {
    name: "ResetPassword",
    components: { Page, ResetPasswordForm },
    methods: {
        handleReset() {
            redirectTo("login");
            Bus.$emit("flash-message", {
                text: this.$t("reset_password.success_message"),
                type: "success",
            });
        },
    },
};
</script>
