import DataTable from "@/components/tables/DataTable";

/**
 * Data table mixin
 */
export default {
    components: {
        DataTable,
    },
    props: {
        rows: {
            type: Array,
            default: () => [],
        },
        meta: {
            type: Object,
            default: () => ({
                current_page: 1,
                from: 0,
                last_page: 1,
                per_page: 10,
                to: 10,
                total: 0,
            }),
        },
        loading: {
            type: Boolean,
            default: true,
        },
        error: {
            type: Object,
            default: () => {},
        },
    },
    methods: {
        refresh(payload) {
            this.$emit("refresh", payload);
        },
    },
};
