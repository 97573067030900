<template>
    <div class="badge flex">
        <div
            :class="[
                badgeClass,
                'flex items-center gap-1 rounded-full px-2 py-1.5 text-xs ring-1 ring-inset',
            ]"
        >
            <Icon
                v-if="icon"
                :icon="icon"
                class="h-3 w-3 flex-none fill-error-dark stroke-white"
            />
            <slot />
            <span class="text-xs font-medium leading-none">
                {{ text }}
                <span v-if="loading" class="-ml-0.5">
                    <span v-for="x in 3" :key="x" class="load_dot">.</span>
                </span>
            </span>
        </div>
    </div>
</template>

<script>
import Icon from "@/components/icons/BaseIcon";
import { IconEnums } from "@/utils/icons";

export const BadgeVariants = {
    DEFAULT: "default",
    SUCCESS: "success",
    WARNING: "warning",
    ERROR: "error",
    PRIMARY: "primary",
};

export default {
    name: "BadgeItem",
    components: { Icon },
    props: {
        variant: {
            type: String,
            default: BadgeVariants.DEFAULT,
            validator(type) {
                return Object.values(BadgeVariants).includes(type);
            },
        },
        text: {
            type: String,
            required: false,
        },
        icon: {
            type: Object,
            required: false,
            validator(IconEnum) {
                return Object.values(IconEnums).includes(IconEnum);
            },
        },
        loading: {
            type: Boolean,
            default: false,
        },
    },
    data: () => ({
        IconEnums,
    }),
    computed: {
        badgeClass() {
            if (this.variant === BadgeVariants.SUCCESS) {
                return "bg-emerald-50/50 text-success-dark ring-emerald-500/20";
            }

            if (this.variant === BadgeVariants.WARNING) {
                return "bg-yellow-50/50 text-warning-dark ring-yellow-500/20";
            }

            if (this.variant === BadgeVariants.ERROR) {
                return "bg-rose-50/50 text-error-dark ring-rose-500/20";
            }

            if (this.variant === BadgeVariants.PRIMARY) {
                return "bg-indigo-50/50 text-primary-dark ring-indigo-500/20";
            }

            return "bg-slate-50/50 text-slate-600 ring-slate-500/20";
        },
    },
};
</script>
