<template>
    <DataTable
        :headers="headers"
        :rows="rows"
        :loading="loading"
        :loadingRows="2"
        :error="error"
    >
        <template v-slot:name="{ item }">
            <span class="d-block h5">{{ item.domain }}</span>
        </template>
        <template v-slot:verified_at="{ item }">
            <Badge
                :variant="
                    item.verified_at
                        ? BadgeVariants.SUCCESS
                        : BadgeVariants.WARNING
                "
                :text="
                    item.verified_at
                        ? $t(`settings.verified`).toString()
                        : $t(`settings.verification_required`).toString()
                "
            />
        </template>
        <template v-slot:action="{ item }">
            <VerifyDomainForm v-if="!item.verified_at" :domain="item.id" />
            <div v-else class="flex flex-col items-end justify-end">
                <span class="block text-slate-500">{{
                    $t("settings.domains.verified_on")
                }}</span>
                <span>{{ formatDate(item.verified_at) }}</span>
            </div>
        </template>
    </DataTable>
</template>

<script>
import { formatDate } from "@/utils/date";

import VerifyDomainForm from "@/components/forms/VerifyDomainForm";
import DataTableMixin from "@/components/tables/DataTableMixin";
import Badge, { BadgeVariants } from "@/components/shared/Badge";

export default {
    name: "DomainTable",
    mixins: [DataTableMixin],
    components: {
        Badge,
        VerifyDomainForm,
    },
    data: () => ({
        BadgeVariants,
        headers: [
            {
                title: "domain",
                key: "domain",
                sortable: false,
            },
            {
                title: "status",
                key: "verified_at",
                sortable: false,
            },
            {
                title: "default",
                key: "action",
                sortable: false,
            },
        ],
    }),
    methods: {
        formatDate,
    },
};
</script>
