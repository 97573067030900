<template>
    <TwoCol>
        <div slot="content">
            <Prompt
                :active="showSettingsPrompt"
                @close="showSettingsPrompt = false"
                ><h3 slot="header">{{ $t("simulations.edit_title") }}</h3>
                <div slot="content">
                    <CampaignUpdateForm
                        @saved="handleSaved"
                        v-if="!loading"
                    /></div
            ></Prompt>
            <Prompt :active="showLaunchPrompt" @close="showLaunchPrompt = false"
                ><h3 slot="header">{{ $t("simulations.send_campaign") }}</h3>
                <div slot="content">
                    <div class="flex flex-col">
                        <div
                            class="mb-4 flex flex-col rounded-xl bg-slate-50 p-4"
                        >
                            <p
                                class="leading-6 text-slate-900"
                                v-html="
                                    $t('simulations.campaign_notice', {
                                        count: recipientCount,
                                    })
                                "
                            ></p>
                        </div>
                        <div class="flex flex-col">
                            <FormInput
                                v-model="sendToCount"
                                rules="required"
                                name="sent_to_count"
                                type="number"
                                class="mb-4"
                            />
                            <FormButton
                                :loading="campaignLoading"
                                @click.native="send"
                            >
                                {{ $t("simulations.send_campaign") }}
                            </FormButton>
                        </div>
                    </div>
                </div></Prompt
            >
            <SlideOver
                :active="showSimulationPreview"
                @close="showSimulationPreview = false"
                :gutter="false"
            >
                <h2
                    slot="title"
                    class="text-base font-semibold leading-6 text-slate-900"
                >
                    {{ $t("simulations.preview.title") }}
                </h2>
                <template v-slot:content>
                    <SimulationPreview
                        v-if="simulationPreview"
                        :simulation="simulationPreview"
                    />
                </template>
            </SlideOver>
            <Heading
                :title="campaignName"
                :returnTo="{ name: 'campaign.index' }"
            >
                <div v-if="isEditable && isManager" class="space-x-2">
                    <FormButton
                        @click="showSettingsPrompt = true"
                        :variant="ButtonVariants.OUTLINE"
                        >{{ $t("simulations.edit_button") }}</FormButton
                    >
                    <FormButton
                        :variant="ButtonVariants.OUTLINE"
                        :loading="testLoading"
                        @click="sendTest"
                        >{{ $t("simulations.send_test") }}</FormButton
                    >
                </div>
            </Heading>
            <div class="flex flex-1 flex-grow flex-col">
                <Alert :error="error" class="mb-4" />
                <div class="card relative mb-4 bg-slate-200">
                    <div
                        v-if="isEditable || loading"
                        class="z-1 absolute bottom-0 left-0 h-full w-full bg-gradient-to-t from-slate-50 opacity-80"
                    ></div>
                    <div
                        class="grid grid-cols-1 gap-px md:grid-cols-2 xl:grid-cols-3"
                    >
                        <ValueCard
                            :loading="loading"
                            :title="
                                $t(
                                    'statistics.simulations.count.title'
                                ).toString()
                            "
                            :text="
                                $t(
                                    'statistics.simulations.count.text'
                                ).toString()
                            "
                            :unit="
                                $t(
                                    'statistics.simulations.count.unit'
                                ).toString()
                            "
                            :value="count"
                            class="col-span-1 bg-white md:col-span-2 xl:col-span-1"
                        />
                        <ValueCard
                            :loading="loading"
                            :title="
                                $t(
                                    'statistics.simulations.open_rate.title'
                                ).toString()
                            "
                            :text="
                                $t(
                                    'statistics.simulations.open_rate.text'
                                ).toString()
                            "
                            :value="openRate"
                            :pct="true"
                            :lower-threshold="7000"
                            class="bg-white"
                        />
                        <ValueCard
                            :loading="loading"
                            :title="
                                $t(
                                    'statistics.simulations.click_rate.title'
                                ).toString()
                            "
                            :text="
                                $t(
                                    'statistics.simulations.click_rate.text'
                                ).toString()
                            "
                            :value="clickRate"
                            :pct="true"
                            :lower-threshold="500"
                            class="bg-white"
                        />
                    </div>
                </div>
                <div class="row mb-4 flex items-center justify-end">
                    <p class="mr-2 text-sm text-slate-500">Status</p>
                    <FormSelect v-model="status">
                        <option
                            v-for="status in statuses"
                            :key="status"
                            :value="status"
                        >
                            {{ $t(`simulations.filter.status.${status}`) }}
                        </option>
                    </FormSelect>
                </div>
                <div class="card">
                    <CampaignEventsTable
                        :rows="eventRows"
                        :meta="eventMeta"
                        :loading="loadingEvents"
                        @emit="showPreview"
                        @refresh="handleRefresh"
                    />
                </div>
            </div>
        </div>
        <div slot="sidebar">
            <Heading
                :title="$t('simulations.summary.title').toString()"
                :text="$t('simulations.summary.text').toString()"
            />
            <div class="flex flex-col">
                <div class="mb-4 flex flex-col rounded-xl bg-white shadow-sm">
                    <ContentLoading :loading="loading">
                        <div
                            class="flex flex-col divide-y divide-slate-100 border-b border-slate-100"
                        >
                            <ListItem
                                :title="
                                    $t('simulations.summary.status').toString()
                                "
                            >
                                <StatusBadge :status="campaignStatus" />
                            </ListItem>
                            <ListItem
                                :title="
                                    $t('simulations.summary.name').toString()
                                "
                                :text="campaignName"
                            />
                            <ListItem
                                :title="
                                    $t(
                                        'simulations.summary.delivery'
                                    ).toString()
                                "
                                :text="delivery"
                            />
                            <ListItem
                                :title="
                                    $t(
                                        'simulations.summary.audience'
                                    ).toString()
                                "
                                :text="audience"
                            />
                        </div>
                    </ContentLoading>
                    <div
                        v-if="isEditable || (isRecurring && !isEditable)"
                        class="flex flex-col p-4"
                    >
                        <FormButton
                            v-if="isEditable"
                            @click="showLaunchPrompt = true"
                        >
                            {{ $t("simulations.send_campaign") }}
                        </FormButton>
                        <ToggleCampaignStateButton
                            v-if="isRecurring && !isEditable"
                        />
                    </div>
                </div>
            </div>
        </div>
    </TwoCol>
</template>

<script>
import { AuthStoreNamespacedTypes } from "@/store/modules/auth";
import { CampaignStoreNamespacedTypes } from "@/store/modules/campaign";

import { getProperty } from "@/utils/object";

import Bus from "@/bus";
import TwoCol from "@/components/layout/TwoCol";
import Heading from "@/components/layout/Heading";
import CampaignUpdateForm from "@/components/forms/CampaignUpdateForm";
import FormButton, {
    ButtonVariants,
} from "@/components/forms/fields/FormButton";
import Alert from "@/components/ui/Alert";
import Prompt from "@/components/ui/Prompt";
import CampaignEventsTable from "@/components/display/campaign/CampaignEventsTable";
import ToggleCampaignStateButton from "@/components/display/campaign/ToggleCampaignStateButton";
import FormInput from "@/components/forms/fields/FormInput";
import FormSelect from "@/components/forms/fields/FormSelect";
import SlideOver from "@/components/ui/SlideOver";
import SimulationPreview from "@/components/display/campaign/SimulationPreview";
import ValueCard from "@/components/statistics/ValueCard";
import StatusBadge from "@/components/display/campaign/StatusBadge";
import ContentLoading from "@/components/ui/ContentLoading";
import ListItem from "@/components/ui/ListItem";

export default {
    name: "SimulationSingle",
    components: {
        ListItem,
        ContentLoading,
        StatusBadge,
        ValueCard,
        SimulationPreview,
        SlideOver,
        FormSelect,
        FormInput,
        ToggleCampaignStateButton,
        CampaignEventsTable,
        Prompt,
        Alert,
        FormButton,
        CampaignUpdateForm,
        Heading,
        TwoCol,
    },
    data: () => ({
        ButtonVariants,
        testLoading: false,
        campaignLoading: false,
        showSettingsPrompt: false,
        showLaunchPrompt: false,
        sendToCount: 0,
        status: "all",
        statuses: ["all", "open", "click"],
        showSimulationPreview: false,
        simulationPreview: null,
    }),
    computed: {
        /**
         * The user model
         * @type {Boolean}
         */
        isManager() {
            return this.$store.getters[
                AuthStoreNamespacedTypes.getters.IS_MANAGER
            ]();
        },
        campaignId() {
            return getProperty(this.$route.params, "id");
        },

        /**
         * @type {Boolean}
         */
        loading() {
            return this.$store.getters[
                CampaignStoreNamespacedTypes.getters.CAMPAIGNS_LOADING
            ]();
        },

        /**
         * @type {Object}
         */
        error() {
            return this.$store.getters[
                CampaignStoreNamespacedTypes.getters.CAMPAIGNS_ERROR
            ]();
        },

        /**
         * @type {Array}
         */
        campaign() {
            return this.$store.getters[
                CampaignStoreNamespacedTypes.getters.CAMPAIGN
            ]();
        },

        /**
         * @type {Object}
         */
        group() {
            return getProperty(this.campaign, "group", {
                name: "",
                employee_active_count: 0,
            });
        },

        /**
         * @type {Object}
         */
        recipientCount() {
            return getProperty(this.group, "employee_active_count", 0);
        },

        /**
         * @type {Boolean}
         */
        loadingEvents() {
            return this.$store.getters[
                CampaignStoreNamespacedTypes.getters.CAMPAIGNS_EVENTS_LOADING
            ]();
        },

        /**
         * @type {Object}
         */
        events() {
            return this.$store.getters[
                CampaignStoreNamespacedTypes.getters.CAMPAIGN_EVENTS
            ]();
        },

        /**
         * @type {Array}
         */
        eventRows() {
            return getProperty(this.events, "data", []);
        },

        /**
         * @type {Object}
         */
        eventMeta() {
            return getProperty(this.events, "meta", null);
        },

        /**
         * @type {string}
         */
        campaignName() {
            return getProperty(
                this.campaign,
                "name",
                this.$t("simulations.missing_name")
            );
        },

        /**
         * @type {string}
         */
        campaignStatus() {
            return getProperty(
                this.campaign,
                "status",
                this.$t("simulations.missing_status")
            );
        },

        /**
         * @return {string}
         */
        audience() {
            return getProperty(
                this.campaign,
                "group.name",
                this.$t("simulations.summary.missing_audience")
            );
        },

        /**
         * @return {string}
         */
        delivery() {
            let delivery = getProperty(this.campaign, "delivery", "one_off");

            return this.$t("simulations.summary.delivery_" + delivery);
        },

        /**
         * @type {Boolean}
         */
        isEditable() {
            return this.campaignStatus === "draft";
        },

        /**
         * @type {Boolean}
         */
        isRecurring() {
            return getProperty(this.campaign, "delivery") === "recurring";
        },

        /**
         * @type {Object}
         */
        campaignStats() {
            return getProperty(this.campaign, "report.data", {
                click_rate: 0,
                count: 0,
                open_rate: 0,
                click_count: 0,
                open_count: 0,
                send_count: 0,
                unique_click_count: 0,
                unique_open_count: 0,
            });
        },

        /**
         * @type {Number}
         */
        count() {
            return getProperty(this.campaignStats, "send_count", 0);
        },

        /**
         * @type {Number}
         */
        openRate() {
            return getProperty(this.campaignStats, "open_rate", 0);
        },

        /**
         * @type {Number}
         */
        clickRate() {
            return getProperty(this.campaignStats, "click_rate", 0);
        },
    },
    watch: {
        status() {
            this.handleRefresh();
        },
    },
    methods: {
        handleSaved() {
            this.$store
                .dispatch(
                    CampaignStoreNamespacedTypes.actions.GET_CAMPAIGN,
                    this.campaignId
                )
                .then(() => {
                    Bus.$emit("flash-message", {
                        text: this.$t("simulations.campaign_updated"),
                        type: "success",
                    });

                    this.showSettingsPrompt = false;
                });
        },
        send() {
            if (Number(this.sendToCount) !== Number(this.recipientCount)) {
                Bus.$emit("flash-message", {
                    text: this.$t("simulations.wrong_recipient_count"),
                    type: "error",
                });
                return;
            }

            this.campaignLoading = true;

            this.$store
                .dispatch(
                    CampaignStoreNamespacedTypes.actions.SEND,
                    this.campaignId
                )
                .then(() => {
                    Bus.$emit("flash-message", {
                        text: this.$t("simulations.campaign_sent"),
                        type: "success",
                    });
                    this.showLaunchPrompt = false;
                })
                .finally(() => {
                    this.campaignLoading = false;
                });
        },
        sendTest() {
            this.testLoading = true;

            this.$store
                .dispatch(
                    CampaignStoreNamespacedTypes.actions.SEND_TEST,
                    this.campaignId
                )
                .then(() => {
                    Bus.$emit("flash-message", {
                        text: this.$t("simulations.test_sent"),
                        type: "success",
                    });
                })
                .finally(() => {
                    this.testLoading = false;
                });
        },
        showPreview(event) {
            this.simulationPreview = event;
            this.showSimulationPreview = true;
        },
        handleRefresh(payload = {}) {
            if (this.status !== "all") {
                payload.status = this.status;
            }

            this.$store.dispatch(
                CampaignStoreNamespacedTypes.actions.GET_CAMPAIGN_EVENTS,
                {
                    campaign: this.campaignId,
                    query: payload,
                }
            );
        },
    },
    beforeCreate() {
        this.$store.commit(
            CampaignStoreNamespacedTypes.mutations.SET_CAMPAIGNS_LOADING,
            true
        );
        this.$store.commit(
            CampaignStoreNamespacedTypes.mutations.SET_CAMPAIGN_EVENTS,
            {
                data: [],
                meta: {
                    current_page: 1,
                    from: 0,
                    last_page: 1,
                    per_page: 10,
                    to: 10,
                    total: 0,
                },
            }
        );
    },
    mounted() {
        this.$store.dispatch(
            CampaignStoreNamespacedTypes.actions.GET_CAMPAIGN,
            this.campaignId
        );
    },
};
</script>
