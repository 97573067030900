<template>
    <DataTable
        :headers="headers"
        :rows="rows"
        :meta="meta"
        :loading="loading"
        :error="error"
        :emit="true"
        @emit="$emit('emit', $event)"
        @update="refresh"
    >
        <template v-slot:employee="{ item }">
            <div class="flex items-center">
                <Avatar
                    :image="item.employee.avatar"
                    :name="item.employee.full_name"
                />
                <div class="ml-3">
                    <div class="text-sm font-medium text-slate-900">
                        {{ item.employee.full_name }}
                    </div>
                    <div class="text-sm text-slate-500">
                        {{ item.employee.email || "No email found" }}
                    </div>
                </div>
            </div>
        </template>
        <template v-slot:status="{ item }">
            <EventStatusBadge :open="item.has_open" :click="item.has_click" />
        </template>
        <template v-slot:sent_at="{ item }">
            {{ item.sent_at | formatDateTime }}
        </template>
    </DataTable>
</template>

<script>
import { formatDateTime } from "@/utils/date";
import { initials } from "@/utils/filters";

import EventStatusBadge from "./EventStatusBadge";
import DataTableMixin from "@/components/tables/DataTableMixin";
import Avatar from "@/components/shared/Avatar";

export default {
    mixins: [DataTableMixin],
    components: {
        Avatar,
        EventStatusBadge,
    },
    filters: {
        /**
         * @type {String}
         */
        initials,

        /**
         * @type {String}
         */
        formatDateTime,
    },
    data: () => ({
        headers: [
            {
                title: "employee",
                key: "employee",
                sortable: false,
            },
            {
                title: "status",
                key: "status",
                sortable: false,
            },
            {
                title: "sent_at",
                key: "sent_at",
                sortable: true,
            },
        ],
    }),
};
</script>
