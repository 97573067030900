<template>
    <DashboardPage>
        <template>
            <AvgScoreCard
                :loading="statisticsLoading"
                :title="$t('statistics.resilience_score.title').toString()"
                :text="$t('statistics.resilience_score.text').toString()"
                :value="scoreValue"
                :max="100"
                :min="0"
                :unit="$t('statistics.ninety_days').toString()"
                to="dashboard.resilience"
                class="card"
            />
            <ValueCard
                :loading="statisticsLoading"
                :title="$t('statistics.training_coverage.title').toString()"
                :text="$t('statistics.training_coverage.text').toString()"
                :unit="$t('statistics.ninety_days').toString()"
                :value="trainingCoverageValue"
                :pct="true"
                :upper-threshold="8000"
                to="training.index"
                class="card"
            />
            <ValueCard
                v-if="hasFeature('app-simulations')"
                :loading="statisticsLoading"
                :title="$t('statistics.simulation_coverage.title').toString()"
                :text="$t('statistics.simulation_coverage.text').toString()"
                :unit="$t('statistics.ninety_days').toString()"
                :value="simulationCoverageValue"
                :pct="true"
                :upper-threshold="9000"
                to="campaign.index"
                class="card"
            />
            <ValueCard
                :loading="statisticsLoading"
                :title="$t('statistics.overdue.title').toString()"
                :text="$t('statistics.overdue.text').toString()"
                :unit="$t('statistics.overdue.unit').toString()"
                :value="deadlineValue"
                to="training.deadlines"
                class="card"
            />
            <BarChartCard
                :loading="statisticsLoading"
                :title="$t('statistics.activity.title').toString()"
                :unit="$t('statistics.ninety_days').toString()"
                :data="activeEmployeesGraph"
                class="card col-span-1 md:col-span-2"
            />
            <GroupChartCard
                :loading="statisticsLoading"
                :title="$t('statistics.employees.title').toString()"
                identifier="employees"
                :data="employees"
                :rows="3"
                class="card"
            />
            <GroupChartCard
                :loading="statisticsLoading"
                :title="$t('statistics.onboarded.title').toString()"
                identifier="onboarded"
                :data="onboarding"
                :rows="2"
                class="card"
            />
        </template>
    </DashboardPage>
</template>

<script>
import { StatisticsStoreNamespacedTypes } from "@/store/modules/statistics";

import { getProperty } from "@/utils/object";

import GroupChartCard from "@/components/statistics/GroupChartCard";
import DashboardPage from "@/components/layout/DashboardPage";
import FeatureFlagMixin from "@/mixins/FeatureFlagMixin";
import AvgScoreCard from "@/components/statistics/AvgScoreCard";
import ValueCard from "@/components/statistics/ValueCard";
import BarChartCard from "@/components/statistics/BarChartCard";

export default {
    name: "DashboardIndex",
    mixins: [FeatureFlagMixin],
    components: {
        BarChartCard,
        ValueCard,
        AvgScoreCard,
        DashboardPage,
        GroupChartCard,
    },
    data: () => ({
        statisticsLoading: true,
    }),
    computed: {
        /**
         * @type {Object}
         */
        error() {
            return this.$store.getters[
                StatisticsStoreNamespacedTypes.getters.ERROR
            ]();
        },
        /**
         * @type {Object}
         */
        statistics() {
            return this.$store.getters[
                StatisticsStoreNamespacedTypes.getters.STATISTICS
            ]();
        },
        score() {
            return getProperty(this.statistics, "score", {
                avg: 0,
                max: 0,
                min: 0,
            });
        },
        scoreValue() {
            return getProperty(this.score, "avg", 0);
        },
        scoreMax() {
            return getProperty(this.score, "max", 0);
        },
        scoreMin() {
            return getProperty(this.score, "min", 0);
        },
        trainingCoverage() {
            return getProperty(this.statistics, "training_coverage", {
                total: 0,
                active: 0,
                pct: 0,
            });
        },
        trainingCoverageValue() {
            return getProperty(this.trainingCoverage, "pct", 0);
        },
        simulationCoverage() {
            return getProperty(this.statistics, "simulation_coverage", {
                total: 0,
                active: 0,
                pct: 0,
            });
        },
        simulationCoverageValue() {
            return getProperty(this.simulationCoverage, "pct", 0);
        },

        /**
         * @type {Object}
         */
        employees() {
            return getProperty(this.statistics, "employees", {
                count: 0,
                groups: [
                    {
                        label: "beginner",
                        count: 0,
                        pct: 0,
                        color: "indigo-200",
                    },
                    {
                        label: "intermediate",
                        count: 0,
                        pct: 0,
                        color: "indigo-400",
                    },
                    {
                        label: "expert",
                        count: 0,
                        pct: 0,
                        color: "indigo-600",
                    },
                ],
            });
        },
        activeEmployeesGraph() {
            return getProperty(this.statistics, "active_employees.graph", []);
        },

        /**
         * @type {Object}
         */
        onboarding() {
            return getProperty(this.statistics, "onboarding", {
                count: 0,
                total_count: 0,
                groups: [
                    {
                        label: "not_onboarded",
                        count: 0,
                        pct: 0,
                        color: "error-dark",
                    },
                    {
                        label: "onboarded",
                        count: 0,
                        pct: 0,
                        color: "success-dark",
                    },
                ],
            });
        },

        /**
         * @type {Object}
         */
        deadlines() {
            return getProperty(this.statistics, "deadlines", {
                total: 0,
            });
        },
        deadlineValue() {
            return getProperty(this.deadlines, "total", 0);
        },
    },
    methods: {
        getDashboardStatistics() {
            this.statisticsLoading = true;
            this.$store
                .dispatch(
                    StatisticsStoreNamespacedTypes.actions
                        .GET_DASHBOARD_STATISTICS
                )
                .then(() => {
                    this.statisticsLoading = false;
                });
        },
    },
    beforeCreate() {
        this.$store.commit(
            StatisticsStoreNamespacedTypes.mutations.SET_ERROR,
            null
        );
    },
    mounted() {
        this.getDashboardStatistics();
    },
};
</script>
