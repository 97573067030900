var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(_vm.loading)?_c('GroupLoadingSkeleton',{attrs:{"count":_vm.rows}}):_c('div',{staticClass:"flex flex-col p-4"},[(_vm.hasGroupData)?_c('div',{staticClass:"flex h-4 w-full space-x-2 overflow-hidden"},_vm._l((_vm.groups),function(group){return _c('GroupChartItem',{key:group.label + '-badge',attrs:{"group":group}})}),1):_c('div',{staticClass:"flex h-4 w-full rounded bg-slate-100"}),_c('div',{staticClass:"-mx-4 mt-4 flex flex-row items-stretch space-x-1 divide-x divide-slate-200"},_vm._l((_vm.groups),function(group){return _c('div',{key:group.label,staticClass:"flex flex-1 flex-col items-center justify-center px-2"},[_c('p',{staticClass:"mb-2 text-sm"},[_c('span',{staticClass:"mr-2 inline-block h-2 w-2 rounded-full",class:`bg-${group.color}`}),_c('span',{staticClass:"align-middle capitalize",domProps:{"textContent":_vm._s(
                            _vm.$t(
                                'statistics.' +
                                    _vm.identifier +
                                    '.' +
                                    group.label
                            )
                        )}})]),_c('p',{staticClass:"text-lg font-medium"},[_vm._v(" "+_vm._s(_vm._f("formatPercentRound")(group.pct || 0))+"% ")])])}),0)])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }