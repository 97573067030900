<template>
    <div class="relative">
        <DynamicTabs :tabs="tabs" class="mb-4" />
        <Alert :error="error" class="mb-4" />
        <slot />
    </div>
</template>

<script>
import { WorkspaceStoreNamespacedTypes } from "@/store/modules/workspace";

import Alert from "@/components/ui/Alert";
import DynamicTabs from "@/components/tabs/DynamicTabs";
import FeatureFlagMixin from "@/mixins/FeatureFlagMixin";

export default {
    name: "WorkspaceSettingsPage",
    mixins: [FeatureFlagMixin],
    components: {
        DynamicTabs,
        Alert,
    },
    computed: {
        /**
         * @type {Array}
         */
        workspace() {
            return this.$store.getters[
                WorkspaceStoreNamespacedTypes.getters.WORKSPACE
            ]();
        },

        /**
         * @type {Array}
         */
        tabs() {
            let tabs = [
                {
                    text: "settings",
                    route: {
                        name: "settings",
                    },
                },
            ];

            if (this.hasFeature("app-threats")) {
                tabs.push({
                    text: "threats",
                    route: {
                        name: "settings.threats",
                    },
                });
            }

            if (this.hasFeature("app-simulations")) {
                tabs.push({
                    text: "simulations",
                    route: {
                        name: "settings.simulations",
                    },
                });
            }

            tabs.push(
                {
                    text: "export",
                    route: { name: "settings.export" },
                },
                {
                    text: "import",
                    route: { name: "settings.import" },
                },
                {
                    text: "integrations",
                    route: {
                        name: "settings.integrations",
                    },
                },
                {
                    text: "domains",
                    route: {
                        name: "settings.domains",
                    },
                }
            );

            return tabs;
        },

        /**
         * The error state of the API request.
         * @type {Object}
         */
        error() {
            return this.$store.getters[
                WorkspaceStoreNamespacedTypes.getters.WORKSPACE_ERROR
            ]();
        },
    },
    mounted() {
        this.$store.dispatch(
            WorkspaceStoreNamespacedTypes.actions.GET_WORKSPACE,
            {
                force: true,
            }
        );
    },
};
</script>
