<template>
    <div :class="{ 'relative py-12': loading }">
        <LoadingSpinner v-if="loading" />
        <div v-else>
            <slot />
        </div>
    </div>
</template>

<script>
import LoadingSpinner from "./LoadingSpinner";

export default {
    name: "ContentLoading",
    components: { LoadingSpinner },
    props: {
        loading: {
            type: Boolean,
            default: true,
        },
    },
};
</script>
