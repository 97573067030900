var render = function render(){var _vm=this,_c=_vm._self._c;return _c('ValidationProvider',{staticClass:"form-field form-field-toggle",attrs:{"tag":"div","vid":_vm.vid,"rules":_vm.rules,"name":_vm.name || _vm.label},scopedSlots:_vm._u([{key:"default",fn:function({ errors, ariaMsg }){return [_c('button',{staticClass:"relative mr-2.5 inline-flex h-6 w-10 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent p-0.5 transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2",class:{
            'bg-slate-600': _vm.disabled,
            'bg-indigo-600': _vm.innerValue && !_vm.disabled,
            'bg-slate-200': !_vm.innerValue && !_vm.disabled,
        },attrs:{"disabled":_vm.disabled,"type":"button"},on:{"click":function($event){_vm.innerValue = !_vm.innerValue}}},[_c('span',{staticClass:"sr-only"},[_vm._v(_vm._s(_vm.label || _vm.name))]),_c('span',{staticClass:"pointer-events-none inline-block h-4 w-4 translate-x-0 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out",class:{
                'translate-x-4': _vm.innerValue,
                'translate-x-0': !_vm.innerValue,
            },attrs:{"aria-hidden":"true"}})]),_c('div',{on:{"click":_vm.toggle}},[_c('p',{staticClass:"mb-1 block cursor-pointer text-sm font-medium text-slate-900"},[_vm._v(" "+_vm._s(_vm.label || _vm.name)+" ")]),(errors[0])?_c('span',_vm._b({staticClass:"block text-sm text-red-600"},'span',ariaMsg,false),[_vm._v(_vm._s(errors[0]))]):(_vm.help)?_c('p',{staticClass:"block cursor-pointer text-sm text-slate-500"},[_vm._v(" "+_vm._s(_vm.help)+" ")]):_vm._e()])]}}])})
}
var staticRenderFns = []

export { render, staticRenderFns }