<template>
    <div
        class="flex h-96 flex-col items-center justify-center rounded-xl border bg-slate-50 p-4"
    >
        <Icon
            :icon="IconEnums.ERROR"
            class="mx-auto h-12 w-12 text-slate-400"
        />
        <h2 class="mt-3 text-base font-semibold leading-6 text-slate-900">
            {{ $t("threats.analysis.not_available.heading") }}
        </h2>
        <p class="text-sm text-slate-500">
            {{ $t("threats.analysis.not_available.text") }}
        </p>
    </div>
</template>

<script>
import Icon from "@/components/icons/BaseIcon";
import { IconEnums } from "@/utils/icons";

export default {
    name: "MissingAnalysisPlaceholder",
    components: { Icon },
    data: () => ({
        IconEnums,
    }),
};
</script>
